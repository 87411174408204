// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Css from "@emotion/css";
import * as Core from "@material-ui/core";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as Assets$DvmAdminFrontendFr from "../../../assets/Assets.bs.js";
import * as Constants$DvmAdminFrontendFr from "../../Constants.bs.js";
import * as App_Styles$DvmAdminFrontendFr from "../../App_Styles.bs.js";
import * as Route_Paths$DvmAdminFrontendFr from "../../../route/Route_Paths.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_TopMenu_Item$DvmAdminFrontendFr from "./App_TopMenu_Item.bs.js";
import * as App_TopMenu_User$DvmAdminFrontendFr from "./App_TopMenu_User.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../context/user/User_Types_Utils.bs.js";
import * as Utils_Authentication$DvmAdminFrontendFr from "../../utils/Utils_Authentication.bs.js";
import * as Contract_Errors_Menu_Icon$DvmAdminFrontendFr from "../../../pages/upload/contractErrors/Contract_Errors_Menu_Icon.bs.js";
import * as Energy_New_Types_Menu_Icon$DvmAdminFrontendFr from "../../../pages/energy/Energy_New_Types_Menu_Icon.bs.js";
import * as App_TopMenu_Calculations_Banner$DvmAdminFrontendFr from "./App_TopMenu_Calculations_Banner.bs.js";
import * as Model_Version_NewVersions_Menu_Icon$DvmAdminFrontendFr from "../../../pages/modelVersionList/Model_Version_NewVersions_Menu_Icon.bs.js";
import * as Model_Version_Missing_Motorization_Menu_Icon$DvmAdminFrontendFr from "../../../pages/modelVersionList/Model_Version_Missing_Motorization_Menu_Icon.bs.js";

var topMenu = Css.css({
      backgroundColor: Theme_Colors$DvmAdminFrontendFr.black,
      boxShadow: "none"
    });

var toolBar = Css.css({
      height: Constants$DvmAdminFrontendFr.topMenuHeight
    });

var appName = Css.css({
      fontSize: "2rem",
      paddingLeft: "1.6rem",
      whiteSpace: "nowrap"
    });

var renaultLogo = Css.css({
      borderRight: "0.1rem solid black",
      height: "3.2rem",
      paddingRight: "2rem",
      width: "auto"
    });

var paddingLogo = Css.css({
      padding: "3.6rem"
    });

var paddingRight = Css.css({
      paddingRight: "1rem"
    });

var bannersBox = Css.css({
      position: "fixed",
      top: Constants$DvmAdminFrontendFr.topMenuHeight,
      width: "100%"
    });

var Styles = {
  topMenu: topMenu,
  toolBar: toolBar,
  appName: appName,
  renaultLogo: renaultLogo,
  paddingLogo: paddingLogo,
  paddingRight: paddingRight,
  bannersBox: bannersBox
};

function App_TopMenu(Props) {
  var hideFeaturesOpt = Props.hideFeatures;
  var hideFeatures = hideFeaturesOpt !== undefined ? hideFeaturesOpt : false;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var selectedRole = match[0].selectedRole;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match$1 = Belt_List.size(url.path);
  var path = match$1 !== 0 ? Belt_List.reduce(url.path, "", (function (a, b) {
            return a + "/" + b;
          })) : "/";
  var menuRoutes = React.useMemo((function () {
          return function (param) {
            return Route_Paths$DvmAdminFrontendFr.getMenuRoutes(selectedRole, param);
          };
        }), [selectedRole]);
  return React.createElement(Core.AppBar, {
              children: null,
              className: topMenu,
              position: "sticky"
            }, React.createElement(Core.Box, {
                  className: toolBar,
                  children: null,
                  alignItems: "stretch",
                  display: "flex"
                }, React.createElement(Core.Box, {
                      className: paddingLogo,
                      children: null,
                      alignItems: "center",
                      display: "flex"
                    }, React.createElement(Assets$DvmAdminFrontendFr.RenaultGroupLogo.make, {
                          className: renaultLogo
                        }), React.createElement("div", {
                          className: appName
                        }, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                              className: App_Styles$DvmAdminFrontendFr.book,
                              children: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.appNameShort),
                              variant: "h3"
                            }))), hideFeatures ? null : React.createElement(React.Fragment, undefined, selectedRole === /* NoRole */9 ? null : Belt_Array.mapWithIndex(Curry._1(menuRoutes, intl), (function (index, item) {
                                return React.createElement(App_TopMenu_Item$DvmAdminFrontendFr.make, {
                                            path: path,
                                            route: item,
                                            key: String(index)
                                          });
                              })), React.createElement(Core.Box, {
                            flexGrow: 1
                          }), User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole) ? React.createElement(Core.Box, {
                              className: paddingRight,
                              children: null,
                              alignItems: "center",
                              display: "flex",
                              gridColumnGap: "10px"
                            }, React.createElement(Energy_New_Types_Menu_Icon$DvmAdminFrontendFr.make, {}), React.createElement(Model_Version_NewVersions_Menu_Icon$DvmAdminFrontendFr.make, {}), React.createElement(Model_Version_Missing_Motorization_Menu_Icon$DvmAdminFrontendFr.make, {}), React.createElement(Contract_Errors_Menu_Icon$DvmAdminFrontendFr.make, {})) : null, selectedRole === /* NoRole */9 ? null : React.createElement(Core.Box, {
                              children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                    className: paddingRight,
                                    children: intl.formatMessage(User_Types_Utils$DvmAdminFrontendFr.isAdminDealer(selectedRole) ? Utils_Authentication$DvmAdminFrontendFr.getRoleTranslation(/* Dealer */7) : Utils_Authentication$DvmAdminFrontendFr.getRoleTranslation(selectedRole))
                                  }),
                              alignItems: "center",
                              display: "flex"
                            }), React.createElement(App_TopMenu_User$DvmAdminFrontendFr.make, {}))), React.createElement(Core.Box, {
                  className: bannersBox,
                  children: React.createElement(App_TopMenu_Calculations_Banner$DvmAdminFrontendFr.make, {}),
                  display: "flex",
                  flexDirection: "column"
                }));
}

var make = App_TopMenu;

export {
  Styles ,
  make ,
}
/* topMenu Not a pure module */
