// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Grid$Mui from "rescript-material-ui/src/Grid.bs.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Button.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../common/components/App_OverlaySpinner.bs.js";
import * as App_RadioFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_RadioFormInput.bs.js";
import * as App_SelectFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_SelectFormInput.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Order_Detail_Reselling_Api$DvmAdminFrontendFr from "./Order_Detail_Reselling_Api.bs.js";
import * as Order_Detail_Reselling_Router$DvmAdminFrontendFr from "./Order_Detail_Reselling_Router.bs.js";
import * as Order_Detail_Reselling_Create_Form$DvmAdminFrontendFr from "./Order_Detail_Reselling_Create_Form.bs.js";
import * as Order_Detail_Reselling_Confirm_Modal$DvmAdminFrontendFr from "./Order_Detail_Reselling_Confirm_Modal.bs.js";

function Order_Detail_Reselling_Create_Modal(Props) {
  var onClose = Props.onClose;
  var orderId = Props.orderId;
  var order = Props.order;
  var fromParcExitEdit = Props.fromParcExitEdit;
  var reloadPage = Props.reloadPage;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match$2[1];
  var result = match$2[0];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResultDisableReselling = match$3[1];
  var resultDisableReselling = match$3[0];
  var match$4 = React.useState(function () {
        return null;
      });
  var setConfirmModal = match$4[1];
  React.useEffect((function () {
          if (typeof result === "number") {
            result === /* NotStarted */0;
          } else if (result.TAG === /* Ok */0) {
            Belt_Option.mapWithDefault(result._0.response, undefined, Order_Detail_Reselling_Router$DvmAdminFrontendFr.routeToResellPage);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: result._0
                      }
                    }));
          }
        }), [result]);
  React.useEffect((function () {
          if (typeof resultDisableReselling === "number") {
            resultDisableReselling === /* NotStarted */0;
          } else if (resultDisableReselling.TAG === /* Ok */0) {
            Belt_Option.mapWithDefault(reloadPage, undefined, (function (x) {
                    Curry._1(x, undefined);
                  }));
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: resultDisableReselling._0
                      }
                    }));
          }
        }), [resultDisableReselling]);
  var form = Order_Detail_Reselling_Create_Form$DvmAdminFrontendFr.Form.useForm(Order_Detail_Reselling_Create_Form$DvmAdminFrontendFr.initialState, (function (output, submissionCallbacks) {
          if (output.createReselling) {
            return Order_Detail_Reselling_Api$DvmAdminFrontendFr.postResellingOrder(userManager, setResult, orderId, submissionCallbacks, selectedRole, output.resellingOrderType);
          } else {
            return Order_Detail_Reselling_Api$DvmAdminFrontendFr.disableReselling(userManager, setResultDisableReselling, orderId, submissionCallbacks, selectedRole);
          }
        }));
  var tmp;
  if (form.input.createReselling) {
    var tmp$1 = {
      label: Messages_Common$DvmAdminFrontendFr.orderType,
      options: Belt_Option.mapWithDefault(order.orderType, /* [] */0, (function (x) {
              switch (x) {
                case /* VE */2 :
                    return {
                            hd: {
                              label: {
                                TAG: /* Message */0,
                                _0: Messages_Orders_Detail$DvmAdminFrontendFr.resellingES
                              },
                              value: "ES"
                            },
                            tl: {
                              hd: {
                                label: {
                                  TAG: /* Message */0,
                                  _0: Messages_Orders_Detail$DvmAdminFrontendFr.resellingEP
                                },
                                value: "EP"
                              },
                              tl: /* [] */0
                            }
                          };
                case /* RR */4 :
                case /* RC */5 :
                    break;
                case /* SO */0 :
                case /* CL */1 :
                case /* VD */3 :
                case /* PG */6 :
                case /* EP */7 :
                case /* ES */8 :
                case /* RP */9 :
                case /* RS */10 :
                case /* NOTSET */11 :
                    return /* [] */0;
                
              }
              return {
                      hd: {
                        label: {
                          TAG: /* Message */0,
                          _0: Messages_Orders_Detail$DvmAdminFrontendFr.resellingRS
                        },
                        value: "RS"
                      },
                      tl: {
                        hd: {
                          label: {
                            TAG: /* Message */0,
                            _0: Messages_Orders_Detail$DvmAdminFrontendFr.resellingRP
                          },
                          value: "RP"
                        },
                        tl: /* [] */0
                      }
                    };
            })),
      value: {
        TAG: /* Single */0,
        _0: {
          value: form.input.resellingOrderType,
          onChange: Curry._1(form.updateResellingOrderType, (function (input, resellingOrderType) {
                  return {
                          resellingOrderType: resellingOrderType,
                          createReselling: input.createReselling
                        };
                }))
        }
      },
      id: "resellingOrderType",
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength,
      required: /* OnlyLabel */1
    };
    if (form.resellingOrderTypeResult !== undefined) {
      tmp$1.validationResult = Caml_option.valFromOption(form.resellingOrderTypeResult);
    }
    tmp = React.createElement(Core.Grid, {
          children: React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, tmp$1),
          item: true,
          xs: Grid$Mui.Xs[12]
        });
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(App_Modal$DvmAdminFrontendFr.make, {
                  open: true,
                  onClose: onClose,
                  title: intl.formatMessage(Messages_Orders_Detail$DvmAdminFrontendFr.resellingModalTitle),
                  minWidth: "500px",
                  children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                        show: App_Types_Result$DvmAdminFrontendFr.isPending(result) || App_Types_Result$DvmAdminFrontendFr.isPending(resultDisableReselling),
                        children: React.createElement("form", {
                              onSubmit: (function ($$event) {
                                  $$event.preventDefault();
                                })
                            }, React.createElement(Core.Grid, {
                                  children: null,
                                  container: true,
                                  direction: "column",
                                  justifyContent: "space-between",
                                  spacing: 2
                                }, fromParcExitEdit ? React.createElement(Core.Grid, {
                                        children: React.createElement(App_RadioFormInput$DvmAdminFrontendFr.make, {
                                              value: form.input.createReselling ? "yes" : "no",
                                              options: {
                                                hd: {
                                                  label: {
                                                    TAG: /* Message */0,
                                                    _0: Messages_Orders_Detail$DvmAdminFrontendFr.resellingRecipient
                                                  },
                                                  value: "yes"
                                                },
                                                tl: {
                                                  hd: {
                                                    label: {
                                                      TAG: /* Message */0,
                                                      _0: Messages_Orders_Detail$DvmAdminFrontendFr.enterExitParcNoResell
                                                    },
                                                    value: "no"
                                                  },
                                                  tl: /* [] */0
                                                }
                                              },
                                              onChange: (function (val) {
                                                  Curry._2(form.updateCreateReselling, (function (input, createReselling) {
                                                          return {
                                                                  resellingOrderType: input.resellingOrderType,
                                                                  createReselling: createReselling
                                                                };
                                                        }), val === "yes");
                                                }),
                                              className: App_Styles_Common$DvmAdminFrontendFr.Inputs.fullLength
                                            }),
                                        item: true,
                                        xs: Grid$Mui.Xs[12],
                                        style: {
                                          paddingBottom: "20px"
                                        }
                                      }) : null, React.createElement(Core.Grid, {
                                      children: tmp,
                                      container: true,
                                      justifyContent: "space-between",
                                      spacing: 2,
                                      xs: Grid$Mui.Xs[12]
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                            onClick: (function (param) {
                                                Curry._1(setConfirmModal, (function (param) {
                                                        return React.createElement(Order_Detail_Reselling_Confirm_Modal$DvmAdminFrontendFr.make, {
                                                                    onClose: (function (param) {
                                                                        Curry._1(setConfirmModal, (function (param) {
                                                                                return null;
                                                                              }));
                                                                      }),
                                                                    onOk: (function (param) {
                                                                        Curry._1(setConfirmModal, (function (param) {
                                                                                return null;
                                                                              }));
                                                                        Curry._1(form.submit, undefined);
                                                                      }),
                                                                    createReselling: form.input.createReselling
                                                                  });
                                                      }));
                                              }),
                                            label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                            color: "primary",
                                            size: "medium",
                                            style: {
                                              width: "100%"
                                            }
                                          }),
                                      item: true,
                                      xs: Grid$Mui.Xs[12]
                                    })))
                      })
                }), match$4[0]);
}

var Form;

var Api;

var InputStyles;

var make = Order_Detail_Reselling_Create_Modal;

export {
  Form ,
  Api ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
