// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Css from "@emotion/css";
import * as Webapi__Dom__Document from "rescript-webapi/src/Webapi/Dom/Webapi__Dom__Document.bs.js";
import * as EnvVar$DvmAdminFrontendFr from "../bindings/global/EnvVar.bs.js";
import * as Constants$DvmAdminFrontendFr from "../common/Constants.bs.js";
import * as App_TopMenu$DvmAdminFrontendFr from "../common/components/topMenu/App_TopMenu.bs.js";
import * as Route_Switch$DvmAdminFrontendFr from "../route/Route_Switch.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../common/App_Typography.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../context/user/User_Types_Utils.bs.js";

var mainElementStyle = Css.css({
      padding: "55px 100px 25px 100px"
    });

function preprodInfoStyle(whatCountry) {
  var match = whatCountry ? [
      "#AD1519",
      "#FABD00",
      "#AD1519"
    ] : [
      "#ed2939",
      "#ffffff",
      "#002395"
    ];
  var midColor = match[1];
  var topColor = match[0];
  return Css.css({
              background: "linear-gradient(to bottom, " + topColor + ", " + topColor + " 33.33%, " + midColor + " 33.33%, " + midColor + " 66.66%, " + match[2] + " 66.66%)",
              backgroundColor: "#48cdc6",
              fontSize: "14px",
              fontWeight: "bold",
              height: "calc(100vh - " + Constants$DvmAdminFrontendFr.topMenuHeight + ")",
              maxWidth: "20px",
              position: "fixed",
              textAlign: "center",
              top: Constants$DvmAdminFrontendFr.topMenuHeight,
              writingMode: "tb-rl",
              transform: "rotate(180deg)"
            });
}

function App_Main(Props) {
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var selectedRole = match[0].selectedRole;
  var whatCountry = function (param) {
    return User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole);
  };
  React.useEffect((function () {
          var match = User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole);
          var title = match ? "DVME ES NV" : "DVME FR NV";
          Belt_Option.mapWithDefault(Webapi__Dom__Document.asHtmlDocument(document), undefined, (function (x) {
                  x.title = title;
                }));
        }), [whatCountry]);
  var tmp;
  if (EnvVar$DvmAdminFrontendFr.currentEnvironmentPreprod) {
    var match$1 = User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole);
    tmp = React.createElement("div", {
          className: preprodInfoStyle(User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole))
        }, match$1 ? "PRE-PRODUCTION (ES)" : "PRE-PRODUCTION (FR)");
  } else {
    tmp = null;
  }
  return React.createElement("div", undefined, React.createElement(App_TopMenu$DvmAdminFrontendFr.make, {}), React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                  style: {
                    paddingTop: "10px",
                    position: "fixed",
                    right: "10px",
                    zIndex: "1100"
                  },
                  children: "Restricted B",
                  variant: "body"
                }), tmp, React.createElement("main", {
                  className: mainElementStyle
                }, React.createElement(Route_Switch$DvmAdminFrontendFr.make, {})));
}

var make = App_Main;

export {
  mainElementStyle ,
  preprodInfoStyle ,
  make ,
}
/* mainElementStyle Not a pure module */
