// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../../../intl/Intl_Utils.bs.js";
import * as App_LabeledText$DvmAdminFrontendFr from "../../../../../../common/components/App_LabeledText.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Common.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../../../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Order_Detail_Correct_Box$DvmAdminFrontendFr from "../Order_Detail_Correct_Box.bs.js";

function Order_Detail_Correct_Vehicle_Content(Props) {
  var usedCarDetail = Props.usedCarDetail;
  var color = Props.color;
  var title = Props.title;
  var newestUndeployedCorrection = Props.newestUndeployedCorrection;
  var form = Props.form;
  var intl = ReactIntl.useIntl();
  var checkEmptyString = function (string) {
    return Belt_Option.mapWithDefault(string, undefined, (function (x) {
                  if (x.length > 0) {
                    return x;
                  }
                  
                }));
  };
  var usedCarDetail$1 = Belt_Option.getWithDefault(usedCarDetail, {
        policeNumber: undefined,
        registrationNumber: undefined,
        registrationDate: undefined,
        brand: undefined,
        model: undefined,
        version: undefined,
        firstReleaseDate: undefined,
        vin: undefined,
        value: undefined,
        estimatedValue: undefined,
        endOfLife: undefined
      });
  var match = Belt_Option.mapWithDefault(newestUndeployedCorrection, [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      ], (function (x) {
          return [
                  Belt_Option.mapWithDefault(checkEmptyString(x.usedCarPoliceNumber), "", (function (x) {
                          if (x !== Belt_Option.getWithDefault(usedCarDetail$1.policeNumber, "")) {
                            return " (" + x + ")";
                          } else {
                            return "";
                          }
                        })),
                  Belt_Option.mapWithDefault(x.usedCarLastRegistrationDate, "", (function (x) {
                          if (ReDate.isSameDay(x, Belt_Option.getWithDefault(usedCarDetail$1.registrationDate, new Date(0.0)))) {
                            return "";
                          } else {
                            return " (" + Intl_Utils$DvmAdminFrontendFr.formatDate(intl, x) + ")";
                          }
                        })),
                  Belt_Option.mapWithDefault(checkEmptyString(x.usedCarRegistrationID), "", (function (x) {
                          if (x !== Belt_Option.getWithDefault(usedCarDetail$1.registrationNumber, "")) {
                            return " (" + x + ")";
                          } else {
                            return "";
                          }
                        })),
                  Belt_Option.mapWithDefault(x.usedCarFirstReleaseDate, "", (function (x) {
                          if (ReDate.isSameDay(x, Belt_Option.getWithDefault(usedCarDetail$1.firstReleaseDate, new Date(0.0)))) {
                            return "";
                          } else {
                            return " (" + Intl_Utils$DvmAdminFrontendFr.formatDate(intl, x) + ")";
                          }
                        })),
                  Belt_Option.mapWithDefault(checkEmptyString(x.brandUsedCar), "", (function (x) {
                          if (x !== Belt_Option.getWithDefault(usedCarDetail$1.brand, "")) {
                            return " (" + x + ")";
                          } else {
                            return "";
                          }
                        })),
                  Belt_Option.mapWithDefault(checkEmptyString(x.modelUsedCar), "", (function (x) {
                          if (x !== Belt_Option.getWithDefault(usedCarDetail$1.model, "")) {
                            return " (" + x + ")";
                          } else {
                            return "";
                          }
                        })),
                  Belt_Option.mapWithDefault(checkEmptyString(x.versionUsedCar), "", (function (x) {
                          if (x !== Belt_Option.getWithDefault(usedCarDetail$1.version, "")) {
                            return " (" + x + ")";
                          } else {
                            return "";
                          }
                        })),
                  Belt_Option.mapWithDefault(checkEmptyString(x.vinUsedCar), "", (function (x) {
                          if (x !== Belt_Option.getWithDefault(usedCarDetail$1.vin, "")) {
                            return " (" + x + ")";
                          } else {
                            return "";
                          }
                        })),
                  Belt_Option.mapWithDefault(x.realTradeAmount, "", (function (x) {
                          if (x !== Belt_Option.getWithDefault(usedCarDetail$1.value, 0.0)) {
                            return " (" + Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, x) + ")";
                          } else {
                            return "";
                          }
                        })),
                  Belt_Option.mapWithDefault(x.estimatedTradeAmount, "", (function (x) {
                          if (x !== Belt_Option.getWithDefault(usedCarDetail$1.estimatedValue, 0.0)) {
                            return " (" + Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, x) + ")";
                          } else {
                            return "";
                          }
                        })),
                  Belt_Option.mapWithDefault(x.scrapyardIndicator, "", (function (x) {
                          if (x !== Belt_Option.getWithDefault(usedCarDetail$1.endOfLife, false)) {
                            return " (" + intl.formatMessage(x ? Messages_Common$DvmAdminFrontendFr.yes : Messages_Common$DvmAdminFrontendFr.no) + ")";
                          } else {
                            return "";
                          }
                        }))
                ];
        }));
  var match$1 = form.input.orderType;
  var tmp;
  var exit = 0;
  switch (match$1) {
    case /* SO */0 :
    case /* ES */8 :
    case /* RS */10 :
        tmp = [];
        break;
    case /* CL */1 :
    case /* VE */2 :
    case /* VD */3 :
    case /* RR */4 :
    case /* RC */5 :
    case /* PG */6 :
    case /* EP */7 :
    case /* RP */9 :
    case /* NOTSET */11 :
        exit = 1;
        break;
    
  }
  if (exit === 1) {
    tmp = [
      React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
            label: Messages_Orders_Detail$DvmAdminFrontendFr.valueVO,
            children: Belt_Option.mapWithDefault(usedCarDetail$1.value, "-", (function (eta) {
                    return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, eta);
                  })) + match[8]
          }),
      React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
            label: Messages_Orders_Detail$DvmAdminFrontendFr.estimatedValueVO,
            children: Belt_Option.mapWithDefault(usedCarDetail$1.estimatedValue, "-", (function (eta) {
                    return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, eta);
                  })) + match[9]
          }),
      React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
            label: Messages_Orders_Detail$DvmAdminFrontendFr.endOfLifeVO,
            children: Belt_Option.mapWithDefault(usedCarDetail$1.endOfLife, "-", (function (x) {
                    return intl.formatMessage(x ? Messages_Common$DvmAdminFrontendFr.yes : Messages_Common$DvmAdminFrontendFr.no);
                  })) + match[10]
          })
    ];
  }
  return React.createElement(Order_Detail_Correct_Box$DvmAdminFrontendFr.make, {
              content: Belt_Array.concat([
                    React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                          label: Messages_Orders_Detail$DvmAdminFrontendFr.reprPoliceNumber,
                          children: Belt_Option.getWithDefault(usedCarDetail$1.policeNumber, "-") + match[0]
                        }),
                    React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                          label: Messages_Common$DvmAdminFrontendFr.registrationVO,
                          children: Belt_Option.getWithDefault(usedCarDetail$1.registrationNumber, "-") + match[2]
                        }),
                    React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                          label: Messages_Orders_Detail$DvmAdminFrontendFr.registrationDate,
                          children: Belt_Option.mapWithDefault(usedCarDetail$1.registrationDate, "-", (function (param) {
                                  return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                })) + match[1]
                        }),
                    React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                          label: Messages_Orders_Detail$DvmAdminFrontendFr.reprDatePremiere,
                          children: Belt_Option.mapWithDefault(usedCarDetail$1.firstReleaseDate, "-", (function (param) {
                                  return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                })) + match[3]
                        }),
                    React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                          label: Messages_Common$DvmAdminFrontendFr.brandVO,
                          children: Belt_Option.getWithDefault(usedCarDetail$1.brand, "-") + match[4]
                        }),
                    React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                          label: Messages_Common$DvmAdminFrontendFr.modelVO,
                          children: Belt_Option.getWithDefault(usedCarDetail$1.model, "-") + match[5]
                        }),
                    React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                          label: Messages_Common$DvmAdminFrontendFr.versionVO,
                          children: Belt_Option.getWithDefault(usedCarDetail$1.version, "-") + match[6]
                        }),
                    React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                          label: Messages_Common$DvmAdminFrontendFr.vinVO,
                          children: Belt_Option.getWithDefault(usedCarDetail$1.vin, "-") + match[7]
                        })
                  ], tmp),
              color: color,
              title: title
            });
}

var make = Order_Detail_Correct_Vehicle_Content;

export {
  make ,
}
/* react Not a pure module */
