// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../common/components/icons/App_Icon.bs.js";
import * as App_Styles$DvmAdminFrontendFr from "../../../common/App_Styles.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../intl/Intl_Utils.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../common/App_Typography.bs.js";
import * as App_LabeledText$DvmAdminFrontendFr from "../../../common/components/App_LabeledText.bs.js";
import * as Order_Search_Api$DvmAdminFrontendFr from "../search/Order_Search_Api.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../context/user/User_Types_Utils.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../common/styles/App_Styles_Common.bs.js";
import * as Order_Detail_Utils$DvmAdminFrontendFr from "./Order_Detail_Utils.bs.js";
import * as Order_Detail_Router$DvmAdminFrontendFr from "./Order_Detail_Router.bs.js";
import * as Order_Detail_Styles$DvmAdminFrontendFr from "./Order_Detail_Styles.bs.js";
import * as Order_Detail_Bonuses$DvmAdminFrontendFr from "./bonuses/Order_Detail_Bonuses.bs.js";
import * as App_Clickable_Element$DvmAdminFrontendFr from "../../../common/components/button/App_Clickable_Element.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Order_Detail_Old_Vehicle$DvmAdminFrontendFr from "./oldVehicle/Order_Detail_Old_Vehicle.bs.js";
import * as Order_Detail_Layout_Setup$DvmAdminFrontendFr from "./Order_Detail_Layout_Setup.bs.js";
import * as Order_Detail_Old_Vehicle_Add$DvmAdminFrontendFr from "./oldVehicle/Order_Detail_Old_Vehicle_Add.bs.js";
import * as Order_Detail_Old_Vehicle_Utils$DvmAdminFrontendFr from "./oldVehicle/Order_Detail_Old_Vehicle_Utils.bs.js";
import * as Order_Detail_Page_VNStatus_Chip$DvmAdminFrontendFr from "./Order_Detail_Page_VNStatus_Chip.bs.js";
import * as Order_Detail_Reselling_Edits_Api$DvmAdminFrontendFr from "./reselling/edits/Order_Detail_Reselling_Edits_Api.bs.js";
import * as Order_Detail_Reselling_Edits_Modal$DvmAdminFrontendFr from "./reselling/edits/Order_Detail_Reselling_Edits_Modal.bs.js";
import * as Order_Detail_Reselling_Edits_Utils$DvmAdminFrontendFr from "./reselling/edits/Order_Detail_Reselling_Edits_Utils.bs.js";
import * as Order_Detail_Reselling_Delete_Modal$DvmAdminFrontendFr from "./reselling/Order_Detail_Reselling_Delete_Modal.bs.js";
import * as Order_Detail_Reselling_Button_Add_Redirect$DvmAdminFrontendFr from "./reselling/Order_Detail_Reselling_Button_Add_Redirect.bs.js";
import * as Order_Detail_Page_NonFleet_Detail_Items_Box$DvmAdminFrontendFr from "./Order_Detail_Page_NonFleet_Detail_Items_Box.bs.js";

function Order_Detail_Page_NonFleet(Props) {
  var order = Props.order;
  var orderId = Props.orderId;
  var reloadPage = Props.reloadPage;
  var correctionsResult = Props.correctionsResult;
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var alert = ReactAlert.useAlert();
  var intl = ReactIntl.useIntl();
  var match$2 = React.useState(function () {
        return null;
      });
  var setModal = match$2[1];
  var match$3 = React.useState(function () {
        return false;
      });
  var setShowHistory = match$3[1];
  var match$4 = React.useState(function () {
        return null;
      });
  var setLastEdit = match$4[1];
  var match$5 = React.useState(function () {
        
      });
  var setLastCorrectionValues = match$5[1];
  var lastCorrectionValues = match$5[0];
  var match$6 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setClientTypes = match$6[1];
  var match$7 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSalesTypes = match$7[1];
  React.useEffect((function () {
          if (User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* Spain */1) {
            Order_Search_Api$DvmAdminFrontendFr.fetchClientTypes(alert, userManager, setClientTypes, selectedRole);
            Order_Search_Api$DvmAdminFrontendFr.fetchSaleTypes(alert, userManager, setSalesTypes, selectedRole);
          }
          
        }), []);
  var match$8 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSiretBudgetLLD = match$8[1];
  var siretBudgetLLD = match$8[0];
  React.useEffect((function () {
          if (typeof correctionsResult !== "number") {
            var match = Order_Detail_Utils$DvmAdminFrontendFr.actualOrderType(order, lastCorrectionValues);
            var exit = 0;
            if (!(match !== 8 && match !== 10)) {
              exit = 1;
            }
            if (exit === 1) {
              var siret = Belt_Option.mapWithDefault(lastCorrectionValues, order.detail.siret, (function (x) {
                      return Belt_Option.flatMap(x.siret, (function (x) {
                                    if (x.length > 0) {
                                      return x;
                                    }
                                    
                                  }));
                    }));
              if (siret !== undefined) {
                Order_Detail_Reselling_Edits_Api$DvmAdminFrontendFr.checkSiretBudgetLLD(userManager, setSiretBudgetLLD, selectedRole, siret);
              }
              
            }
            
          }
          
        }), [
        correctionsResult,
        lastCorrectionValues
      ]);
  React.useEffect((function () {
          if (typeof correctionsResult === "number") {
            correctionsResult === /* NotStarted */0;
          } else if (correctionsResult.TAG === /* Ok */0) {
            var correctionsSorted = Belt_List.sort(Belt_List.keep(correctionsResult._0.content, (function (x) {
                        return Belt_Option.getWithDefault(x.editor, "") !== "INITIAL";
                      })), (function (a, b) {
                    return ReDate.compareDesc(Belt_Option.getWithDefault(a.timeStamp, new Date(0.0)), Belt_Option.getWithDefault(b.timeStamp, new Date(0.0)));
                  }));
            var match = Belt_Option.mapWithDefault(Belt_List.get(correctionsSorted, 0), [
                  undefined,
                  ""
                ], (function (x) {
                    return [
                            Belt_Option.mapWithDefault(x.timeStamp, "", (function (param) {
                                    return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                  })) + (
                              selectedRole !== /* Dealer */7 || !User_Types_Utils$DvmAdminFrontendFr.isAdminDealer(selectedRole) ? " par " + Belt_Option.getWithDefault(x.editor, "") : ""
                            ),
                            Belt_Option.getWithDefault(x.comment, "")
                          ];
                  }));
            var lastEditComment = match[1];
            var lastEditMessage = match[0];
            Curry._1(setLastCorrectionValues, (function (param) {
                    return Belt_List.get(correctionsSorted, 0);
                  }));
            Curry._1(setShowHistory, (function (param) {
                    return Belt_List.length(correctionsSorted) > 0;
                  }));
            Curry._1(setLastEdit, (function (param) {
                    return Belt_Option.mapWithDefault(lastEditMessage, null, (function (lastEditMessage) {
                                  return React.createElement(Core.Grid, {
                                              children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                    color: "textSecondary",
                                                    children: React.createElement(Core.Grid, {
                                                          children: null,
                                                          container: true,
                                                          spacing: 1
                                                        }, React.createElement(Core.Grid, {
                                                              children: "" + intl.formatMessage(Messages_Orders_Detail$DvmAdminFrontendFr.lastModification) + " " + lastEditMessage + "",
                                                              item: true
                                                            }), lastEditComment.length > 0 ? React.createElement(Core.Grid, {
                                                                children: React.createElement(Core.Tooltip, {
                                                                      arrow: true,
                                                                      children: React.createElement("div", undefined, React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                                size: /* Large */3,
                                                                                type_: /* Information */43
                                                                              })),
                                                                      classes: {
                                                                        tooltip: App_Styles_Common$DvmAdminFrontendFr.tooltip
                                                                      },
                                                                      placement: "bottom",
                                                                      title: lastEditComment
                                                                    }),
                                                                item: true
                                                              }) : null),
                                                    variant: "subtitle"
                                                  }),
                                              item: true
                                            });
                                }));
                  }));
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: correctionsResult._0
                      }
                    }));
          }
        }), [correctionsResult]);
  var tmp = {
    order: order,
    actualOrderType: Order_Detail_Utils$DvmAdminFrontendFr.actualOrderType(order, lastCorrectionValues),
    siretBudgetLLD: siretBudgetLLD
  };
  if (lastCorrectionValues !== undefined) {
    tmp.lastCorrectionValues = Caml_option.valFromOption(lastCorrectionValues);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Core.AppBar, {
                  children: React.createElement(Core.Grid, {
                        children: Belt_Array.mapWithIndex(Belt_Array.concat(Order_Detail_Layout_Setup$DvmAdminFrontendFr.headerArray(order, selectedRole, Order_Detail_Utils$DvmAdminFrontendFr.actualOrderType(order, lastCorrectionValues), undefined), User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* Spain */1 ? Order_Detail_Layout_Setup$DvmAdminFrontendFr.spainHeaders(match$6[0], match$7[0], order) : []), (function (index, element) {
                                return React.createElement(Core.Grid, {
                                            children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                                  label: element.label,
                                                  children: element.val
                                                }),
                                            item: true,
                                            key: String(index)
                                          });
                              })),
                        className: Order_Detail_Styles$DvmAdminFrontendFr.titleBox,
                        container: true,
                        justifyContent: "space-evenly",
                        spacing: 1
                      }),
                  className: App_Styles$DvmAdminFrontendFr.stickyBar
                }), React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      direction: "column",
                      key: "0"
                    }, React.createElement(Core.Grid, {
                          alignItems: "center",
                          children: null,
                          container: true,
                          item: true,
                          style: {
                            gridColumnGap: "8px"
                          }
                        }, React.createElement(Core.Grid, {
                              children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                    color: "primary",
                                    style: {
                                      fontWeight: "600"
                                    },
                                    children: "" + intl.formatMessage(Order_Detail_Utils$DvmAdminFrontendFr.isResell(Order_Detail_Utils$DvmAdminFrontendFr.actualOrderType(order, lastCorrectionValues)) ? Messages_Orders_Detail$DvmAdminFrontendFr.reselingOrderDetail : Messages_Orders_Detail$DvmAdminFrontendFr.orderDetail) + " " + Belt_Option.getWithDefault(order.orderNumberCustomer, "-") + "",
                                    variant: "h3"
                                  }),
                              item: true
                            }), React.createElement(Order_Detail_Page_VNStatus_Chip$DvmAdminFrontendFr.make, tmp), Order_Detail_Utils$DvmAdminFrontendFr.isResell(Order_Detail_Utils$DvmAdminFrontendFr.actualOrderType(order, lastCorrectionValues)) && Order_Detail_Reselling_Edits_Utils$DvmAdminFrontendFr.resellOrderEditable(order) && (User_Types_Utils$DvmAdminFrontendFr.isAdminDealer(selectedRole) || selectedRole === /* Dealer */7 || User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole)) ? React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                                    children: React.createElement(Core.IconButton, {
                                          onClick: (function ($$event) {
                                              $$event.stopPropagation();
                                              Curry._1(setModal, (function (param) {
                                                      return React.createElement(Order_Detail_Reselling_Edits_Modal$DvmAdminFrontendFr.make, {
                                                                  onClose: (function (param) {
                                                                      Curry._1(setModal, (function (param) {
                                                                              return null;
                                                                            }));
                                                                    }),
                                                                  orderId: orderId,
                                                                  order: order,
                                                                  reloadPage: reloadPage,
                                                                  correctionsResult: correctionsResult,
                                                                  actualOrderType: Order_Detail_Utils$DvmAdminFrontendFr.actualOrderType(order, lastCorrectionValues)
                                                                });
                                                    }));
                                            }),
                                          children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                size: /* Large */3,
                                                type_: /* Edit */60
                                              })
                                        }),
                                    item: true
                                  }), React.createElement(Core.Grid, {
                                    children: React.createElement(Core.IconButton, {
                                          onClick: (function ($$event) {
                                              $$event.stopPropagation();
                                              Curry._1(setModal, (function (param) {
                                                      return React.createElement(Order_Detail_Reselling_Delete_Modal$DvmAdminFrontendFr.make, {
                                                                  onClose: (function (param) {
                                                                      Curry._1(setModal, (function (param) {
                                                                              return null;
                                                                            }));
                                                                    }),
                                                                  orderId: orderId
                                                                });
                                                    }));
                                            }),
                                          children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                size: /* Large */3,
                                                type_: /* Trash */38
                                              })
                                        }),
                                    item: true
                                  })) : null, (User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole) || selectedRole === /* Advisor */0) && !Belt_Option.getWithDefault(order.archived, true) && !Order_Detail_Utils$DvmAdminFrontendFr.isResell(Order_Detail_Utils$DvmAdminFrontendFr.actualOrderType(order, lastCorrectionValues)) ? React.createElement(Core.Grid, {
                                children: React.createElement(Core.Tooltip, {
                                      children: React.createElement(Core.IconButton, {
                                            onClick: (function (param) {
                                                Order_Detail_Router$DvmAdminFrontendFr.routeToCorrectionPage(orderId, "correction");
                                              }),
                                            children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                  type_: /* Edit */60
                                                })
                                          }),
                                      placement: "right",
                                      title: intl.formatMessage(Messages_Orders_Detail$DvmAdminFrontendFr.correct)
                                    }),
                                item: true
                              }) : null), React.createElement(Core.Grid, {
                          alignItems: "center",
                          children: null,
                          container: true,
                          item: true,
                          spacing: 2
                        }, match$4[0], match$3[0] ? React.createElement(Core.Grid, {
                                children: React.createElement(App_Clickable_Element$DvmAdminFrontendFr.make, {
                                      onClick: (function (param) {
                                          Order_Detail_Router$DvmAdminFrontendFr.routeToCorrectionPage(orderId, "history");
                                        }),
                                      children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                            style: {
                                              color: Theme_Colors$DvmAdminFrontendFr.secondaryBlue,
                                              textDecoration: "underline"
                                            },
                                            children: intl.formatMessage(Messages_Orders_Detail$DvmAdminFrontendFr.historyLink),
                                            variant: "subtitle"
                                          })
                                    }),
                                item: true
                              }) : null), React.createElement(Order_Detail_Page_NonFleet_Detail_Items_Box$DvmAdminFrontendFr.make, {
                          order: order,
                          orderId: orderId,
                          reloadPage: reloadPage,
                          lastCorrectionValues: lastCorrectionValues,
                          siretBudgetLLD: siretBudgetLLD,
                          correctionsResult: correctionsResult
                        })), React.createElement(Core.Box, {
                      children: Order_Detail_Old_Vehicle_Utils$DvmAdminFrontendFr.isVOEmpty(order, lastCorrectionValues) ? (
                          !Belt_Option.getWithDefault(order.archived, false) && (Order_Detail_Utils$DvmAdminFrontendFr.isResell(Order_Detail_Utils$DvmAdminFrontendFr.actualOrderType(order, lastCorrectionValues)) || Belt_Option.getWithDefault(order.detail.delivered, false)) ? React.createElement(Order_Detail_Old_Vehicle_Add$DvmAdminFrontendFr.make, {
                                  newestUsedCarValues: Order_Detail_Old_Vehicle_Utils$DvmAdminFrontendFr.getCurrentValues(order, lastCorrectionValues),
                                  orderId: orderId,
                                  reloadPage: reloadPage,
                                  order: order
                                }) : null
                        ) : React.createElement(Order_Detail_Old_Vehicle$DvmAdminFrontendFr.make, {
                              newestUsedCarValues: Order_Detail_Old_Vehicle_Utils$DvmAdminFrontendFr.getCurrentValues(order, lastCorrectionValues),
                              orderId: orderId,
                              reloadPage: reloadPage,
                              order: order,
                              actualOrderType: Order_Detail_Utils$DvmAdminFrontendFr.actualOrderType(order, lastCorrectionValues)
                            }),
                      marginTop: 3
                    }), React.createElement(Order_Detail_Reselling_Button_Add_Redirect$DvmAdminFrontendFr.make, {
                      orderId: orderId,
                      order: order,
                      lastCorrectionValues: lastCorrectionValues
                    }), React.createElement(Order_Detail_Bonuses$DvmAdminFrontendFr.make, {
                      order: order,
                      orderId: orderId,
                      reloadPage: reloadPage,
                      actualOrderType: Order_Detail_Utils$DvmAdminFrontendFr.actualOrderType(order, lastCorrectionValues),
                      key: orderId
                    })), match$2[0]);
}

var Setup;

var Api;

var DealerEditUtils;

var OrderUtils;

var make = Order_Detail_Page_NonFleet;

export {
  Setup ,
  Api ,
  DealerEditUtils ,
  OrderUtils ,
  make ,
}
/* react Not a pure module */
