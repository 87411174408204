// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactAlert from "react-alert";
import * as App_Spinner$DvmAdminFrontendFr from "../../../common/components/App_Spinner.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as Order_Detail_Api$DvmAdminFrontendFr from "./Order_Detail_Api.bs.js";
import * as Order_Detail_Utils$DvmAdminFrontendFr from "./Order_Detail_Utils.bs.js";
import * as Order_Detail_Page_Fleet$DvmAdminFrontendFr from "./Order_Detail_Page_Fleet.bs.js";
import * as Order_Detail_Correct_Api$DvmAdminFrontendFr from "./correct/Order_Detail_Correct_Api.bs.js";
import * as Order_Detail_Page_NonFleet$DvmAdminFrontendFr from "./Order_Detail_Page_NonFleet.bs.js";

function Order_Detail(Props) {
  var orderId = Props.orderId;
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var alert = ReactAlert.useAlert();
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setOrderTypeResult = match$2[1];
  var orderTypeResult = match$2[0];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setOrder = match$3[1];
  var order = match$3[0];
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setCorrectionsResult = match$4[1];
  var correctionsResult = match$4[0];
  var reloadPage = function (param) {
    if (typeof orderTypeResult !== "number" && orderTypeResult.TAG === /* Ok */0) {
      Order_Detail_Api$DvmAdminFrontendFr.getOrder(userManager, setOrder, orderId, orderTypeResult._0.response, selectedRole);
    }
    Order_Detail_Correct_Api$DvmAdminFrontendFr.getAllCorrections(alert, userManager, setCorrectionsResult, orderId, selectedRole);
  };
  React.useEffect((function () {
          Order_Detail_Api$DvmAdminFrontendFr.getOrderType(userManager, setOrderTypeResult, orderId, selectedRole);
        }), []);
  React.useEffect((function () {
          if (typeof orderTypeResult === "number") {
            orderTypeResult === /* NotStarted */0;
          } else if (orderTypeResult.TAG === /* Ok */0) {
            reloadPage(undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: orderTypeResult._0
                      }
                    }));
          }
        }), [orderTypeResult]);
  React.useEffect((function () {
          if (typeof order !== "number" && order.TAG === /* Error */1) {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: order._0
                      }
                    }));
          }
          
        }), [order]);
  if (typeof order === "number") {
    if (order === /* NotStarted */0) {
      return null;
    } else {
      return React.createElement(App_Spinner$DvmAdminFrontendFr.make, {});
    }
  }
  if (order.TAG !== /* Ok */0) {
    return null;
  }
  var order$1 = order._0;
  if (order$1.TAG === /* Fleet */0) {
    return React.createElement(Order_Detail_Page_Fleet$DvmAdminFrontendFr.make, {
                order: Order_Detail_Utils$DvmAdminFrontendFr.makeOrder(order$1),
                orderId: orderId,
                reloadPage: reloadPage,
                correctionsResult: correctionsResult
              });
  } else {
    return React.createElement(Order_Detail_Page_NonFleet$DvmAdminFrontendFr.make, {
                order: Order_Detail_Utils$DvmAdminFrontendFr.makeOrder(order$1),
                orderId: orderId,
                reloadPage: reloadPage,
                correctionsResult: correctionsResult
              });
  }
}

var make = Order_Detail;

export {
  make ,
}
/* react Not a pure module */
