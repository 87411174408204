// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as App_Button$DvmAdminFrontendFr from "../../../../../common/components/button/App_Button.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../context/user/User_Context.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../../../context/user/User_Types_Utils.bs.js";
import * as Messages_Orders_Derogate$DvmAdminFrontendFr from "../../../../../intl/messages/orders/Messages_Orders_Derogate.bs.js";

function Order_Detail_Bonus_Derogate_Button(Props) {
  var openModal = Props.openModal;
  var order = Props.order;
  var state = Props.state;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  if (Belt_Option.mapWithDefault(state, false, (function (x) {
            return x === /* PREINVOICED */5 || x === /* NOBONUS */3 ? true : x === /* PAID */4;
          })) && !Belt_Option.getWithDefault(order.archived, true) && User_Types_Utils$DvmAdminFrontendFr.isAdmin(match[0].selectedRole)) {
    return React.createElement(Core.Grid, {
                children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                      onClick: openModal,
                      label: intl.formatMessage(Messages_Orders_Derogate$DvmAdminFrontendFr.derogateButton),
                      color: "secondary",
                      size: "small"
                    }),
                item: true
              });
  } else {
    return null;
  }
}

var make = Order_Detail_Bonus_Derogate_Button;

export {
  make ,
}
/* react Not a pure module */
