// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../common/components/icons/App_Icon.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../../context/user/User_Types_Utils.bs.js";
import * as Order_Detail_Utils$DvmAdminFrontendFr from "../Order_Detail_Utils.bs.js";
import * as App_Clickable_Element$DvmAdminFrontendFr from "../../../../common/components/button/App_Clickable_Element.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Order_Detail_Reselling_Router$DvmAdminFrontendFr from "./Order_Detail_Reselling_Router.bs.js";
import * as Order_Detail_Reselling_Create_Modal$DvmAdminFrontendFr from "./Order_Detail_Reselling_Create_Modal.bs.js";

function Order_Detail_Reselling_Button_Add_Redirect(Props) {
  var orderId = Props.orderId;
  var order = Props.order;
  var lastCorrectionValues = Props.lastCorrectionValues;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function () {
        return null;
      });
  var setModal = match[1];
  var match$1 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var selectedRole = match$1[0].selectedRole;
  var hideReselling = !(User_Types_Utils$DvmAdminFrontendFr.isAdminDealer(selectedRole) || selectedRole === /* Dealer */7 || User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole)) || Order_Detail_Utils$DvmAdminFrontendFr.isResell(Order_Detail_Utils$DvmAdminFrontendFr.actualOrderType(order, lastCorrectionValues)) || Belt_Option.getWithDefault(order.detail.resultPARCEndDateAutoFlag, true) || Belt_Option.isNone(order.detail.parcEndDate) && Belt_Option.mapWithDefault(lastCorrectionValues, true, (function (x) {
          return Belt_Option.isNone(x.parcEndDate);
        })) || !Belt_Option.getWithDefault(order.detail.resellable, true);
  if (hideReselling) {
    return null;
  } else {
    return React.createElement(Core.Box, {
                children: null,
                marginTop: 3
              }, React.createElement(App_Clickable_Element$DvmAdminFrontendFr.make, {
                    onClick: (function (param) {
                        Belt_Option.mapWithDefault(order.resoldOrderId, Curry._1(setModal, (function (param) {
                                    return React.createElement(Order_Detail_Reselling_Create_Modal$DvmAdminFrontendFr.make, {
                                                onClose: (function (param) {
                                                    Curry._1(setModal, (function (param) {
                                                            return null;
                                                          }));
                                                  }),
                                                orderId: orderId,
                                                order: order,
                                                fromParcExitEdit: false
                                              });
                                  })), Order_Detail_Reselling_Router$DvmAdminFrontendFr.routeToResellPage);
                      }),
                    children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                          color: "primary",
                          style: {
                            fontWeight: "600"
                          },
                          children: React.createElement(Core.Grid, {
                                children: null,
                                container: true,
                                spacing: 1
                              }, Belt_Option.isSome(order.resoldOrderId) ? null : React.createElement(Core.Grid, {
                                      children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                            size: /* Large */3,
                                            type_: /* More */17
                                          }),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                    children: intl.formatMessage(Belt_Option.isSome(order.resoldOrderId) ? Messages_Orders_Detail$DvmAdminFrontendFr.orderResellingRedirectButton : Messages_Orders_Detail$DvmAdminFrontendFr.orderResellingCreateButton),
                                    item: true
                                  })),
                          variant: "h3"
                        })
                  }), match[0]);
  }
}

var OrderUtils;

var make = Order_Detail_Reselling_Button_Add_Redirect;

export {
  OrderUtils ,
  make ,
}
/* react Not a pure module */
