// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Order_Detail_Old_Vehicle_Edits_Form$DvmAdminFrontendFr from "./Order_Detail_Old_Vehicle_Edits_Form.bs.js";

function voActionsStatesEditable(order) {
  var reprStatesEditable = Belt_Array.keep(Belt_Array.keepMap(Belt_Option.getWithDefault(order.repr, []), (function (x) {
              return x.state;
            })), (function (x) {
          if (x === /* PAID */4 || x === /* PREINVOICED */5) {
            return true;
          } else {
            return x === /* NOBONUS */3;
          }
        })).length === 0;
  var match = Belt_Option.getWithDefault(order.orderType, /* NOTSET */11);
  var nonfleetVOActionStatesEditable = match > 10 || match < 1 ? true : Belt_Array.keep(Belt_Array.keepMap(Belt_Array.keep(Belt_Option.getWithDefault(order.actions, []), (function (x) {
                    return Belt_Option.getWithDefault(x.withUsedCar, false);
                  })), (function (x) {
                return x.state;
              })), (function (x) {
            if (x === /* PAID */4 || x === /* PREINVOICED */5) {
              return true;
            } else {
              return x === /* NOBONUS */3;
            }
          })).length === 0;
  if (reprStatesEditable) {
    return nonfleetVOActionStatesEditable;
  } else {
    return false;
  }
}

function getInitialInput(newestUsedCarValues) {
  return {
          value: Belt_Option.mapWithDefault(newestUsedCarValues.value, Order_Detail_Old_Vehicle_Edits_Form$DvmAdminFrontendFr.initialState.value, (function (x) {
                  return String(x);
                })),
          estimatedValue: Belt_Option.mapWithDefault(newestUsedCarValues.estimatedValue, Order_Detail_Old_Vehicle_Edits_Form$DvmAdminFrontendFr.initialState.estimatedValue, (function (x) {
                  return String(x);
                })),
          endOfLife: Belt_Option.mapWithDefault(newestUsedCarValues.endOfLife, Order_Detail_Old_Vehicle_Edits_Form$DvmAdminFrontendFr.initialState.endOfLife, (function (x) {
                  if (x) {
                    return "true";
                  } else {
                    return "false";
                  }
                })),
          brandUsedCar: Belt_Option.getWithDefault(newestUsedCarValues.brand, Order_Detail_Old_Vehicle_Edits_Form$DvmAdminFrontendFr.initialState.brandUsedCar),
          modelUsedCar: Belt_Option.getWithDefault(newestUsedCarValues.model, Order_Detail_Old_Vehicle_Edits_Form$DvmAdminFrontendFr.initialState.modelUsedCar),
          versionUsedCar: Belt_Option.getWithDefault(newestUsedCarValues.version, Order_Detail_Old_Vehicle_Edits_Form$DvmAdminFrontendFr.initialState.versionUsedCar),
          registrationNumberUsedCar: Belt_Option.getWithDefault(newestUsedCarValues.registrationNumber, Order_Detail_Old_Vehicle_Edits_Form$DvmAdminFrontendFr.initialState.registrationNumberUsedCar),
          vinUsedCar: Belt_Option.getWithDefault(newestUsedCarValues.vin, Order_Detail_Old_Vehicle_Edits_Form$DvmAdminFrontendFr.initialState.vinUsedCar),
          firstReleaseDateCar: Belt_Option.mapWithDefault(newestUsedCarValues.firstReleaseDate, Order_Detail_Old_Vehicle_Edits_Form$DvmAdminFrontendFr.initialState.firstReleaseDateCar, (function (x) {
                  return Caml_option.some(x);
                })),
          registrationDateUsedCar: Belt_Option.mapWithDefault(newestUsedCarValues.registrationDate, Order_Detail_Old_Vehicle_Edits_Form$DvmAdminFrontendFr.initialState.registrationDateUsedCar, (function (x) {
                  return Caml_option.some(x);
                })),
          policeNumber: Belt_Option.getWithDefault(newestUsedCarValues.policeNumber, Order_Detail_Old_Vehicle_Edits_Form$DvmAdminFrontendFr.initialState.policeNumber)
        };
}

function numberValuesChanged(newestUsedCarValues, value, estimatedValue) {
  var origInput = getInitialInput(newestUsedCarValues);
  var match = Belt_Float.fromString(origInput.value);
  var valueChanged = value !== undefined ? (
      match !== undefined ? value !== match : true
    ) : false;
  var match$1 = Belt_Float.fromString(origInput.estimatedValue);
  var estimatedValueChanged = estimatedValue !== undefined ? (
      match$1 !== undefined ? estimatedValue !== match$1 : true
    ) : false;
  if (valueChanged) {
    return true;
  } else {
    return estimatedValueChanged;
  }
}

var Form;

export {
  Form ,
  voActionsStatesEditable ,
  getInitialInput ,
  numberValuesChanged ,
}
/* Order_Detail_Old_Vehicle_Edits_Form-DvmAdminFrontendFr Not a pure module */
