// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Button.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Button.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Order_Detail_Dealer_Edits_Api$DvmAdminFrontendFr from "./Order_Detail_Dealer_Edits_Api.bs.js";

function Order_Detail_Dealer_Edits_ParcExit_Delete_Modal(Props) {
  var onClose = Props.onClose;
  var orderId = Props.orderId;
  var onOk = Props.onOk;
  var order = Props.order;
  var alert = ReactAlert.useAlert();
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSaveResult = match$2[1];
  var saveResult = match$2[0];
  React.useEffect((function () {
          if (typeof saveResult === "number") {
            saveResult === /* NotStarted */0;
          } else if (saveResult.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            Curry._1(onOk, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: saveResult._0
                      }
                    }));
          }
        }), [saveResult]);
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Messages_Orders_Detail$DvmAdminFrontendFr.parcExitDeleteTitle),
              minWidth: "700px",
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(saveResult),
                    children: React.createElement(Core.Grid, {
                          alignItems: "center",
                          children: null,
                          container: true,
                          direction: "column",
                          spacing: 4,
                          style: {
                            overflow: "hidden"
                          }
                        }, Belt_Option.mapWithDefault(order.resoldOrderId, null, (function (x) {
                                if (x.length > 0) {
                                  return React.createElement(Core.Grid, {
                                              children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                    color: "error",
                                                    style: {
                                                      fontWeight: "600",
                                                      maxWidth: "500px",
                                                      textAlign: "center"
                                                    },
                                                    children: intl.formatMessage(Messages_Orders_Detail$DvmAdminFrontendFr.parcExitDeleteWarning),
                                                    variant: "subtitle"
                                                  }),
                                              container: true,
                                              item: true,
                                              justifyContent: "center"
                                            });
                                } else {
                                  return null;
                                }
                              })), React.createElement(Core.Grid, {
                              children: null,
                              container: true,
                              item: true,
                              justify: "center",
                              spacing: 4
                            }, React.createElement(Core.Grid, {
                                  children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                        onClick: (function (param) {
                                            Curry._1(onClose, undefined);
                                          }),
                                        label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.cancel),
                                        color: "primaryBorder",
                                        size: "medium"
                                      }),
                                  item: true
                                }), React.createElement(Core.Grid, {
                                  children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                        onClick: (function (param) {
                                            Order_Detail_Dealer_Edits_Api$DvmAdminFrontendFr.deleteExitParcDate(userManager, setSaveResult, orderId, selectedRole);
                                          }),
                                        label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                        size: "medium"
                                      }),
                                  item: true
                                })))
                  }),
              titleMaxWidth: "100vw"
            });
}

var Api;

var make = Order_Detail_Dealer_Edits_ParcExit_Delete_Modal;

export {
  Api ,
  make ,
}
/* react Not a pure module */
