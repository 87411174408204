// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.bs.js";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RestApi$DvmAdminFrontendFr from "../../../../../api/RestApi.bs.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Date.bs.js";
import * as Order_Detail_Reselling_Edits_Types$DvmAdminFrontendFr from "./Order_Detail_Reselling_Edits_Types.bs.js";

function postResellEditCorrection(userManager, setResult, submissionCallbacks, selectedRole, dealerEditsCorrection) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("POST", {
          TAG: /* Json */0,
          _0: Order_Detail_Reselling_Edits_Types$DvmAdminFrontendFr.orderCorrection_encode(dealerEditsCorrection)
        }, userManager, selectedRole, "/orders/correction/resold/" + dealerEditsCorrection.orderID + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: ""
                              };
                      }));
        }
        var error = result._0;
        Curry._1(submissionCallbacks.notifyOnFailure, /* FailedToUpdateResellOrder */0);
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function t_encode(v) {
  return Js_dict.fromArray([[
                "response",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.response)
              ]]);
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var response = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict._0, "response"), null));
  if (response.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              response: response._0
            }
          };
  }
  var e = response._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".response" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var ResponseDate = {
  t_encode: t_encode,
  t_decode: t_decode
};

function t_encode$1(v) {
  return Js_dict.fromArray([[
                "response",
                Decco.boolToJson(v.response)
              ]]);
}

function t_decode$1(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var response = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict._0, "response"), null));
  if (response.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              response: response._0
            }
          };
  }
  var e = response._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".response" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var ResponseBool = {
  t_encode: t_encode$1,
  t_decode: t_decode$1
};

function checkSiretActive(userManager, setResult, selectedRole, siret) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", t_decode, undefined, userManager, selectedRole, "/sirets/siret/live?siret=" + siret + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var r = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: Belt_Option.mapWithDefault(r.response, true, (function (foundEndDate) {
                                        return !ReDate.isBefore(foundEndDate, new Date());
                                      }))
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function checkSiretBudgetLLD(userManager, setResult, selectedRole, siret) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", t_decode$1, undefined, userManager, selectedRole, "/sirets/siret/budget/check?siret=" + siret + "&budgetType=LLD").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var res = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: res.response
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function checkSiretExists(userManager, setResult, selectedRole, siret) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", t_decode$1, undefined, userManager, selectedRole, "/sirets/siret/exist?siret=" + siret + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var res = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: res.response
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function checkSirenExists(userManager, setResult, selectedRole, siren) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("GET", t_decode$1, undefined, userManager, selectedRole, "/sirets/siren/exist?siren=" + siren + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var res = result._0;
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: res.response
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

var CorrectionForm;

export {
  CorrectionForm ,
  postResellEditCorrection ,
  ResponseDate ,
  ResponseBool ,
  checkSiretActive ,
  checkSiretBudgetLLD ,
  checkSiretExists ,
  checkSirenExists ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
