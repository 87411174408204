// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Order_Detail_Reselling_Edits_Form$DvmAdminFrontendFr from "./Order_Detail_Reselling_Edits_Form.bs.js";

function resellOrderEditable(order) {
  return Belt_Array.keep(Belt_Array.keepMap(Belt_Option.getWithDefault(order.actions, []), (function (x) {
                    return x.state;
                  })), (function (x) {
                if (x === /* PAID */4 || x === /* PREINVOICED */5) {
                  return true;
                } else {
                  return x === /* NOBONUS */3;
                }
              })).length === 0;
}

function getInitialInput(lastCorrectionValues, order) {
  var match = Belt_Option.mapWithDefault(lastCorrectionValues, [
        order.orderType,
        order.detail.siret,
        order.detail.shortCustomerName,
        order.detail.clientFirstName,
        order.detail.finalCustomerLLD,
        order.detail.registrationDate,
        order.detail.customerInvoiceDate,
        order.detail.customerInvoiceNumber,
        order.detail.discount,
        order.detail.orderDate,
        order.detail.deliveryDate,
        order.detail.agentCode,
        order.detail.salesmanName,
        order.detail.financialCode
      ], (function (lastCorr) {
          return [
                  lastCorr.orderType,
                  lastCorr.siret,
                  lastCorr.shortCustomerName,
                  lastCorr.customerFirstName,
                  lastCorr.sirenFinalCustomerLLD,
                  lastCorr.newCarRegistrationDate,
                  lastCorr.customerInvoiceDate,
                  lastCorr.customerInvoiceNumber,
                  lastCorr.customerDiscount,
                  lastCorr.orderDate,
                  lastCorr.newCarDeliveryDateToCustomer,
                  lastCorr.agentCode,
                  lastCorr.salesmanName,
                  lastCorr.easyPack
                ];
        }));
  var agent = Belt_Option.getWithDefault(match[11], Order_Detail_Reselling_Edits_Form$DvmAdminFrontendFr.initialState.agentCode);
  return {
          orderType: Belt_Option.getWithDefault(match[0], Order_Detail_Reselling_Edits_Form$DvmAdminFrontendFr.initialState.orderType),
          siret: Belt_Option.getWithDefault(match[1], Order_Detail_Reselling_Edits_Form$DvmAdminFrontendFr.initialState.siret),
          finalCustomer: Belt_Option.getWithDefault(match[4], Order_Detail_Reselling_Edits_Form$DvmAdminFrontendFr.initialState.finalCustomer),
          shortCustomerName: Belt_Option.getWithDefault(match[2], Order_Detail_Reselling_Edits_Form$DvmAdminFrontendFr.initialState.shortCustomerName),
          customerFirstName: Belt_Option.getWithDefault(match[3], Order_Detail_Reselling_Edits_Form$DvmAdminFrontendFr.initialState.customerFirstName),
          orderDate: match[9],
          registrationDate: match[5],
          deliveryDate: match[10],
          invoiceNumber: Belt_Option.getWithDefault(match[7], Order_Detail_Reselling_Edits_Form$DvmAdminFrontendFr.initialState.invoiceNumber),
          invoiceClient: match[6],
          customerDiscount: Belt_Option.mapWithDefault(match[8], Order_Detail_Reselling_Edits_Form$DvmAdminFrontendFr.initialState.customerDiscount, (function (x) {
                  return String(x);
                })),
          agentCode: agent === "000" ? "" : agent,
          salesmanName: Belt_Option.getWithDefault(match[12], Order_Detail_Reselling_Edits_Form$DvmAdminFrontendFr.initialState.salesmanName),
          easyPack: Belt_Option.getWithDefault(match[13], Order_Detail_Reselling_Edits_Form$DvmAdminFrontendFr.initialState.easyPack),
          siretExists: true,
          siretActive: true,
          siretBudgetLLD: false,
          finalCustomerExists: true
        };
}

function postWithLatestCorrection($$final, orderId) {
  return {
          orderID: orderId,
          orderType: $$final.orderType,
          siret: $$final.siret,
          sirenFinalCustomerLLD: $$final.finalCustomer,
          newCarRegistrationDate: $$final.registrationDate,
          customerInvoiceDate: $$final.invoiceClient,
          customerInvoiceNumber: $$final.invoiceNumber,
          customerDiscount: $$final.customerDiscount,
          easyPack: $$final.easyPack,
          shortCustomerName: $$final.shortCustomerName,
          customerFirstName: $$final.customerFirstName,
          orderDate: $$final.orderDate,
          newCarDeliveryDateToCustomer: $$final.deliveryDate,
          agentCode: $$final.agentCode === "" ? "000" : $$final.agentCode,
          salesmanName: $$final.salesmanName
        };
}

var Form;

export {
  Form ,
  resellOrderEditable ,
  getInitialInput ,
  postWithLatestCorrection ,
}
/* Order_Detail_Reselling_Edits_Form-DvmAdminFrontendFr Not a pure module */
