// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../../common/types/App_Types_Date.bs.js";

function nonfleetPARCDatesEditable(order, lookAtExit) {
  var match = Belt_Option.getWithDefault(order.orderType, /* NOTSET */11);
  if (match > 10 || match < 1) {
    return true;
  } else {
    return Belt_Array.keep(Belt_Array.keepMap(Belt_Array.keep(Belt_Option.getWithDefault(order.actions, []), (function (x) {
                          if (lookAtExit) {
                            return Belt_Option.getWithDefault(x.isPARCExit, false);
                          } else {
                            return true;
                          }
                        })), (function (x) {
                      return x.state;
                    })), (function (x) {
                  if (x === /* PAID */4 || x === /* PREINVOICED */5) {
                    return true;
                  } else {
                    return x === /* NOBONUS */3;
                  }
                })).length === 0;
  }
}

function orderDeliveredForSevenDays(order) {
  return Belt_Option.mapWithDefault(order.detail.deliveryDate, false, (function (date) {
                return ReDate.differenceInCalendarDays(ReDate.startOfDay(new Date()), date) > 7;
              }));
}

function fillForm(order, newestUndeployedCorrection) {
  var match = Belt_Option.mapWithDefault(newestUndeployedCorrection, [
        order.detail.siret,
        order.detail.finalCustomerLLD,
        Belt_Option.mapWithDefault(order.budget, undefined, (function (x) {
                return x.discount;
              })),
        order.detail.acquisitionType,
        order.detail.budget,
        order.detail.registrationDate,
        order.detail.registrationId,
        order.detail.customerInvoiceDate,
        order.detail.customerInvoiceNumber,
        order.orderType,
        order.detail.discount,
        order.detail.financialCode,
        order.detail.shortCustomerName,
        order.detail.clientFirstName,
        order.detail.parcStartDate,
        order.detail.parcEndDate,
        order.detail.orderDate,
        order.detail.renaultInvoiceDate,
        order.detail.madaDate,
        order.detail.madcDate
      ], (function (x) {
          return [
                  x.siret,
                  x.sirenFinalCustomerLLD,
                  x.companyDiscountPercentage,
                  x.acquisitionType,
                  x.budgetType,
                  x.newCarRegistrationDate,
                  x.newCarRegistrationID,
                  x.customerInvoiceDate,
                  x.customerInvoiceNumber,
                  x.orderType,
                  x.customerDiscount,
                  x.easyPack,
                  x.shortCustomerName,
                  x.customerFirstName,
                  x.parcStartDate,
                  x.parcEndDate,
                  x.orderDate,
                  x.renaultInvoiceDate,
                  x.carSaleAvailibilityDateMADA,
                  x.carSaleAvailibilityDateMADC
                ];
        }));
  return {
          siret: Belt_Option.getWithDefault(match[0], ""),
          finalCustomer: Belt_Option.getWithDefault(match[1], ""),
          discount: Belt_Option.mapWithDefault(match[2], "", (function (x) {
                  return String(x);
                })),
          acquisitionType: Belt_Option.getWithDefault(match[3], ""),
          budget: Belt_Option.getWithDefault(match[4], ""),
          registrationDate: match[5],
          registrationNumber: Belt_Option.getWithDefault(match[6], ""),
          invoiceClient: match[7],
          invoiceNumber: Belt_Option.getWithDefault(match[8], ""),
          comment: "",
          orderType: Belt_Option.getWithDefault(match[9], /* NOTSET */11),
          customerDiscount: Belt_Option.mapWithDefault(match[10], "", (function (x) {
                  return String(x);
                })),
          easyPack: Belt_Option.getWithDefault(match[11], ""),
          shortCustomerName: Belt_Option.getWithDefault(match[12], ""),
          customerFirstName: Belt_Option.getWithDefault(match[13], ""),
          parcStartDate: Belt_Option.mapWithDefault(match[14], order.detail.parcStartCalcDate, (function (x) {
                  return Caml_option.some(x);
                })),
          parcEndDate: match[15],
          orderDate: match[16],
          renaultInvoiceDate: match[17],
          carSaleAvailibilityDateMADA: match[18],
          carSaleAvailibilityDateMADC: match[19]
        };
}

function fillFormVehicle(order, newestUndeployedCorrection) {
  var match = Belt_Option.mapWithDefault(newestUndeployedCorrection, Belt_Option.mapWithDefault(order.usedCarDetail, [
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined
          ], (function (x) {
              return [
                      x.policeNumber,
                      x.registrationDate,
                      x.registrationNumber,
                      x.firstReleaseDate,
                      x.brand,
                      x.model,
                      x.version,
                      x.vin,
                      x.value,
                      x.estimatedValue,
                      x.endOfLife,
                      order.orderType
                    ];
            })), (function (x) {
          return [
                  x.usedCarPoliceNumber,
                  x.usedCarLastRegistrationDate,
                  x.usedCarRegistrationID,
                  x.usedCarFirstReleaseDate,
                  x.brandUsedCar,
                  x.modelUsedCar,
                  x.versionUsedCar,
                  x.vinUsedCar,
                  x.realTradeAmount,
                  x.estimatedTradeAmount,
                  x.scrapyardIndicator,
                  x.orderType
                ];
        }));
  return {
          orderType: Belt_Option.getWithDefault(match[11], /* NOTSET */11),
          policeNumber: Belt_Option.getWithDefault(match[0], ""),
          usedCarLastRegistrationDate: match[1],
          usedCarRegistrationID: Belt_Option.getWithDefault(match[2], ""),
          usedCarFirstReleaseDate: match[3],
          brand: Belt_Option.getWithDefault(match[4], ""),
          model: Belt_Option.getWithDefault(match[5], ""),
          version: Belt_Option.getWithDefault(match[6], ""),
          vin: Belt_Option.getWithDefault(match[7], ""),
          valueUsedCar: Belt_Option.mapWithDefault(match[8], "", (function (x) {
                  return String(x);
                })),
          estimatedValueUsedCar: Belt_Option.mapWithDefault(match[9], "", (function (x) {
                  return String(x);
                })),
          endOfLifeUsedCar: Belt_Option.mapWithDefault(match[10], "", (function (x) {
                  if (x) {
                    return "true";
                  } else {
                    return "false";
                  }
                }))
        };
}

function fillFormPerf(order, newestUndeployedCorrection) {
  var perfDetails = Belt_Option.getWithDefault(order.detail.perfDetails, /* [] */0);
  var match = Belt_Option.mapWithDefault(newestUndeployedCorrection, [
        Belt_Option.mapWithDefault(Belt_List.get(perfDetails, 0), [
              undefined,
              undefined,
              undefined
            ], (function (x) {
                return [
                        x.planPerfID,
                        x.bonusActionTypePERF,
                        x.planPerfAmount
                      ];
              })),
        Belt_Option.mapWithDefault(Belt_List.get(perfDetails, 1), [
              undefined,
              undefined,
              undefined
            ], (function (x) {
                return [
                        x.planPerfID,
                        x.bonusActionTypePERF,
                        x.planPerfAmount
                      ];
              })),
        Belt_Option.mapWithDefault(Belt_List.get(perfDetails, 2), [
              undefined,
              undefined,
              undefined
            ], (function (x) {
                return [
                        x.planPerfID,
                        x.bonusActionTypePERF,
                        x.planPerfAmount
                      ];
              }))
      ], (function (x) {
          return [
                  [
                    x.planPerfID,
                    x.bonusActionTypePERF,
                    x.planPerfAmount
                  ],
                  [
                    x.planPerfID2,
                    x.bonusActionTypePERF2,
                    x.planPerfAmount2
                  ],
                  [
                    x.planPerfID3,
                    x.bonusActionTypePERF3,
                    x.planPerfAmount3
                  ]
                ];
        }));
  var match$1 = match[2];
  var match$2 = match[1];
  var match$3 = match[0];
  return {
          bonusActionTypePERF: Belt_Option.getWithDefault(match$3[1], ""),
          bonusActionTypePERF2: Belt_Option.getWithDefault(match$2[1], ""),
          bonusActionTypePERF3: Belt_Option.getWithDefault(match$1[1], ""),
          planPerfAmount: Belt_Option.mapWithDefault(match$3[2], "", (function (x) {
                  return String(x);
                })),
          planPerfAmount2: Belt_Option.mapWithDefault(match$2[2], "", (function (x) {
                  return String(x);
                })),
          planPerfAmount3: Belt_Option.mapWithDefault(match$1[2], "", (function (x) {
                  return String(x);
                })),
          planPerfID: Belt_Option.getWithDefault(match$3[0], ""),
          planPerfID2: Belt_Option.getWithDefault(match$2[0], ""),
          planPerfID3: Belt_Option.getWithDefault(match$1[0], "")
        };
}

function fillFormAdditional(order, newestUndeployedCorrection) {
  var match = Belt_Option.mapWithDefault(newestUndeployedCorrection, [
        order.detail.model,
        order.detail.version,
        order.detail.phase,
        order.detail.salesmanName,
        order.detail.agentCode
      ], (function (x) {
          return [
                  x.modelID,
                  x.versionID,
                  x.phase,
                  x.salesmanName,
                  x.agentCode
                ];
        }));
  return {
          modelID: Belt_Option.getWithDefault(match[0], ""),
          versionID: Belt_Option.getWithDefault(match[1], ""),
          phase: Belt_Option.getWithDefault(match[2], ""),
          salesmanName: Belt_Option.getWithDefault(match[3], ""),
          agentCode: Belt_Option.getWithDefault(match[4], "")
        };
}

function shouldShowVO(order, newestUndeployedCorrection) {
  var undeployed = Belt_Option.mapWithDefault(newestUndeployedCorrection, false, (function (x) {
          if (Belt_Option.getWithDefault(x.usedCarPoliceNumber, "").length > 0 || Belt_Option.getWithDefault(x.usedCarRegistrationID, "").length > 0 || Belt_Option.mapWithDefault(x.usedCarLastRegistrationDate, false, App_Types_Date$DvmAdminFrontendFr.isValid) || Belt_Option.mapWithDefault(x.usedCarFirstReleaseDate, false, App_Types_Date$DvmAdminFrontendFr.isValid) || Belt_Option.getWithDefault(x.brandUsedCar, "").length > 0 || Belt_Option.getWithDefault(x.modelUsedCar, "").length > 0 || Belt_Option.getWithDefault(x.versionUsedCar, "").length > 0 || Belt_Option.getWithDefault(x.vinUsedCar, "").length > 0) {
            return true;
          }
          var match = Belt_Option.getWithDefault(order.orderType, /* NOTSET */11);
          if (match > 10 || match < 1) {
            return false;
          } else if (Belt_Option.mapWithDefault(x.realTradeAmount, false, (function (x) {
                    return x > 0.0;
                  })) || Belt_Option.mapWithDefault(x.estimatedTradeAmount, false, (function (x) {
                    return x > 0.0;
                  }))) {
            return true;
          } else {
            return Belt_Option.getWithDefault(x.scrapyardIndicator, false);
          }
        }));
  if (Belt_Option.isSome(order.usedCarDetail)) {
    return true;
  } else {
    return undeployed;
  }
}

var Form;

var FormPerfs;

var FormVehicle;

var FormAdditional;

export {
  Form ,
  FormPerfs ,
  FormVehicle ,
  FormAdditional ,
  nonfleetPARCDatesEditable ,
  orderDeliveredForSevenDays ,
  fillForm ,
  fillFormVehicle ,
  fillFormPerf ,
  fillFormAdditional ,
  shouldShowVO ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
