// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Date.bs.js";
import * as Order_Detail_Types_Order_Type$DvmAdminFrontendFr from "../../Order_Detail_Types_Order_Type.bs.js";

function orderCorrection_encode(v) {
  return Js_dict.fromArray([
              [
                "orderID",
                Decco.stringToJson(v.orderID)
              ],
              [
                "orderType",
                Decco.optionToJson(Order_Detail_Types_Order_Type$DvmAdminFrontendFr.Decco.t_encode, v.orderType)
              ],
              [
                "siret",
                Decco.optionToJson(Decco.stringToJson, v.siret)
              ],
              [
                "sirenFinalCustomerLLD",
                Decco.optionToJson(Decco.stringToJson, v.sirenFinalCustomerLLD)
              ],
              [
                "newCarRegistrationDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.newCarRegistrationDate)
              ],
              [
                "customerInvoiceDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.customerInvoiceDate)
              ],
              [
                "customerInvoiceNumber",
                Decco.optionToJson(Decco.stringToJson, v.customerInvoiceNumber)
              ],
              [
                "customerDiscount",
                Decco.optionToJson(Decco.floatToJson, v.customerDiscount)
              ],
              [
                "easyPack",
                Decco.optionToJson(Decco.stringToJson, v.easyPack)
              ],
              [
                "shortCustomerName",
                Decco.optionToJson(Decco.stringToJson, v.shortCustomerName)
              ],
              [
                "customerFirstName",
                Decco.optionToJson(Decco.stringToJson, v.customerFirstName)
              ],
              [
                "orderDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.orderDate)
              ],
              [
                "newCarDeliveryDateToCustomer",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.newCarDeliveryDateToCustomer)
              ],
              [
                "agentCode",
                Decco.optionToJson(Decco.stringToJson, v.agentCode)
              ],
              [
                "salesmanName",
                Decco.optionToJson(Decco.stringToJson, v.salesmanName)
              ]
            ]);
}

function orderCorrection_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var orderID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderID"), null));
  if (orderID.TAG === /* Ok */0) {
    var orderType = Decco.optionFromJson(Order_Detail_Types_Order_Type$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderType"), null));
    if (orderType.TAG === /* Ok */0) {
      var siret = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "siret"), null));
      if (siret.TAG === /* Ok */0) {
        var sirenFinalCustomerLLD = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "sirenFinalCustomerLLD"), null));
        if (sirenFinalCustomerLLD.TAG === /* Ok */0) {
          var newCarRegistrationDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "newCarRegistrationDate"), null));
          if (newCarRegistrationDate.TAG === /* Ok */0) {
            var customerInvoiceDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "customerInvoiceDate"), null));
            if (customerInvoiceDate.TAG === /* Ok */0) {
              var customerInvoiceNumber = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "customerInvoiceNumber"), null));
              if (customerInvoiceNumber.TAG === /* Ok */0) {
                var customerDiscount = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "customerDiscount"), null));
                if (customerDiscount.TAG === /* Ok */0) {
                  var easyPack = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "easyPack"), null));
                  if (easyPack.TAG === /* Ok */0) {
                    var shortCustomerName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "shortCustomerName"), null));
                    if (shortCustomerName.TAG === /* Ok */0) {
                      var customerFirstName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "customerFirstName"), null));
                      if (customerFirstName.TAG === /* Ok */0) {
                        var orderDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderDate"), null));
                        if (orderDate.TAG === /* Ok */0) {
                          var newCarDeliveryDateToCustomer = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "newCarDeliveryDateToCustomer"), null));
                          if (newCarDeliveryDateToCustomer.TAG === /* Ok */0) {
                            var agentCode = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "agentCode"), null));
                            if (agentCode.TAG === /* Ok */0) {
                              var salesmanName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "salesmanName"), null));
                              if (salesmanName.TAG === /* Ok */0) {
                                return {
                                        TAG: /* Ok */0,
                                        _0: {
                                          orderID: orderID._0,
                                          orderType: orderType._0,
                                          siret: siret._0,
                                          sirenFinalCustomerLLD: sirenFinalCustomerLLD._0,
                                          newCarRegistrationDate: newCarRegistrationDate._0,
                                          customerInvoiceDate: customerInvoiceDate._0,
                                          customerInvoiceNumber: customerInvoiceNumber._0,
                                          customerDiscount: customerDiscount._0,
                                          easyPack: easyPack._0,
                                          shortCustomerName: shortCustomerName._0,
                                          customerFirstName: customerFirstName._0,
                                          orderDate: orderDate._0,
                                          newCarDeliveryDateToCustomer: newCarDeliveryDateToCustomer._0,
                                          agentCode: agentCode._0,
                                          salesmanName: salesmanName._0
                                        }
                                      };
                              }
                              var e = salesmanName._0;
                              return {
                                      TAG: /* Error */1,
                                      _0: {
                                        path: ".salesmanName" + e.path,
                                        message: e.message,
                                        value: e.value
                                      }
                                    };
                            }
                            var e$1 = agentCode._0;
                            return {
                                    TAG: /* Error */1,
                                    _0: {
                                      path: ".agentCode" + e$1.path,
                                      message: e$1.message,
                                      value: e$1.value
                                    }
                                  };
                          }
                          var e$2 = newCarDeliveryDateToCustomer._0;
                          return {
                                  TAG: /* Error */1,
                                  _0: {
                                    path: ".newCarDeliveryDateToCustomer" + e$2.path,
                                    message: e$2.message,
                                    value: e$2.value
                                  }
                                };
                        }
                        var e$3 = orderDate._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".orderDate" + e$3.path,
                                  message: e$3.message,
                                  value: e$3.value
                                }
                              };
                      }
                      var e$4 = customerFirstName._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".customerFirstName" + e$4.path,
                                message: e$4.message,
                                value: e$4.value
                              }
                            };
                    }
                    var e$5 = shortCustomerName._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".shortCustomerName" + e$5.path,
                              message: e$5.message,
                              value: e$5.value
                            }
                          };
                  }
                  var e$6 = easyPack._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".easyPack" + e$6.path,
                            message: e$6.message,
                            value: e$6.value
                          }
                        };
                }
                var e$7 = customerDiscount._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".customerDiscount" + e$7.path,
                          message: e$7.message,
                          value: e$7.value
                        }
                      };
              }
              var e$8 = customerInvoiceNumber._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".customerInvoiceNumber" + e$8.path,
                        message: e$8.message,
                        value: e$8.value
                      }
                    };
            }
            var e$9 = customerInvoiceDate._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".customerInvoiceDate" + e$9.path,
                      message: e$9.message,
                      value: e$9.value
                    }
                  };
          }
          var e$10 = newCarRegistrationDate._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".newCarRegistrationDate" + e$10.path,
                    message: e$10.message,
                    value: e$10.value
                  }
                };
        }
        var e$11 = sirenFinalCustomerLLD._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".sirenFinalCustomerLLD" + e$11.path,
                  message: e$11.message,
                  value: e$11.value
                }
              };
      }
      var e$12 = siret._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".siret" + e$12.path,
                message: e$12.message,
                value: e$12.value
              }
            };
    }
    var e$13 = orderType._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".orderType" + e$13.path,
              message: e$13.message,
              value: e$13.value
            }
          };
  }
  var e$14 = orderID._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".orderID" + e$14.path,
            message: e$14.message,
            value: e$14.value
          }
        };
}

export {
  orderCorrection_encode ,
  orderCorrection_decode ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
