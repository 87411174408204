// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function createResponseType(responseTypes) {
  if (Belt_List.some(responseTypes, (function (responseType) {
            return responseType === "none";
          }))) {
    return "none";
  } else {
    return Belt_List.reduce(responseTypes, "", (function (result, responseType) {
                  if (result.length === 0) {
                    return String(responseType);
                  } else {
                    return "" + result + " " + String(responseType) + "";
                  }
                }));
  }
}

function createScope(scopes) {
  return Belt_List.reduce(scopes, "", (function (result, item) {
                if (result.length === 0) {
                  return item;
                } else {
                  return "" + result + " " + item + "";
                }
              }));
}

function createOidcClientSettings(automaticSilentRenew, responseTypes, scopes, acrValues, authority, clientId, metadata, param) {
  var currentLocationOrigin = window.location.origin;
  var redirectUri = currentLocationOrigin.endsWith("/") ? currentLocationOrigin : "" + currentLocationOrigin + "/";
  return {
          authority: authority,
          client_id: clientId,
          redirect_uri: redirectUri,
          post_logout_redirect_uri: redirectUri + "logout",
          silent_redirect_uri: undefined,
          automaticSilentRenew: automaticSilentRenew,
          response_type: Belt_Option.mapWithDefault(responseTypes, undefined, (function (responseTypes) {
                  return createResponseType(responseTypes);
                })),
          scope: Belt_Option.mapWithDefault(scopes, undefined, (function (scopes) {
                  return createScope(scopes);
                })),
          acr_values: acrValues,
          metadata: metadata,
          accessTokenExpiringNotificationTime: 180
        };
}

export {
  createResponseType ,
  createScope ,
  createOidcClientSettings ,
}
/* No side effect */
