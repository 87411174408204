// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as RestApi$DvmAdminFrontendFr from "../../../../api/RestApi.bs.js";
import * as Flash_Edit_Types$DvmAdminFrontendFr from "../../../flash/edit/Flash_Edit_Types.bs.js";

function deleteResellingOrder(userManager, setResult, selectedRole, orderId) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("DELETE", undefined, userManager, selectedRole, "/orders/" + orderId + "/resell").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function postResellingOrder(userManager, setResult, orderId, submissionCallbacks, selectedRole, resellingOrderType) {
  Curry._1(setResult, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.call("POST", Flash_Edit_Types$DvmAdminFrontendFr.responseType_decode, undefined, userManager, selectedRole, "/orders/" + orderId + "/resell?resellingOrderType=" + resellingOrderType + "").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          var resellingOrderId = result._0;
          Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
          return Curry._1(setResult, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: resellingOrderId
                              };
                      }));
        }
        var error = result._0;
        Curry._1(submissionCallbacks.notifyOnFailure, /* FailedToCreateResellingOrder */0);
        Curry._1(setResult, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

function disableReselling(userManager, setResultDisableReselling, orderId, submissionCallbacks, selectedRole) {
  Curry._1(setResultDisableReselling, (function (param) {
          return /* Pending */1;
        }));
  RestApi$DvmAdminFrontendFr.WithEmptyResponse.call("PUT", undefined, userManager, selectedRole, "/orders/" + orderId + "/resell/disable").then(function (result) {
        if (result.TAG === /* OkResult */0) {
          Curry._1(submissionCallbacks.notifyOnSuccess, undefined);
          return Curry._1(setResultDisableReselling, (function (param) {
                        return {
                                TAG: /* Ok */0,
                                _0: undefined
                              };
                      }));
        }
        var error = result._0;
        Curry._1(submissionCallbacks.notifyOnFailure, /* FailedToCreateResellingOrder */0);
        Curry._1(setResultDisableReselling, (function (param) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              }));
      });
}

export {
  deleteResellingOrder ,
  postResellingOrder ,
  disableReselling ,
}
/* RestApi-DvmAdminFrontendFr Not a pure module */
