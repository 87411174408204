// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReDate_utils from "@mobily/rescript-date/src/ReDate_utils.bs.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../types/App_Types_Date.bs.js";
import * as Messages_Error_Inputs$DvmAdminFrontendFr from "../../intl/messages/Messages_Error_Inputs.bs.js";

function validateRequired(value) {
  if (value === "") {
    return {
            TAG: /* Error */1,
            _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldRequired
          };
  } else {
    return {
            TAG: /* Ok */0,
            _0: value
          };
  }
}

function validateWithRegex(value, regex) {
  if (regex.test(value)) {
    return {
            TAG: /* Ok */0,
            _0: value
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
          };
  }
}

function validateDate(value, param) {
  return Belt_Option.mapWithDefault(value, {
              TAG: /* Ok */0,
              _0: undefined
            }, (function (date) {
                if (App_Types_Date$DvmAdminFrontendFr.isValid(date)) {
                  return {
                          TAG: /* Ok */0,
                          _0: Caml_option.some(date)
                        };
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
                        };
                }
              }));
}

function validateRequiredDate(value, param) {
  return Belt_Option.mapWithDefault(value, {
              TAG: /* Error */1,
              _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldRequired
            }, (function (date) {
                if (App_Types_Date$DvmAdminFrontendFr.isValid(date)) {
                  return {
                          TAG: /* Ok */0,
                          _0: date
                        };
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
                        };
                }
              }));
}

function validateDateCompare(smallerDate, largerDate, thisDate, needsSameYearOpt, dateTooSmallMessageOpt, dateTooLargeMessageOpt, canBeEqualOpt, param) {
  var needsSameYear = needsSameYearOpt !== undefined ? needsSameYearOpt : false;
  var dateTooSmallMessage = dateTooSmallMessageOpt !== undefined ? dateTooSmallMessageOpt : Messages_Error_Inputs$DvmAdminFrontendFr.dateTooSmall;
  var dateTooLargeMessage = dateTooLargeMessageOpt !== undefined ? dateTooLargeMessageOpt : Messages_Error_Inputs$DvmAdminFrontendFr.dateTooLarge;
  var canBeEqual = canBeEqualOpt !== undefined ? canBeEqualOpt : false;
  var match = thisDate === "smaller" ? [
      smallerDate,
      largerDate
    ] : [
      largerDate,
      smallerDate
    ];
  var that = match[1];
  return Belt_Option.mapWithDefault(match[0], {
              TAG: /* Ok */0,
              _0: undefined
            }, (function ($$this) {
                if (App_Types_Date$DvmAdminFrontendFr.isValid($$this)) {
                  return Belt_Option.mapWithDefault(that, {
                              TAG: /* Ok */0,
                              _0: Caml_option.some($$this)
                            }, (function (that) {
                                if (!App_Types_Date$DvmAdminFrontendFr.isValid(that)) {
                                  return {
                                          TAG: /* Error */1,
                                          _0: Messages_Error_Inputs$DvmAdminFrontendFr.otherDateNotValid
                                        };
                                }
                                var thisStartDay = ReDate_utils.makeStartOfDayDate($$this);
                                var thatStartDay = ReDate_utils.makeStartOfDayDate(that);
                                if (ReDate.getYear(thisStartDay) === ReDate.getYear(thatStartDay) || !needsSameYear) {
                                  if (thisDate === "smaller") {
                                    if (thisStartDay.valueOf() < thatStartDay.valueOf() || (
                                        canBeEqual ? thisStartDay.valueOf() === thatStartDay.valueOf() : false
                                      )) {
                                      return {
                                              TAG: /* Ok */0,
                                              _0: Caml_option.some($$this)
                                            };
                                    } else {
                                      return {
                                              TAG: /* Error */1,
                                              _0: dateTooLargeMessage
                                            };
                                    }
                                  } else if (thisStartDay.valueOf() > thatStartDay.valueOf() || (
                                      canBeEqual ? thisStartDay.valueOf() === thatStartDay.valueOf() : false
                                    )) {
                                    return {
                                            TAG: /* Ok */0,
                                            _0: Caml_option.some($$this)
                                          };
                                  } else {
                                    return {
                                            TAG: /* Error */1,
                                            _0: dateTooSmallMessage
                                          };
                                  }
                                } else {
                                  return {
                                          TAG: /* Error */1,
                                          _0: Messages_Error_Inputs$DvmAdminFrontendFr.datesNotSameYear
                                        };
                                }
                              }));
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
                        };
                }
              }));
}

function validateRequiredDateCompare(smallerDate, largerDate, thisDate, required, needsSameYearOpt, param) {
  var needsSameYear = needsSameYearOpt !== undefined ? needsSameYearOpt : false;
  var match = thisDate === "smaller" ? [
      smallerDate,
      largerDate
    ] : [
      largerDate,
      smallerDate
    ];
  var that = match[1];
  return Belt_Option.mapWithDefault(match[0], required ? ({
                  TAG: /* Error */1,
                  _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldRequired
                }) : ({
                  TAG: /* Ok */0,
                  _0: undefined
                }), (function ($$this) {
                if (App_Types_Date$DvmAdminFrontendFr.isValid($$this)) {
                  return Belt_Option.mapWithDefault(that, {
                              TAG: /* Error */1,
                              _0: Messages_Error_Inputs$DvmAdminFrontendFr.otherDateNotValid
                            }, (function (that) {
                                if (App_Types_Date$DvmAdminFrontendFr.isValid(that)) {
                                  if (ReDate.getYear($$this) === ReDate.getYear(that) || !needsSameYear) {
                                    if (thisDate === "smaller") {
                                      if ($$this.valueOf() < that.valueOf()) {
                                        return {
                                                TAG: /* Ok */0,
                                                _0: Caml_option.some($$this)
                                              };
                                      } else {
                                        return {
                                                TAG: /* Error */1,
                                                _0: Messages_Error_Inputs$DvmAdminFrontendFr.dateTooLarge
                                              };
                                      }
                                    } else if ($$this.valueOf() > that.valueOf()) {
                                      return {
                                              TAG: /* Ok */0,
                                              _0: Caml_option.some($$this)
                                            };
                                    } else {
                                      return {
                                              TAG: /* Error */1,
                                              _0: Messages_Error_Inputs$DvmAdminFrontendFr.dateTooSmall
                                            };
                                    }
                                  } else {
                                    return {
                                            TAG: /* Error */1,
                                            _0: Messages_Error_Inputs$DvmAdminFrontendFr.datesNotSameYear
                                          };
                                  }
                                } else {
                                  return {
                                          TAG: /* Error */1,
                                          _0: Messages_Error_Inputs$DvmAdminFrontendFr.otherDateNotValid
                                        };
                                }
                              }));
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
                        };
                }
              }));
}

function getErrorMessage(intl, validationResult) {
  return Belt_Option.flatMap(validationResult, (function (validationResult) {
                if (validationResult.TAG === /* Ok */0) {
                  return ;
                } else {
                  return intl.formatMessage(validationResult._0);
                }
              }));
}

function validateInteger(value) {
  if (value === "") {
    return {
            TAG: /* Ok */0,
            _0: undefined
          };
  } else if (/^\-?\d+$/.test(value)) {
    return {
            TAG: /* Ok */0,
            _0: Belt_Int.fromString(value)
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
          };
  }
}

function validateIntegerRequired(value) {
  if (value !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: value
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldRequired
          };
  }
}

function validateIntegerPositiveRequired(value) {
  if (value !== undefined) {
    if (value >= 0) {
      return {
              TAG: /* Ok */0,
              _0: value
            };
    } else {
      return {
              TAG: /* Error */1,
              _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldPositive
            };
    }
  } else {
    return {
            TAG: /* Error */1,
            _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldRequired
          };
  }
}

function validateIntegerNegativeRequired(value) {
  if (value !== undefined) {
    if (value <= 0) {
      return {
              TAG: /* Ok */0,
              _0: value
            };
    } else {
      return {
              TAG: /* Error */1,
              _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldNegative
            };
    }
  } else {
    return {
            TAG: /* Error */1,
            _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldRequired
          };
  }
}

function validateFloatPositiveRequired(value) {
  var number = Belt_Float.fromString(value);
  if (number !== undefined) {
    if (number >= 0) {
      return {
              TAG: /* Ok */0,
              _0: number
            };
    } else {
      return {
              TAG: /* Error */1,
              _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldPositive
            };
    }
  } else {
    return {
            TAG: /* Error */1,
            _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldRequired
          };
  }
}

function validateFloatPositive(value) {
  var number = Belt_Float.fromString(value);
  if (number !== undefined) {
    if (number >= 0) {
      return {
              TAG: /* Ok */0,
              _0: number
            };
    } else {
      return {
              TAG: /* Error */1,
              _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldPositive
            };
    }
  } else {
    return {
            TAG: /* Ok */0,
            _0: undefined
          };
  }
}

function validateFloatMaximumAndRequired(value, maximum) {
  var number = Belt_Float.fromString(value);
  if (number !== undefined) {
    if (number > maximum) {
      return {
              TAG: /* Error */1,
              _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
            };
    } else {
      return {
              TAG: /* Ok */0,
              _0: number
            };
    }
  } else {
    return {
            TAG: /* Error */1,
            _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldRequired
          };
  }
}

function validateListRequired(list) {
  var match = Belt_List.length(list);
  if (match !== 0) {
    return {
            TAG: /* Ok */0,
            _0: list
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldRequired
          };
  }
}

function validateListMaxLength(length, list) {
  if (Belt_List.length(list) > length) {
    return {
            TAG: /* Error */1,
            _0: Messages_Error_Inputs$DvmAdminFrontendFr.listTooLong
          };
  } else {
    return {
            TAG: /* Ok */0,
            _0: list
          };
  }
}

function validateMaxCharacters(value, maxCharacters) {
  if (value.length <= maxCharacters) {
    return {
            TAG: /* Ok */0,
            _0: value
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldMoreCharactersThanMaximum
          };
  }
}

function validateLenghtRange(value, minCharacters, maxCharacters) {
  var valueLength = value.length;
  var match = valueLength < minCharacters;
  var match$1 = valueLength > maxCharacters;
  if (match) {
    return {
            TAG: /* Error */1,
            _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldLessCharactersThanMinimum
          };
  } else if (match$1) {
    return {
            TAG: /* Error */1,
            _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldMoreCharactersThanMaximum
          };
  } else {
    return {
            TAG: /* Ok */0,
            _0: value
          };
  }
}

export {
  validateRequired ,
  validateWithRegex ,
  validateDate ,
  validateRequiredDate ,
  validateDateCompare ,
  validateRequiredDateCompare ,
  getErrorMessage ,
  validateInteger ,
  validateIntegerRequired ,
  validateIntegerPositiveRequired ,
  validateIntegerNegativeRequired ,
  validateFloatPositiveRequired ,
  validateFloatPositive ,
  validateFloatMaximumAndRequired ,
  validateListRequired ,
  validateListMaxLength ,
  validateMaxCharacters ,
  validateLenghtRange ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
