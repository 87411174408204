// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../../../common/utils/Utils_Form.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../../../context/user/User_Types_Utils.bs.js";
import * as Messages_Error_Inputs$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Error_Inputs.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../../../intl/messages/orders/Messages_Orders_Detail.bs.js";

var validators_easyPack = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: param.easyPack
            };
    })
};

var validators_salesmanName = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: param.salesmanName
            };
    })
};

var validators_agentCode = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var agentCode = param.agentCode;
      if (agentCode.length === 0) {
        return {
                TAG: /* Ok */0,
                _0: agentCode
              };
      } else {
        return Utils_Form$DvmAdminFrontendFr.validateLenghtRange(agentCode, 3, 3);
      }
    })
};

var validators_customerDiscount = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      var number = Belt_Float.fromString(param.customerDiscount);
      if (number !== undefined) {
        if (number >= 0) {
          return {
                  TAG: /* Ok */0,
                  _0: number
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: Messages_Orders_Detail$DvmAdminFrontendFr.customerDiscountPositive
                };
        }
      } else {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
    })
};

var validators_invoiceClient = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return Utils_Form$DvmAdminFrontendFr.validateDate(param.invoiceClient, undefined);
    })
};

var validators_invoiceNumber = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: param.invoiceNumber
            };
    })
};

var validators_deliveryDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var deliveryDate = param.deliveryDate;
      if (User_Types_Utils$DvmAdminFrontendFr.isAdmin(param$1.selectedRole)) {
        return Utils_Form$DvmAdminFrontendFr.validateDate(deliveryDate, undefined);
      } else {
        return Belt_Option.mapWithDefault(param$1.lastOrigOrderCorrectionValues, Utils_Form$DvmAdminFrontendFr.validateDate(deliveryDate, undefined), (function (corrVals) {
                      return Utils_Form$DvmAdminFrontendFr.validateDateCompare(corrVals.parcEndDate, deliveryDate, "larger", undefined, Messages_Orders_Detail$DvmAdminFrontendFr.deliveryDateBeforeExitParc, undefined, undefined, undefined);
                    }));
      }
    })
};

var validators_registrationDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      return Utils_Form$DvmAdminFrontendFr.validateDate(param.registrationDate, undefined);
    })
};

var validators_orderDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var orderDate = param.orderDate;
      if (User_Types_Utils$DvmAdminFrontendFr.isAdmin(param$1.selectedRole)) {
        return Utils_Form$DvmAdminFrontendFr.validateDate(orderDate, undefined);
      } else {
        return Belt_Option.mapWithDefault(param$1.lastOrigOrderCorrectionValues, {
                    TAG: /* Error */1,
                    _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
                  }, (function (corrVals) {
                      return Belt_Option.mapWithDefault(corrVals.parcEndDate, {
                                  TAG: /* Error */1,
                                  _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
                                }, (function (parcEnd) {
                                    var val = Utils_Form$DvmAdminFrontendFr.validateDateCompare(Caml_option.some(ReDate.startOfMonth(parcEnd)), orderDate, "larger", undefined, Messages_Orders_Detail$DvmAdminFrontendFr.orderDateNotInParcPeriod, undefined, true, undefined);
                                    if (val.TAG === /* Ok */0) {
                                      return Utils_Form$DvmAdminFrontendFr.validateDateCompare(val._0, Caml_option.some(parcEnd), "smaller", undefined, undefined, Messages_Orders_Detail$DvmAdminFrontendFr.orderDateNotInParcPeriod, true, undefined);
                                    } else {
                                      return {
                                              TAG: /* Error */1,
                                              _0: val._0
                                            };
                                    }
                                  }));
                    }));
      }
    })
};

var validators_customerFirstName = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      var orderType = param.orderType;
      if (orderType === /* EP */7 || orderType === /* RP */9) {
        return {
                TAG: /* Ok */0,
                _0: param.customerFirstName
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
    })
};

var validators_shortCustomerName = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      var orderType = param.orderType;
      if (orderType === /* EP */7 || orderType === /* RP */9) {
        return {
                TAG: /* Ok */0,
                _0: param.shortCustomerName
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
    })
};

var validators_finalCustomer = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      var orderType = param.orderType;
      if (!(orderType === /* ES */8 || orderType === /* RS */10)) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
      var finalCustomer = param.finalCustomer;
      if (!(param.finalCustomerExists || finalCustomer.length === 0)) {
        return {
                TAG: /* Error */1,
                _0: Messages_Orders_Detail$DvmAdminFrontendFr.sirenNotExist
              };
      }
      if (!(param.siretBudgetLLD && param.siretActive && param.siret.length > 0)) {
        return {
                TAG: /* Ok */0,
                _0: finalCustomer
              };
      }
      var val = Utils_Form$DvmAdminFrontendFr.validateRequired(finalCustomer);
      if (val.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: val._0
              };
      } else {
        return {
                TAG: /* Error */1,
                _0: val._0
              };
      }
    })
};

var validators_siret = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      var orderType = param.orderType;
      if (!(orderType === /* ES */8 || orderType === /* RS */10)) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
      var siret = param.siret;
      if (param.siretActive && param.siretExists || siret.length === 0) {
        return {
                TAG: /* Ok */0,
                _0: siret
              };
      } else {
        return {
                TAG: /* Error */1,
                _0: Messages_Orders_Detail$DvmAdminFrontendFr.siretNotActiveOrWrong
              };
      }
    })
};

var validators_orderType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      var orderType = param.orderType;
      if (orderType > 10 || orderType < 7) {
        return {
                TAG: /* Error */1,
                _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldRequired
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: orderType
              };
      }
    })
};

var validators = {
  finalCustomerExists: undefined,
  siretBudgetLLD: undefined,
  siretActive: undefined,
  siretExists: undefined,
  easyPack: validators_easyPack,
  salesmanName: validators_salesmanName,
  agentCode: validators_agentCode,
  customerDiscount: validators_customerDiscount,
  invoiceClient: validators_invoiceClient,
  invoiceNumber: validators_invoiceNumber,
  deliveryDate: validators_deliveryDate,
  registrationDate: validators_registrationDate,
  orderDate: validators_orderDate,
  customerFirstName: validators_customerFirstName,
  shortCustomerName: validators_shortCustomerName,
  finalCustomer: validators_finalCustomer,
  siret: validators_siret,
  orderType: validators_orderType
};

function initialFieldsStatuses(_input) {
  return {
          finalCustomerExists: /* Pristine */0,
          siretBudgetLLD: /* Pristine */0,
          siretActive: /* Pristine */0,
          siretExists: /* Pristine */0,
          easyPack: /* Pristine */0,
          salesmanName: /* Pristine */0,
          agentCode: /* Pristine */0,
          customerDiscount: /* Pristine */0,
          invoiceClient: /* Pristine */0,
          invoiceNumber: /* Pristine */0,
          deliveryDate: /* Pristine */0,
          registrationDate: /* Pristine */0,
          orderDate: /* Pristine */0,
          customerFirstName: /* Pristine */0,
          shortCustomerName: /* Pristine */0,
          finalCustomer: /* Pristine */0,
          siret: /* Pristine */0,
          orderType: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses, metadata) {
  var match_0 = {
    TAG: /* Ok */0,
    _0: input.finalCustomerExists
  };
  var match_0$1 = {
    TAG: /* Ok */0,
    _0: input.siretBudgetLLD
  };
  var match_0$2 = {
    TAG: /* Ok */0,
    _0: input.siretActive
  };
  var match_0$3 = {
    TAG: /* Ok */0,
    _0: input.siretExists
  };
  var match = fieldsStatuses.easyPack;
  var match_0$4 = match ? match._0 : Curry._2(validators.easyPack.validate, input, metadata);
  var match$1 = fieldsStatuses.salesmanName;
  var match_0$5 = match$1 ? match$1._0 : Curry._2(validators.salesmanName.validate, input, metadata);
  var match$2 = fieldsStatuses.agentCode;
  var match_0$6 = match$2 ? match$2._0 : Curry._2(validators.agentCode.validate, input, metadata);
  var match$3 = fieldsStatuses.customerDiscount;
  var match_0$7 = match$3 ? match$3._0 : Curry._2(validators.customerDiscount.validate, input, metadata);
  var match$4 = fieldsStatuses.invoiceClient;
  var match_0$8 = match$4 ? match$4._0 : Curry._2(validators.invoiceClient.validate, input, metadata);
  var match$5 = fieldsStatuses.invoiceNumber;
  var match_0$9 = match$5 ? match$5._0 : Curry._2(validators.invoiceNumber.validate, input, metadata);
  var match$6 = fieldsStatuses.deliveryDate;
  var match_0$10 = match$6 ? match$6._0 : Curry._2(validators.deliveryDate.validate, input, metadata);
  var match$7 = fieldsStatuses.registrationDate;
  var match_0$11 = match$7 ? match$7._0 : Curry._2(validators.registrationDate.validate, input, metadata);
  var match$8 = fieldsStatuses.orderDate;
  var match_0$12 = match$8 ? match$8._0 : Curry._2(validators.orderDate.validate, input, metadata);
  var match$9 = fieldsStatuses.customerFirstName;
  var match_0$13 = match$9 ? match$9._0 : Curry._2(validators.customerFirstName.validate, input, metadata);
  var match$10 = fieldsStatuses.shortCustomerName;
  var match_0$14 = match$10 ? match$10._0 : Curry._2(validators.shortCustomerName.validate, input, metadata);
  var match$11 = fieldsStatuses.finalCustomer;
  var match_0$15 = match$11 ? match$11._0 : Curry._2(validators.finalCustomer.validate, input, metadata);
  var match$12 = fieldsStatuses.siret;
  var match_0$16 = match$12 ? match$12._0 : Curry._2(validators.siret.validate, input, metadata);
  var match$13 = fieldsStatuses.orderType;
  var match_0$17 = match$13 ? match$13._0 : Curry._2(validators.orderType.validate, input, metadata);
  var finalCustomerExistsResult = match_0;
  var finalCustomerExistsResult$1;
  if (finalCustomerExistsResult.TAG === /* Ok */0) {
    var siretBudgetLLDResult = match_0$1;
    if (siretBudgetLLDResult.TAG === /* Ok */0) {
      var siretActiveResult = match_0$2;
      if (siretActiveResult.TAG === /* Ok */0) {
        var siretExistsResult = match_0$3;
        if (siretExistsResult.TAG === /* Ok */0) {
          var easyPackResult = match_0$4;
          if (easyPackResult.TAG === /* Ok */0) {
            var salesmanNameResult = match_0$5;
            if (salesmanNameResult.TAG === /* Ok */0) {
              var agentCodeResult = match_0$6;
              if (agentCodeResult.TAG === /* Ok */0) {
                var customerDiscountResult = match_0$7;
                if (customerDiscountResult.TAG === /* Ok */0) {
                  var invoiceClientResult = match_0$8;
                  if (invoiceClientResult.TAG === /* Ok */0) {
                    var invoiceNumberResult = match_0$9;
                    if (invoiceNumberResult.TAG === /* Ok */0) {
                      var deliveryDateResult = match_0$10;
                      if (deliveryDateResult.TAG === /* Ok */0) {
                        var registrationDateResult = match_0$11;
                        if (registrationDateResult.TAG === /* Ok */0) {
                          var orderDateResult = match_0$12;
                          if (orderDateResult.TAG === /* Ok */0) {
                            var customerFirstNameResult = match_0$13;
                            if (customerFirstNameResult.TAG === /* Ok */0) {
                              var shortCustomerNameResult = match_0$14;
                              if (shortCustomerNameResult.TAG === /* Ok */0) {
                                var finalCustomerResult = match_0$15;
                                if (finalCustomerResult.TAG === /* Ok */0) {
                                  var siretResult = match_0$16;
                                  if (siretResult.TAG === /* Ok */0) {
                                    var orderTypeResult = match_0$17;
                                    if (orderTypeResult.TAG === /* Ok */0) {
                                      return {
                                              TAG: /* Valid */0,
                                              output: {
                                                orderType: orderTypeResult._0,
                                                siret: siretResult._0,
                                                finalCustomer: finalCustomerResult._0,
                                                shortCustomerName: shortCustomerNameResult._0,
                                                customerFirstName: customerFirstNameResult._0,
                                                orderDate: orderDateResult._0,
                                                registrationDate: registrationDateResult._0,
                                                deliveryDate: deliveryDateResult._0,
                                                invoiceNumber: invoiceNumberResult._0,
                                                invoiceClient: invoiceClientResult._0,
                                                customerDiscount: customerDiscountResult._0,
                                                agentCode: agentCodeResult._0,
                                                salesmanName: salesmanNameResult._0,
                                                easyPack: easyPackResult._0,
                                                siretExists: siretExistsResult._0,
                                                siretActive: siretActiveResult._0,
                                                siretBudgetLLD: siretBudgetLLDResult._0,
                                                finalCustomerExists: finalCustomerExistsResult._0
                                              },
                                              fieldsStatuses: {
                                                finalCustomerExists: /* Dirty */{
                                                  _0: finalCustomerExistsResult,
                                                  _1: /* Hidden */1
                                                },
                                                siretBudgetLLD: /* Dirty */{
                                                  _0: siretBudgetLLDResult,
                                                  _1: /* Hidden */1
                                                },
                                                siretActive: /* Dirty */{
                                                  _0: siretActiveResult,
                                                  _1: /* Hidden */1
                                                },
                                                siretExists: /* Dirty */{
                                                  _0: siretExistsResult,
                                                  _1: /* Hidden */1
                                                },
                                                easyPack: /* Dirty */{
                                                  _0: easyPackResult,
                                                  _1: /* Shown */0
                                                },
                                                salesmanName: /* Dirty */{
                                                  _0: salesmanNameResult,
                                                  _1: /* Shown */0
                                                },
                                                agentCode: /* Dirty */{
                                                  _0: agentCodeResult,
                                                  _1: /* Shown */0
                                                },
                                                customerDiscount: /* Dirty */{
                                                  _0: customerDiscountResult,
                                                  _1: /* Shown */0
                                                },
                                                invoiceClient: /* Dirty */{
                                                  _0: invoiceClientResult,
                                                  _1: /* Shown */0
                                                },
                                                invoiceNumber: /* Dirty */{
                                                  _0: invoiceNumberResult,
                                                  _1: /* Shown */0
                                                },
                                                deliveryDate: /* Dirty */{
                                                  _0: deliveryDateResult,
                                                  _1: /* Shown */0
                                                },
                                                registrationDate: /* Dirty */{
                                                  _0: registrationDateResult,
                                                  _1: /* Shown */0
                                                },
                                                orderDate: /* Dirty */{
                                                  _0: orderDateResult,
                                                  _1: /* Shown */0
                                                },
                                                customerFirstName: /* Dirty */{
                                                  _0: customerFirstNameResult,
                                                  _1: /* Shown */0
                                                },
                                                shortCustomerName: /* Dirty */{
                                                  _0: shortCustomerNameResult,
                                                  _1: /* Shown */0
                                                },
                                                finalCustomer: /* Dirty */{
                                                  _0: finalCustomerResult,
                                                  _1: /* Shown */0
                                                },
                                                siret: /* Dirty */{
                                                  _0: siretResult,
                                                  _1: /* Shown */0
                                                },
                                                orderType: /* Dirty */{
                                                  _0: orderTypeResult,
                                                  _1: /* Shown */0
                                                }
                                              },
                                              collectionsStatuses: undefined
                                            };
                                    }
                                    finalCustomerExistsResult$1 = finalCustomerExistsResult;
                                  } else {
                                    finalCustomerExistsResult$1 = finalCustomerExistsResult;
                                  }
                                } else {
                                  finalCustomerExistsResult$1 = finalCustomerExistsResult;
                                }
                              } else {
                                finalCustomerExistsResult$1 = finalCustomerExistsResult;
                              }
                            } else {
                              finalCustomerExistsResult$1 = finalCustomerExistsResult;
                            }
                          } else {
                            finalCustomerExistsResult$1 = finalCustomerExistsResult;
                          }
                        } else {
                          finalCustomerExistsResult$1 = finalCustomerExistsResult;
                        }
                      } else {
                        finalCustomerExistsResult$1 = finalCustomerExistsResult;
                      }
                    } else {
                      finalCustomerExistsResult$1 = finalCustomerExistsResult;
                    }
                  } else {
                    finalCustomerExistsResult$1 = finalCustomerExistsResult;
                  }
                } else {
                  finalCustomerExistsResult$1 = finalCustomerExistsResult;
                }
              } else {
                finalCustomerExistsResult$1 = finalCustomerExistsResult;
              }
            } else {
              finalCustomerExistsResult$1 = finalCustomerExistsResult;
            }
          } else {
            finalCustomerExistsResult$1 = finalCustomerExistsResult;
          }
        } else {
          finalCustomerExistsResult$1 = finalCustomerExistsResult;
        }
      } else {
        finalCustomerExistsResult$1 = finalCustomerExistsResult;
      }
    } else {
      finalCustomerExistsResult$1 = finalCustomerExistsResult;
    }
  } else {
    finalCustomerExistsResult$1 = finalCustomerExistsResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            finalCustomerExists: /* Dirty */{
              _0: finalCustomerExistsResult$1,
              _1: /* Hidden */1
            },
            siretBudgetLLD: /* Dirty */{
              _0: match_0$1,
              _1: /* Hidden */1
            },
            siretActive: /* Dirty */{
              _0: match_0$2,
              _1: /* Hidden */1
            },
            siretExists: /* Dirty */{
              _0: match_0$3,
              _1: /* Hidden */1
            },
            easyPack: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            salesmanName: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            },
            agentCode: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            },
            customerDiscount: /* Dirty */{
              _0: match_0$7,
              _1: /* Shown */0
            },
            invoiceClient: /* Dirty */{
              _0: match_0$8,
              _1: /* Shown */0
            },
            invoiceNumber: /* Dirty */{
              _0: match_0$9,
              _1: /* Shown */0
            },
            deliveryDate: /* Dirty */{
              _0: match_0$10,
              _1: /* Shown */0
            },
            registrationDate: /* Dirty */{
              _0: match_0$11,
              _1: /* Shown */0
            },
            orderDate: /* Dirty */{
              _0: match_0$12,
              _1: /* Shown */0
            },
            customerFirstName: /* Dirty */{
              _0: match_0$13,
              _1: /* Shown */0
            },
            shortCustomerName: /* Dirty */{
              _0: match_0$14,
              _1: /* Shown */0
            },
            finalCustomer: /* Dirty */{
              _0: match_0$15,
              _1: /* Shown */0
            },
            siret: /* Dirty */{
              _0: match_0$16,
              _1: /* Shown */0
            },
            orderType: /* Dirty */{
              _0: match_0$17,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, metadata, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurFinalCustomerExistsField */0 :
                  var result = Formality.validateFieldOnBlurWithoutValidator(state.input.finalCustomerExists, state.fieldsStatuses.finalCustomerExists, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  finalCustomerExists: status,
                                  siretBudgetLLD: init.siretBudgetLLD,
                                  siretActive: init.siretActive,
                                  siretExists: init.siretExists,
                                  easyPack: init.easyPack,
                                  salesmanName: init.salesmanName,
                                  agentCode: init.agentCode,
                                  customerDiscount: init.customerDiscount,
                                  invoiceClient: init.invoiceClient,
                                  invoiceNumber: init.invoiceNumber,
                                  deliveryDate: init.deliveryDate,
                                  registrationDate: init.registrationDate,
                                  orderDate: init.orderDate,
                                  customerFirstName: init.customerFirstName,
                                  shortCustomerName: init.shortCustomerName,
                                  finalCustomer: init.finalCustomer,
                                  siret: init.siret,
                                  orderType: init.orderType
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSiretBudgetLLDField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithoutValidator(state.input.siretBudgetLLD, state.fieldsStatuses.siretBudgetLLD, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  finalCustomerExists: init.finalCustomerExists,
                                  siretBudgetLLD: status,
                                  siretActive: init.siretActive,
                                  siretExists: init.siretExists,
                                  easyPack: init.easyPack,
                                  salesmanName: init.salesmanName,
                                  agentCode: init.agentCode,
                                  customerDiscount: init.customerDiscount,
                                  invoiceClient: init.invoiceClient,
                                  invoiceNumber: init.invoiceNumber,
                                  deliveryDate: init.deliveryDate,
                                  registrationDate: init.registrationDate,
                                  orderDate: init.orderDate,
                                  customerFirstName: init.customerFirstName,
                                  shortCustomerName: init.shortCustomerName,
                                  finalCustomer: init.finalCustomer,
                                  siret: init.siret,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSiretActiveField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithoutValidator(state.input.siretActive, state.fieldsStatuses.siretActive, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  finalCustomerExists: init.finalCustomerExists,
                                  siretBudgetLLD: init.siretBudgetLLD,
                                  siretActive: status,
                                  siretExists: init.siretExists,
                                  easyPack: init.easyPack,
                                  salesmanName: init.salesmanName,
                                  agentCode: init.agentCode,
                                  customerDiscount: init.customerDiscount,
                                  invoiceClient: init.invoiceClient,
                                  invoiceNumber: init.invoiceNumber,
                                  deliveryDate: init.deliveryDate,
                                  registrationDate: init.registrationDate,
                                  orderDate: init.orderDate,
                                  customerFirstName: init.customerFirstName,
                                  shortCustomerName: init.shortCustomerName,
                                  finalCustomer: init.finalCustomer,
                                  siret: init.siret,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSiretExistsField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithoutValidator(state.input.siretExists, state.fieldsStatuses.siretExists, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  finalCustomerExists: init.finalCustomerExists,
                                  siretBudgetLLD: init.siretBudgetLLD,
                                  siretActive: init.siretActive,
                                  siretExists: status,
                                  easyPack: init.easyPack,
                                  salesmanName: init.salesmanName,
                                  agentCode: init.agentCode,
                                  customerDiscount: init.customerDiscount,
                                  invoiceClient: init.invoiceClient,
                                  invoiceNumber: init.invoiceNumber,
                                  deliveryDate: init.deliveryDate,
                                  registrationDate: init.registrationDate,
                                  orderDate: init.orderDate,
                                  customerFirstName: init.customerFirstName,
                                  shortCustomerName: init.shortCustomerName,
                                  finalCustomer: init.finalCustomer,
                                  siret: init.siret,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEasyPackField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.easyPack, validators_easyPack, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  finalCustomerExists: init.finalCustomerExists,
                                  siretBudgetLLD: init.siretBudgetLLD,
                                  siretActive: init.siretActive,
                                  siretExists: init.siretExists,
                                  easyPack: status,
                                  salesmanName: init.salesmanName,
                                  agentCode: init.agentCode,
                                  customerDiscount: init.customerDiscount,
                                  invoiceClient: init.invoiceClient,
                                  invoiceNumber: init.invoiceNumber,
                                  deliveryDate: init.deliveryDate,
                                  registrationDate: init.registrationDate,
                                  orderDate: init.orderDate,
                                  customerFirstName: init.customerFirstName,
                                  shortCustomerName: init.shortCustomerName,
                                  finalCustomer: init.finalCustomer,
                                  siret: init.siret,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSalesmanNameField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.salesmanName, validators_salesmanName, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  finalCustomerExists: init.finalCustomerExists,
                                  siretBudgetLLD: init.siretBudgetLLD,
                                  siretActive: init.siretActive,
                                  siretExists: init.siretExists,
                                  easyPack: init.easyPack,
                                  salesmanName: status,
                                  agentCode: init.agentCode,
                                  customerDiscount: init.customerDiscount,
                                  invoiceClient: init.invoiceClient,
                                  invoiceNumber: init.invoiceNumber,
                                  deliveryDate: init.deliveryDate,
                                  registrationDate: init.registrationDate,
                                  orderDate: init.orderDate,
                                  customerFirstName: init.customerFirstName,
                                  shortCustomerName: init.shortCustomerName,
                                  finalCustomer: init.finalCustomer,
                                  siret: init.siret,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurAgentCodeField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.agentCode, validators_agentCode, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  finalCustomerExists: init.finalCustomerExists,
                                  siretBudgetLLD: init.siretBudgetLLD,
                                  siretActive: init.siretActive,
                                  siretExists: init.siretExists,
                                  easyPack: init.easyPack,
                                  salesmanName: init.salesmanName,
                                  agentCode: status,
                                  customerDiscount: init.customerDiscount,
                                  invoiceClient: init.invoiceClient,
                                  invoiceNumber: init.invoiceNumber,
                                  deliveryDate: init.deliveryDate,
                                  registrationDate: init.registrationDate,
                                  orderDate: init.orderDate,
                                  customerFirstName: init.customerFirstName,
                                  shortCustomerName: init.shortCustomerName,
                                  finalCustomer: init.finalCustomer,
                                  siret: init.siret,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCustomerDiscountField */7 :
                  var result$7 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.customerDiscount, validators_customerDiscount, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  finalCustomerExists: init.finalCustomerExists,
                                  siretBudgetLLD: init.siretBudgetLLD,
                                  siretActive: init.siretActive,
                                  siretExists: init.siretExists,
                                  easyPack: init.easyPack,
                                  salesmanName: init.salesmanName,
                                  agentCode: init.agentCode,
                                  customerDiscount: status,
                                  invoiceClient: init.invoiceClient,
                                  invoiceNumber: init.invoiceNumber,
                                  deliveryDate: init.deliveryDate,
                                  registrationDate: init.registrationDate,
                                  orderDate: init.orderDate,
                                  customerFirstName: init.customerFirstName,
                                  shortCustomerName: init.shortCustomerName,
                                  finalCustomer: init.finalCustomer,
                                  siret: init.siret,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurInvoiceClientField */8 :
                  var result$8 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.invoiceClient, validators_invoiceClient, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  finalCustomerExists: init.finalCustomerExists,
                                  siretBudgetLLD: init.siretBudgetLLD,
                                  siretActive: init.siretActive,
                                  siretExists: init.siretExists,
                                  easyPack: init.easyPack,
                                  salesmanName: init.salesmanName,
                                  agentCode: init.agentCode,
                                  customerDiscount: init.customerDiscount,
                                  invoiceClient: status,
                                  invoiceNumber: init.invoiceNumber,
                                  deliveryDate: init.deliveryDate,
                                  registrationDate: init.registrationDate,
                                  orderDate: init.orderDate,
                                  customerFirstName: init.customerFirstName,
                                  shortCustomerName: init.shortCustomerName,
                                  finalCustomer: init.finalCustomer,
                                  siret: init.siret,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurInvoiceNumberField */9 :
                  var result$9 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.invoiceNumber, validators_invoiceNumber, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  finalCustomerExists: init.finalCustomerExists,
                                  siretBudgetLLD: init.siretBudgetLLD,
                                  siretActive: init.siretActive,
                                  siretExists: init.siretExists,
                                  easyPack: init.easyPack,
                                  salesmanName: init.salesmanName,
                                  agentCode: init.agentCode,
                                  customerDiscount: init.customerDiscount,
                                  invoiceClient: init.invoiceClient,
                                  invoiceNumber: status,
                                  deliveryDate: init.deliveryDate,
                                  registrationDate: init.registrationDate,
                                  orderDate: init.orderDate,
                                  customerFirstName: init.customerFirstName,
                                  shortCustomerName: init.shortCustomerName,
                                  finalCustomer: init.finalCustomer,
                                  siret: init.siret,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$9 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$9,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDeliveryDateField */10 :
                  var result$10 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.deliveryDate, validators_deliveryDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  finalCustomerExists: init.finalCustomerExists,
                                  siretBudgetLLD: init.siretBudgetLLD,
                                  siretActive: init.siretActive,
                                  siretExists: init.siretExists,
                                  easyPack: init.easyPack,
                                  salesmanName: init.salesmanName,
                                  agentCode: init.agentCode,
                                  customerDiscount: init.customerDiscount,
                                  invoiceClient: init.invoiceClient,
                                  invoiceNumber: init.invoiceNumber,
                                  deliveryDate: status,
                                  registrationDate: init.registrationDate,
                                  orderDate: init.orderDate,
                                  customerFirstName: init.customerFirstName,
                                  shortCustomerName: init.shortCustomerName,
                                  finalCustomer: init.finalCustomer,
                                  siret: init.siret,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$10 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$10,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurRegistrationDateField */11 :
                  var result$11 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.registrationDate, validators_registrationDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  finalCustomerExists: init.finalCustomerExists,
                                  siretBudgetLLD: init.siretBudgetLLD,
                                  siretActive: init.siretActive,
                                  siretExists: init.siretExists,
                                  easyPack: init.easyPack,
                                  salesmanName: init.salesmanName,
                                  agentCode: init.agentCode,
                                  customerDiscount: init.customerDiscount,
                                  invoiceClient: init.invoiceClient,
                                  invoiceNumber: init.invoiceNumber,
                                  deliveryDate: init.deliveryDate,
                                  registrationDate: status,
                                  orderDate: init.orderDate,
                                  customerFirstName: init.customerFirstName,
                                  shortCustomerName: init.shortCustomerName,
                                  finalCustomer: init.finalCustomer,
                                  siret: init.siret,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$11 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$11,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurOrderDateField */12 :
                  var result$12 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.orderDate, validators_orderDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  finalCustomerExists: init.finalCustomerExists,
                                  siretBudgetLLD: init.siretBudgetLLD,
                                  siretActive: init.siretActive,
                                  siretExists: init.siretExists,
                                  easyPack: init.easyPack,
                                  salesmanName: init.salesmanName,
                                  agentCode: init.agentCode,
                                  customerDiscount: init.customerDiscount,
                                  invoiceClient: init.invoiceClient,
                                  invoiceNumber: init.invoiceNumber,
                                  deliveryDate: init.deliveryDate,
                                  registrationDate: init.registrationDate,
                                  orderDate: status,
                                  customerFirstName: init.customerFirstName,
                                  shortCustomerName: init.shortCustomerName,
                                  finalCustomer: init.finalCustomer,
                                  siret: init.siret,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$12 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$12,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCustomerFirstNameField */13 :
                  var result$13 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.customerFirstName, validators_customerFirstName, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  finalCustomerExists: init.finalCustomerExists,
                                  siretBudgetLLD: init.siretBudgetLLD,
                                  siretActive: init.siretActive,
                                  siretExists: init.siretExists,
                                  easyPack: init.easyPack,
                                  salesmanName: init.salesmanName,
                                  agentCode: init.agentCode,
                                  customerDiscount: init.customerDiscount,
                                  invoiceClient: init.invoiceClient,
                                  invoiceNumber: init.invoiceNumber,
                                  deliveryDate: init.deliveryDate,
                                  registrationDate: init.registrationDate,
                                  orderDate: init.orderDate,
                                  customerFirstName: status,
                                  shortCustomerName: init.shortCustomerName,
                                  finalCustomer: init.finalCustomer,
                                  siret: init.siret,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$13 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$13,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurShortCustomerNameField */14 :
                  var result$14 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.shortCustomerName, validators_shortCustomerName, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  finalCustomerExists: init.finalCustomerExists,
                                  siretBudgetLLD: init.siretBudgetLLD,
                                  siretActive: init.siretActive,
                                  siretExists: init.siretExists,
                                  easyPack: init.easyPack,
                                  salesmanName: init.salesmanName,
                                  agentCode: init.agentCode,
                                  customerDiscount: init.customerDiscount,
                                  invoiceClient: init.invoiceClient,
                                  invoiceNumber: init.invoiceNumber,
                                  deliveryDate: init.deliveryDate,
                                  registrationDate: init.registrationDate,
                                  orderDate: init.orderDate,
                                  customerFirstName: init.customerFirstName,
                                  shortCustomerName: status,
                                  finalCustomer: init.finalCustomer,
                                  siret: init.siret,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$14 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$14,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurFinalCustomerField */15 :
                  var result$15 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.finalCustomer, validators_finalCustomer, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  finalCustomerExists: init.finalCustomerExists,
                                  siretBudgetLLD: init.siretBudgetLLD,
                                  siretActive: init.siretActive,
                                  siretExists: init.siretExists,
                                  easyPack: init.easyPack,
                                  salesmanName: init.salesmanName,
                                  agentCode: init.agentCode,
                                  customerDiscount: init.customerDiscount,
                                  invoiceClient: init.invoiceClient,
                                  invoiceNumber: init.invoiceNumber,
                                  deliveryDate: init.deliveryDate,
                                  registrationDate: init.registrationDate,
                                  orderDate: init.orderDate,
                                  customerFirstName: init.customerFirstName,
                                  shortCustomerName: init.shortCustomerName,
                                  finalCustomer: status,
                                  siret: init.siret,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$15 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$15,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurSiretField */16 :
                  var result$16 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.siret, validators_siret, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  finalCustomerExists: init.finalCustomerExists,
                                  siretBudgetLLD: init.siretBudgetLLD,
                                  siretActive: init.siretActive,
                                  siretExists: init.siretExists,
                                  easyPack: init.easyPack,
                                  salesmanName: init.salesmanName,
                                  agentCode: init.agentCode,
                                  customerDiscount: init.customerDiscount,
                                  invoiceClient: init.invoiceClient,
                                  invoiceNumber: init.invoiceNumber,
                                  deliveryDate: init.deliveryDate,
                                  registrationDate: init.registrationDate,
                                  orderDate: init.orderDate,
                                  customerFirstName: init.customerFirstName,
                                  shortCustomerName: init.shortCustomerName,
                                  finalCustomer: init.finalCustomer,
                                  siret: status,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$16 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$16,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurOrderTypeField */17 :
                  var result$17 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.orderType, validators_orderType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  finalCustomerExists: init.finalCustomerExists,
                                  siretBudgetLLD: init.siretBudgetLLD,
                                  siretActive: init.siretActive,
                                  siretExists: init.siretExists,
                                  easyPack: init.easyPack,
                                  salesmanName: init.salesmanName,
                                  agentCode: init.agentCode,
                                  customerDiscount: init.customerDiscount,
                                  invoiceClient: init.invoiceClient,
                                  invoiceNumber: init.invoiceNumber,
                                  deliveryDate: init.deliveryDate,
                                  registrationDate: init.registrationDate,
                                  orderDate: init.orderDate,
                                  customerFirstName: init.customerFirstName,
                                  shortCustomerName: init.shortCustomerName,
                                  finalCustomer: init.finalCustomer,
                                  siret: init.siret,
                                  orderType: status
                                };
                        }));
                  if (result$17 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$17,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */18 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses, metadata);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */18,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */19,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */21);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */20);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */19 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */20 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */21 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateFinalCustomerExistsField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  var result$18 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput, metadata, nextFieldsStatuses.contents.finalCustomer, validators_finalCustomer, (function (status) {
                          var init = nextFieldsStatuses.contents;
                          return {
                                  finalCustomerExists: init.finalCustomerExists,
                                  siretBudgetLLD: init.siretBudgetLLD,
                                  siretActive: init.siretActive,
                                  siretExists: init.siretExists,
                                  easyPack: init.easyPack,
                                  salesmanName: init.salesmanName,
                                  agentCode: init.agentCode,
                                  customerDiscount: init.customerDiscount,
                                  invoiceClient: init.invoiceClient,
                                  invoiceNumber: init.invoiceNumber,
                                  deliveryDate: init.deliveryDate,
                                  registrationDate: init.registrationDate,
                                  orderDate: init.orderDate,
                                  customerFirstName: init.customerFirstName,
                                  shortCustomerName: init.shortCustomerName,
                                  finalCustomer: status,
                                  siret: init.siret,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$18 !== undefined) {
                    nextFieldsStatuses.contents = result$18;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput.finalCustomerExists, (function (status) {
                                    var init = nextFieldsStatuses.contents;
                                    return {
                                            finalCustomerExists: status,
                                            siretBudgetLLD: init.siretBudgetLLD,
                                            siretActive: init.siretActive,
                                            siretExists: init.siretExists,
                                            easyPack: init.easyPack,
                                            salesmanName: init.salesmanName,
                                            agentCode: init.agentCode,
                                            customerDiscount: init.customerDiscount,
                                            invoiceClient: init.invoiceClient,
                                            invoiceNumber: init.invoiceNumber,
                                            deliveryDate: init.deliveryDate,
                                            registrationDate: init.registrationDate,
                                            orderDate: init.orderDate,
                                            customerFirstName: init.customerFirstName,
                                            shortCustomerName: init.shortCustomerName,
                                            finalCustomer: init.finalCustomer,
                                            siret: init.siret,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSiretBudgetLLDField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  var result$19 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$1, metadata, nextFieldsStatuses$1.contents.finalCustomer, validators_finalCustomer, (function (status) {
                          var init = nextFieldsStatuses$1.contents;
                          return {
                                  finalCustomerExists: init.finalCustomerExists,
                                  siretBudgetLLD: init.siretBudgetLLD,
                                  siretActive: init.siretActive,
                                  siretExists: init.siretExists,
                                  easyPack: init.easyPack,
                                  salesmanName: init.salesmanName,
                                  agentCode: init.agentCode,
                                  customerDiscount: init.customerDiscount,
                                  invoiceClient: init.invoiceClient,
                                  invoiceNumber: init.invoiceNumber,
                                  deliveryDate: init.deliveryDate,
                                  registrationDate: init.registrationDate,
                                  orderDate: init.orderDate,
                                  customerFirstName: init.customerFirstName,
                                  shortCustomerName: init.shortCustomerName,
                                  finalCustomer: status,
                                  siret: init.siret,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$19 !== undefined) {
                    nextFieldsStatuses$1.contents = result$19;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$1.siretBudgetLLD, (function (status) {
                                    var init = nextFieldsStatuses$1.contents;
                                    return {
                                            finalCustomerExists: init.finalCustomerExists,
                                            siretBudgetLLD: status,
                                            siretActive: init.siretActive,
                                            siretExists: init.siretExists,
                                            easyPack: init.easyPack,
                                            salesmanName: init.salesmanName,
                                            agentCode: init.agentCode,
                                            customerDiscount: init.customerDiscount,
                                            invoiceClient: init.invoiceClient,
                                            invoiceNumber: init.invoiceNumber,
                                            deliveryDate: init.deliveryDate,
                                            registrationDate: init.registrationDate,
                                            orderDate: init.orderDate,
                                            customerFirstName: init.customerFirstName,
                                            shortCustomerName: init.shortCustomerName,
                                            finalCustomer: init.finalCustomer,
                                            siret: init.siret,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSiretActiveField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$2 = {
                    contents: state.fieldsStatuses
                  };
                  var result$20 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$2, metadata, nextFieldsStatuses$2.contents.siret, validators_siret, (function (status) {
                          var init = nextFieldsStatuses$2.contents;
                          return {
                                  finalCustomerExists: init.finalCustomerExists,
                                  siretBudgetLLD: init.siretBudgetLLD,
                                  siretActive: init.siretActive,
                                  siretExists: init.siretExists,
                                  easyPack: init.easyPack,
                                  salesmanName: init.salesmanName,
                                  agentCode: init.agentCode,
                                  customerDiscount: init.customerDiscount,
                                  invoiceClient: init.invoiceClient,
                                  invoiceNumber: init.invoiceNumber,
                                  deliveryDate: init.deliveryDate,
                                  registrationDate: init.registrationDate,
                                  orderDate: init.orderDate,
                                  customerFirstName: init.customerFirstName,
                                  shortCustomerName: init.shortCustomerName,
                                  finalCustomer: init.finalCustomer,
                                  siret: status,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$20 !== undefined) {
                    nextFieldsStatuses$2.contents = result$20;
                  }
                  var result$21 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$2, metadata, nextFieldsStatuses$2.contents.finalCustomer, validators_finalCustomer, (function (status) {
                          var init = nextFieldsStatuses$2.contents;
                          return {
                                  finalCustomerExists: init.finalCustomerExists,
                                  siretBudgetLLD: init.siretBudgetLLD,
                                  siretActive: init.siretActive,
                                  siretExists: init.siretExists,
                                  easyPack: init.easyPack,
                                  salesmanName: init.salesmanName,
                                  agentCode: init.agentCode,
                                  customerDiscount: init.customerDiscount,
                                  invoiceClient: init.invoiceClient,
                                  invoiceNumber: init.invoiceNumber,
                                  deliveryDate: init.deliveryDate,
                                  registrationDate: init.registrationDate,
                                  orderDate: init.orderDate,
                                  customerFirstName: init.customerFirstName,
                                  shortCustomerName: init.shortCustomerName,
                                  finalCustomer: status,
                                  siret: init.siret,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$21 !== undefined) {
                    nextFieldsStatuses$2.contents = result$21;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$2.siretActive, (function (status) {
                                    var init = nextFieldsStatuses$2.contents;
                                    return {
                                            finalCustomerExists: init.finalCustomerExists,
                                            siretBudgetLLD: init.siretBudgetLLD,
                                            siretActive: status,
                                            siretExists: init.siretExists,
                                            easyPack: init.easyPack,
                                            salesmanName: init.salesmanName,
                                            agentCode: init.agentCode,
                                            customerDiscount: init.customerDiscount,
                                            invoiceClient: init.invoiceClient,
                                            invoiceNumber: init.invoiceNumber,
                                            deliveryDate: init.deliveryDate,
                                            registrationDate: init.registrationDate,
                                            orderDate: init.orderDate,
                                            customerFirstName: init.customerFirstName,
                                            shortCustomerName: init.shortCustomerName,
                                            finalCustomer: init.finalCustomer,
                                            siret: init.siret,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSiretExistsField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$3 = {
                    contents: state.fieldsStatuses
                  };
                  var result$22 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$3, metadata, nextFieldsStatuses$3.contents.siret, validators_siret, (function (status) {
                          var init = nextFieldsStatuses$3.contents;
                          return {
                                  finalCustomerExists: init.finalCustomerExists,
                                  siretBudgetLLD: init.siretBudgetLLD,
                                  siretActive: init.siretActive,
                                  siretExists: init.siretExists,
                                  easyPack: init.easyPack,
                                  salesmanName: init.salesmanName,
                                  agentCode: init.agentCode,
                                  customerDiscount: init.customerDiscount,
                                  invoiceClient: init.invoiceClient,
                                  invoiceNumber: init.invoiceNumber,
                                  deliveryDate: init.deliveryDate,
                                  registrationDate: init.registrationDate,
                                  orderDate: init.orderDate,
                                  customerFirstName: init.customerFirstName,
                                  shortCustomerName: init.shortCustomerName,
                                  finalCustomer: init.finalCustomer,
                                  siret: status,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$22 !== undefined) {
                    nextFieldsStatuses$3.contents = result$22;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$3.siretExists, (function (status) {
                                    var init = nextFieldsStatuses$3.contents;
                                    return {
                                            finalCustomerExists: init.finalCustomerExists,
                                            siretBudgetLLD: init.siretBudgetLLD,
                                            siretActive: init.siretActive,
                                            siretExists: status,
                                            easyPack: init.easyPack,
                                            salesmanName: init.salesmanName,
                                            agentCode: init.agentCode,
                                            customerDiscount: init.customerDiscount,
                                            invoiceClient: init.invoiceClient,
                                            invoiceNumber: init.invoiceNumber,
                                            deliveryDate: init.deliveryDate,
                                            registrationDate: init.registrationDate,
                                            orderDate: init.orderDate,
                                            customerFirstName: init.customerFirstName,
                                            shortCustomerName: init.shortCustomerName,
                                            finalCustomer: init.finalCustomer,
                                            siret: init.siret,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEasyPackField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$4, metadata, state.fieldsStatuses.easyPack, state.submissionStatus, validators_easyPack, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            finalCustomerExists: init.finalCustomerExists,
                                            siretBudgetLLD: init.siretBudgetLLD,
                                            siretActive: init.siretActive,
                                            siretExists: init.siretExists,
                                            easyPack: status,
                                            salesmanName: init.salesmanName,
                                            agentCode: init.agentCode,
                                            customerDiscount: init.customerDiscount,
                                            invoiceClient: init.invoiceClient,
                                            invoiceNumber: init.invoiceNumber,
                                            deliveryDate: init.deliveryDate,
                                            registrationDate: init.registrationDate,
                                            orderDate: init.orderDate,
                                            customerFirstName: init.customerFirstName,
                                            shortCustomerName: init.shortCustomerName,
                                            finalCustomer: init.finalCustomer,
                                            siret: init.siret,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSalesmanNameField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$5, metadata, state.fieldsStatuses.salesmanName, state.submissionStatus, validators_salesmanName, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            finalCustomerExists: init.finalCustomerExists,
                                            siretBudgetLLD: init.siretBudgetLLD,
                                            siretActive: init.siretActive,
                                            siretExists: init.siretExists,
                                            easyPack: init.easyPack,
                                            salesmanName: status,
                                            agentCode: init.agentCode,
                                            customerDiscount: init.customerDiscount,
                                            invoiceClient: init.invoiceClient,
                                            invoiceNumber: init.invoiceNumber,
                                            deliveryDate: init.deliveryDate,
                                            registrationDate: init.registrationDate,
                                            orderDate: init.orderDate,
                                            customerFirstName: init.customerFirstName,
                                            shortCustomerName: init.shortCustomerName,
                                            finalCustomer: init.finalCustomer,
                                            siret: init.siret,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateAgentCodeField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$6, metadata, state.fieldsStatuses.agentCode, state.submissionStatus, validators_agentCode, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            finalCustomerExists: init.finalCustomerExists,
                                            siretBudgetLLD: init.siretBudgetLLD,
                                            siretActive: init.siretActive,
                                            siretExists: init.siretExists,
                                            easyPack: init.easyPack,
                                            salesmanName: init.salesmanName,
                                            agentCode: status,
                                            customerDiscount: init.customerDiscount,
                                            invoiceClient: init.invoiceClient,
                                            invoiceNumber: init.invoiceNumber,
                                            deliveryDate: init.deliveryDate,
                                            registrationDate: init.registrationDate,
                                            orderDate: init.orderDate,
                                            customerFirstName: init.customerFirstName,
                                            shortCustomerName: init.shortCustomerName,
                                            finalCustomer: init.finalCustomer,
                                            siret: init.siret,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCustomerDiscountField */7 :
                  var nextInput$7 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$7, metadata, state.fieldsStatuses.customerDiscount, state.submissionStatus, validators_customerDiscount, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            finalCustomerExists: init.finalCustomerExists,
                                            siretBudgetLLD: init.siretBudgetLLD,
                                            siretActive: init.siretActive,
                                            siretExists: init.siretExists,
                                            easyPack: init.easyPack,
                                            salesmanName: init.salesmanName,
                                            agentCode: init.agentCode,
                                            customerDiscount: status,
                                            invoiceClient: init.invoiceClient,
                                            invoiceNumber: init.invoiceNumber,
                                            deliveryDate: init.deliveryDate,
                                            registrationDate: init.registrationDate,
                                            orderDate: init.orderDate,
                                            customerFirstName: init.customerFirstName,
                                            shortCustomerName: init.shortCustomerName,
                                            finalCustomer: init.finalCustomer,
                                            siret: init.siret,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateInvoiceClientField */8 :
                  var nextInput$8 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$8, metadata, state.fieldsStatuses.invoiceClient, state.submissionStatus, validators_invoiceClient, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            finalCustomerExists: init.finalCustomerExists,
                                            siretBudgetLLD: init.siretBudgetLLD,
                                            siretActive: init.siretActive,
                                            siretExists: init.siretExists,
                                            easyPack: init.easyPack,
                                            salesmanName: init.salesmanName,
                                            agentCode: init.agentCode,
                                            customerDiscount: init.customerDiscount,
                                            invoiceClient: status,
                                            invoiceNumber: init.invoiceNumber,
                                            deliveryDate: init.deliveryDate,
                                            registrationDate: init.registrationDate,
                                            orderDate: init.orderDate,
                                            customerFirstName: init.customerFirstName,
                                            shortCustomerName: init.shortCustomerName,
                                            finalCustomer: init.finalCustomer,
                                            siret: init.siret,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateInvoiceNumberField */9 :
                  var nextInput$9 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$9,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$9, metadata, state.fieldsStatuses.invoiceNumber, state.submissionStatus, validators_invoiceNumber, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            finalCustomerExists: init.finalCustomerExists,
                                            siretBudgetLLD: init.siretBudgetLLD,
                                            siretActive: init.siretActive,
                                            siretExists: init.siretExists,
                                            easyPack: init.easyPack,
                                            salesmanName: init.salesmanName,
                                            agentCode: init.agentCode,
                                            customerDiscount: init.customerDiscount,
                                            invoiceClient: init.invoiceClient,
                                            invoiceNumber: status,
                                            deliveryDate: init.deliveryDate,
                                            registrationDate: init.registrationDate,
                                            orderDate: init.orderDate,
                                            customerFirstName: init.customerFirstName,
                                            shortCustomerName: init.shortCustomerName,
                                            finalCustomer: init.finalCustomer,
                                            siret: init.siret,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDeliveryDateField */10 :
                  var nextInput$10 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$10,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$10, metadata, state.fieldsStatuses.deliveryDate, state.submissionStatus, validators_deliveryDate, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            finalCustomerExists: init.finalCustomerExists,
                                            siretBudgetLLD: init.siretBudgetLLD,
                                            siretActive: init.siretActive,
                                            siretExists: init.siretExists,
                                            easyPack: init.easyPack,
                                            salesmanName: init.salesmanName,
                                            agentCode: init.agentCode,
                                            customerDiscount: init.customerDiscount,
                                            invoiceClient: init.invoiceClient,
                                            invoiceNumber: init.invoiceNumber,
                                            deliveryDate: status,
                                            registrationDate: init.registrationDate,
                                            orderDate: init.orderDate,
                                            customerFirstName: init.customerFirstName,
                                            shortCustomerName: init.shortCustomerName,
                                            finalCustomer: init.finalCustomer,
                                            siret: init.siret,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateRegistrationDateField */11 :
                  var nextInput$11 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$11,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$11, metadata, state.fieldsStatuses.registrationDate, state.submissionStatus, validators_registrationDate, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            finalCustomerExists: init.finalCustomerExists,
                                            siretBudgetLLD: init.siretBudgetLLD,
                                            siretActive: init.siretActive,
                                            siretExists: init.siretExists,
                                            easyPack: init.easyPack,
                                            salesmanName: init.salesmanName,
                                            agentCode: init.agentCode,
                                            customerDiscount: init.customerDiscount,
                                            invoiceClient: init.invoiceClient,
                                            invoiceNumber: init.invoiceNumber,
                                            deliveryDate: init.deliveryDate,
                                            registrationDate: status,
                                            orderDate: init.orderDate,
                                            customerFirstName: init.customerFirstName,
                                            shortCustomerName: init.shortCustomerName,
                                            finalCustomer: init.finalCustomer,
                                            siret: init.siret,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateOrderDateField */12 :
                  var nextInput$12 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$12,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$12, metadata, state.fieldsStatuses.orderDate, state.submissionStatus, validators_orderDate, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            finalCustomerExists: init.finalCustomerExists,
                                            siretBudgetLLD: init.siretBudgetLLD,
                                            siretActive: init.siretActive,
                                            siretExists: init.siretExists,
                                            easyPack: init.easyPack,
                                            salesmanName: init.salesmanName,
                                            agentCode: init.agentCode,
                                            customerDiscount: init.customerDiscount,
                                            invoiceClient: init.invoiceClient,
                                            invoiceNumber: init.invoiceNumber,
                                            deliveryDate: init.deliveryDate,
                                            registrationDate: init.registrationDate,
                                            orderDate: status,
                                            customerFirstName: init.customerFirstName,
                                            shortCustomerName: init.shortCustomerName,
                                            finalCustomer: init.finalCustomer,
                                            siret: init.siret,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCustomerFirstNameField */13 :
                  var nextInput$13 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$13,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$13, metadata, state.fieldsStatuses.customerFirstName, state.submissionStatus, validators_customerFirstName, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            finalCustomerExists: init.finalCustomerExists,
                                            siretBudgetLLD: init.siretBudgetLLD,
                                            siretActive: init.siretActive,
                                            siretExists: init.siretExists,
                                            easyPack: init.easyPack,
                                            salesmanName: init.salesmanName,
                                            agentCode: init.agentCode,
                                            customerDiscount: init.customerDiscount,
                                            invoiceClient: init.invoiceClient,
                                            invoiceNumber: init.invoiceNumber,
                                            deliveryDate: init.deliveryDate,
                                            registrationDate: init.registrationDate,
                                            orderDate: init.orderDate,
                                            customerFirstName: status,
                                            shortCustomerName: init.shortCustomerName,
                                            finalCustomer: init.finalCustomer,
                                            siret: init.siret,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateShortCustomerNameField */14 :
                  var nextInput$14 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$14,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$14, metadata, state.fieldsStatuses.shortCustomerName, state.submissionStatus, validators_shortCustomerName, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            finalCustomerExists: init.finalCustomerExists,
                                            siretBudgetLLD: init.siretBudgetLLD,
                                            siretActive: init.siretActive,
                                            siretExists: init.siretExists,
                                            easyPack: init.easyPack,
                                            salesmanName: init.salesmanName,
                                            agentCode: init.agentCode,
                                            customerDiscount: init.customerDiscount,
                                            invoiceClient: init.invoiceClient,
                                            invoiceNumber: init.invoiceNumber,
                                            deliveryDate: init.deliveryDate,
                                            registrationDate: init.registrationDate,
                                            orderDate: init.orderDate,
                                            customerFirstName: init.customerFirstName,
                                            shortCustomerName: status,
                                            finalCustomer: init.finalCustomer,
                                            siret: init.siret,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateFinalCustomerField */15 :
                  var nextInput$15 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$15,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$15, metadata, state.fieldsStatuses.finalCustomer, state.submissionStatus, validators_finalCustomer, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            finalCustomerExists: init.finalCustomerExists,
                                            siretBudgetLLD: init.siretBudgetLLD,
                                            siretActive: init.siretActive,
                                            siretExists: init.siretExists,
                                            easyPack: init.easyPack,
                                            salesmanName: init.salesmanName,
                                            agentCode: init.agentCode,
                                            customerDiscount: init.customerDiscount,
                                            invoiceClient: init.invoiceClient,
                                            invoiceNumber: init.invoiceNumber,
                                            deliveryDate: init.deliveryDate,
                                            registrationDate: init.registrationDate,
                                            orderDate: init.orderDate,
                                            customerFirstName: init.customerFirstName,
                                            shortCustomerName: init.shortCustomerName,
                                            finalCustomer: status,
                                            siret: init.siret,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateSiretField */16 :
                  var nextInput$16 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$4 = {
                    contents: state.fieldsStatuses
                  };
                  var result$23 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$16, metadata, nextFieldsStatuses$4.contents.finalCustomer, validators_finalCustomer, (function (status) {
                          var init = nextFieldsStatuses$4.contents;
                          return {
                                  finalCustomerExists: init.finalCustomerExists,
                                  siretBudgetLLD: init.siretBudgetLLD,
                                  siretActive: init.siretActive,
                                  siretExists: init.siretExists,
                                  easyPack: init.easyPack,
                                  salesmanName: init.salesmanName,
                                  agentCode: init.agentCode,
                                  customerDiscount: init.customerDiscount,
                                  invoiceClient: init.invoiceClient,
                                  invoiceNumber: init.invoiceNumber,
                                  deliveryDate: init.deliveryDate,
                                  registrationDate: init.registrationDate,
                                  orderDate: init.orderDate,
                                  customerFirstName: init.customerFirstName,
                                  shortCustomerName: init.shortCustomerName,
                                  finalCustomer: status,
                                  siret: init.siret,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$23 !== undefined) {
                    nextFieldsStatuses$4.contents = result$23;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$16,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$16, metadata, nextFieldsStatuses$4.contents.siret, state.submissionStatus, validators_siret, (function (status) {
                                    var init = nextFieldsStatuses$4.contents;
                                    return {
                                            finalCustomerExists: init.finalCustomerExists,
                                            siretBudgetLLD: init.siretBudgetLLD,
                                            siretActive: init.siretActive,
                                            siretExists: init.siretExists,
                                            easyPack: init.easyPack,
                                            salesmanName: init.salesmanName,
                                            agentCode: init.agentCode,
                                            customerDiscount: init.customerDiscount,
                                            invoiceClient: init.invoiceClient,
                                            invoiceNumber: init.invoiceNumber,
                                            deliveryDate: init.deliveryDate,
                                            registrationDate: init.registrationDate,
                                            orderDate: init.orderDate,
                                            customerFirstName: init.customerFirstName,
                                            shortCustomerName: init.shortCustomerName,
                                            finalCustomer: init.finalCustomer,
                                            siret: status,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateOrderTypeField */17 :
                  var nextInput$17 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$5 = {
                    contents: state.fieldsStatuses
                  };
                  var result$24 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$17, metadata, nextFieldsStatuses$5.contents.siret, validators_siret, (function (status) {
                          var init = nextFieldsStatuses$5.contents;
                          return {
                                  finalCustomerExists: init.finalCustomerExists,
                                  siretBudgetLLD: init.siretBudgetLLD,
                                  siretActive: init.siretActive,
                                  siretExists: init.siretExists,
                                  easyPack: init.easyPack,
                                  salesmanName: init.salesmanName,
                                  agentCode: init.agentCode,
                                  customerDiscount: init.customerDiscount,
                                  invoiceClient: init.invoiceClient,
                                  invoiceNumber: init.invoiceNumber,
                                  deliveryDate: init.deliveryDate,
                                  registrationDate: init.registrationDate,
                                  orderDate: init.orderDate,
                                  customerFirstName: init.customerFirstName,
                                  shortCustomerName: init.shortCustomerName,
                                  finalCustomer: init.finalCustomer,
                                  siret: status,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$24 !== undefined) {
                    nextFieldsStatuses$5.contents = result$24;
                  }
                  var result$25 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$17, metadata, nextFieldsStatuses$5.contents.finalCustomer, validators_finalCustomer, (function (status) {
                          var init = nextFieldsStatuses$5.contents;
                          return {
                                  finalCustomerExists: init.finalCustomerExists,
                                  siretBudgetLLD: init.siretBudgetLLD,
                                  siretActive: init.siretActive,
                                  siretExists: init.siretExists,
                                  easyPack: init.easyPack,
                                  salesmanName: init.salesmanName,
                                  agentCode: init.agentCode,
                                  customerDiscount: init.customerDiscount,
                                  invoiceClient: init.invoiceClient,
                                  invoiceNumber: init.invoiceNumber,
                                  deliveryDate: init.deliveryDate,
                                  registrationDate: init.registrationDate,
                                  orderDate: init.orderDate,
                                  customerFirstName: init.customerFirstName,
                                  shortCustomerName: init.shortCustomerName,
                                  finalCustomer: status,
                                  siret: init.siret,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$25 !== undefined) {
                    nextFieldsStatuses$5.contents = result$25;
                  }
                  var result$26 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$17, metadata, nextFieldsStatuses$5.contents.shortCustomerName, validators_shortCustomerName, (function (status) {
                          var init = nextFieldsStatuses$5.contents;
                          return {
                                  finalCustomerExists: init.finalCustomerExists,
                                  siretBudgetLLD: init.siretBudgetLLD,
                                  siretActive: init.siretActive,
                                  siretExists: init.siretExists,
                                  easyPack: init.easyPack,
                                  salesmanName: init.salesmanName,
                                  agentCode: init.agentCode,
                                  customerDiscount: init.customerDiscount,
                                  invoiceClient: init.invoiceClient,
                                  invoiceNumber: init.invoiceNumber,
                                  deliveryDate: init.deliveryDate,
                                  registrationDate: init.registrationDate,
                                  orderDate: init.orderDate,
                                  customerFirstName: init.customerFirstName,
                                  shortCustomerName: status,
                                  finalCustomer: init.finalCustomer,
                                  siret: init.siret,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$26 !== undefined) {
                    nextFieldsStatuses$5.contents = result$26;
                  }
                  var result$27 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$17, metadata, nextFieldsStatuses$5.contents.customerFirstName, validators_customerFirstName, (function (status) {
                          var init = nextFieldsStatuses$5.contents;
                          return {
                                  finalCustomerExists: init.finalCustomerExists,
                                  siretBudgetLLD: init.siretBudgetLLD,
                                  siretActive: init.siretActive,
                                  siretExists: init.siretExists,
                                  easyPack: init.easyPack,
                                  salesmanName: init.salesmanName,
                                  agentCode: init.agentCode,
                                  customerDiscount: init.customerDiscount,
                                  invoiceClient: init.invoiceClient,
                                  invoiceNumber: init.invoiceNumber,
                                  deliveryDate: init.deliveryDate,
                                  registrationDate: init.registrationDate,
                                  orderDate: init.orderDate,
                                  customerFirstName: status,
                                  shortCustomerName: init.shortCustomerName,
                                  finalCustomer: init.finalCustomer,
                                  siret: init.siret,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$27 !== undefined) {
                    nextFieldsStatuses$5.contents = result$27;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$17,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$17, metadata, nextFieldsStatuses$5.contents.orderType, state.submissionStatus, validators_orderType, (function (status) {
                                    var init = nextFieldsStatuses$5.contents;
                                    return {
                                            finalCustomerExists: init.finalCustomerExists,
                                            siretBudgetLLD: init.siretBudgetLLD,
                                            siretActive: init.siretActive,
                                            siretExists: init.siretExists,
                                            easyPack: init.easyPack,
                                            salesmanName: init.salesmanName,
                                            agentCode: init.agentCode,
                                            customerDiscount: init.customerDiscount,
                                            invoiceClient: init.invoiceClient,
                                            invoiceNumber: init.invoiceNumber,
                                            deliveryDate: init.deliveryDate,
                                            registrationDate: init.registrationDate,
                                            orderDate: init.orderDate,
                                            customerFirstName: init.customerFirstName,
                                            shortCustomerName: init.shortCustomerName,
                                            finalCustomer: init.finalCustomer,
                                            siret: init.siret,
                                            orderType: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */18 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */19 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */20 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateFinalCustomerExists: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateFinalCustomerExistsField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateSiretBudgetLLD: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSiretBudgetLLDField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateSiretActive: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSiretActiveField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateSiretExists: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSiretExistsField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEasyPack: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEasyPackField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateSalesmanName: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSalesmanNameField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateAgentCode: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateAgentCodeField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCustomerDiscount: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCustomerDiscountField */7,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateInvoiceClient: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateInvoiceClientField */8,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateInvoiceNumber: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateInvoiceNumberField */9,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDeliveryDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDeliveryDateField */10,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateRegistrationDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateRegistrationDateField */11,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateOrderDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateOrderDateField */12,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCustomerFirstName: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCustomerFirstNameField */13,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateShortCustomerName: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateShortCustomerNameField */14,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateFinalCustomer: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateFinalCustomerField */15,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateSiret: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSiretField */16,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateOrderType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateOrderTypeField */17,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurFinalCustomerExists: (function (param) {
              Curry._1(dispatch, /* BlurFinalCustomerExistsField */0);
            }),
          blurSiretBudgetLLD: (function (param) {
              Curry._1(dispatch, /* BlurSiretBudgetLLDField */1);
            }),
          blurSiretActive: (function (param) {
              Curry._1(dispatch, /* BlurSiretActiveField */2);
            }),
          blurSiretExists: (function (param) {
              Curry._1(dispatch, /* BlurSiretExistsField */3);
            }),
          blurEasyPack: (function (param) {
              Curry._1(dispatch, /* BlurEasyPackField */4);
            }),
          blurSalesmanName: (function (param) {
              Curry._1(dispatch, /* BlurSalesmanNameField */5);
            }),
          blurAgentCode: (function (param) {
              Curry._1(dispatch, /* BlurAgentCodeField */6);
            }),
          blurCustomerDiscount: (function (param) {
              Curry._1(dispatch, /* BlurCustomerDiscountField */7);
            }),
          blurInvoiceClient: (function (param) {
              Curry._1(dispatch, /* BlurInvoiceClientField */8);
            }),
          blurInvoiceNumber: (function (param) {
              Curry._1(dispatch, /* BlurInvoiceNumberField */9);
            }),
          blurDeliveryDate: (function (param) {
              Curry._1(dispatch, /* BlurDeliveryDateField */10);
            }),
          blurRegistrationDate: (function (param) {
              Curry._1(dispatch, /* BlurRegistrationDateField */11);
            }),
          blurOrderDate: (function (param) {
              Curry._1(dispatch, /* BlurOrderDateField */12);
            }),
          blurCustomerFirstName: (function (param) {
              Curry._1(dispatch, /* BlurCustomerFirstNameField */13);
            }),
          blurShortCustomerName: (function (param) {
              Curry._1(dispatch, /* BlurShortCustomerNameField */14);
            }),
          blurFinalCustomer: (function (param) {
              Curry._1(dispatch, /* BlurFinalCustomerField */15);
            }),
          blurSiret: (function (param) {
              Curry._1(dispatch, /* BlurSiretField */16);
            }),
          blurOrderType: (function (param) {
              Curry._1(dispatch, /* BlurOrderTypeField */17);
            }),
          finalCustomerExistsResult: Formality.exposeFieldResult(state.fieldsStatuses.finalCustomerExists),
          siretBudgetLLDResult: Formality.exposeFieldResult(state.fieldsStatuses.siretBudgetLLD),
          siretActiveResult: Formality.exposeFieldResult(state.fieldsStatuses.siretActive),
          siretExistsResult: Formality.exposeFieldResult(state.fieldsStatuses.siretExists),
          easyPackResult: Formality.exposeFieldResult(state.fieldsStatuses.easyPack),
          salesmanNameResult: Formality.exposeFieldResult(state.fieldsStatuses.salesmanName),
          agentCodeResult: Formality.exposeFieldResult(state.fieldsStatuses.agentCode),
          customerDiscountResult: Formality.exposeFieldResult(state.fieldsStatuses.customerDiscount),
          invoiceClientResult: Formality.exposeFieldResult(state.fieldsStatuses.invoiceClient),
          invoiceNumberResult: Formality.exposeFieldResult(state.fieldsStatuses.invoiceNumber),
          deliveryDateResult: Formality.exposeFieldResult(state.fieldsStatuses.deliveryDate),
          registrationDateResult: Formality.exposeFieldResult(state.fieldsStatuses.registrationDate),
          orderDateResult: Formality.exposeFieldResult(state.fieldsStatuses.orderDate),
          customerFirstNameResult: Formality.exposeFieldResult(state.fieldsStatuses.customerFirstName),
          shortCustomerNameResult: Formality.exposeFieldResult(state.fieldsStatuses.shortCustomerName),
          finalCustomerResult: Formality.exposeFieldResult(state.fieldsStatuses.finalCustomer),
          siretResult: Formality.exposeFieldResult(state.fieldsStatuses.siret),
          orderTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.orderType),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              var exit = 0;
              if (match.finalCustomerExists || match.siretBudgetLLD || match.siretActive || match.siretExists || match.easyPack || match.salesmanName || match.agentCode || match.customerDiscount || match.invoiceClient || match.invoiceNumber || match.deliveryDate || match.registrationDate || match.orderDate || match.customerFirstName || match.shortCustomerName || match.finalCustomer || match.siret) {
                exit = 1;
              } else {
                if (!match.orderType) {
                  return false;
                }
                exit = 1;
              }
              if (exit === 1) {
                var exit$1 = 0;
                exit$1 = 2;
                if (exit$1 === 2) {
                  var exit$2 = 0;
                  exit$2 = 3;
                  if (exit$2 === 3) {
                    var exit$3 = 0;
                    exit$3 = 4;
                    if (exit$3 === 4) {
                      var exit$4 = 0;
                      exit$4 = 5;
                      if (exit$4 === 5) {
                        var exit$5 = 0;
                        exit$5 = 6;
                        if (exit$5 === 6) {
                          var exit$6 = 0;
                          exit$6 = 7;
                          if (exit$6 === 7) {
                            return true;
                          }
                          
                        }
                        
                      }
                      
                    }
                    
                  }
                  
                }
                
              }
              
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses, metadata);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */18);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */19);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */20);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */20,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */21);
            })
        };
}

var Form = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var Types;

var initialState$1 = {
  orderType: /* NOTSET */11,
  siret: "",
  finalCustomer: "",
  shortCustomerName: "",
  customerFirstName: "",
  orderDate: undefined,
  registrationDate: undefined,
  deliveryDate: undefined,
  invoiceNumber: "",
  invoiceClient: undefined,
  customerDiscount: "",
  agentCode: "",
  salesmanName: "",
  easyPack: "",
  siretExists: true,
  siretActive: true,
  siretBudgetLLD: false,
  finalCustomerExists: true
};

export {
  Types ,
  Form ,
  initialState$1 as initialState,
}
/* react Not a pure module */
