// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import MessagesJson from "./_locales/es/messages.json";
import MessagesJson$1 from "./_locales/en-GB/messages.json";
import MessagesJson$2 from "./_locales/fr-FR/messages.json";

var en = MessagesJson$1;

var fr = MessagesJson$2;

var es = MessagesJson;

function getFrenchOrigString(key) {
  return Belt_Option.mapWithDefault(Js_dict.get(fr, key), "", (function (label) {
                return label.message + " (same as french)";
              }));
}

function getTranslation(locale) {
  var tmp;
  switch (locale) {
    case /* En */0 :
        tmp = en;
        break;
    case /* Fr */1 :
        tmp = fr;
        break;
    case /* EnWithID */2 :
        tmp = Js_dict.fromArray(Belt_Array.mapWithIndex(Js_dict.entries(en), (function (_index, param) {
                    var entry = param[1];
                    var key = param[0];
                    var string = entry.message;
                    var tmp = string === "" ? getFrenchOrigString(key) : string;
                    return [
                            key,
                            {
                              message: "" + tmp + " (" + key + ")",
                              description: entry.description
                            }
                          ];
                  })));
        break;
    case /* FrWithID */3 :
        tmp = Js_dict.fromArray(Belt_Array.mapWithIndex(Js_dict.entries(fr), (function (_index, param) {
                    var entry = param[1];
                    var key = param[0];
                    var string = entry.message;
                    var tmp = string === "" ? getFrenchOrigString(key) : string;
                    return [
                            key,
                            {
                              message: "" + tmp + " (" + key + ")",
                              description: entry.description
                            }
                          ];
                  })));
        break;
    case /* Es */4 :
        tmp = es;
        break;
    
  }
  return Js_dict.map((function (param) {
                return param.message;
              }), tmp);
}

export {
  en ,
  fr ,
  es ,
  getFrenchOrigString ,
  getTranslation ,
}
/* en Not a pure module */
