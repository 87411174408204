// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../../common/utils/Utils_Form.bs.js";

var validators_endOfLifeUsedCar = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var tmp;
      switch (param.endOfLifeUsedCar) {
        case "false" :
            tmp = false;
            break;
        case "true" :
            tmp = true;
            break;
        default:
          tmp = undefined;
      }
      return {
              TAG: /* Ok */0,
              _0: tmp
            };
    })
};

var validators_estimatedValueUsedCar = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      if ((param.orderType - 1 >>> 0) > 5) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      } else {
        return Utils_Form$DvmAdminFrontendFr.validateFloatPositive(param.estimatedValueUsedCar);
      }
    })
};

var validators_valueUsedCar = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      if ((param.orderType - 1 >>> 0) > 5) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      } else {
        return Utils_Form$DvmAdminFrontendFr.validateFloatPositive(param.valueUsedCar);
      }
    })
};

var validators_vin = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.vin
            };
    })
};

var validators_version = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.version
            };
    })
};

var validators_model = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateMaxCharacters(param.model, 3);
    })
};

var validators_brand = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateMaxCharacters(param.brand, 3);
    })
};

var validators_usedCarFirstReleaseDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateDate(param.usedCarFirstReleaseDate, undefined);
    })
};

var validators_usedCarRegistrationID = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.usedCarRegistrationID
            };
    })
};

var validators_usedCarLastRegistrationDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param) {
      return Utils_Form$DvmAdminFrontendFr.validateDate(param.usedCarLastRegistrationDate, undefined);
    })
};

var validators_policeNumber = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      return {
              TAG: /* Ok */0,
              _0: param.policeNumber
            };
    })
};

var validators = {
  endOfLifeUsedCar: validators_endOfLifeUsedCar,
  estimatedValueUsedCar: validators_estimatedValueUsedCar,
  valueUsedCar: validators_valueUsedCar,
  vin: validators_vin,
  version: validators_version,
  model: validators_model,
  brand: validators_brand,
  usedCarFirstReleaseDate: validators_usedCarFirstReleaseDate,
  usedCarRegistrationID: validators_usedCarRegistrationID,
  usedCarLastRegistrationDate: validators_usedCarLastRegistrationDate,
  policeNumber: validators_policeNumber,
  orderType: undefined
};

function initialFieldsStatuses(_input) {
  return {
          endOfLifeUsedCar: /* Pristine */0,
          estimatedValueUsedCar: /* Pristine */0,
          valueUsedCar: /* Pristine */0,
          vin: /* Pristine */0,
          version: /* Pristine */0,
          model: /* Pristine */0,
          brand: /* Pristine */0,
          usedCarFirstReleaseDate: /* Pristine */0,
          usedCarRegistrationID: /* Pristine */0,
          usedCarLastRegistrationDate: /* Pristine */0,
          policeNumber: /* Pristine */0,
          orderType: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.endOfLifeUsedCar;
  var match_0 = match ? match._0 : Curry._1(validators.endOfLifeUsedCar.validate, input);
  var match$1 = fieldsStatuses.estimatedValueUsedCar;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.estimatedValueUsedCar.validate, input);
  var match$2 = fieldsStatuses.valueUsedCar;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.valueUsedCar.validate, input);
  var match$3 = fieldsStatuses.vin;
  var match_0$3 = match$3 ? match$3._0 : Curry._1(validators.vin.validate, input);
  var match$4 = fieldsStatuses.version;
  var match_0$4 = match$4 ? match$4._0 : Curry._1(validators.version.validate, input);
  var match$5 = fieldsStatuses.model;
  var match_0$5 = match$5 ? match$5._0 : Curry._1(validators.model.validate, input);
  var match$6 = fieldsStatuses.brand;
  var match_0$6 = match$6 ? match$6._0 : Curry._1(validators.brand.validate, input);
  var match$7 = fieldsStatuses.usedCarFirstReleaseDate;
  var match_0$7 = match$7 ? match$7._0 : Curry._1(validators.usedCarFirstReleaseDate.validate, input);
  var match$8 = fieldsStatuses.usedCarRegistrationID;
  var match_0$8 = match$8 ? match$8._0 : Curry._1(validators.usedCarRegistrationID.validate, input);
  var match$9 = fieldsStatuses.usedCarLastRegistrationDate;
  var match_0$9 = match$9 ? match$9._0 : Curry._1(validators.usedCarLastRegistrationDate.validate, input);
  var match$10 = fieldsStatuses.policeNumber;
  var match_0$10 = match$10 ? match$10._0 : Curry._1(validators.policeNumber.validate, input);
  var match_0$11 = {
    TAG: /* Ok */0,
    _0: input.orderType
  };
  var endOfLifeUsedCarResult = match_0;
  var endOfLifeUsedCarResult$1;
  if (endOfLifeUsedCarResult.TAG === /* Ok */0) {
    var estimatedValueUsedCarResult = match_0$1;
    if (estimatedValueUsedCarResult.TAG === /* Ok */0) {
      var valueUsedCarResult = match_0$2;
      if (valueUsedCarResult.TAG === /* Ok */0) {
        var vinResult = match_0$3;
        if (vinResult.TAG === /* Ok */0) {
          var versionResult = match_0$4;
          if (versionResult.TAG === /* Ok */0) {
            var modelResult = match_0$5;
            if (modelResult.TAG === /* Ok */0) {
              var brandResult = match_0$6;
              if (brandResult.TAG === /* Ok */0) {
                var usedCarFirstReleaseDateResult = match_0$7;
                if (usedCarFirstReleaseDateResult.TAG === /* Ok */0) {
                  var usedCarRegistrationIDResult = match_0$8;
                  if (usedCarRegistrationIDResult.TAG === /* Ok */0) {
                    var usedCarLastRegistrationDateResult = match_0$9;
                    if (usedCarLastRegistrationDateResult.TAG === /* Ok */0) {
                      var policeNumberResult = match_0$10;
                      if (policeNumberResult.TAG === /* Ok */0) {
                        var orderTypeResult = match_0$11;
                        if (orderTypeResult.TAG === /* Ok */0) {
                          return {
                                  TAG: /* Valid */0,
                                  output: {
                                    orderType: orderTypeResult._0,
                                    policeNumber: policeNumberResult._0,
                                    usedCarLastRegistrationDate: usedCarLastRegistrationDateResult._0,
                                    usedCarRegistrationID: usedCarRegistrationIDResult._0,
                                    usedCarFirstReleaseDate: usedCarFirstReleaseDateResult._0,
                                    brand: brandResult._0,
                                    model: modelResult._0,
                                    version: versionResult._0,
                                    vin: vinResult._0,
                                    valueUsedCar: valueUsedCarResult._0,
                                    estimatedValueUsedCar: estimatedValueUsedCarResult._0,
                                    endOfLifeUsedCar: endOfLifeUsedCarResult._0
                                  },
                                  fieldsStatuses: {
                                    endOfLifeUsedCar: /* Dirty */{
                                      _0: endOfLifeUsedCarResult,
                                      _1: /* Shown */0
                                    },
                                    estimatedValueUsedCar: /* Dirty */{
                                      _0: estimatedValueUsedCarResult,
                                      _1: /* Shown */0
                                    },
                                    valueUsedCar: /* Dirty */{
                                      _0: valueUsedCarResult,
                                      _1: /* Shown */0
                                    },
                                    vin: /* Dirty */{
                                      _0: vinResult,
                                      _1: /* Shown */0
                                    },
                                    version: /* Dirty */{
                                      _0: versionResult,
                                      _1: /* Shown */0
                                    },
                                    model: /* Dirty */{
                                      _0: modelResult,
                                      _1: /* Shown */0
                                    },
                                    brand: /* Dirty */{
                                      _0: brandResult,
                                      _1: /* Shown */0
                                    },
                                    usedCarFirstReleaseDate: /* Dirty */{
                                      _0: usedCarFirstReleaseDateResult,
                                      _1: /* Shown */0
                                    },
                                    usedCarRegistrationID: /* Dirty */{
                                      _0: usedCarRegistrationIDResult,
                                      _1: /* Shown */0
                                    },
                                    usedCarLastRegistrationDate: /* Dirty */{
                                      _0: usedCarLastRegistrationDateResult,
                                      _1: /* Shown */0
                                    },
                                    policeNumber: /* Dirty */{
                                      _0: policeNumberResult,
                                      _1: /* Shown */0
                                    },
                                    orderType: /* Dirty */{
                                      _0: orderTypeResult,
                                      _1: /* Hidden */1
                                    }
                                  },
                                  collectionsStatuses: undefined
                                };
                        }
                        endOfLifeUsedCarResult$1 = endOfLifeUsedCarResult;
                      } else {
                        endOfLifeUsedCarResult$1 = endOfLifeUsedCarResult;
                      }
                    } else {
                      endOfLifeUsedCarResult$1 = endOfLifeUsedCarResult;
                    }
                  } else {
                    endOfLifeUsedCarResult$1 = endOfLifeUsedCarResult;
                  }
                } else {
                  endOfLifeUsedCarResult$1 = endOfLifeUsedCarResult;
                }
              } else {
                endOfLifeUsedCarResult$1 = endOfLifeUsedCarResult;
              }
            } else {
              endOfLifeUsedCarResult$1 = endOfLifeUsedCarResult;
            }
          } else {
            endOfLifeUsedCarResult$1 = endOfLifeUsedCarResult;
          }
        } else {
          endOfLifeUsedCarResult$1 = endOfLifeUsedCarResult;
        }
      } else {
        endOfLifeUsedCarResult$1 = endOfLifeUsedCarResult;
      }
    } else {
      endOfLifeUsedCarResult$1 = endOfLifeUsedCarResult;
    }
  } else {
    endOfLifeUsedCarResult$1 = endOfLifeUsedCarResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            endOfLifeUsedCar: /* Dirty */{
              _0: endOfLifeUsedCarResult$1,
              _1: /* Shown */0
            },
            estimatedValueUsedCar: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            valueUsedCar: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            vin: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            version: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            model: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            },
            brand: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            },
            usedCarFirstReleaseDate: /* Dirty */{
              _0: match_0$7,
              _1: /* Shown */0
            },
            usedCarRegistrationID: /* Dirty */{
              _0: match_0$8,
              _1: /* Shown */0
            },
            usedCarLastRegistrationDate: /* Dirty */{
              _0: match_0$9,
              _1: /* Shown */0
            },
            policeNumber: /* Dirty */{
              _0: match_0$10,
              _1: /* Shown */0
            },
            orderType: /* Dirty */{
              _0: match_0$11,
              _1: /* Hidden */1
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurEndOfLifeUsedCarField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.endOfLifeUsedCar, validators_endOfLifeUsedCar, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endOfLifeUsedCar: status,
                                  estimatedValueUsedCar: init.estimatedValueUsedCar,
                                  valueUsedCar: init.valueUsedCar,
                                  vin: init.vin,
                                  version: init.version,
                                  model: init.model,
                                  brand: init.brand,
                                  usedCarFirstReleaseDate: init.usedCarFirstReleaseDate,
                                  usedCarRegistrationID: init.usedCarRegistrationID,
                                  usedCarLastRegistrationDate: init.usedCarLastRegistrationDate,
                                  policeNumber: init.policeNumber,
                                  orderType: init.orderType
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEstimatedValueUsedCarField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.estimatedValueUsedCar, validators_estimatedValueUsedCar, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endOfLifeUsedCar: init.endOfLifeUsedCar,
                                  estimatedValueUsedCar: status,
                                  valueUsedCar: init.valueUsedCar,
                                  vin: init.vin,
                                  version: init.version,
                                  model: init.model,
                                  brand: init.brand,
                                  usedCarFirstReleaseDate: init.usedCarFirstReleaseDate,
                                  usedCarRegistrationID: init.usedCarRegistrationID,
                                  usedCarLastRegistrationDate: init.usedCarLastRegistrationDate,
                                  policeNumber: init.policeNumber,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurValueUsedCarField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.valueUsedCar, validators_valueUsedCar, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endOfLifeUsedCar: init.endOfLifeUsedCar,
                                  estimatedValueUsedCar: init.estimatedValueUsedCar,
                                  valueUsedCar: status,
                                  vin: init.vin,
                                  version: init.version,
                                  model: init.model,
                                  brand: init.brand,
                                  usedCarFirstReleaseDate: init.usedCarFirstReleaseDate,
                                  usedCarRegistrationID: init.usedCarRegistrationID,
                                  usedCarLastRegistrationDate: init.usedCarLastRegistrationDate,
                                  policeNumber: init.policeNumber,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVinField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.vin, validators_vin, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endOfLifeUsedCar: init.endOfLifeUsedCar,
                                  estimatedValueUsedCar: init.estimatedValueUsedCar,
                                  valueUsedCar: init.valueUsedCar,
                                  vin: status,
                                  version: init.version,
                                  model: init.model,
                                  brand: init.brand,
                                  usedCarFirstReleaseDate: init.usedCarFirstReleaseDate,
                                  usedCarRegistrationID: init.usedCarRegistrationID,
                                  usedCarLastRegistrationDate: init.usedCarLastRegistrationDate,
                                  policeNumber: init.policeNumber,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVersionField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.version, validators_version, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endOfLifeUsedCar: init.endOfLifeUsedCar,
                                  estimatedValueUsedCar: init.estimatedValueUsedCar,
                                  valueUsedCar: init.valueUsedCar,
                                  vin: init.vin,
                                  version: status,
                                  model: init.model,
                                  brand: init.brand,
                                  usedCarFirstReleaseDate: init.usedCarFirstReleaseDate,
                                  usedCarRegistrationID: init.usedCarRegistrationID,
                                  usedCarLastRegistrationDate: init.usedCarLastRegistrationDate,
                                  policeNumber: init.policeNumber,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurModelField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.model, validators_model, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endOfLifeUsedCar: init.endOfLifeUsedCar,
                                  estimatedValueUsedCar: init.estimatedValueUsedCar,
                                  valueUsedCar: init.valueUsedCar,
                                  vin: init.vin,
                                  version: init.version,
                                  model: status,
                                  brand: init.brand,
                                  usedCarFirstReleaseDate: init.usedCarFirstReleaseDate,
                                  usedCarRegistrationID: init.usedCarRegistrationID,
                                  usedCarLastRegistrationDate: init.usedCarLastRegistrationDate,
                                  policeNumber: init.policeNumber,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBrandField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.brand, validators_brand, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endOfLifeUsedCar: init.endOfLifeUsedCar,
                                  estimatedValueUsedCar: init.estimatedValueUsedCar,
                                  valueUsedCar: init.valueUsedCar,
                                  vin: init.vin,
                                  version: init.version,
                                  model: init.model,
                                  brand: status,
                                  usedCarFirstReleaseDate: init.usedCarFirstReleaseDate,
                                  usedCarRegistrationID: init.usedCarRegistrationID,
                                  usedCarLastRegistrationDate: init.usedCarLastRegistrationDate,
                                  policeNumber: init.policeNumber,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurUsedCarFirstReleaseDateField */7 :
                  var result$7 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.usedCarFirstReleaseDate, validators_usedCarFirstReleaseDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endOfLifeUsedCar: init.endOfLifeUsedCar,
                                  estimatedValueUsedCar: init.estimatedValueUsedCar,
                                  valueUsedCar: init.valueUsedCar,
                                  vin: init.vin,
                                  version: init.version,
                                  model: init.model,
                                  brand: init.brand,
                                  usedCarFirstReleaseDate: status,
                                  usedCarRegistrationID: init.usedCarRegistrationID,
                                  usedCarLastRegistrationDate: init.usedCarLastRegistrationDate,
                                  policeNumber: init.policeNumber,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurUsedCarRegistrationIDField */8 :
                  var result$8 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.usedCarRegistrationID, validators_usedCarRegistrationID, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endOfLifeUsedCar: init.endOfLifeUsedCar,
                                  estimatedValueUsedCar: init.estimatedValueUsedCar,
                                  valueUsedCar: init.valueUsedCar,
                                  vin: init.vin,
                                  version: init.version,
                                  model: init.model,
                                  brand: init.brand,
                                  usedCarFirstReleaseDate: init.usedCarFirstReleaseDate,
                                  usedCarRegistrationID: status,
                                  usedCarLastRegistrationDate: init.usedCarLastRegistrationDate,
                                  policeNumber: init.policeNumber,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurUsedCarLastRegistrationDateField */9 :
                  var result$9 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.usedCarLastRegistrationDate, validators_usedCarLastRegistrationDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endOfLifeUsedCar: init.endOfLifeUsedCar,
                                  estimatedValueUsedCar: init.estimatedValueUsedCar,
                                  valueUsedCar: init.valueUsedCar,
                                  vin: init.vin,
                                  version: init.version,
                                  model: init.model,
                                  brand: init.brand,
                                  usedCarFirstReleaseDate: init.usedCarFirstReleaseDate,
                                  usedCarRegistrationID: init.usedCarRegistrationID,
                                  usedCarLastRegistrationDate: status,
                                  policeNumber: init.policeNumber,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$9 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$9,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurPoliceNumberField */10 :
                  var result$10 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.policeNumber, validators_policeNumber, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endOfLifeUsedCar: init.endOfLifeUsedCar,
                                  estimatedValueUsedCar: init.estimatedValueUsedCar,
                                  valueUsedCar: init.valueUsedCar,
                                  vin: init.vin,
                                  version: init.version,
                                  model: init.model,
                                  brand: init.brand,
                                  usedCarFirstReleaseDate: init.usedCarFirstReleaseDate,
                                  usedCarRegistrationID: init.usedCarRegistrationID,
                                  usedCarLastRegistrationDate: init.usedCarLastRegistrationDate,
                                  policeNumber: status,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$10 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$10,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurOrderTypeField */11 :
                  var result$11 = Formality.validateFieldOnBlurWithoutValidator(state.input.orderType, state.fieldsStatuses.orderType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  endOfLifeUsedCar: init.endOfLifeUsedCar,
                                  estimatedValueUsedCar: init.estimatedValueUsedCar,
                                  valueUsedCar: init.valueUsedCar,
                                  vin: init.vin,
                                  version: init.version,
                                  model: init.model,
                                  brand: init.brand,
                                  usedCarFirstReleaseDate: init.usedCarFirstReleaseDate,
                                  usedCarRegistrationID: init.usedCarRegistrationID,
                                  usedCarLastRegistrationDate: init.usedCarLastRegistrationDate,
                                  policeNumber: init.policeNumber,
                                  orderType: status
                                };
                        }));
                  if (result$11 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$11,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */12 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */12,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */13,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */15);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */14);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */13 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */14 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */15 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateEndOfLifeUsedCarField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.endOfLifeUsedCar, state.submissionStatus, validators_endOfLifeUsedCar, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            endOfLifeUsedCar: status,
                                            estimatedValueUsedCar: init.estimatedValueUsedCar,
                                            valueUsedCar: init.valueUsedCar,
                                            vin: init.vin,
                                            version: init.version,
                                            model: init.model,
                                            brand: init.brand,
                                            usedCarFirstReleaseDate: init.usedCarFirstReleaseDate,
                                            usedCarRegistrationID: init.usedCarRegistrationID,
                                            usedCarLastRegistrationDate: init.usedCarLastRegistrationDate,
                                            policeNumber: init.policeNumber,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEstimatedValueUsedCarField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.estimatedValueUsedCar, state.submissionStatus, validators_estimatedValueUsedCar, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            endOfLifeUsedCar: init.endOfLifeUsedCar,
                                            estimatedValueUsedCar: status,
                                            valueUsedCar: init.valueUsedCar,
                                            vin: init.vin,
                                            version: init.version,
                                            model: init.model,
                                            brand: init.brand,
                                            usedCarFirstReleaseDate: init.usedCarFirstReleaseDate,
                                            usedCarRegistrationID: init.usedCarRegistrationID,
                                            usedCarLastRegistrationDate: init.usedCarLastRegistrationDate,
                                            policeNumber: init.policeNumber,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateValueUsedCarField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.valueUsedCar, state.submissionStatus, validators_valueUsedCar, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            endOfLifeUsedCar: init.endOfLifeUsedCar,
                                            estimatedValueUsedCar: init.estimatedValueUsedCar,
                                            valueUsedCar: status,
                                            vin: init.vin,
                                            version: init.version,
                                            model: init.model,
                                            brand: init.brand,
                                            usedCarFirstReleaseDate: init.usedCarFirstReleaseDate,
                                            usedCarRegistrationID: init.usedCarRegistrationID,
                                            usedCarLastRegistrationDate: init.usedCarLastRegistrationDate,
                                            policeNumber: init.policeNumber,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVinField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.vin, state.submissionStatus, validators_vin, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            endOfLifeUsedCar: init.endOfLifeUsedCar,
                                            estimatedValueUsedCar: init.estimatedValueUsedCar,
                                            valueUsedCar: init.valueUsedCar,
                                            vin: status,
                                            version: init.version,
                                            model: init.model,
                                            brand: init.brand,
                                            usedCarFirstReleaseDate: init.usedCarFirstReleaseDate,
                                            usedCarRegistrationID: init.usedCarRegistrationID,
                                            usedCarLastRegistrationDate: init.usedCarLastRegistrationDate,
                                            policeNumber: init.policeNumber,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVersionField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.version, state.submissionStatus, validators_version, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            endOfLifeUsedCar: init.endOfLifeUsedCar,
                                            estimatedValueUsedCar: init.estimatedValueUsedCar,
                                            valueUsedCar: init.valueUsedCar,
                                            vin: init.vin,
                                            version: status,
                                            model: init.model,
                                            brand: init.brand,
                                            usedCarFirstReleaseDate: init.usedCarFirstReleaseDate,
                                            usedCarRegistrationID: init.usedCarRegistrationID,
                                            usedCarLastRegistrationDate: init.usedCarLastRegistrationDate,
                                            policeNumber: init.policeNumber,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateModelField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$5, state.fieldsStatuses.model, state.submissionStatus, validators_model, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            endOfLifeUsedCar: init.endOfLifeUsedCar,
                                            estimatedValueUsedCar: init.estimatedValueUsedCar,
                                            valueUsedCar: init.valueUsedCar,
                                            vin: init.vin,
                                            version: init.version,
                                            model: status,
                                            brand: init.brand,
                                            usedCarFirstReleaseDate: init.usedCarFirstReleaseDate,
                                            usedCarRegistrationID: init.usedCarRegistrationID,
                                            usedCarLastRegistrationDate: init.usedCarLastRegistrationDate,
                                            policeNumber: init.policeNumber,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBrandField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$6, state.fieldsStatuses.brand, state.submissionStatus, validators_brand, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            endOfLifeUsedCar: init.endOfLifeUsedCar,
                                            estimatedValueUsedCar: init.estimatedValueUsedCar,
                                            valueUsedCar: init.valueUsedCar,
                                            vin: init.vin,
                                            version: init.version,
                                            model: init.model,
                                            brand: status,
                                            usedCarFirstReleaseDate: init.usedCarFirstReleaseDate,
                                            usedCarRegistrationID: init.usedCarRegistrationID,
                                            usedCarLastRegistrationDate: init.usedCarLastRegistrationDate,
                                            policeNumber: init.policeNumber,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateUsedCarFirstReleaseDateField */7 :
                  var nextInput$7 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$7, state.fieldsStatuses.usedCarFirstReleaseDate, state.submissionStatus, validators_usedCarFirstReleaseDate, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            endOfLifeUsedCar: init.endOfLifeUsedCar,
                                            estimatedValueUsedCar: init.estimatedValueUsedCar,
                                            valueUsedCar: init.valueUsedCar,
                                            vin: init.vin,
                                            version: init.version,
                                            model: init.model,
                                            brand: init.brand,
                                            usedCarFirstReleaseDate: status,
                                            usedCarRegistrationID: init.usedCarRegistrationID,
                                            usedCarLastRegistrationDate: init.usedCarLastRegistrationDate,
                                            policeNumber: init.policeNumber,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateUsedCarRegistrationIDField */8 :
                  var nextInput$8 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$8, state.fieldsStatuses.usedCarRegistrationID, state.submissionStatus, validators_usedCarRegistrationID, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            endOfLifeUsedCar: init.endOfLifeUsedCar,
                                            estimatedValueUsedCar: init.estimatedValueUsedCar,
                                            valueUsedCar: init.valueUsedCar,
                                            vin: init.vin,
                                            version: init.version,
                                            model: init.model,
                                            brand: init.brand,
                                            usedCarFirstReleaseDate: init.usedCarFirstReleaseDate,
                                            usedCarRegistrationID: status,
                                            usedCarLastRegistrationDate: init.usedCarLastRegistrationDate,
                                            policeNumber: init.policeNumber,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateUsedCarLastRegistrationDateField */9 :
                  var nextInput$9 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$9,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$9, state.fieldsStatuses.usedCarLastRegistrationDate, state.submissionStatus, validators_usedCarLastRegistrationDate, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            endOfLifeUsedCar: init.endOfLifeUsedCar,
                                            estimatedValueUsedCar: init.estimatedValueUsedCar,
                                            valueUsedCar: init.valueUsedCar,
                                            vin: init.vin,
                                            version: init.version,
                                            model: init.model,
                                            brand: init.brand,
                                            usedCarFirstReleaseDate: init.usedCarFirstReleaseDate,
                                            usedCarRegistrationID: init.usedCarRegistrationID,
                                            usedCarLastRegistrationDate: status,
                                            policeNumber: init.policeNumber,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdatePoliceNumberField */10 :
                  var nextInput$10 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$10,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$10, state.fieldsStatuses.policeNumber, state.submissionStatus, validators_policeNumber, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            endOfLifeUsedCar: init.endOfLifeUsedCar,
                                            estimatedValueUsedCar: init.estimatedValueUsedCar,
                                            valueUsedCar: init.valueUsedCar,
                                            vin: init.vin,
                                            version: init.version,
                                            model: init.model,
                                            brand: init.brand,
                                            usedCarFirstReleaseDate: init.usedCarFirstReleaseDate,
                                            usedCarRegistrationID: init.usedCarRegistrationID,
                                            usedCarLastRegistrationDate: init.usedCarLastRegistrationDate,
                                            policeNumber: status,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateOrderTypeField */11 :
                  var nextInput$11 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  var result$12 = Formality.validateDependentFieldOnChange(nextInput$11, nextFieldsStatuses.contents.valueUsedCar, validators_valueUsedCar, (function (status) {
                          var init = nextFieldsStatuses.contents;
                          return {
                                  endOfLifeUsedCar: init.endOfLifeUsedCar,
                                  estimatedValueUsedCar: init.estimatedValueUsedCar,
                                  valueUsedCar: status,
                                  vin: init.vin,
                                  version: init.version,
                                  model: init.model,
                                  brand: init.brand,
                                  usedCarFirstReleaseDate: init.usedCarFirstReleaseDate,
                                  usedCarRegistrationID: init.usedCarRegistrationID,
                                  usedCarLastRegistrationDate: init.usedCarLastRegistrationDate,
                                  policeNumber: init.policeNumber,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$12 !== undefined) {
                    nextFieldsStatuses.contents = result$12;
                  }
                  var result$13 = Formality.validateDependentFieldOnChange(nextInput$11, nextFieldsStatuses.contents.estimatedValueUsedCar, validators_estimatedValueUsedCar, (function (status) {
                          var init = nextFieldsStatuses.contents;
                          return {
                                  endOfLifeUsedCar: init.endOfLifeUsedCar,
                                  estimatedValueUsedCar: status,
                                  valueUsedCar: init.valueUsedCar,
                                  vin: init.vin,
                                  version: init.version,
                                  model: init.model,
                                  brand: init.brand,
                                  usedCarFirstReleaseDate: init.usedCarFirstReleaseDate,
                                  usedCarRegistrationID: init.usedCarRegistrationID,
                                  usedCarLastRegistrationDate: init.usedCarLastRegistrationDate,
                                  policeNumber: init.policeNumber,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$13 !== undefined) {
                    nextFieldsStatuses.contents = result$13;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$11,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$11.orderType, (function (status) {
                                    var init = nextFieldsStatuses.contents;
                                    return {
                                            endOfLifeUsedCar: init.endOfLifeUsedCar,
                                            estimatedValueUsedCar: init.estimatedValueUsedCar,
                                            valueUsedCar: init.valueUsedCar,
                                            vin: init.vin,
                                            version: init.version,
                                            model: init.model,
                                            brand: init.brand,
                                            usedCarFirstReleaseDate: init.usedCarFirstReleaseDate,
                                            usedCarRegistrationID: init.usedCarRegistrationID,
                                            usedCarLastRegistrationDate: init.usedCarLastRegistrationDate,
                                            policeNumber: init.policeNumber,
                                            orderType: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */12 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */13 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */14 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateEndOfLifeUsedCar: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEndOfLifeUsedCarField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEstimatedValueUsedCar: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEstimatedValueUsedCarField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateValueUsedCar: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateValueUsedCarField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateVin: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVinField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateVersion: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVersionField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateModel: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateModelField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateBrand: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBrandField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateUsedCarFirstReleaseDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateUsedCarFirstReleaseDateField */7,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateUsedCarRegistrationID: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateUsedCarRegistrationIDField */8,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateUsedCarLastRegistrationDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateUsedCarLastRegistrationDateField */9,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updatePoliceNumber: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdatePoliceNumberField */10,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateOrderType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateOrderTypeField */11,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurEndOfLifeUsedCar: (function (param) {
              Curry._1(dispatch, /* BlurEndOfLifeUsedCarField */0);
            }),
          blurEstimatedValueUsedCar: (function (param) {
              Curry._1(dispatch, /* BlurEstimatedValueUsedCarField */1);
            }),
          blurValueUsedCar: (function (param) {
              Curry._1(dispatch, /* BlurValueUsedCarField */2);
            }),
          blurVin: (function (param) {
              Curry._1(dispatch, /* BlurVinField */3);
            }),
          blurVersion: (function (param) {
              Curry._1(dispatch, /* BlurVersionField */4);
            }),
          blurModel: (function (param) {
              Curry._1(dispatch, /* BlurModelField */5);
            }),
          blurBrand: (function (param) {
              Curry._1(dispatch, /* BlurBrandField */6);
            }),
          blurUsedCarFirstReleaseDate: (function (param) {
              Curry._1(dispatch, /* BlurUsedCarFirstReleaseDateField */7);
            }),
          blurUsedCarRegistrationID: (function (param) {
              Curry._1(dispatch, /* BlurUsedCarRegistrationIDField */8);
            }),
          blurUsedCarLastRegistrationDate: (function (param) {
              Curry._1(dispatch, /* BlurUsedCarLastRegistrationDateField */9);
            }),
          blurPoliceNumber: (function (param) {
              Curry._1(dispatch, /* BlurPoliceNumberField */10);
            }),
          blurOrderType: (function (param) {
              Curry._1(dispatch, /* BlurOrderTypeField */11);
            }),
          endOfLifeUsedCarResult: Formality.exposeFieldResult(state.fieldsStatuses.endOfLifeUsedCar),
          estimatedValueUsedCarResult: Formality.exposeFieldResult(state.fieldsStatuses.estimatedValueUsedCar),
          valueUsedCarResult: Formality.exposeFieldResult(state.fieldsStatuses.valueUsedCar),
          vinResult: Formality.exposeFieldResult(state.fieldsStatuses.vin),
          versionResult: Formality.exposeFieldResult(state.fieldsStatuses.version),
          modelResult: Formality.exposeFieldResult(state.fieldsStatuses.model),
          brandResult: Formality.exposeFieldResult(state.fieldsStatuses.brand),
          usedCarFirstReleaseDateResult: Formality.exposeFieldResult(state.fieldsStatuses.usedCarFirstReleaseDate),
          usedCarRegistrationIDResult: Formality.exposeFieldResult(state.fieldsStatuses.usedCarRegistrationID),
          usedCarLastRegistrationDateResult: Formality.exposeFieldResult(state.fieldsStatuses.usedCarLastRegistrationDate),
          policeNumberResult: Formality.exposeFieldResult(state.fieldsStatuses.policeNumber),
          orderTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.orderType),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (!match.endOfLifeUsedCar && !match.estimatedValueUsedCar && !match.valueUsedCar && !match.vin && !match.version && !match.model && !match.brand && !match.usedCarFirstReleaseDate && !match.usedCarRegistrationID && !match.usedCarLastRegistrationDate && !match.policeNumber && !match.orderType) {
                return false;
              }
              return true;
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */12);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */13);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */14);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */14,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */15);
            })
        };
}

var CorrectionVehicleForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var Types;

var initialState$1 = {
  orderType: /* NOTSET */11,
  policeNumber: "",
  usedCarLastRegistrationDate: undefined,
  usedCarRegistrationID: "",
  usedCarFirstReleaseDate: undefined,
  brand: "",
  model: "",
  version: "",
  vin: "",
  valueUsedCar: "",
  estimatedValueUsedCar: "",
  endOfLifeUsedCar: ""
};

export {
  Types ,
  CorrectionVehicleForm ,
  initialState$1 as initialState,
}
/* react Not a pure module */
