// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../../common/components/button/App_Button.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../common/App_Typography.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../common/styles/App_Styles_Common.bs.js";
import * as Dealer_Detail_Api$DvmAdminFrontendFr from "../../../../dealer/detail/Dealer_Detail_Api.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../common/components/App_OverlaySpinner.bs.js";
import * as App_SelectFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_SelectFormInput.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Messages_FinancialCodes$DvmAdminFrontendFr from "../../../../../intl/messages/easypack/Messages_FinancialCodes.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as Messages_Orders_Correction$DvmAdminFrontendFr from "../../../../../intl/messages/orders/Messages_Orders_Correction.bs.js";
import * as Order_Detail_Types_Order_Type$DvmAdminFrontendFr from "../../Order_Detail_Types_Order_Type.bs.js";
import * as Order_Detail_Reselling_Edits_Api$DvmAdminFrontendFr from "./Order_Detail_Reselling_Edits_Api.bs.js";
import * as Order_Detail_Reselling_Edits_Form$DvmAdminFrontendFr from "./Order_Detail_Reselling_Edits_Form.bs.js";
import * as Order_Detail_Reselling_Edits_Utils$DvmAdminFrontendFr from "./Order_Detail_Reselling_Edits_Utils.bs.js";
import * as Order_Detail_Reselling_Edits_Inputs_Sirets$DvmAdminFrontendFr from "./Order_Detail_Reselling_Edits_Inputs_Sirets.bs.js";

function Order_Detail_Reselling_Edits_Inputs(Props) {
  var orderId = Props.orderId;
  var order = Props.order;
  var setSaveResult = Props.setSaveResult;
  var lastCorrectionValues = Props.lastCorrectionValues;
  var lastOrigOrderCorrectionValues = Props.lastOrigOrderCorrectionValues;
  var actualOrderType = Props.actualOrderType;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return false;
      });
  var setConfirmModal = match$2[1];
  var match$3 = React.useState(function () {
        
      });
  var setFinalValues = match$3[1];
  var finalValues = match$3[0];
  var match$4 = React.useState(function () {
        
      });
  var setSubmissionCallbacks = match$4[1];
  var submissionCallbacks = match$4[0];
  var match$5 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setAgents = match$5[1];
  var agents = match$5[0];
  var match$6 = React.useState(function () {
        return /* NotStarted */0;
      });
  var siretActiveResult = match$6[0];
  var match$7 = React.useState(function () {
        return /* NotStarted */0;
      });
  var siretBudgetLLD = match$7[0];
  var match$8 = React.useState(function () {
        return /* NotStarted */0;
      });
  var siretExists = match$8[0];
  var match$9 = React.useState(function () {
        return /* NotStarted */0;
      });
  var finalCustomerExists = match$9[0];
  var match$10 = React.useState(function () {
        return false;
      });
  var formConfirmDisable = match$10[0];
  React.useEffect((function () {
          Belt_Option.mapWithDefault(order.dealerID, undefined, (function (x) {
                  Dealer_Detail_Api$DvmAdminFrontendFr.fetchAgents(userManager, setAgents, "?limit=1000&offset=0&filter=dealerID=" + x + "", selectedRole);
                }));
        }), []);
  var postChange = function (param) {
    if (finalValues !== undefined && submissionCallbacks !== undefined) {
      return Order_Detail_Reselling_Edits_Api$DvmAdminFrontendFr.postResellEditCorrection(userManager, setSaveResult, submissionCallbacks, selectedRole, Order_Detail_Reselling_Edits_Utils$DvmAdminFrontendFr.postWithLatestCorrection(finalValues, orderId));
    }
    
  };
  var form = Order_Detail_Reselling_Edits_Form$DvmAdminFrontendFr.Form.useForm(Order_Detail_Reselling_Edits_Utils$DvmAdminFrontendFr.getInitialInput(lastCorrectionValues, order), {
        selectedRole: selectedRole,
        lastOrigOrderCorrectionValues: lastOrigOrderCorrectionValues
      }, (function (output, submissionCallbacks) {
          Curry._1(setFinalValues, (function (param) {
                  return output;
                }));
          Curry._1(setSubmissionCallbacks, (function (param) {
                  return submissionCallbacks;
                }));
        }));
  React.useEffect((function () {
          if (finalValues !== undefined && submissionCallbacks !== undefined) {
            Curry._1(setConfirmModal, (function (param) {
                    return true;
                  }));
          }
          
        }), [
        finalValues,
        submissionCallbacks
      ]);
  var tmp;
  switch (actualOrderType) {
    case /* EP */7 :
    case /* ES */8 :
        tmp = {
          hd: "ES",
          tl: {
            hd: "EP",
            tl: /* [] */0
          }
        };
        break;
    case /* RP */9 :
    case /* RS */10 :
        tmp = {
          hd: "RS",
          tl: {
            hd: "RP",
            tl: /* [] */0
          }
        };
        break;
    case /* SO */0 :
    case /* CL */1 :
    case /* VE */2 :
    case /* VD */3 :
    case /* RR */4 :
    case /* RC */5 :
    case /* PG */6 :
    case /* NOTSET */11 :
        tmp = /* [] */0;
        break;
    
  }
  var tmp$1 = {
    label: Messages_Common$DvmAdminFrontendFr.orderType,
    options: Belt_List.map(tmp, (function (x) {
            return {
                    label: {
                      TAG: /* String */3,
                      _0: x
                    },
                    value: x
                  };
          })),
    value: {
      TAG: /* Single */0,
      _0: {
        value: Order_Detail_Types_Order_Type$DvmAdminFrontendFr.toString(form.input.orderType),
        onChange: (function (val) {
            Curry._2(form.updateOrderType, (function (input, orderType) {
                    return {
                            orderType: orderType,
                            siret: input.siret,
                            finalCustomer: input.finalCustomer,
                            shortCustomerName: input.shortCustomerName,
                            customerFirstName: input.customerFirstName,
                            orderDate: input.orderDate,
                            registrationDate: input.registrationDate,
                            deliveryDate: input.deliveryDate,
                            invoiceNumber: input.invoiceNumber,
                            invoiceClient: input.invoiceClient,
                            customerDiscount: input.customerDiscount,
                            agentCode: input.agentCode,
                            salesmanName: input.salesmanName,
                            easyPack: input.easyPack,
                            siretExists: input.siretExists,
                            siretActive: input.siretActive,
                            siretBudgetLLD: input.siretBudgetLLD,
                            finalCustomerExists: input.finalCustomerExists
                          };
                  }), Belt_Option.getWithDefault(Order_Detail_Types_Order_Type$DvmAdminFrontendFr.fromString(val), /* NOTSET */11));
          })
      }
    },
    id: "orderType",
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    required: /* OnlyLabel */1
  };
  if (form.orderTypeResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.orderTypeResult);
  }
  var tmp$2;
  if (form.input.orderType === /* ES */8 || form.input.orderType === /* RS */10) {
    tmp$2 = React.createElement(Order_Detail_Reselling_Edits_Inputs_Sirets$DvmAdminFrontendFr.make, {
          form: form,
          siretActiveResult: siretActiveResult,
          setSiretActiveResult: match$6[1],
          siretBudgetLLD: siretBudgetLLD,
          setSiretBudgetLLD: match$7[1],
          siretExists: siretExists,
          setSiretExists: match$8[1],
          finalCustomerExists: finalCustomerExists,
          setFinalCustomerExists: match$9[1],
          setFormConfirmDisable: match$10[1]
        });
  } else {
    var tmp$3 = {
      onChange: Curry._1(form.updateShortCustomerName, (function (input, shortCustomerName) {
              return {
                      orderType: input.orderType,
                      siret: input.siret,
                      finalCustomer: input.finalCustomer,
                      shortCustomerName: shortCustomerName,
                      customerFirstName: input.customerFirstName,
                      orderDate: input.orderDate,
                      registrationDate: input.registrationDate,
                      deliveryDate: input.deliveryDate,
                      invoiceNumber: input.invoiceNumber,
                      invoiceClient: input.invoiceClient,
                      customerDiscount: input.customerDiscount,
                      agentCode: input.agentCode,
                      salesmanName: input.salesmanName,
                      easyPack: input.easyPack,
                      siretExists: input.siretExists,
                      siretActive: input.siretActive,
                      siretBudgetLLD: input.siretBudgetLLD,
                      finalCustomerExists: input.finalCustomerExists
                    };
            })),
      value: form.input.shortCustomerName,
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
      label: Messages_Orders_Detail$DvmAdminFrontendFr.customerName
    };
    if (form.shortCustomerNameResult !== undefined) {
      tmp$3.validationResult = Caml_option.valFromOption(form.shortCustomerNameResult);
    }
    var tmp$4 = {
      onChange: Curry._1(form.updateCustomerFirstName, (function (input, customerFirstName) {
              return {
                      orderType: input.orderType,
                      siret: input.siret,
                      finalCustomer: input.finalCustomer,
                      shortCustomerName: input.shortCustomerName,
                      customerFirstName: customerFirstName,
                      orderDate: input.orderDate,
                      registrationDate: input.registrationDate,
                      deliveryDate: input.deliveryDate,
                      invoiceNumber: input.invoiceNumber,
                      invoiceClient: input.invoiceClient,
                      customerDiscount: input.customerDiscount,
                      agentCode: input.agentCode,
                      salesmanName: input.salesmanName,
                      easyPack: input.easyPack,
                      siretExists: input.siretExists,
                      siretActive: input.siretActive,
                      siretBudgetLLD: input.siretBudgetLLD,
                      finalCustomerExists: input.finalCustomerExists
                    };
            })),
      value: form.input.customerFirstName,
      className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
      label: Messages_Orders_Detail$DvmAdminFrontendFr.clientFirstName
    };
    if (form.customerFirstNameResult !== undefined) {
      tmp$4.validationResult = Caml_option.valFromOption(form.customerFirstNameResult);
    }
    tmp$2 = React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
              children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$3),
              item: true
            }), React.createElement(Core.Grid, {
              children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$4),
              item: true
            }));
  }
  var tmp$5 = {
    label: Messages_Orders_Detail$DvmAdminFrontendFr.orderDateV2,
    value: form.input.orderDate,
    onChange: Curry._1(form.updateOrderDate, (function (input, orderDate) {
            return {
                    orderType: input.orderType,
                    siret: input.siret,
                    finalCustomer: input.finalCustomer,
                    shortCustomerName: input.shortCustomerName,
                    customerFirstName: input.customerFirstName,
                    orderDate: orderDate,
                    registrationDate: input.registrationDate,
                    deliveryDate: input.deliveryDate,
                    invoiceNumber: input.invoiceNumber,
                    invoiceClient: input.invoiceClient,
                    customerDiscount: input.customerDiscount,
                    agentCode: input.agentCode,
                    salesmanName: input.salesmanName,
                    easyPack: input.easyPack,
                    siretExists: input.siretExists,
                    siretActive: input.siretActive,
                    siretBudgetLLD: input.siretBudgetLLD,
                    finalCustomerExists: input.finalCustomerExists
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
  };
  if (form.orderDateResult !== undefined) {
    tmp$5.validationResult = Caml_option.valFromOption(form.orderDateResult);
  }
  var tmp$6 = {
    label: Messages_Orders_Detail$DvmAdminFrontendFr.deliveryDate,
    value: form.input.deliveryDate,
    onChange: Curry._1(form.updateDeliveryDate, (function (input, deliveryDate) {
            return {
                    orderType: input.orderType,
                    siret: input.siret,
                    finalCustomer: input.finalCustomer,
                    shortCustomerName: input.shortCustomerName,
                    customerFirstName: input.customerFirstName,
                    orderDate: input.orderDate,
                    registrationDate: input.registrationDate,
                    deliveryDate: deliveryDate,
                    invoiceNumber: input.invoiceNumber,
                    invoiceClient: input.invoiceClient,
                    customerDiscount: input.customerDiscount,
                    agentCode: input.agentCode,
                    salesmanName: input.salesmanName,
                    easyPack: input.easyPack,
                    siretExists: input.siretExists,
                    siretActive: input.siretActive,
                    siretBudgetLLD: input.siretBudgetLLD,
                    finalCustomerExists: input.finalCustomerExists
                  };
          })),
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
  };
  if (form.deliveryDateResult !== undefined) {
    tmp$6.validationResult = Caml_option.valFromOption(form.deliveryDateResult);
  }
  var tmp$7 = {
    label: Messages_Common$DvmAdminFrontendFr.agent,
    value: {
      TAG: /* Single */0,
      _0: {
        value: form.input.agentCode,
        onChange: Curry._1(form.updateAgentCode, (function (input, agentCode) {
                return {
                        orderType: input.orderType,
                        siret: input.siret,
                        finalCustomer: input.finalCustomer,
                        shortCustomerName: input.shortCustomerName,
                        customerFirstName: input.customerFirstName,
                        orderDate: input.orderDate,
                        registrationDate: input.registrationDate,
                        deliveryDate: input.deliveryDate,
                        invoiceNumber: input.invoiceNumber,
                        invoiceClient: input.invoiceClient,
                        customerDiscount: input.customerDiscount,
                        agentCode: agentCode,
                        salesmanName: input.salesmanName,
                        easyPack: input.easyPack,
                        siretExists: input.siretExists,
                        siretActive: input.siretActive,
                        siretBudgetLLD: input.siretBudgetLLD,
                        finalCustomerExists: input.finalCustomerExists
                      };
              }))
      }
    },
    options: {
      TAG: /* Labeled */1,
      _0: App_Types_Result$DvmAdminFrontendFr.mapWithDefault(agents, /* [] */0, (function (x) {
              return Belt_List.map(Belt_List.sort(x.content, (function (a, b) {
                                return Caml.string_compare(a.agentCode, b.agentCode);
                              })), (function (x) {
                            return {
                                    label: {
                                      TAG: /* String */3,
                                      _0: x.agentCode + Belt_Option.mapWithDefault(x.dealerName, "", (function (x) {
                                              return " - " + x + "";
                                            }))
                                    },
                                    value: x.agentCode
                                  };
                          }));
            }))
    },
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    onSearch: (function (val) {
        Curry._2(form.updateAgentCode, (function (input, agentCode) {
                return {
                        orderType: input.orderType,
                        siret: input.siret,
                        finalCustomer: input.finalCustomer,
                        shortCustomerName: input.shortCustomerName,
                        customerFirstName: input.customerFirstName,
                        orderDate: input.orderDate,
                        registrationDate: input.registrationDate,
                        deliveryDate: input.deliveryDate,
                        invoiceNumber: input.invoiceNumber,
                        invoiceClient: input.invoiceClient,
                        customerDiscount: input.customerDiscount,
                        agentCode: agentCode,
                        salesmanName: input.salesmanName,
                        easyPack: input.easyPack,
                        siretExists: input.siretExists,
                        siretActive: input.siretActive,
                        siretBudgetLLD: input.siretBudgetLLD,
                        finalCustomerExists: input.finalCustomerExists
                      };
              }), val.replace(/[^0-9]/g, ""));
      }),
    freeSolo: true
  };
  if (form.agentCodeResult !== undefined) {
    tmp$7.validationResult = Caml_option.valFromOption(form.agentCodeResult);
  }
  var tmp$8 = {
    onChange: Curry._1(form.updateCustomerDiscount, (function (input, customerDiscount) {
            return {
                    orderType: input.orderType,
                    siret: input.siret,
                    finalCustomer: input.finalCustomer,
                    shortCustomerName: input.shortCustomerName,
                    customerFirstName: input.customerFirstName,
                    orderDate: input.orderDate,
                    registrationDate: input.registrationDate,
                    deliveryDate: input.deliveryDate,
                    invoiceNumber: input.invoiceNumber,
                    invoiceClient: input.invoiceClient,
                    customerDiscount: customerDiscount,
                    agentCode: input.agentCode,
                    salesmanName: input.salesmanName,
                    easyPack: input.easyPack,
                    siretExists: input.siretExists,
                    siretActive: input.siretActive,
                    siretBudgetLLD: input.siretBudgetLLD,
                    finalCustomerExists: input.finalCustomerExists
                  };
          })),
    value: form.input.customerDiscount,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    label: Messages_Common$DvmAdminFrontendFr.discountTTC,
    InputProps: {
      startAdornment: React.createElement(Core.InputAdornment, {
            children: "€",
            position: "start"
          })
    },
    inputType: "number"
  };
  if (form.customerDiscountResult !== undefined) {
    tmp$8.validationResult = Caml_option.valFromOption(form.customerDiscountResult);
  }
  return React.createElement(React.Fragment, undefined, React.createElement("form", {
                  onSubmit: (function ($$event) {
                      $$event.preventDefault();
                      if (App_Types_Result$DvmAdminFrontendFr.isPending(siretExists) || App_Types_Result$DvmAdminFrontendFr.isPending(finalCustomerExists) || App_Types_Result$DvmAdminFrontendFr.isPending(siretActiveResult) || App_Types_Result$DvmAdminFrontendFr.isPending(siretBudgetLLD) || formConfirmDisable) {
                        return ;
                      } else {
                        return Curry._1(form.submit, undefined);
                      }
                    })
                }, React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      direction: "column",
                      justifyContent: "space-between",
                      spacing: 2,
                      wrap: "nowrap"
                    }, React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          item: true,
                          spacing: 1
                        }, React.createElement(Core.Grid, {
                              children: React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, tmp$1),
                              item: true
                            }), tmp$2, React.createElement(Core.Grid, {
                              children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$5),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, {
                                    label: Messages_Orders_Detail$DvmAdminFrontendFr.registrationDateV2,
                                    value: form.input.registrationDate,
                                    onChange: Curry._1(form.updateRegistrationDate, (function (input, registrationDate) {
                                            return {
                                                    orderType: input.orderType,
                                                    siret: input.siret,
                                                    finalCustomer: input.finalCustomer,
                                                    shortCustomerName: input.shortCustomerName,
                                                    customerFirstName: input.customerFirstName,
                                                    orderDate: input.orderDate,
                                                    registrationDate: registrationDate,
                                                    deliveryDate: input.deliveryDate,
                                                    invoiceNumber: input.invoiceNumber,
                                                    invoiceClient: input.invoiceClient,
                                                    customerDiscount: input.customerDiscount,
                                                    agentCode: input.agentCode,
                                                    salesmanName: input.salesmanName,
                                                    easyPack: input.easyPack,
                                                    siretExists: input.siretExists,
                                                    siretActive: input.siretActive,
                                                    siretBudgetLLD: input.siretBudgetLLD,
                                                    finalCustomerExists: input.finalCustomerExists
                                                  };
                                          })),
                                    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, tmp$6),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                                    onChange: Curry._1(form.updateInvoiceNumber, (function (input, invoiceNumber) {
                                            return {
                                                    orderType: input.orderType,
                                                    siret: input.siret,
                                                    finalCustomer: input.finalCustomer,
                                                    shortCustomerName: input.shortCustomerName,
                                                    customerFirstName: input.customerFirstName,
                                                    orderDate: input.orderDate,
                                                    registrationDate: input.registrationDate,
                                                    deliveryDate: input.deliveryDate,
                                                    invoiceNumber: invoiceNumber,
                                                    invoiceClient: input.invoiceClient,
                                                    customerDiscount: input.customerDiscount,
                                                    agentCode: input.agentCode,
                                                    salesmanName: input.salesmanName,
                                                    easyPack: input.easyPack,
                                                    siretExists: input.siretExists,
                                                    siretActive: input.siretActive,
                                                    siretBudgetLLD: input.siretBudgetLLD,
                                                    finalCustomerExists: input.finalCustomerExists
                                                  };
                                          })),
                                    value: form.input.invoiceNumber,
                                    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
                                    label: Messages_Orders_Detail$DvmAdminFrontendFr.invoiceNumber
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, {
                                    label: Messages_Orders_Detail$DvmAdminFrontendFr.invoiceClientV2,
                                    value: form.input.invoiceClient,
                                    onChange: Curry._1(form.updateInvoiceClient, (function (input, invoiceClient) {
                                            return {
                                                    orderType: input.orderType,
                                                    siret: input.siret,
                                                    finalCustomer: input.finalCustomer,
                                                    shortCustomerName: input.shortCustomerName,
                                                    customerFirstName: input.customerFirstName,
                                                    orderDate: input.orderDate,
                                                    registrationDate: input.registrationDate,
                                                    deliveryDate: input.deliveryDate,
                                                    invoiceNumber: input.invoiceNumber,
                                                    invoiceClient: invoiceClient,
                                                    customerDiscount: input.customerDiscount,
                                                    agentCode: input.agentCode,
                                                    salesmanName: input.salesmanName,
                                                    easyPack: input.easyPack,
                                                    siretExists: input.siretExists,
                                                    siretActive: input.siretActive,
                                                    siretBudgetLLD: input.siretBudgetLLD,
                                                    finalCustomerExists: input.finalCustomerExists
                                                  };
                                          })),
                                    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                                    show: App_Types_Result$DvmAdminFrontendFr.isPending(agents),
                                    children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, tmp$7)
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                                    onChange: Curry._1(form.updateSalesmanName, (function (input, salesmanName) {
                                            return {
                                                    orderType: input.orderType,
                                                    siret: input.siret,
                                                    finalCustomer: input.finalCustomer,
                                                    shortCustomerName: input.shortCustomerName,
                                                    customerFirstName: input.customerFirstName,
                                                    orderDate: input.orderDate,
                                                    registrationDate: input.registrationDate,
                                                    deliveryDate: input.deliveryDate,
                                                    invoiceNumber: input.invoiceNumber,
                                                    invoiceClient: input.invoiceClient,
                                                    customerDiscount: input.customerDiscount,
                                                    agentCode: input.agentCode,
                                                    salesmanName: salesmanName,
                                                    easyPack: input.easyPack,
                                                    siretExists: input.siretExists,
                                                    siretActive: input.siretActive,
                                                    siretBudgetLLD: input.siretBudgetLLD,
                                                    finalCustomerExists: input.finalCustomerExists
                                                  };
                                          })),
                                    value: form.input.salesmanName,
                                    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
                                    label: Messages_Orders_Detail$DvmAdminFrontendFr.sellerIPN
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$8),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                                    onChange: Curry._1(form.updateEasyPack, (function (input, easyPack) {
                                            return {
                                                    orderType: input.orderType,
                                                    siret: input.siret,
                                                    finalCustomer: input.finalCustomer,
                                                    shortCustomerName: input.shortCustomerName,
                                                    customerFirstName: input.customerFirstName,
                                                    orderDate: input.orderDate,
                                                    registrationDate: input.registrationDate,
                                                    deliveryDate: input.deliveryDate,
                                                    invoiceNumber: input.invoiceNumber,
                                                    invoiceClient: input.invoiceClient,
                                                    customerDiscount: input.customerDiscount,
                                                    agentCode: input.agentCode,
                                                    salesmanName: input.salesmanName,
                                                    easyPack: easyPack,
                                                    siretExists: input.siretExists,
                                                    siretActive: input.siretActive,
                                                    siretBudgetLLD: input.siretBudgetLLD,
                                                    finalCustomerExists: input.finalCustomerExists
                                                  };
                                          })),
                                    value: form.input.easyPack,
                                    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
                                    label: Messages_FinancialCodes$DvmAdminFrontendFr.codeId
                                  }),
                              item: true
                            })), React.createElement(Core.Grid, {
                          alignItems: "flex-end",
                          children: null,
                          container: true,
                          direction: "column",
                          item: true
                        }, React.createElement(Core.Grid, {
                              children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                    label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                    color: "primary",
                                    size: "medium",
                                    type_: "submit",
                                    style: {
                                      width: "200px"
                                    }
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                    noWrap: true,
                                    style: {
                                      color: Theme_Colors$DvmAdminFrontendFr.grayDark2,
                                      fontWeight: "600"
                                    },
                                    children: intl.formatMessage(Messages_Orders_Correction$DvmAdminFrontendFr.correctionWillUpdateTomorrow),
                                    variant: "subtitle"
                                  }),
                              item: true
                            })))), match$2[0] ? React.createElement(App_Modal$DvmAdminFrontendFr.make, {
                    open: true,
                    onClose: (function (param) {
                        Curry._1(setConfirmModal, (function (param) {
                                return false;
                              }));
                        Belt_Option.mapWithDefault(submissionCallbacks, undefined, (function (x) {
                                Curry._1(x.notifyOnFailure, /* FailedToUpdateResellOrder */0);
                              }));
                      }),
                    title: intl.formatMessage(Messages_Orders_Detail$DvmAdminFrontendFr.confirmEditVOModalTitle),
                    minWidth: "400px",
                    children: React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          justifyContent: "space-between"
                        }, React.createElement(Core.Grid, {
                              children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                    onClick: (function (param) {
                                        Curry._1(setConfirmModal, (function (param) {
                                                return false;
                                              }));
                                        Belt_Option.mapWithDefault(submissionCallbacks, undefined, (function (x) {
                                                Curry._1(x.notifyOnFailure, /* FailedToUpdateResellOrder */0);
                                              }));
                                      }),
                                    label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.cancel),
                                    color: "primaryBorder",
                                    size: "medium"
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                    onClick: (function (param) {
                                        postChange(undefined);
                                        Curry._1(setConfirmModal, (function (param) {
                                                return false;
                                              }));
                                      }),
                                    label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                    size: "medium"
                                  }),
                              item: true
                            }))
                  }) : null);
}

var Api;

var Form;

var Utils;

var InputStyles;

var make = Order_Detail_Reselling_Edits_Inputs;

export {
  Api ,
  Form ,
  Utils ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
