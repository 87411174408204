// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../common/components/icons/App_Icon.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../intl/Intl_Utils.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as Order_Detail_Api$DvmAdminFrontendFr from "../Order_Detail_Api.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../../context/user/User_Types_Utils.bs.js";
import * as Order_Detail_Utils$DvmAdminFrontendFr from "../Order_Detail_Utils.bs.js";
import * as Messages_Orders_Modal$DvmAdminFrontendFr from "../../../../intl/messages/orders/Messages_Orders_Modal.bs.js";
import * as Order_Detail_Correct_Utils$DvmAdminFrontendFr from "../correct/Order_Detail_Correct_Utils.bs.js";
import * as Order_Detail_Reselling_Create_Modal$DvmAdminFrontendFr from "../reselling/Order_Detail_Reselling_Create_Modal.bs.js";
import * as Order_Detail_Dealer_Edits_ParcExit_Modal$DvmAdminFrontendFr from "./Order_Detail_Dealer_Edits_ParcExit_Modal.bs.js";
import * as Order_Detail_Dealer_Edits_ParcExit_Delete_Modal$DvmAdminFrontendFr from "./Order_Detail_Dealer_Edits_ParcExit_Delete_Modal.bs.js";

function isParcEditable(order, selectedRole, lastCorrectionValues) {
  var lastStartDateParc = Belt_Option.mapWithDefault(lastCorrectionValues, order.detail.parcStartCalcDate, (function (x) {
          if (Belt_Option.isSome(x.parcStartDate)) {
            return x.parcStartDate;
          } else {
            return order.detail.parcStartCalcDate;
          }
        }));
  var agentExistsDealerBlocked = Belt_Option.mapWithDefault(order.orderType, false, (function (x) {
          if (x === /* RR */4) {
            return true;
          } else {
            return x === /* RC */5;
          }
        })) && Belt_Option.mapWithDefault(order.detail.agentCode, false, (function (x) {
          if (x.length > 0) {
            return x !== "000";
          } else {
            return false;
          }
        })) && (selectedRole === /* Dealer */7 || User_Types_Utils$DvmAdminFrontendFr.isAdminDealer(selectedRole));
  var match = Belt_Option.getWithDefault(order.detail.delivered, false);
  var match$1 = Order_Detail_Correct_Utils$DvmAdminFrontendFr.nonfleetPARCDatesEditable(order, true);
  if (match && lastStartDateParc !== undefined && match$1 && !agentExistsDealerBlocked) {
    if (selectedRole === /* Dealer */7 || User_Types_Utils$DvmAdminFrontendFr.isAdminDealer(selectedRole)) {
      return true;
    } else {
      return User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole);
    }
  } else {
    return false;
  }
}

function isParcDeletable(order, selectedRole, lastEndDateParc) {
  var match = Belt_Option.getWithDefault(order.detail.delivered, false);
  var match$1 = Order_Detail_Correct_Utils$DvmAdminFrontendFr.nonfleetPARCDatesEditable(order, true);
  if (match && lastEndDateParc !== undefined && match$1) {
    if (selectedRole === /* Dealer */7 || User_Types_Utils$DvmAdminFrontendFr.isAdminDealer(selectedRole)) {
      return true;
    } else {
      return User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole);
    }
  } else {
    return false;
  }
}

function isResellingWithoutActionsSafe(resellingOrder, order) {
  return Belt_Option.mapWithDefault(order.resoldOrderId, true, (function (resoldId) {
                if (resoldId.length === 0) {
                  return true;
                } else {
                  return App_Types_Result$DvmAdminFrontendFr.mapWithDefault(resellingOrder, false, (function (x) {
                                return Order_Detail_Correct_Utils$DvmAdminFrontendFr.nonfleetPARCDatesEditable(Order_Detail_Utils$DvmAdminFrontendFr.makeOrder(x), false);
                              }));
                }
              }));
}

function Order_Detail_Dealer_Edits_ParcExit(Props) {
  var orderId = Props.orderId;
  var order = Props.order;
  var reloadPage = Props.reloadPage;
  var lastCorrectionValues = Props.lastCorrectionValues;
  var correctionsResult = Props.correctionsResult;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return null;
      });
  var setModal = match$2[1];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResellingOrder = match$3[1];
  var resellingOrder = match$3[0];
  var lastEndDateParc = Belt_Option.mapWithDefault(lastCorrectionValues, order.detail.parcEndDate, (function (x) {
          return x.parcEndDate;
        }));
  var currentValueFormated = Belt_Option.mapWithDefault(Belt_Option.mapWithDefault(lastEndDateParc, undefined, (function (x) {
              return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, x);
            })), "-", (function (x) {
          return x + Belt_Option.mapWithDefault(order.detail.resultPARCEndDateAutoFlag, "", (function (x) {
                        if (x) {
                          return " - " + intl.formatMessage(Messages_Orders_Modal$DvmAdminFrontendFr.parcAutomatic);
                        } else {
                          return "";
                        }
                      }));
        }));
  React.useEffect((function () {
          if (typeof correctionsResult === "number" || !(resellingOrder === 0 && isParcDeletable(order, selectedRole, lastEndDateParc))) {
            
          } else {
            Belt_Option.mapWithDefault(order.resoldOrderId, undefined, (function (resoldId) {
                    if (resoldId.length > 0) {
                      return Order_Detail_Api$DvmAdminFrontendFr.getOrder(userManager, setResellingOrder, resoldId, /* CL */1, selectedRole);
                    }
                    
                  }));
          }
        }), [
        correctionsResult,
        lastCorrectionValues
      ]);
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  children: null,
                  container: true,
                  spacing: 2
                }, React.createElement(Core.Grid, {
                      children: currentValueFormated,
                      item: true
                    }), isParcEditable(order, selectedRole, lastCorrectionValues) ? React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                            children: React.createElement(Core.IconButton, {
                                  onClick: (function (param) {
                                      Curry._1(setModal, (function (param) {
                                              return React.createElement(Order_Detail_Dealer_Edits_ParcExit_Modal$DvmAdminFrontendFr.make, {
                                                          onClose: (function (param) {
                                                              Curry._1(setModal, (function (param) {
                                                                      return null;
                                                                    }));
                                                            }),
                                                          orderId: orderId,
                                                          onOk: (function (param) {
                                                              if (Belt_Option.getWithDefault(order.detail.resellable, true)) {
                                                                
                                                              } else {
                                                                Curry._1(reloadPage, undefined);
                                                              }
                                                              Curry._1(setModal, (function (param) {
                                                                      if (Belt_Option.getWithDefault(order.detail.resellable, true)) {
                                                                        return React.createElement(Order_Detail_Reselling_Create_Modal$DvmAdminFrontendFr.make, {
                                                                                    onClose: (function (param) {
                                                                                        Curry._1(setModal, (function (param) {
                                                                                                return null;
                                                                                              }));
                                                                                        Curry._1(reloadPage, undefined);
                                                                                      }),
                                                                                    orderId: orderId,
                                                                                    order: order,
                                                                                    fromParcExitEdit: true,
                                                                                    reloadPage: reloadPage
                                                                                  });
                                                                      } else {
                                                                        return null;
                                                                      }
                                                                    }));
                                                            }),
                                                          order: order
                                                        });
                                            }));
                                    }),
                                  style: {
                                    padding: "0px"
                                  },
                                  children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                        size: /* Regular */2,
                                        type_: /* Edit */60
                                      })
                                }),
                            item: true
                          }), isParcDeletable(order, selectedRole, lastEndDateParc) && isResellingWithoutActionsSafe(resellingOrder, order) ? React.createElement(Core.Grid, {
                              children: React.createElement(Core.IconButton, {
                                    onClick: (function (param) {
                                        Curry._1(setModal, (function (param) {
                                                return React.createElement(Order_Detail_Dealer_Edits_ParcExit_Delete_Modal$DvmAdminFrontendFr.make, {
                                                            onClose: (function (param) {
                                                                Curry._1(setModal, (function (param) {
                                                                        return null;
                                                                      }));
                                                              }),
                                                            orderId: orderId,
                                                            onOk: (function (param) {
                                                                Curry._1(reloadPage, undefined);
                                                              }),
                                                            order: order
                                                          });
                                              }));
                                      }),
                                    style: {
                                      padding: "0px"
                                    },
                                    children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                          size: /* Regular */2,
                                          type_: /* Trash */38,
                                          color: "red"
                                        })
                                  }),
                              item: true
                            }) : null) : null), match$2[0]);
}

var make = Order_Detail_Dealer_Edits_ParcExit;

export {
  isParcEditable ,
  isParcDeletable ,
  isResellingWithoutActionsSafe ,
  make ,
}
/* react Not a pure module */
