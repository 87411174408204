// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Styles$DvmAdminFrontendFr from "../../../../common/App_Styles.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../intl/Intl_Utils.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_LabeledText$DvmAdminFrontendFr from "../../../../common/components/App_LabeledText.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as Order_Search_Api$DvmAdminFrontendFr from "../../../order/search/Order_Search_Api.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../../context/user/User_Types_Utils.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as Flash_Detail_Styles$DvmAdminFrontendFr from "./Flash_Detail_Styles.bs.js";
import * as Messages_Flash_Edit$DvmAdminFrontendFr from "../../../../intl/messages/flash/Messages_Flash_Edit.bs.js";
import * as Messages_Flash_Detail$DvmAdminFrontendFr from "../../../../intl/messages/flash/Messages_Flash_Detail.bs.js";

function Flash_Detail_AppBar(Props) {
  var flash = Props.flash;
  var flashType = Props.flashType;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setClientTypes = match$2[1];
  var clientTypes = match$2[0];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSalesTypes = match$3[1];
  var salesTypes = match$3[0];
  React.useEffect((function () {
          if (User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* Spain */1) {
            Order_Search_Api$DvmAdminFrontendFr.fetchClientTypes(alert, userManager, setClientTypes, selectedRole);
            Order_Search_Api$DvmAdminFrontendFr.fetchSaleTypes(alert, userManager, setSalesTypes, selectedRole);
          }
          
        }), []);
  var salesWithLabelsToArray = function (salesArray) {
    var sales = App_Types_Result$DvmAdminFrontendFr.getWithDefault(salesTypes, /* [] */0);
    return Belt_Array.map(salesArray, (function (sale) {
                  return Belt_Option.mapWithDefault(Belt_List.getBy(sales, (function (x) {
                                    return x.paramOne === sale;
                                  })), sale, (function (x) {
                                return x.paramOne + " " + Belt_Option.mapWithDefault(x.paramTwo, "", (function (x) {
                                              return "- " + x;
                                            }));
                              }));
                }));
  };
  var tmp;
  if (flash.actionType === "PERF") {
    tmp = null;
  } else {
    var match$4 = flash.actionType;
    var tmp$1;
    switch (match$4) {
      case "POOL" :
          tmp$1 = Messages_Flash_Edit$DvmAdminFrontendFr.maxBonus;
          break;
      case "PSER" :
          tmp$1 = Messages_Flash_Detail$DvmAdminFrontendFr.transformationInPercent;
          break;
      default:
        tmp$1 = Belt_Option.mapWithDefault(flash.amount, Messages_Common$DvmAdminFrontendFr.amount, (function (x) {
                var match = x.numberType;
                if (match !== 0) {
                  return Messages_Flash_Detail$DvmAdminFrontendFr.amountEuroHT;
                } else {
                  return Messages_Flash_Detail$DvmAdminFrontendFr.amountInPercent;
                }
              }));
    }
    tmp = React.createElement(Core.Grid, {
          children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                label: tmp$1,
                children: Belt_Option.mapWithDefault(flash.amount, "-", (function (x) {
                        var valueDisplay = Belt_Option.flatMap(x.value, (function (x) {
                                if (Belt_Option.getWithDefault(flash.hasCustomAmount, false)) {
                                  return ;
                                } else {
                                  return x;
                                }
                              }));
                        var match = x.numberType;
                        switch (match) {
                          case /* PERCENT */0 :
                              return Belt_Option.mapWithDefault(valueDisplay, "-", (function (x) {
                                            return Intl_Utils$DvmAdminFrontendFr.formatPercent(intl, x);
                                          })) + (
                                      Belt_Option.mapWithDefault(flash.orderType, false, (function (x) {
                                              return Belt_Array.some(x, (function (x) {
                                                            if (x === "VE" || x === "VD" || x === "RR" || x === "RC" || x === "CL" || x === "ES" || x === "EP" || x === "RS") {
                                                              return true;
                                                            } else {
                                                              return x === "RP";
                                                            }
                                                          }));
                                            })) || User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* Spain */1 ? Belt_Option.mapWithDefault(flash.priceType, "", (function (x) {
                                                var tmp;
                                                switch (x) {
                                                  case "PVC_HT" :
                                                      tmp = "PVC";
                                                      break;
                                                  case "PVRINVOICEAMOUNT" :
                                                      tmp = "PVR";
                                                      break;
                                                  case "TRANSFORMATION" :
                                                      tmp = "Prix de transformation";
                                                      break;
                                                  default:
                                                    tmp = "";
                                                }
                                                return " (" + tmp + ")";
                                              })) : " (PVC)"
                                    );
                          case /* EURO */1 :
                              return Belt_Option.mapWithDefault(valueDisplay, "-", (function (eta) {
                                            return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, eta);
                                          }));
                          case /* NOTSET */2 :
                              return "";
                          
                        }
                      }))
              }),
          item: true
        });
  }
  var tmp$2;
  if (User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* Spain */1) {
    var tmp$3 = {
      arrow: true,
      children: React.createElement("div", undefined, React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                label: Messages_Common$DvmAdminFrontendFr.saleType,
                children: Belt_Option.mapWithDefault(flash.saleTypes, "-", (function (x) {
                        if (x.length === 1) {
                          return salesWithLabelsToArray(x).join(",");
                        } else {
                          return x.join(",");
                        }
                      }))
              })),
      classes: {
        tooltip: App_Styles_Common$DvmAdminFrontendFr.tooltipLineBreak
      },
      placement: "bottom",
      title: Belt_Option.mapWithDefault(flash.saleTypes, "-", (function (x) {
              return salesWithLabelsToArray(x).join("\n");
            })),
      style: {
        display: "flex",
        alignItems: "center"
      }
    };
    var tmp$4 = Belt_Option.mapWithDefault(flash.saleTypes, undefined, (function (x) {
            if (x.length === 1) {
              return false;
            }
            
          }));
    if (tmp$4 !== undefined) {
      tmp$3.open = tmp$4;
    }
    tmp$2 = React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
              children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                    label: Messages_Common$DvmAdminFrontendFr.clientType,
                    children: Belt_Option.mapWithDefault(flash.clientTypes, "-", (function (x) {
                            var clients = App_Types_Result$DvmAdminFrontendFr.getWithDefault(clientTypes, /* [] */0);
                            return Belt_Array.map(x, (function (client) {
                                            return Belt_Option.mapWithDefault(Belt_List.getBy(clients, (function (x) {
                                                              return x.paramOne === client;
                                                            })), client, (function (x) {
                                                          return x.paramOne + " " + Belt_Option.mapWithDefault(x.paramTwo, "", (function (x) {
                                                                        return "- " + x;
                                                                      }));
                                                        }));
                                          })).join(",");
                          }))
                  }),
              item: true
            }), React.createElement(Core.Grid, {
              children: React.createElement(Core.Tooltip, tmp$3),
              item: true
            }));
  } else {
    tmp$2 = React.createElement(Core.Grid, {
          children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                label: Messages_Common$DvmAdminFrontendFr.orderType,
                children: Belt_Option.mapWithDefault(flash.orderType, "-", (function (x) {
                        return x.join(",");
                      }))
              }),
          item: true
        });
  }
  return React.createElement(Core.AppBar, {
              children: React.createElement(Core.Grid, {
                    children: null,
                    className: Flash_Detail_Styles$DvmAdminFrontendFr.titleBox,
                    container: true,
                    justifyContent: "space-evenly",
                    spacing: 1
                  }, flashType ? null : React.createElement(Core.Grid, {
                          children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                label: Messages_Common$DvmAdminFrontendFr.budget,
                                children: Belt_Option.mapWithDefault(flash.budget, "-", (function (x) {
                                        return x.join(",");
                                      }))
                              }),
                          item: true
                        }), React.createElement(Core.Grid, {
                        children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Flash_Detail$DvmAdminFrontendFr.flashType,
                              children: flash.actionType
                            }),
                        item: true
                      }), React.createElement(Core.Grid, {
                        children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Flash_Detail$DvmAdminFrontendFr.flashCode,
                              children: flash.actionId
                            }),
                        item: true
                      }), React.createElement(Core.Grid, {
                        children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Flash_Detail$DvmAdminFrontendFr.flashLabel,
                              children: Belt_Option.getWithDefault(flash.actionLabel, "-")
                            }),
                        item: true
                      }), tmp, React.createElement(Core.Grid, {
                        children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                              label: Messages_Flash_Detail$DvmAdminFrontendFr.cumulable,
                              children: Belt_Option.mapWithDefault(flash.cumulative, "", (function (x) {
                                      if (x) {
                                        return intl.formatMessage(Messages_Common$DvmAdminFrontendFr.yes);
                                      } else {
                                        return intl.formatMessage(Messages_Common$DvmAdminFrontendFr.no);
                                      }
                                    }))
                            }),
                        item: true
                      }), tmp$2, flashType && User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) !== /* Spain */1 ? React.createElement(Core.Grid, {
                          children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                label: Messages_Flash_Edit$DvmAdminFrontendFr.requestType,
                                children: Belt_Option.getWithDefault(flash.requestType, "-")
                              }),
                          item: true
                        }) : null),
              className: App_Styles$DvmAdminFrontendFr.stickyBar
            });
}

var make = Flash_Detail_AppBar;

export {
  make ,
}
/* react Not a pure module */
