// Generated by ReScript, PLEASE EDIT WITH CARE


var titleCreate = {
  id: "b23e435b-a414-493c-b740-357475bedddc",
  defaultMessage: "Créer une action commerciale"
};

var bonusAmount = {
  id: "a604f47f-7ea2-4319-a6d0-440f039cb05f",
  defaultMessage: "Montant de l’aide"
};

var inEuro = {
  id: "f3d40a34-9cf8-46c3-a5e0-da15fbadde88",
  defaultMessage: "en €"
};

var inPercent = {
  id: "f8e33972-6e81-43bb-918a-bc711dbe97ca",
  defaultMessage: "en %"
};

var ageInput = {
  id: "4e2cea3b-59cc-4eac-866a-9fdd248bf030",
  defaultMessage: "Saisir l’âge"
};

var vehicleAgeTitle = {
  id: "9d1f4940-f371-45b7-93c4-183115ce1c67",
  defaultMessage: "Âge du VO (en année)*"
};

var possesionAgeTitle = {
  id: "92a46e7f-8606-4bfe-b435-33075bfa98a6",
  defaultMessage: "Durée de possession VO (en mois)*"
};

var includeExcludeTitle = {
  id: "a2740cda-4fdc-40d0-b7d0-956c964c9cd4",
  defaultMessage: "Marques éligibles"
};

var mainInfoTitle = {
  id: "784df524-a6bf-4c71-8963-b111c109ae6b",
  defaultMessage: "Informations Action Commerciale"
};

var detailsTitle = {
  id: "49641072-df9f-4ba4-9c3a-cf263a380510",
  defaultMessage: "Détails :"
};

var oldVehicleInfoTitle = {
  id: "62276f16-7347-4cc5-b324-67a923f779cb",
  defaultMessage: "Informations VO"
};

var newVehicleInfoTitle = {
  id: "22c85469-d201-41e5-8c27-2e88d13989ad",
  defaultMessage: "Information du VN"
};

var backToList = {
  id: "f918ed5f-f48f-45a5-a134-a0d0a2b42c85",
  defaultMessage: "RETOUR À LA LISTE DES ACTIONS"
};

var createAction = {
  id: "25206b40-77e9-4fc2-8b3c-d00e677ef46d",
  defaultMessage: "Créer l’action"
};

var editAction = {
  id: "1e007b60-de8c-41bc-b2ac-304298be26a8",
  defaultMessage: "Enregistrer les modifications"
};

var nonCumulableTitle = {
  id: "1eba9b4c-914f-4959-873b-015cab1a8a0a",
  defaultMessage: "Gérer les actions non cumulables"
};

var selectedActions = {
  id: "ad0b190f-23f0-45e6-be69-357854ae5d7c",
  defaultMessage: "Actions non cumulables sélectionnées"
};

var versionLabelCommercial = {
  id: "326280a1-71d7-4661-ab53-7ca2123cad4d",
  defaultMessage: "Libellé version"
};

var modelVersion = {
  id: "3397cb2d-7c5b-4f88-aeba-d363620910fe",
  defaultMessage: "Modele Version"
};

var modelVersionFleetTitle = {
  id: "bf41c10d-669d-4f7a-af77-4ad59f6dff5c",
  defaultMessage: "Gérer les modèles / versions associés à cette action"
};

var modelVersionNonFleetTitle = {
  id: "b612559b-4436-425b-bf5c-7e0ed9714627",
  defaultMessage: "Personnaliser les montants d'aide sur cette action"
};

var modelVersionSearch = {
  id: "4218c216-fc9d-41a7-88f9-47a93e8464dc",
  defaultMessage: "Rechercher par libellé version"
};

var sameAsFlash = {
  id: "ff445dd3-3e36-4179-bc4c-fd01003781d4",
  defaultMessage: "même date que l’action"
};

var addModel = {
  id: "3a1e8163-37fa-408c-ab1b-bf38eaf9389c",
  defaultMessage: "Ajouter des modèles"
};

var addVersions = {
  id: "fdba91e2-4fe7-434d-81c6-6f2f0ded970b",
  defaultMessage: "Modèle / Versions à associer à l’action"
};

var duplicateVersions = {
  id: "b3c7dea5-870f-4a8b-8cc6-bc1bbb4960cc",
  defaultMessage: "Dupliquer les modèles / versions d’une autre action"
};

var selectModel = {
  id: "47a581d8-08ba-4ed1-b4d8-3558d35b2abb",
  defaultMessage: "1. Sélectionner le modèle"
};

var selectGenreModel = {
  id: "351d6ce3-c8c5-4d8b-a893-569b37e61db4",
  defaultMessage: "1. Sélectionner le genre / modèle"
};

var selectVersions = {
  id: "aa1da192-5cb3-4013-974f-9340bc65494f",
  defaultMessage: "2. Sélectionner les versions à associer à l’action"
};

var phaseFacultative = {
  id: "6720cf77-d355-4c95-a220-1644baee6c95",
  defaultMessage: "Phase (facult.)"
};

var deleteAllButton = {
  id: "1af74183-227b-4eca-b06e-7f54bd5280d9",
  defaultMessage: "Supprimer tout"
};

var deleteAllTitle = {
  id: "8bc83b6a-7796-411e-a569-ad5f2669c52b",
  defaultMessage: "Êtes-vous sûr de vouloir supprimer tout ?"
};

var perfType = {
  id: "099f197d-c5e4-4fc5-b22d-380cc80d4c25",
  defaultMessage: "Type PERF"
};

var maxBonus = {
  id: "95fdc305-b86f-4b08-a950-24d3de1473a9",
  defaultMessage: "Montant max"
};

var followingStartDateError = {
  id: "76d730a2-221b-419e-9d6e-de5134466dc9",
  defaultMessage: "La date de fin chevauche une autre période"
};

var applyToResults = {
  id: "d947f700-3f49-4504-b879-43953d83154b",
  defaultMessage: "Appliquer à la recherche"
};

var remainingToEdit = {
  id: "82f3174a-f818-4a6b-894f-bb143954c6ed",
  defaultMessage: "{amount} sur {total} est rempli et sera enregistré"
};

var editActionsTitle = {
  id: "b9d5850c-eef2-4423-9760-6ae09a329591",
  defaultMessage: "Sélectionner une action à appliquer aux résultats de votre recherche ({count, plural, one {# ligne sera concernée} other {# lignes seront concernées}}):"
};

var newMaxAmount = {
  id: "3ff762bb-a208-429f-859f-92966ceee74e",
  defaultMessage: "Appliquer un nouveau montant max."
};

var editMaxAmount = {
  id: "eccfc20c-5c15-4d16-90f9-38c92ecc72ab",
  defaultMessage: "Corriger le montant max."
};

var newEndDate = {
  id: "eb3f3ca0-fd88-4735-97d7-26131947115c",
  defaultMessage: "Appliquer une nouvelle date de fin"
};

var highestStartDateError = {
  id: "6687ec6d-84b8-42dc-a9fb-4c74b608e8f3",
  defaultMessage: "Certaines lignes ont une date de début supérieure à celle-ci"
};

var combinedDateError = {
  id: "0ab6edba-97f0-49c2-8d62-6729df462698",
  defaultMessage: "La date de fin ne peut pas être appliquée à toutes les périodes filtrées"
};

var combinedDateNewPeriodError = {
  id: "69524ca8-fd4e-461d-8b89-ffc4f4c56ce6",
  defaultMessage: "La date de début est à l'intérieur de la période d'un autre enregistrement"
};

var lowestStartNewPeriodError = {
  id: "f032b8f4-aa52-4790-ad48-7a9c1da03c15",
  defaultMessage: "La date de début chevauche une autre période"
};

var dateInput = {
  id: "3a7a538b-2186-4f16-ad32-cfd6563d9658",
  defaultMessage: "Période valable le"
};

var previousEndDateError = {
  id: "97354cd5-db23-494b-a573-a58185cecee1",
  defaultMessage: "La date de début chevauche la période précédente"
};

var brandInclusion = {
  id: "38f2e5df-38ad-48a4-a2a9-3c025cddb985",
  defaultMessage: "INCLUSION"
};

var brandExclusion = {
  id: "6a5b1671-aee7-433d-bc52-19500ebde82c",
  defaultMessage: "EXCLUSION"
};

var brandAll = {
  id: "2f6007f0-cc64-46cc-bc84-405dd1fa58ae",
  defaultMessage: "TOUTES"
};

var startDateLinkedToMain = {
  id: "3903e37e-c544-4902-8bb1-c51023f7916e",
  defaultMessage: "Date début (liée)"
};

var actionWithVO = {
  id: "acc7102a-07ee-4bfa-b6a2-07da9b1a3c2b",
  defaultMessage: "Action avec VO"
};

var bonusAmountHT = {
  id: "2695598d-1f76-4108-b8b5-e52b85cd4618",
  defaultMessage: "Montant de l’aide HT (à payer)"
};

var bonusAmountTTC = {
  id: "d660e247-ca6f-437b-802e-cabd84424f49",
  defaultMessage: "Montant de l’aide TTC (informatif)"
};

var actionCodeCreationError = {
  id: "0366d45a-eb61-44a5-a9d7-4722c48a2c45",
  defaultMessage: "Echec de création du code action"
};

var includeFinancialCode = {
  id: "20f16195-8cf4-4b68-a99e-34351c3595e3",
  defaultMessage: "Action avec un code barème financement"
};

var switchMethodModalTitle = {
  id: "46820811-94dd-41ae-9ceb-5e9e51583e1c",
  defaultMessage: "Changement de méthode ?"
};

var switchMethodModalQuestion = {
  id: "6d3e7d45-44ed-4452-8583-1fae781956b8",
  defaultMessage: "Le changement de méthode supprimera tous les modèles et versions pour cette Action, voulez-vous continuer ?"
};

var inclusionMethod = {
  id: "18887919-3f10-42ab-b03c-dd23df159c57",
  defaultMessage: "Inclure ou Exclure des versions"
};

var includeVersions = {
  id: "cd1af7a0-0490-45bd-950b-787f3bc9a657",
  defaultMessage: "Inclure des versions"
};

var includeModels = {
  id: "36a3eecf-be9c-44c9-8ff0-b332b5f85b8f",
  defaultMessage: "Inclure des modèles, exclure des versions"
};

var modelsToInclude = {
  id: "9cf2b3f9-e880-4aad-b0b7-4ee6cb0ad0e9",
  defaultMessage: "1. Inclure des modèles"
};

var versionsToExclude = {
  id: "a39ada25-27a0-412b-8ca6-134534eb6a40",
  defaultMessage: "2. Exclure des versions"
};

var changesPending = {
  id: "64c6e574-357e-4f1c-ab31-6911b7043415",
  defaultMessage: "Modifications en attente"
};

var notFound = {
  id: "6920fba9-e8ae-4e1f-8511-5ff36f72797a",
  defaultMessage: "Introuvable"
};

var filterOnlyLatest = {
  id: "7f946faa-8dcc-4b47-9069-5aba6e0169f3",
  defaultMessage: "Afficher uniquement les périodes récentes"
};

var hasToSelectOnlyLatestTooltip = {
  id: "f8b58d6a-5278-45de-9d92-d4e418a30ce9",
  defaultMessage: "Vous devez sélectionner uniquement la dernière ligne pour utiliser cette opération"
};

var deleteFilteredPerfVersionsTitle = {
  id: "eab9895e-650b-4e2e-99cb-6565c222603e",
  defaultMessage: "Supprimer les versions filtrées"
};

var deletePerfVersionsModalMessage = {
  id: "8b8aa26a-ef1e-4596-ae62-b98d5965fa20",
  defaultMessage: "Êtes-vous sûr de vouloir supprimer {count, plural, one {# version} other {# versions}} ?"
};

var addModelsForVOHint = {
  id: "46ef61c9-fdd6-4967-9719-33615ab7f92a",
  defaultMessage: "Appuyez sur ENTER après chaque code (max 3 caractères)"
};

var includeModelsPhasesEnergyNewVehicle = {
  id: "2bfba85d-dea9-4623-a14b-19edda6f7e6d",
  defaultMessage: "Inclure Modèle/ Phase/ Energie"
};

var selectionForInclusion = {
  id: "04613ea8-8f79-40c9-823a-0c9cf0893640",
  defaultMessage: "Sélection à inclure"
};

var selectionForExclusion = {
  id: "057163de-5606-47a0-a961-2ac3de65e48c",
  defaultMessage: "Sélection pour exclusion"
};

var energyDropdown = {
  id: "39cc3dfb-c3a5-40bb-8275-7df2730c40bf",
  defaultMessage: "Energie"
};

var nonFleetCustomAmount = {
  id: "1747a382-6b62-457a-858f-6f19dbb01478",
  defaultMessage: "Montant personnalisé"
};

var editAllFilteredNonFleet = {
  id: "99c93ec0-1e08-4d83-a80b-bc55a0c38943",
  defaultMessage: "Appliquer à toute la sélection"
};

var customNonFleetAmountsTitle = {
  id: "497f2681-7a3e-493f-a27d-b56fe31c10d2",
  defaultMessage: "{actionID} - Personnaliser les montants"
};

var newVersionsWillBeAdded = {
  id: "4c0caf06-209c-4170-a4af-1bfc1fd7d68b",
  defaultMessage: "Les nouvelles versions seront ajoutées automatiquement à l’action"
};

var newVersionsWillNotBeAdded = {
  id: "a6b6e8f2-7e1a-42a1-a04e-a05eacc6689e",
  defaultMessage: "Les nouvelles versions ne seront pas ajoutées automatiquement à l’action"
};

var includeAll = {
  id: "286ad141-bf32-48cf-8c10-4302e3735318",
  defaultMessage: "Tout inclure"
};

var numberAsPercentageWarning = {
  id: "22fe5dc3-a1bb-448a-9910-a8a82d7dedd8",
  defaultMessage: "Êtes-vous sûr du % saisi ?"
};

var excelSheetSelect = {
  id: "f6c3c74c-a1eb-4dc4-9e2b-6d02dd945052",
  defaultMessage: "Feuille Excel"
};

var excelColumn = {
  id: "d42772f5-e546-4f6a-827c-05d5c4179786",
  defaultMessage: "Sélectionner une colonne"
};

var versionsNotFoundAndNotSaved = {
  id: "a9433a49-aba3-4a19-b70c-d5395815f5b4",
  defaultMessage: "{count} de versions introuvables et ne seront pas sauvegardées"
};

var excelRemoveAlreadySelected = {
  id: "000e3e18-d113-4c89-bfeb-7a053e689f5e",
  defaultMessage: "Supprimer la sélection actuelle"
};

var uploadVersions = {
  id: "6063082f-3f8b-46f3-863d-9a65f494cc49",
  defaultMessage: "Importer des versions"
};

var selectModelFirst = {
  id: "40533053-3188-405c-8f3b-ea92cda245ac",
  defaultMessage: "Sélection du modèle obligatoire"
};

var parcEntryPeriod = {
  id: "a721ad1f-c64f-44ef-b4b9-cbd42904d4ad",
  defaultMessage: "Période d'entrée au PARC"
};

var parcExitPeriod = {
  id: "5c5fce36-1dfe-431e-b24d-427dd9970ba0",
  defaultMessage: "Période sortie de PARC"
};

var parcType = {
  id: "7d5cb37c-37cb-4350-ae84-d8d7e37c2ab2",
  defaultMessage: "Type de primes"
};

var detentionDuration = {
  id: "6fbe4369-c2c7-4282-936a-346a57d20315",
  defaultMessage: "Durée de possession par rapport à la date d'entrée de PARC (en jours)"
};

var dateMin = {
  id: "6e07fcee-1eb3-427a-ac50-34947428a66b",
  defaultMessage: "Date min"
};

var dateMax = {
  id: "8c95e99e-4ac5-42c3-aa03-85341ed10e22",
  defaultMessage: "Date max"
};

var bonusWithExitParc = {
  id: "aea6f65c-bb12-4726-b51a-e8d8357547ec",
  defaultMessage: "Action prime de sortie PARC"
};

var dealerAccordionTitle = {
  id: "7dd7273e-93aa-4ff3-a964-e61396f0a9bb",
  defaultMessage: "Informations Affaires et Agents"
};

var onlyOnePeriodMustBeFilled = {
  id: "af1eb48f-5204-4103-a79a-1fd5458373e7",
  defaultMessage: "Une seule période de date doit être remplie."
};

var requestType = {
  id: "a2021bf7-7fc5-4477-8c99-845fa48d8ab2",
  defaultMessage: "Type de demande"
};

var selectAllModels = {
  id: "a69703f1-5434-453b-9331-6cac1137b456",
  defaultMessage: "Tous les modèles"
};

var nameR2plaque = {
  id: "893d649b-bda1-4615-9d30-d8c435261a94",
  defaultMessage: "Groupe Régional (plaque)"
};

var choiceOfDealers = {
  id: "0cd165cc-b566-4563-83b6-1feb64d8ae38",
  defaultMessage: "Choix du ou des dealers"
};

var choiceOfAgents = {
  id: "0b133c03-5b9e-4f27-9c88-1d611bfd5f31",
  defaultMessage: "Choix du ou des agents"
};

var franchisedSelectionTitle = {
  id: "12b95825-3f49-475a-80b9-70d25f2e7850",
  defaultMessage: "Affaires et agents franchisés"
};

var includeEPwithCL = {
  id: "0a573704-d501-4333-926c-197403ed6770",
  defaultMessage: "Inclure EP"
};

var resellingOrderType = {
  id: "c34dc826-279d-4945-b2d9-df45454190f2",
  defaultMessage: "Type de commande revente"
};

export {
  titleCreate ,
  bonusAmount ,
  inEuro ,
  inPercent ,
  ageInput ,
  vehicleAgeTitle ,
  possesionAgeTitle ,
  includeExcludeTitle ,
  mainInfoTitle ,
  detailsTitle ,
  oldVehicleInfoTitle ,
  newVehicleInfoTitle ,
  backToList ,
  createAction ,
  editAction ,
  nonCumulableTitle ,
  selectedActions ,
  versionLabelCommercial ,
  modelVersion ,
  modelVersionFleetTitle ,
  modelVersionNonFleetTitle ,
  modelVersionSearch ,
  sameAsFlash ,
  addModel ,
  addVersions ,
  duplicateVersions ,
  selectModel ,
  selectGenreModel ,
  selectVersions ,
  phaseFacultative ,
  deleteAllButton ,
  deleteAllTitle ,
  perfType ,
  maxBonus ,
  followingStartDateError ,
  applyToResults ,
  remainingToEdit ,
  editActionsTitle ,
  newMaxAmount ,
  editMaxAmount ,
  newEndDate ,
  highestStartDateError ,
  combinedDateError ,
  combinedDateNewPeriodError ,
  lowestStartNewPeriodError ,
  dateInput ,
  previousEndDateError ,
  brandInclusion ,
  brandExclusion ,
  brandAll ,
  startDateLinkedToMain ,
  actionWithVO ,
  bonusAmountHT ,
  bonusAmountTTC ,
  actionCodeCreationError ,
  includeFinancialCode ,
  switchMethodModalTitle ,
  switchMethodModalQuestion ,
  inclusionMethod ,
  includeVersions ,
  includeModels ,
  modelsToInclude ,
  versionsToExclude ,
  changesPending ,
  notFound ,
  filterOnlyLatest ,
  hasToSelectOnlyLatestTooltip ,
  deleteFilteredPerfVersionsTitle ,
  deletePerfVersionsModalMessage ,
  addModelsForVOHint ,
  includeModelsPhasesEnergyNewVehicle ,
  selectionForInclusion ,
  selectionForExclusion ,
  energyDropdown ,
  nonFleetCustomAmount ,
  editAllFilteredNonFleet ,
  customNonFleetAmountsTitle ,
  newVersionsWillBeAdded ,
  newVersionsWillNotBeAdded ,
  includeAll ,
  numberAsPercentageWarning ,
  excelSheetSelect ,
  excelColumn ,
  versionsNotFoundAndNotSaved ,
  excelRemoveAlreadySelected ,
  uploadVersions ,
  selectModelFirst ,
  parcEntryPeriod ,
  parcExitPeriod ,
  parcType ,
  detentionDuration ,
  dateMin ,
  dateMax ,
  bonusWithExitParc ,
  dealerAccordionTitle ,
  onlyOnePeriodMustBeFilled ,
  requestType ,
  selectAllModels ,
  nameR2plaque ,
  choiceOfDealers ,
  choiceOfAgents ,
  franchisedSelectionTitle ,
  includeEPwithCL ,
  resellingOrderType ,
}
/* No side effect */
