// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FetchClient$DvmAdminFrontendFr from "./FetchClient.bs.js";
import * as Messages_Error$DvmAdminFrontendFr from "../intl/messages/Messages_Error.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../common/types/App_Types_Result.bs.js";

function getAccessTokenAndCall(userManager, successHandler) {
  if (userManager !== undefined) {
    return $$Promise.$$catch(Caml_option.valFromOption(userManager).getUser().then(function (user) {
                    if (user == null) {
                      return Promise.resolve({
                                  TAG: /* ErrorResult */1,
                                  _0: App_Types_Result$DvmAdminFrontendFr.Rest.makeError(undefined, undefined, undefined, Messages_Error$DvmAdminFrontendFr.noAccessToken.id)
                                });
                    } else {
                      return Curry._1(successHandler, /* AccessToken */{
                                  _0: user.access_token
                                });
                    }
                  }), (function (param) {
                  return Promise.resolve({
                              TAG: /* ErrorResult */1,
                              _0: App_Types_Result$DvmAdminFrontendFr.Rest.makeError(undefined, undefined, undefined, Messages_Error$DvmAdminFrontendFr.noAccessToken.id)
                            });
                }));
  } else {
    return Promise.resolve({
                TAG: /* ErrorResult */1,
                _0: App_Types_Result$DvmAdminFrontendFr.Rest.makeError(undefined, undefined, undefined, Messages_Error$DvmAdminFrontendFr.noAccessToken.id)
              });
  }
}

function call(httpMethod, responseDecoder, bodyOpt, userManager, selectedRole, url) {
  var body = bodyOpt !== undefined ? bodyOpt : ({
        TAG: /* Json */0,
        _0: {}
      });
  var partial_arg = body;
  return getAccessTokenAndCall(userManager, (function (param) {
                return FetchClient$DvmAdminFrontendFr.call(httpMethod, partial_arg, responseDecoder, selectedRole, url, param);
              }));
}

function call$1(httpMethod, bodyOpt, userManager, selectedRole, url) {
  var body = bodyOpt !== undefined ? bodyOpt : ({
        TAG: /* Json */0,
        _0: {}
      });
  var partial_arg = body;
  return getAccessTokenAndCall(userManager, (function (param) {
                return FetchClient$DvmAdminFrontendFr.WithBlobResponse.call(httpMethod, partial_arg, selectedRole, url, param);
              }));
}

var WithBlobResponse = {
  call: call$1
};

function call$2(httpMethod, bodyOpt, userManager, selectedRole, url) {
  var body = bodyOpt !== undefined ? bodyOpt : ({
        TAG: /* Json */0,
        _0: {}
      });
  var partial_arg = body;
  return getAccessTokenAndCall(userManager, (function (param) {
                return FetchClient$DvmAdminFrontendFr.WithEmptyResponse.call(httpMethod, partial_arg, selectedRole, url, param);
              }));
}

var WithEmptyResponse = {
  call: call$2
};

export {
  call ,
  WithBlobResponse ,
  WithEmptyResponse ,
}
/* FetchClient-DvmAdminFrontendFr Not a pure module */
