// Generated by ReScript, PLEASE EDIT WITH CARE


function fromCode(langCode) {
  switch (langCode) {
    case "en" :
    case "en-GB" :
        return /* En */0;
    case "enWithID" :
        return /* EnWithID */2;
    case "es" :
    case "es-ES" :
        return /* Es */4;
    case "fr" :
    case "fr-FR" :
        return /* Fr */1;
    case "frWithID" :
        return /* FrWithID */3;
    default:
      return ;
  }
}

function toString(locale) {
  switch (locale) {
    case /* En */0 :
        return "en-GB";
    case /* Fr */1 :
        return "fr-FR";
    case /* EnWithID */2 :
        return "enWithID";
    case /* FrWithID */3 :
        return "frWithID";
    case /* Es */4 :
        return "es";
    
  }
}

export {
  fromCode ,
  toString ,
}
/* No side effect */
