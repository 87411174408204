// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactAlert from "react-alert";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Button.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Visibility_Profiles_Api$DvmAdminFrontendFr from "../search/Visibility_Profiles_Api.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as Messages_Visibility_Profiles$DvmAdminFrontendFr from "../../../intl/messages/visibilityProfiles/Messages_Visibility_Profiles.bs.js";
import * as Visibility_Profiles_Modal_Form$DvmAdminFrontendFr from "./Visibility_Profiles_Modal_Form.bs.js";
import * as Visibility_Profiles_Delete_Modal$DvmAdminFrontendFr from "./Visibility_Profiles_Delete_Modal.bs.js";
import * as App_TransferListFormInput_Virtualized$DvmAdminFrontendFr from "../../../common/components/input/App_TransferListFormInput_Virtualized.bs.js";

function Visibility_Profiles_Modal(Props) {
  var profile = Props.profile;
  var allBIRs = Props.allBIRs;
  var onClose = Props.onClose;
  var refresh = Props.refresh;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setResult = match$2[1];
  var result = match$2[0];
  var match$3 = React.useState(function () {
        return null;
      });
  var setModal = match$3[1];
  var match$4 = React.useState(function () {
        return /* [] */0;
      });
  var setDrFilter = match$4[1];
  var drFilter = match$4[0];
  var match$5 = React.useState(function () {
        return /* [] */0;
      });
  var setMappedDealers = match$5[1];
  var mappedDealers = match$5[0];
  var form = Visibility_Profiles_Modal_Form$DvmAdminFrontendFr.VisibilityProfileForm.useForm(Visibility_Profiles_Modal_Form$DvmAdminFrontendFr.initialInput, (function (param, submissionCallbacks) {
          Visibility_Profiles_Api$DvmAdminFrontendFr.saveProfile(setResult, userManager, {
                parent: param.userID,
                child: Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(param.dealers)))
              }, submissionCallbacks, selectedRole);
        }));
  var allDRs = Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(allBIRs, (function (x) {
                      return Belt_Option.getWithDefault(x.dr, "");
                    })))));
  var deleteIPN = function (userID) {
    Visibility_Profiles_Api$DvmAdminFrontendFr.deleteProfile(setResult, userManager, userID, selectedRole);
  };
  React.useEffect((function () {
          Belt_Option.mapWithDefault(profile, undefined, (function (profile) {
                  Curry._2(form.updateUserID, (function (input, userID) {
                          return {
                                  userID: userID,
                                  dealers: input.dealers
                                };
                        }), profile.userID);
                  Curry._2(form.updateDealers, (function (input, dealers) {
                          return {
                                  userID: input.userID,
                                  dealers: dealers
                                };
                        }), Belt_List.map(profile.dealerCodes, (function (x) {
                              return Belt_Option.getWithDefault(x.entityCode, "");
                            })));
                }));
        }), []);
  React.useEffect((function () {
          if (typeof result === "number") {
            result === /* NotStarted */0;
          } else if (result.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            Curry._1(refresh, undefined);
            Curry._1(onClose, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: result._0
                      }
                    }));
          }
        }), [result]);
  React.useEffect((function () {
          Curry._1(setMappedDealers, (function (param) {
                  return Belt_List.map(Belt_List.keep(allBIRs, (function (x) {
                                    return Belt_List.has(form.input.dealers, x, (function (a, b) {
                                                  return a === Belt_Option.getWithDefault(b.entityCode, "");
                                                }));
                                  })), (function (x) {
                                return {
                                        label: {
                                          TAG: /* String */3,
                                          _0: "" + Belt_Option.getWithDefault(x.dealerID, "") + " - " + Belt_Option.getWithDefault(x.dealerName, "") + "" + Belt_Option.mapWithDefault(x.entityCode, "", (function (x) {
                                                  return " (" + x + ")";
                                                })) + ""
                                        },
                                        value: Belt_Option.getWithDefault(x.entityCode, "")
                                      };
                              }));
                }));
        }), [form.input.dealers]);
  var tmp = {
    onChange: Curry._1(form.updateUserID, (function (input, userID) {
            return {
                    userID: userID,
                    dealers: input.dealers
                  };
          })),
    value: form.input.userID,
    disabled: Belt_Option.isSome(profile),
    label: Messages_Visibility_Profiles$DvmAdminFrontendFr.ipn
  };
  if (form.userIDResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.userIDResult);
  }
  var tmp$1 = {
    values: mappedDealers,
    options: Belt_List.map(Belt_List.keep(allBIRs, (function (bir) {
                if (Belt_List.length(drFilter) > 0) {
                  return Belt_List.some(drFilter, (function (x) {
                                return x === Belt_Option.getWithDefault(bir.dr, "");
                              }));
                } else {
                  return true;
                }
              })), (function (x) {
            return {
                    label: {
                      TAG: /* String */3,
                      _0: "" + Belt_Option.getWithDefault(x.dealerID, "") + " - " + Belt_Option.getWithDefault(x.dealerName, "") + "" + Belt_Option.mapWithDefault(x.entityCode, "", (function (x) {
                              return " (" + x + ")";
                            })) + ""
                    },
                    value: Belt_Option.getWithDefault(x.entityCode, "")
                  };
          })),
    onChange: (function (val) {
        Curry._2(form.updateDealers, (function (input, dealers) {
                return {
                        userID: input.userID,
                        dealers: dealers
                      };
              }), Belt_List.map(val, (function (x) {
                    return x.value;
                  })));
      }),
    boxHeight: 260,
    boxLabels: {
      left: {
        TAG: /* String */3,
        _0: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.transferListOptions, {
              count: Belt_List.length(Belt_List.keep(allBIRs, (function (element) {
                          return !Belt_List.some(form.input.dealers, (function (x) {
                                        return x === Belt_Option.getWithDefault(element.entityCode, "");
                                      }));
                        })))
            })
      },
      right: {
        TAG: /* String */3,
        _0: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.transferListSelected, {
              count: Belt_List.length(mappedDealers)
            })
      }
    },
    additionalFilters: React.createElement(Core.Grid, {
          children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                label: Messages_Common$DvmAdminFrontendFr.territory,
                value: {
                  TAG: /* Multiple */1,
                  _0: {
                    value: drFilter,
                    onChange: (function (values) {
                        Curry._1(setDrFilter, (function (param) {
                                return values;
                              }));
                      })
                  }
                },
                options: {
                  TAG: /* Unlabeled */0,
                  _0: allDRs
                },
                limitTags: 3,
                smallChips: true
              }),
          item: true,
          style: {
            width: "400px"
          }
        }),
    estimateSize: 38
  };
  if (form.dealersResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.dealersResult);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(App_Modal$DvmAdminFrontendFr.make, {
                  open: true,
                  onClose: onClose,
                  title: Belt_Option.mapWithDefault(profile, intl.formatMessage(Messages_Visibility_Profiles$DvmAdminFrontendFr.modalTitleAdd), (function (profile) {
                          return intl.formatMessage(Messages_Visibility_Profiles$DvmAdminFrontendFr.modalTitleEdit, {
                                      ipn: profile.userID
                                    });
                        })),
                  minWidth: "1200px",
                  children: React.createElement("form", {
                        onSubmit: (function ($$event) {
                            $$event.preventDefault();
                            Curry._1(form.submit, undefined);
                          })
                      }, React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                            show: App_Types_Result$DvmAdminFrontendFr.isPending(result),
                            children: React.createElement(Core.Grid, {
                                  children: null,
                                  container: true,
                                  direction: "column",
                                  spacing: 2
                                }, React.createElement(Core.Grid, {
                                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: React.createElement(App_TransferListFormInput_Virtualized$DvmAdminFrontendFr.make, tmp$1),
                                      item: true
                                    }), React.createElement(Core.Grid, {
                                      children: null,
                                      container: true,
                                      item: true,
                                      justifyContent: "space-between"
                                    }, React.createElement(Core.Grid, {
                                          children: Belt_Option.mapWithDefault(profile, null, (function (x) {
                                                  return React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                                              onClick: (function (param) {
                                                                  Curry._1(setModal, (function (param) {
                                                                          return React.createElement(Visibility_Profiles_Delete_Modal$DvmAdminFrontendFr.make, {
                                                                                      onClose: (function (param) {
                                                                                          Curry._1(setModal, (function (param) {
                                                                                                  return null;
                                                                                                }));
                                                                                        }),
                                                                                      userID: x.userID,
                                                                                      deleteIPN: deleteIPN
                                                                                    });
                                                                        }));
                                                                }),
                                                              label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.$$delete),
                                                              color: "delete",
                                                              size: "medium"
                                                            });
                                                })),
                                          item: true
                                        }), React.createElement(Core.Grid, {
                                          children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                                label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                                size: "medium",
                                                type_: "submit"
                                              }),
                                          item: true
                                        })))
                          }))
                }), match$3[0]);
}

var Api;

var Form;

var Types;

var DeleteModal;

var make = Visibility_Profiles_Modal;

export {
  Api ,
  Form ,
  Types ,
  DeleteModal ,
  make ,
}
/* react Not a pure module */
