// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../../common/components/button/App_Button.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../../common/App_Typography.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../intl/messages/Messages_Common.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../common/styles/App_Styles_Common.bs.js";
import * as App_SelectFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_SelectFormInput.bs.js";
import * as App_DateInputFormField$DvmAdminFrontendFr from "../../../../../common/components/input/App_DateInputFormField.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Messages_Orders_Correction$DvmAdminFrontendFr from "../../../../../intl/messages/orders/Messages_Orders_Correction.bs.js";
import * as Order_Detail_Old_Vehicle_Edits_Api$DvmAdminFrontendFr from "./Order_Detail_Old_Vehicle_Edits_Api.bs.js";
import * as Order_Detail_Old_Vehicle_Edits_Form$DvmAdminFrontendFr from "./Order_Detail_Old_Vehicle_Edits_Form.bs.js";
import * as Order_Detail_Old_Vehicle_Edits_Utils$DvmAdminFrontendFr from "./Order_Detail_Old_Vehicle_Edits_Utils.bs.js";

function Order_Detail_Old_Vehicle_Edits_Inputs(Props) {
  var orderId = Props.orderId;
  var setSaveResult = Props.setSaveResult;
  var newestUsedCarValues = Props.newestUsedCarValues;
  var order = Props.order;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var onlyPolice = !((Belt_Option.mapWithDefault(order.orderType, false, (function (x) {
              if (x === /* ES */8 || x === /* EP */7 || x === /* RS */10) {
                return true;
              } else {
                return x === /* RP */9;
              }
            })) || Belt_Option.getWithDefault(order.detail.delivered, false)) && !Belt_Option.getWithDefault(order.archived, false));
  var match$2 = React.useState(function () {
        return false;
      });
  var setConfirmModal = match$2[1];
  var match$3 = React.useState(function () {
        
      });
  var setFinalValues = match$3[1];
  var finalValues = match$3[0];
  var match$4 = React.useState(function () {
        
      });
  var setSubmissionCallbacks = match$4[1];
  var submissionCallbacks = match$4[0];
  var postChange = function (param) {
    if (finalValues === undefined) {
      return ;
    }
    if (submissionCallbacks === undefined) {
      return ;
    }
    if (onlyPolice) {
      return Belt_Option.mapWithDefault(finalValues.policeNumber, undefined, (function (x) {
                    Order_Detail_Old_Vehicle_Edits_Api$DvmAdminFrontendFr.updatePoliceNumber(userManager, setSaveResult, orderId, intl, submissionCallbacks, selectedRole, x);
                  }));
    }
    var finalVals_value = finalValues.value;
    var finalVals_estimatedValue = finalValues.estimatedValue;
    var finalVals_endOfLife = finalValues.endOfLife;
    var finalVals_brandUsedCar = Belt_Option.flatMap(finalValues.brandUsedCar, (function (x) {
            return x.toUpperCase();
          }));
    var finalVals_modelUsedCar = Belt_Option.flatMap(finalValues.modelUsedCar, (function (x) {
            return x.toUpperCase();
          }));
    var finalVals_versionUsedCar = finalValues.versionUsedCar;
    var finalVals_registrationNumberUsedCar = finalValues.registrationNumberUsedCar;
    var finalVals_vinUsedCar = finalValues.vinUsedCar;
    var finalVals_firstReleaseDateUsedCar = finalValues.firstReleaseDateCar;
    var finalVals_registrationDateUsedCar = finalValues.registrationDateUsedCar;
    var finalVals_registrationDateUsedCarToDelete = Belt_Option.isNone(finalValues.registrationDateUsedCar);
    var finalVals_firstReleaseDateUsedCarToDelete = Belt_Option.isNone(finalValues.firstReleaseDateCar);
    var finalVals_usedCarPoliceNumber = finalValues.policeNumber;
    var finalVals = {
      value: finalVals_value,
      estimatedValue: finalVals_estimatedValue,
      endOfLife: finalVals_endOfLife,
      brandUsedCar: finalVals_brandUsedCar,
      modelUsedCar: finalVals_modelUsedCar,
      versionUsedCar: finalVals_versionUsedCar,
      registrationNumberUsedCar: finalVals_registrationNumberUsedCar,
      vinUsedCar: finalVals_vinUsedCar,
      firstReleaseDateUsedCar: finalVals_firstReleaseDateUsedCar,
      registrationDateUsedCar: finalVals_registrationDateUsedCar,
      registrationDateUsedCarToDelete: finalVals_registrationDateUsedCarToDelete,
      firstReleaseDateUsedCarToDelete: finalVals_firstReleaseDateUsedCarToDelete,
      usedCarPoliceNumber: finalVals_usedCarPoliceNumber
    };
    Order_Detail_Old_Vehicle_Edits_Api$DvmAdminFrontendFr.postDealerEditCorrection(userManager, setSaveResult, orderId, submissionCallbacks, selectedRole, finalVals);
  };
  var form = Order_Detail_Old_Vehicle_Edits_Form$DvmAdminFrontendFr.Form.useForm(Order_Detail_Old_Vehicle_Edits_Utils$DvmAdminFrontendFr.getInitialInput(newestUsedCarValues), {
        onlyPolice: onlyPolice,
        orderType: Belt_Option.getWithDefault(order.orderType, /* NOTSET */11)
      }, (function (output, submissionCallbacks) {
          Curry._1(setFinalValues, (function (param) {
                  return output;
                }));
          Curry._1(setSubmissionCallbacks, (function (param) {
                  return submissionCallbacks;
                }));
        }));
  React.useEffect((function () {
          if (finalValues !== undefined && submissionCallbacks !== undefined) {
            Curry._1(setConfirmModal, (function (param) {
                    return true;
                  }));
          }
          
        }), [
        finalValues,
        submissionCallbacks
      ]);
  var tmp = {
    onChange: (function (val) {
        Curry._2(form.updateBrandUsedCar, (function (input, brandUsedCar) {
                return {
                        value: input.value,
                        estimatedValue: input.estimatedValue,
                        endOfLife: input.endOfLife,
                        brandUsedCar: brandUsedCar,
                        modelUsedCar: input.modelUsedCar,
                        versionUsedCar: input.versionUsedCar,
                        registrationNumberUsedCar: input.registrationNumberUsedCar,
                        vinUsedCar: input.vinUsedCar,
                        firstReleaseDateCar: input.firstReleaseDateCar,
                        registrationDateUsedCar: input.registrationDateUsedCar,
                        policeNumber: input.policeNumber
                      };
              }), val.toUpperCase());
      }),
    value: form.input.brandUsedCar,
    disabled: onlyPolice,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    label: Messages_Common$DvmAdminFrontendFr.brand
  };
  if (form.brandUsedCarResult !== undefined) {
    tmp.validationResult = Caml_option.valFromOption(form.brandUsedCarResult);
  }
  var tmp$1 = {
    onChange: (function (val) {
        Curry._2(form.updateModelUsedCar, (function (input, modelUsedCar) {
                return {
                        value: input.value,
                        estimatedValue: input.estimatedValue,
                        endOfLife: input.endOfLife,
                        brandUsedCar: input.brandUsedCar,
                        modelUsedCar: modelUsedCar,
                        versionUsedCar: input.versionUsedCar,
                        registrationNumberUsedCar: input.registrationNumberUsedCar,
                        vinUsedCar: input.vinUsedCar,
                        firstReleaseDateCar: input.firstReleaseDateCar,
                        registrationDateUsedCar: input.registrationDateUsedCar,
                        policeNumber: input.policeNumber
                      };
              }), val.toUpperCase());
      }),
    value: form.input.modelUsedCar,
    disabled: onlyPolice,
    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
    label: Messages_Common$DvmAdminFrontendFr.model
  };
  if (form.modelUsedCarResult !== undefined) {
    tmp$1.validationResult = Caml_option.valFromOption(form.modelUsedCarResult);
  }
  return React.createElement(React.Fragment, undefined, React.createElement("form", {
                  onSubmit: (function ($$event) {
                      $$event.preventDefault();
                      Curry._1(form.submit, undefined);
                    })
                }, React.createElement(Core.Grid, {
                      children: null,
                      container: true,
                      direction: "column",
                      justifyContent: "space-between",
                      spacing: 2,
                      wrap: "nowrap"
                    }, React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          item: true,
                          spacing: 1
                        }, React.createElement(Core.Grid, {
                              children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, tmp$1),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                                    onChange: Curry._1(form.updateVersionUsedCar, (function (input, versionUsedCar) {
                                            return {
                                                    value: input.value,
                                                    estimatedValue: input.estimatedValue,
                                                    endOfLife: input.endOfLife,
                                                    brandUsedCar: input.brandUsedCar,
                                                    modelUsedCar: input.modelUsedCar,
                                                    versionUsedCar: versionUsedCar,
                                                    registrationNumberUsedCar: input.registrationNumberUsedCar,
                                                    vinUsedCar: input.vinUsedCar,
                                                    firstReleaseDateCar: input.firstReleaseDateCar,
                                                    registrationDateUsedCar: input.registrationDateUsedCar,
                                                    policeNumber: input.policeNumber
                                                  };
                                          })),
                                    value: form.input.versionUsedCar,
                                    disabled: onlyPolice,
                                    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
                                    label: Messages_Common$DvmAdminFrontendFr.version
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                                    onChange: Curry._1(form.updateRegistrationNumberUsedCar, (function (input, registrationNumberUsedCar) {
                                            return {
                                                    value: input.value,
                                                    estimatedValue: input.estimatedValue,
                                                    endOfLife: input.endOfLife,
                                                    brandUsedCar: input.brandUsedCar,
                                                    modelUsedCar: input.modelUsedCar,
                                                    versionUsedCar: input.versionUsedCar,
                                                    registrationNumberUsedCar: registrationNumberUsedCar,
                                                    vinUsedCar: input.vinUsedCar,
                                                    firstReleaseDateCar: input.firstReleaseDateCar,
                                                    registrationDateUsedCar: input.registrationDateUsedCar,
                                                    policeNumber: input.policeNumber
                                                  };
                                          })),
                                    value: form.input.registrationNumberUsedCar,
                                    disabled: onlyPolice,
                                    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
                                    label: Messages_Orders_Detail$DvmAdminFrontendFr.reprImmat
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                                    onChange: Curry._1(form.updateVinUsedCar, (function (input, vinUsedCar) {
                                            return {
                                                    value: input.value,
                                                    estimatedValue: input.estimatedValue,
                                                    endOfLife: input.endOfLife,
                                                    brandUsedCar: input.brandUsedCar,
                                                    modelUsedCar: input.modelUsedCar,
                                                    versionUsedCar: input.versionUsedCar,
                                                    registrationNumberUsedCar: input.registrationNumberUsedCar,
                                                    vinUsedCar: vinUsedCar,
                                                    firstReleaseDateCar: input.firstReleaseDateCar,
                                                    registrationDateUsedCar: input.registrationDateUsedCar,
                                                    policeNumber: input.policeNumber
                                                  };
                                          })),
                                    value: form.input.vinUsedCar,
                                    disabled: onlyPolice,
                                    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
                                    label: Messages_Common$DvmAdminFrontendFr.vin
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, {
                                    label: Messages_Orders_Detail$DvmAdminFrontendFr.reprDatePremiere,
                                    value: form.input.firstReleaseDateCar,
                                    onChange: Curry._1(form.updateFirstReleaseDateCar, (function (input, firstReleaseDateCar) {
                                            return {
                                                    value: input.value,
                                                    estimatedValue: input.estimatedValue,
                                                    endOfLife: input.endOfLife,
                                                    brandUsedCar: input.brandUsedCar,
                                                    modelUsedCar: input.modelUsedCar,
                                                    versionUsedCar: input.versionUsedCar,
                                                    registrationNumberUsedCar: input.registrationNumberUsedCar,
                                                    vinUsedCar: input.vinUsedCar,
                                                    firstReleaseDateCar: firstReleaseDateCar,
                                                    registrationDateUsedCar: input.registrationDateUsedCar,
                                                    policeNumber: input.policeNumber
                                                  };
                                          })),
                                    disabled: onlyPolice,
                                    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_DateInputFormField$DvmAdminFrontendFr.make, {
                                    label: Messages_Orders_Detail$DvmAdminFrontendFr.reprDateImmat,
                                    value: form.input.registrationDateUsedCar,
                                    onChange: Curry._1(form.updateRegistrationDateUsedCar, (function (input, registrationDateUsedCar) {
                                            return {
                                                    value: input.value,
                                                    estimatedValue: input.estimatedValue,
                                                    endOfLife: input.endOfLife,
                                                    brandUsedCar: input.brandUsedCar,
                                                    modelUsedCar: input.modelUsedCar,
                                                    versionUsedCar: input.versionUsedCar,
                                                    registrationNumberUsedCar: input.registrationNumberUsedCar,
                                                    vinUsedCar: input.vinUsedCar,
                                                    firstReleaseDateCar: input.firstReleaseDateCar,
                                                    registrationDateUsedCar: registrationDateUsedCar,
                                                    policeNumber: input.policeNumber
                                                  };
                                          })),
                                    disabled: onlyPolice,
                                    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
                                  }),
                              item: true
                            }), Belt_Option.getWithDefault(order.orderType, /* NOTSET */11) === /* SO */0 ? null : React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                                    children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                                          onChange: Curry._1(form.updateValue, (function (input, value) {
                                                  return {
                                                          value: value,
                                                          estimatedValue: input.estimatedValue,
                                                          endOfLife: input.endOfLife,
                                                          brandUsedCar: input.brandUsedCar,
                                                          modelUsedCar: input.modelUsedCar,
                                                          versionUsedCar: input.versionUsedCar,
                                                          registrationNumberUsedCar: input.registrationNumberUsedCar,
                                                          vinUsedCar: input.vinUsedCar,
                                                          firstReleaseDateCar: input.firstReleaseDateCar,
                                                          registrationDateUsedCar: input.registrationDateUsedCar,
                                                          policeNumber: input.policeNumber
                                                        };
                                                })),
                                          value: form.input.value,
                                          disabled: onlyPolice,
                                          className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
                                          label: Messages_Orders_Detail$DvmAdminFrontendFr.valueVO,
                                          InputProps: {
                                            startAdornment: React.createElement(Core.InputAdornment, {
                                                  children: "€",
                                                  position: "start"
                                                })
                                          },
                                          inputType: "number"
                                        }),
                                    item: true
                                  }), React.createElement(Core.Grid, {
                                    children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                                          onChange: Curry._1(form.updateEstimatedValue, (function (input, estimatedValue) {
                                                  return {
                                                          value: input.value,
                                                          estimatedValue: estimatedValue,
                                                          endOfLife: input.endOfLife,
                                                          brandUsedCar: input.brandUsedCar,
                                                          modelUsedCar: input.modelUsedCar,
                                                          versionUsedCar: input.versionUsedCar,
                                                          registrationNumberUsedCar: input.registrationNumberUsedCar,
                                                          vinUsedCar: input.vinUsedCar,
                                                          firstReleaseDateCar: input.firstReleaseDateCar,
                                                          registrationDateUsedCar: input.registrationDateUsedCar,
                                                          policeNumber: input.policeNumber
                                                        };
                                                })),
                                          value: form.input.estimatedValue,
                                          disabled: onlyPolice,
                                          className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
                                          label: Messages_Orders_Detail$DvmAdminFrontendFr.estimatedValueVO,
                                          InputProps: {
                                            startAdornment: React.createElement(Core.InputAdornment, {
                                                  children: "€",
                                                  position: "start"
                                                })
                                          },
                                          inputType: "number"
                                        }),
                                    item: true
                                  }), React.createElement(Core.Grid, {
                                    children: React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, {
                                          label: Messages_Orders_Detail$DvmAdminFrontendFr.endOfLifeVO,
                                          options: {
                                            hd: {
                                              label: {
                                                TAG: /* Message */0,
                                                _0: Messages_Common$DvmAdminFrontendFr.nothing
                                              },
                                              value: ""
                                            },
                                            tl: {
                                              hd: {
                                                label: {
                                                  TAG: /* Message */0,
                                                  _0: Messages_Common$DvmAdminFrontendFr.yes
                                                },
                                                value: "true"
                                              },
                                              tl: {
                                                hd: {
                                                  label: {
                                                    TAG: /* Message */0,
                                                    _0: Messages_Common$DvmAdminFrontendFr.no
                                                  },
                                                  value: "false"
                                                },
                                                tl: /* [] */0
                                              }
                                            }
                                          },
                                          value: {
                                            TAG: /* Single */0,
                                            _0: {
                                              value: form.input.endOfLife,
                                              onChange: Curry._1(form.updateEndOfLife, (function (input, endOfLife) {
                                                      return {
                                                              value: input.value,
                                                              estimatedValue: input.estimatedValue,
                                                              endOfLife: endOfLife,
                                                              brandUsedCar: input.brandUsedCar,
                                                              modelUsedCar: input.modelUsedCar,
                                                              versionUsedCar: input.versionUsedCar,
                                                              registrationNumberUsedCar: input.registrationNumberUsedCar,
                                                              vinUsedCar: input.vinUsedCar,
                                                              firstReleaseDateCar: input.firstReleaseDateCar,
                                                              registrationDateUsedCar: input.registrationDateUsedCar,
                                                              policeNumber: input.policeNumber
                                                            };
                                                    }))
                                            }
                                          },
                                          id: "endOfLifeVO",
                                          className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
                                          disabled: onlyPolice
                                        }),
                                    item: true
                                  })), React.createElement(Core.Grid, {
                              children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                                    onChange: Curry._1(form.updatePoliceNumber, (function (input, policeNumber) {
                                            return {
                                                    value: input.value,
                                                    estimatedValue: input.estimatedValue,
                                                    endOfLife: input.endOfLife,
                                                    brandUsedCar: input.brandUsedCar,
                                                    modelUsedCar: input.modelUsedCar,
                                                    versionUsedCar: input.versionUsedCar,
                                                    registrationNumberUsedCar: input.registrationNumberUsedCar,
                                                    vinUsedCar: input.vinUsedCar,
                                                    firstReleaseDateCar: input.firstReleaseDateCar,
                                                    registrationDateUsedCar: input.registrationDateUsedCar,
                                                    policeNumber: policeNumber
                                                  };
                                          })),
                                    value: form.input.policeNumber,
                                    className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
                                    label: Messages_Orders_Detail$DvmAdminFrontendFr.reprPoliceNumber
                                  }),
                              item: true
                            })), React.createElement(Core.Grid, {
                          alignItems: "flex-end",
                          children: null,
                          container: true,
                          direction: "column",
                          item: true
                        }, React.createElement(Core.Grid, {
                              children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                    label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                    color: "primary",
                                    size: "medium",
                                    type_: "submit",
                                    style: {
                                      width: "200px"
                                    }
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                    noWrap: true,
                                    style: {
                                      color: Theme_Colors$DvmAdminFrontendFr.grayDark2,
                                      fontWeight: "600"
                                    },
                                    children: intl.formatMessage(Messages_Orders_Correction$DvmAdminFrontendFr.correctionWillUpdateTomorrow),
                                    variant: "subtitle"
                                  }),
                              item: true
                            })))), match$2[0] ? React.createElement(App_Modal$DvmAdminFrontendFr.make, {
                    open: true,
                    onClose: (function (param) {
                        Curry._1(setConfirmModal, (function (param) {
                                return false;
                              }));
                        Belt_Option.mapWithDefault(submissionCallbacks, undefined, (function (x) {
                                Curry._1(x.notifyOnFailure, /* FailedToUpdateOldVehicle */0);
                              }));
                      }),
                    title: intl.formatMessage(Messages_Orders_Detail$DvmAdminFrontendFr.confirmEditVOModalTitle),
                    minWidth: "400px",
                    children: React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          justifyContent: "space-between"
                        }, React.createElement(Core.Grid, {
                              children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                    onClick: (function (param) {
                                        Curry._1(setConfirmModal, (function (param) {
                                                return false;
                                              }));
                                        Belt_Option.mapWithDefault(submissionCallbacks, undefined, (function (x) {
                                                Curry._1(x.notifyOnFailure, /* FailedToUpdateOldVehicle */0);
                                              }));
                                      }),
                                    label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.cancel),
                                    color: "primaryBorder",
                                    size: "medium"
                                  }),
                              item: true
                            }), React.createElement(Core.Grid, {
                              children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                    onClick: (function (param) {
                                        postChange(undefined);
                                        Curry._1(setConfirmModal, (function (param) {
                                                return false;
                                              }));
                                      }),
                                    label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                    size: "medium"
                                  }),
                              item: true
                            }))
                  }) : null);
}

var Api;

var Form;

var Types;

var Utils;

var make = Order_Detail_Old_Vehicle_Edits_Inputs;

export {
  Api ,
  Form ,
  Types ,
  Utils ,
  make ,
}
/* react Not a pure module */
