// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../common/utils/Utils_Form.bs.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../common/types/App_Types_Date.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../context/user/User_Types_Utils.bs.js";
import * as Messages_Error_Inputs$DvmAdminFrontendFr from "../../../intl/messages/Messages_Error_Inputs.bs.js";

function validateDate(smallerDate, largerDate, thisDate, requiredSmaller, requiredLarger, needsSameYearOpt, param) {
  var needsSameYear = needsSameYearOpt !== undefined ? needsSameYearOpt : false;
  var match = thisDate === "smaller" ? [
      smallerDate,
      largerDate,
      requiredSmaller,
      requiredLarger
    ] : [
      largerDate,
      smallerDate,
      requiredLarger,
      requiredSmaller
    ];
  var requiredThat = match[3];
  var that = match[1];
  return Belt_Option.mapWithDefault(match[0], match[2] ? ({
                  TAG: /* Error */1,
                  _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldRequired
                }) : ({
                  TAG: /* Ok */0,
                  _0: undefined
                }), (function ($$this) {
                if (App_Types_Date$DvmAdminFrontendFr.isValid($$this)) {
                  return Belt_Option.mapWithDefault(that, requiredThat ? ({
                                  TAG: /* Error */1,
                                  _0: Messages_Error_Inputs$DvmAdminFrontendFr.otherDateNotValid
                                }) : ({
                                  TAG: /* Ok */0,
                                  _0: Caml_option.some($$this)
                                }), (function (that) {
                                if (App_Types_Date$DvmAdminFrontendFr.isValid(that)) {
                                  if (ReDate.getYear($$this) === ReDate.getYear(that) || !needsSameYear) {
                                    if (thisDate === "smaller") {
                                      if ($$this.valueOf() < that.valueOf()) {
                                        return {
                                                TAG: /* Ok */0,
                                                _0: Caml_option.some($$this)
                                              };
                                      } else {
                                        return {
                                                TAG: /* Error */1,
                                                _0: Messages_Error_Inputs$DvmAdminFrontendFr.dateTooLarge
                                              };
                                      }
                                    } else if ($$this.valueOf() > that.valueOf()) {
                                      return {
                                              TAG: /* Ok */0,
                                              _0: Caml_option.some($$this)
                                            };
                                    } else {
                                      return {
                                              TAG: /* Error */1,
                                              _0: Messages_Error_Inputs$DvmAdminFrontendFr.dateTooSmall
                                            };
                                    }
                                  } else {
                                    return {
                                            TAG: /* Error */1,
                                            _0: Messages_Error_Inputs$DvmAdminFrontendFr.datesNotSameYear
                                          };
                                  }
                                } else {
                                  return {
                                          TAG: /* Error */1,
                                          _0: Messages_Error_Inputs$DvmAdminFrontendFr.otherDateNotValid
                                        };
                                }
                              }));
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
                        };
                }
              }));
}

var validators_requestType = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var requestType = param.requestType;
      if (User_Types_Utils$DvmAdminFrontendFr.whatCountry(param$1.selectedRole) === /* Spain */1) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
      switch (param.orderType) {
        case /* CL */1 :
        case /* ES */6 :
        case /* EP */7 :
        case /* RS */8 :
        case /* RP */9 :
            return {
                    TAG: /* Ok */0,
                    _0: requestType
                  };
        case /* SO */0 :
        case /* VE */2 :
        case /* VD */3 :
        case /* RR */4 :
        case /* RC */5 :
        case /* NOTSET */10 :
            return {
                    TAG: /* Ok */0,
                    _0: undefined
                  };
        
      }
    })
};

var validators_dealerWithAgents = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      var orderType = param.orderType;
      if (orderType === /* RR */4 || orderType === /* RC */5) {
        return {
                TAG: /* Ok */0,
                _0: Belt_List.map(param.dealerWithAgents, (function (x) {
                        return x.value;
                      }))
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: /* [] */0
              };
      }
    })
};

var validators_dealerIds = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      var orderType = param.orderType;
      if (orderType === /* RR */4 || orderType === /* RC */5) {
        return {
                TAG: /* Ok */0,
                _0: Belt_List.map(param.dealerIds, (function (x) {
                        return x.value;
                      }))
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: /* [] */0
              };
      }
    })
};

var validators_isPARCExit = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var isPARCExit = param.isPARCExit;
      switch (param.orderType) {
        case /* VE */2 :
        case /* RR */4 :
        case /* RC */5 :
            break;
        case /* SO */0 :
        case /* CL */1 :
        case /* VD */3 :
        case /* ES */6 :
        case /* EP */7 :
        case /* RS */8 :
        case /* RP */9 :
        case /* NOTSET */10 :
            return {
                    TAG: /* Ok */0,
                    _0: undefined
                  };
        
      }
      if (isPARCExit !== undefined) {
        return {
                TAG: /* Ok */0,
                _0: isPARCExit
              };
      } else {
        return {
                TAG: /* Error */1,
                _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldRequired
              };
      }
    })
};

var validators_priceType = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var priceType = param.priceType;
      if (User_Types_Utils$DvmAdminFrontendFr.whatCountry(param$1.selectedRole) === /* Spain */1 || (param.orderType - 1 >>> 0) <= 8) {
        return {
                TAG: /* Ok */0,
                _0: priceType
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
    })
};

var validators_detentionMax = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      switch (param.orderType) {
        case /* VE */2 :
        case /* RR */4 :
        case /* RC */5 :
            break;
        case /* SO */0 :
        case /* CL */1 :
        case /* VD */3 :
        case /* ES */6 :
        case /* EP */7 :
        case /* RS */8 :
        case /* RP */9 :
        case /* NOTSET */10 :
            return {
                    TAG: /* Ok */0,
                    _0: undefined
                  };
        
      }
      if (!Belt_Option.getWithDefault(param.isPARCExit, false)) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
      var validatedMin = Utils_Form$DvmAdminFrontendFr.validateIntegerPositiveRequired(Belt_Int.fromString(param.detentionMin));
      var validatedMax = Utils_Form$DvmAdminFrontendFr.validateIntegerPositiveRequired(Belt_Int.fromString(param.detentionMax));
      if (validatedMin.TAG !== /* Ok */0) {
        if (validatedMax.TAG === /* Ok */0) {
          return {
                  TAG: /* Error */1,
                  _0: Messages_Error_Inputs$DvmAdminFrontendFr.otherValueNotValid
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: validatedMax._0
                };
        }
      }
      if (validatedMax.TAG !== /* Ok */0) {
        return {
                TAG: /* Error */1,
                _0: validatedMax._0
              };
      }
      var max = validatedMax._0;
      if (validatedMin._0 <= max) {
        return {
                TAG: /* Ok */0,
                _0: max
              };
      } else {
        return {
                TAG: /* Error */1,
                _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
              };
      }
    })
};

var validators_detentionMin = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      switch (param.orderType) {
        case /* VE */2 :
        case /* RR */4 :
        case /* RC */5 :
            break;
        case /* SO */0 :
        case /* CL */1 :
        case /* VD */3 :
        case /* ES */6 :
        case /* EP */7 :
        case /* RS */8 :
        case /* RP */9 :
        case /* NOTSET */10 :
            return {
                    TAG: /* Ok */0,
                    _0: undefined
                  };
        
      }
      if (!Belt_Option.getWithDefault(param.isPARCExit, false)) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
      var validatedMin = Utils_Form$DvmAdminFrontendFr.validateIntegerPositiveRequired(Belt_Int.fromString(param.detentionMin));
      var validatedMax = Utils_Form$DvmAdminFrontendFr.validateIntegerPositiveRequired(Belt_Int.fromString(param.detentionMax));
      if (validatedMin.TAG !== /* Ok */0) {
        return {
                TAG: /* Error */1,
                _0: validatedMin._0
              };
      }
      var min = validatedMin._0;
      if (validatedMax.TAG === /* Ok */0) {
        if (min <= validatedMax._0) {
          return {
                  TAG: /* Ok */0,
                  _0: min
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
                };
        }
      } else {
        return {
                TAG: /* Error */1,
                _0: Messages_Error_Inputs$DvmAdminFrontendFr.otherValueNotValid
              };
      }
    })
};

var validators_exitParcDatePeriodEnd = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      if (param.orderType !== 2 || !Belt_Option.getWithDefault(param.isPARCExit, false)) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      } else {
        return validateDate(param.exitParcDatePeriodStart, param.exitParcDatePeriodEnd, "larger", true, true, undefined, undefined);
      }
    })
};

var validators_exitParcDatePeriodStart = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      if (param.orderType !== 2 || !Belt_Option.getWithDefault(param.isPARCExit, false)) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      } else {
        return validateDate(param.exitParcDatePeriodStart, param.exitParcDatePeriodEnd, "smaller", true, true, undefined, undefined);
      }
    })
};

var validators_entryParcDatePeriodEnd = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var entryParcDatePeriodEnd = param.entryParcDatePeriodEnd;
      var entryParcDatePeriodStart = param.entryParcDatePeriodStart;
      var orderType = param.orderType;
      switch (orderType) {
        case /* VE */2 :
            if (Belt_Option.getWithDefault(param.isPARCExit, true)) {
              return {
                      TAG: /* Ok */0,
                      _0: undefined
                    };
            } else {
              return validateDate(entryParcDatePeriodStart, entryParcDatePeriodEnd, "larger", orderType === /* VE */2, orderType === /* VE */2, undefined, undefined);
            }
        case /* RR */4 :
        case /* RC */5 :
            break;
        case /* SO */0 :
        case /* CL */1 :
        case /* VD */3 :
        case /* ES */6 :
        case /* EP */7 :
        case /* RS */8 :
        case /* RP */9 :
        case /* NOTSET */10 :
            return {
                    TAG: /* Ok */0,
                    _0: undefined
                  };
        
      }
      return validateDate(entryParcDatePeriodStart, entryParcDatePeriodEnd, "larger", false, false, undefined, undefined);
    })
};

var validators_entryParcDatePeriodStart = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var entryParcDatePeriodEnd = param.entryParcDatePeriodEnd;
      var entryParcDatePeriodStart = param.entryParcDatePeriodStart;
      var orderType = param.orderType;
      switch (orderType) {
        case /* VE */2 :
            if (Belt_Option.getWithDefault(param.isPARCExit, true)) {
              return {
                      TAG: /* Ok */0,
                      _0: undefined
                    };
            } else {
              return validateDate(entryParcDatePeriodStart, entryParcDatePeriodEnd, "smaller", orderType === /* VE */2, orderType === /* VE */2, undefined, undefined);
            }
        case /* RR */4 :
        case /* RC */5 :
            break;
        case /* SO */0 :
        case /* CL */1 :
        case /* VD */3 :
        case /* ES */6 :
        case /* EP */7 :
        case /* RS */8 :
        case /* RP */9 :
        case /* NOTSET */10 :
            return {
                    TAG: /* Ok */0,
                    _0: undefined
                  };
        
      }
      return validateDate(entryParcDatePeriodStart, entryParcDatePeriodEnd, "smaller", false, false, undefined, undefined);
    })
};

var validators_financialCodeOrientation = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      if (typeof param.actionType === "number") {
        return {
                TAG: /* Ok */0,
                _0: /* NOTSET */2
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: param.financialCodeOrientation
              };
      }
    })
};

var validators_financialCodes = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      if (typeof param.actionType === "number" || param.financialCodeOrientation !== 1) {
        return {
                TAG: /* Ok */0,
                _0: /* [] */0
              };
      } else {
        return Utils_Form$DvmAdminFrontendFr.validateListRequired(param.financialCodes);
      }
    })
};

var validators_perfType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      var actionType = param.actionType;
      if (actionType === 1) {
        return Utils_Form$DvmAdminFrontendFr.validateRequired(param.perfType);
      } else {
        return {
                TAG: /* Ok */0,
                _0: ""
              };
      }
    })
};

var validators_numberType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      var actionType = param.actionType;
      if (typeof actionType === "number") {
        if (actionType === 1) {
          return {
                  TAG: /* Ok */0,
                  _0: /* NOTSET */2
                };
        }
        if (actionType >= 3) {
          return {
                  TAG: /* Ok */0,
                  _0: /* NOTSET */2
                };
        }
        
      }
      return Belt_Option.mapWithDefault(param.numberType, {
                  TAG: /* Error */1,
                  _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldRequired
                }, (function (x) {
                    return {
                            TAG: /* Ok */0,
                            _0: x
                          };
                  }));
    })
};

var validators_value = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      var value = param.value;
      var actionType = param.actionType;
      if (typeof actionType === "number") {
        if (actionType === 1) {
          return {
                  TAG: /* Ok */0,
                  _0: ""
                };
        }
        if (actionType >= 3) {
          return {
                  TAG: /* Ok */0,
                  _0: ""
                };
        }
        
      }
      var match = Belt_Option.getWithDefault(param.numberType, /* NOTSET */2);
      if (match !== 0) {
        return Utils_Form$DvmAdminFrontendFr.validateRequired(value);
      }
      var val = Utils_Form$DvmAdminFrontendFr.validateFloatMaximumAndRequired(value, 100);
      if (val.TAG !== /* Ok */0) {
        return {
                TAG: /* Error */1,
                _0: val._0
              };
      }
      var val$1 = val._0;
      if (val$1 < 0.0) {
        return {
                TAG: /* Error */1,
                _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldPositive
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: String(val$1)
              };
      }
    })
};

var validators_stocDateType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      var stocDateType = param.stocDateType;
      var actionType = param.actionType;
      if (typeof actionType === "number") {
        if (actionType !== 0) {
          return {
                  TAG: /* Ok */0,
                  _0: /* NOTSET */2
                };
        } else if (stocDateType === /* NOTSET */2) {
          return {
                  TAG: /* Error */1,
                  _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldRequired
                };
        } else {
          return {
                  TAG: /* Ok */0,
                  _0: stocDateType
                };
        }
      } else {
        return {
                TAG: /* Ok */0,
                _0: stocDateType
              };
      }
    })
};

var validators_stocDateEnd = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var stocDateEnd = param.stocDateEnd;
      var stocDateStart = param.stocDateStart;
      var actionType = param.actionType;
      var dateCheck = function (param) {
        return validateDate(stocDateStart, stocDateEnd, "larger", false, false, undefined, undefined);
      };
      if (typeof actionType === "number") {
        if (actionType !== 0) {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        } else {
          return dateCheck(undefined);
        }
      } else if (param.stocDateType === /* NOTSET */2) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      } else {
        return dateCheck(undefined);
      }
    })
};

var validators_stocDateStart = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var stocDateEnd = param.stocDateEnd;
      var stocDateStart = param.stocDateStart;
      var actionType = param.actionType;
      var dateCheck = function (param) {
        return validateDate(stocDateStart, stocDateEnd, "smaller", false, false, undefined, undefined);
      };
      if (typeof actionType === "number") {
        if (actionType !== 0) {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        } else {
          return dateCheck(undefined);
        }
      } else if (param.stocDateType === /* NOTSET */2) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      } else {
        return dateCheck(undefined);
      }
    })
};

var validators_orderType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      var orderType = param.orderType;
      if (orderType >= 10) {
        return {
                TAG: /* Error */1,
                _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldRequired
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: orderType
              };
      }
    })
};

var validators_budgetType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      if (param.orderType === /* SO */0) {
        return Utils_Form$DvmAdminFrontendFr.validateListRequired(param.budgetType);
      } else {
        return {
                TAG: /* Ok */0,
                _0: /* [] */0
              };
      }
    })
};

var validators_actionType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      var actionType = param.actionType;
      if (typeof actionType !== "number") {
        return Utils_Form$DvmAdminFrontendFr.validateMaxCharacters(actionType._0, 4);
      }
      switch (actionType) {
        case /* STOC */0 :
            return {
                    TAG: /* Ok */0,
                    _0: "STOC"
                  };
        case /* PERF */1 :
            return {
                    TAG: /* Ok */0,
                    _0: "PERF"
                  };
        case /* REPR */2 :
            return {
                    TAG: /* Ok */0,
                    _0: "REPR"
                  };
        case /* NOTSET */3 :
            return {
                    TAG: /* Error */1,
                    _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldRequired
                  };
        
      }
    })
};

var validators_actionLabel = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return Utils_Form$DvmAdminFrontendFr.validateWithRegex(param.actionLabel, /^[\x00-\x7F]+$/);
    })
};

var validators_actionID = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: param.actionID
            };
    })
};

var validators = {
  requestType: validators_requestType,
  dealerWithAgents: validators_dealerWithAgents,
  dealerIds: validators_dealerIds,
  isPARCExit: validators_isPARCExit,
  priceType: validators_priceType,
  detentionMax: validators_detentionMax,
  detentionMin: validators_detentionMin,
  exitParcDatePeriodEnd: validators_exitParcDatePeriodEnd,
  exitParcDatePeriodStart: validators_exitParcDatePeriodStart,
  entryParcDatePeriodEnd: validators_entryParcDatePeriodEnd,
  entryParcDatePeriodStart: validators_entryParcDatePeriodStart,
  financialCodeOrientation: validators_financialCodeOrientation,
  financialCodes: validators_financialCodes,
  includeFinancialCode: undefined,
  perfType: validators_perfType,
  numberType: validators_numberType,
  value: validators_value,
  stocDateType: validators_stocDateType,
  stocDateEnd: validators_stocDateEnd,
  stocDateStart: validators_stocDateStart,
  orderType: validators_orderType,
  budgetType: validators_budgetType,
  actionType: validators_actionType,
  actionLabel: validators_actionLabel,
  actionID: validators_actionID
};

function initialFieldsStatuses(_input) {
  return {
          requestType: /* Pristine */0,
          dealerWithAgents: /* Pristine */0,
          dealerIds: /* Pristine */0,
          isPARCExit: /* Pristine */0,
          priceType: /* Pristine */0,
          detentionMax: /* Pristine */0,
          detentionMin: /* Pristine */0,
          exitParcDatePeriodEnd: /* Pristine */0,
          exitParcDatePeriodStart: /* Pristine */0,
          entryParcDatePeriodEnd: /* Pristine */0,
          entryParcDatePeriodStart: /* Pristine */0,
          financialCodeOrientation: /* Pristine */0,
          financialCodes: /* Pristine */0,
          includeFinancialCode: /* Pristine */0,
          perfType: /* Pristine */0,
          numberType: /* Pristine */0,
          value: /* Pristine */0,
          stocDateType: /* Pristine */0,
          stocDateEnd: /* Pristine */0,
          stocDateStart: /* Pristine */0,
          orderType: /* Pristine */0,
          budgetType: /* Pristine */0,
          actionType: /* Pristine */0,
          actionLabel: /* Pristine */0,
          actionID: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses, metadata) {
  var match = fieldsStatuses.requestType;
  var match_0 = match ? match._0 : Curry._2(validators.requestType.validate, input, metadata);
  var match$1 = fieldsStatuses.dealerWithAgents;
  var match_0$1 = match$1 ? match$1._0 : Curry._2(validators.dealerWithAgents.validate, input, metadata);
  var match$2 = fieldsStatuses.dealerIds;
  var match_0$2 = match$2 ? match$2._0 : Curry._2(validators.dealerIds.validate, input, metadata);
  var match$3 = fieldsStatuses.isPARCExit;
  var match_0$3 = match$3 ? match$3._0 : Curry._2(validators.isPARCExit.validate, input, metadata);
  var match$4 = fieldsStatuses.priceType;
  var match_0$4 = match$4 ? match$4._0 : Curry._2(validators.priceType.validate, input, metadata);
  var match$5 = fieldsStatuses.detentionMax;
  var match_0$5 = match$5 ? match$5._0 : Curry._2(validators.detentionMax.validate, input, metadata);
  var match$6 = fieldsStatuses.detentionMin;
  var match_0$6 = match$6 ? match$6._0 : Curry._2(validators.detentionMin.validate, input, metadata);
  var match$7 = fieldsStatuses.exitParcDatePeriodEnd;
  var match_0$7 = match$7 ? match$7._0 : Curry._2(validators.exitParcDatePeriodEnd.validate, input, metadata);
  var match$8 = fieldsStatuses.exitParcDatePeriodStart;
  var match_0$8 = match$8 ? match$8._0 : Curry._2(validators.exitParcDatePeriodStart.validate, input, metadata);
  var match$9 = fieldsStatuses.entryParcDatePeriodEnd;
  var match_0$9 = match$9 ? match$9._0 : Curry._2(validators.entryParcDatePeriodEnd.validate, input, metadata);
  var match$10 = fieldsStatuses.entryParcDatePeriodStart;
  var match_0$10 = match$10 ? match$10._0 : Curry._2(validators.entryParcDatePeriodStart.validate, input, metadata);
  var match$11 = fieldsStatuses.financialCodeOrientation;
  var match_0$11 = match$11 ? match$11._0 : Curry._2(validators.financialCodeOrientation.validate, input, metadata);
  var match$12 = fieldsStatuses.financialCodes;
  var match_0$12 = match$12 ? match$12._0 : Curry._2(validators.financialCodes.validate, input, metadata);
  var match_0$13 = {
    TAG: /* Ok */0,
    _0: input.includeFinancialCode
  };
  var match$13 = fieldsStatuses.perfType;
  var match_0$14 = match$13 ? match$13._0 : Curry._2(validators.perfType.validate, input, metadata);
  var match$14 = fieldsStatuses.numberType;
  var match_0$15 = match$14 ? match$14._0 : Curry._2(validators.numberType.validate, input, metadata);
  var match$15 = fieldsStatuses.value;
  var match_0$16 = match$15 ? match$15._0 : Curry._2(validators.value.validate, input, metadata);
  var match$16 = fieldsStatuses.stocDateType;
  var match_0$17 = match$16 ? match$16._0 : Curry._2(validators.stocDateType.validate, input, metadata);
  var match$17 = fieldsStatuses.stocDateEnd;
  var match_0$18 = match$17 ? match$17._0 : Curry._2(validators.stocDateEnd.validate, input, metadata);
  var match$18 = fieldsStatuses.stocDateStart;
  var match_0$19 = match$18 ? match$18._0 : Curry._2(validators.stocDateStart.validate, input, metadata);
  var match$19 = fieldsStatuses.orderType;
  var match_0$20 = match$19 ? match$19._0 : Curry._2(validators.orderType.validate, input, metadata);
  var match$20 = fieldsStatuses.budgetType;
  var match_0$21 = match$20 ? match$20._0 : Curry._2(validators.budgetType.validate, input, metadata);
  var match$21 = fieldsStatuses.actionType;
  var match_0$22 = match$21 ? match$21._0 : Curry._2(validators.actionType.validate, input, metadata);
  var match$22 = fieldsStatuses.actionLabel;
  var match_0$23 = match$22 ? match$22._0 : Curry._2(validators.actionLabel.validate, input, metadata);
  var match$23 = fieldsStatuses.actionID;
  var match_0$24 = match$23 ? match$23._0 : Curry._2(validators.actionID.validate, input, metadata);
  var requestTypeResult = match_0;
  var requestTypeResult$1;
  if (requestTypeResult.TAG === /* Ok */0) {
    var dealerWithAgentsResult = match_0$1;
    if (dealerWithAgentsResult.TAG === /* Ok */0) {
      var dealerIdsResult = match_0$2;
      if (dealerIdsResult.TAG === /* Ok */0) {
        var isPARCExitResult = match_0$3;
        if (isPARCExitResult.TAG === /* Ok */0) {
          var priceTypeResult = match_0$4;
          if (priceTypeResult.TAG === /* Ok */0) {
            var detentionMaxResult = match_0$5;
            if (detentionMaxResult.TAG === /* Ok */0) {
              var detentionMinResult = match_0$6;
              if (detentionMinResult.TAG === /* Ok */0) {
                var exitParcDatePeriodEndResult = match_0$7;
                if (exitParcDatePeriodEndResult.TAG === /* Ok */0) {
                  var exitParcDatePeriodStartResult = match_0$8;
                  if (exitParcDatePeriodStartResult.TAG === /* Ok */0) {
                    var entryParcDatePeriodEndResult = match_0$9;
                    if (entryParcDatePeriodEndResult.TAG === /* Ok */0) {
                      var entryParcDatePeriodStartResult = match_0$10;
                      if (entryParcDatePeriodStartResult.TAG === /* Ok */0) {
                        var financialCodeOrientationResult = match_0$11;
                        if (financialCodeOrientationResult.TAG === /* Ok */0) {
                          var financialCodesResult = match_0$12;
                          if (financialCodesResult.TAG === /* Ok */0) {
                            var includeFinancialCodeResult = match_0$13;
                            if (includeFinancialCodeResult.TAG === /* Ok */0) {
                              var perfTypeResult = match_0$14;
                              if (perfTypeResult.TAG === /* Ok */0) {
                                var numberTypeResult = match_0$15;
                                if (numberTypeResult.TAG === /* Ok */0) {
                                  var valueResult = match_0$16;
                                  if (valueResult.TAG === /* Ok */0) {
                                    var stocDateTypeResult = match_0$17;
                                    if (stocDateTypeResult.TAG === /* Ok */0) {
                                      var stocDateEndResult = match_0$18;
                                      if (stocDateEndResult.TAG === /* Ok */0) {
                                        var stocDateStartResult = match_0$19;
                                        if (stocDateStartResult.TAG === /* Ok */0) {
                                          var orderTypeResult = match_0$20;
                                          if (orderTypeResult.TAG === /* Ok */0) {
                                            var budgetTypeResult = match_0$21;
                                            if (budgetTypeResult.TAG === /* Ok */0) {
                                              var actionTypeResult = match_0$22;
                                              if (actionTypeResult.TAG === /* Ok */0) {
                                                var actionLabelResult = match_0$23;
                                                if (actionLabelResult.TAG === /* Ok */0) {
                                                  var actionIDResult = match_0$24;
                                                  if (actionIDResult.TAG === /* Ok */0) {
                                                    return {
                                                            TAG: /* Valid */0,
                                                            output: {
                                                              actionID: actionIDResult._0,
                                                              actionLabel: actionLabelResult._0,
                                                              actionType: actionTypeResult._0,
                                                              budgetType: budgetTypeResult._0,
                                                              orderType: orderTypeResult._0,
                                                              stocDateStart: stocDateStartResult._0,
                                                              stocDateEnd: stocDateEndResult._0,
                                                              stocDateType: stocDateTypeResult._0,
                                                              value: valueResult._0,
                                                              numberType: numberTypeResult._0,
                                                              perfType: perfTypeResult._0,
                                                              includeFinancialCode: includeFinancialCodeResult._0,
                                                              financialCodes: financialCodesResult._0,
                                                              financialCodeOrientation: financialCodeOrientationResult._0,
                                                              entryParcDatePeriodStart: entryParcDatePeriodStartResult._0,
                                                              entryParcDatePeriodEnd: entryParcDatePeriodEndResult._0,
                                                              exitParcDatePeriodStart: exitParcDatePeriodStartResult._0,
                                                              exitParcDatePeriodEnd: exitParcDatePeriodEndResult._0,
                                                              detentionMin: detentionMinResult._0,
                                                              detentionMax: detentionMaxResult._0,
                                                              priceType: priceTypeResult._0,
                                                              isPARCExit: isPARCExitResult._0,
                                                              dealerIds: dealerIdsResult._0,
                                                              dealerWithAgents: dealerWithAgentsResult._0,
                                                              requestType: requestTypeResult._0
                                                            },
                                                            fieldsStatuses: {
                                                              requestType: /* Dirty */{
                                                                _0: requestTypeResult,
                                                                _1: /* Shown */0
                                                              },
                                                              dealerWithAgents: /* Dirty */{
                                                                _0: dealerWithAgentsResult,
                                                                _1: /* Shown */0
                                                              },
                                                              dealerIds: /* Dirty */{
                                                                _0: dealerIdsResult,
                                                                _1: /* Shown */0
                                                              },
                                                              isPARCExit: /* Dirty */{
                                                                _0: isPARCExitResult,
                                                                _1: /* Shown */0
                                                              },
                                                              priceType: /* Dirty */{
                                                                _0: priceTypeResult,
                                                                _1: /* Shown */0
                                                              },
                                                              detentionMax: /* Dirty */{
                                                                _0: detentionMaxResult,
                                                                _1: /* Shown */0
                                                              },
                                                              detentionMin: /* Dirty */{
                                                                _0: detentionMinResult,
                                                                _1: /* Shown */0
                                                              },
                                                              exitParcDatePeriodEnd: /* Dirty */{
                                                                _0: exitParcDatePeriodEndResult,
                                                                _1: /* Shown */0
                                                              },
                                                              exitParcDatePeriodStart: /* Dirty */{
                                                                _0: exitParcDatePeriodStartResult,
                                                                _1: /* Shown */0
                                                              },
                                                              entryParcDatePeriodEnd: /* Dirty */{
                                                                _0: entryParcDatePeriodEndResult,
                                                                _1: /* Shown */0
                                                              },
                                                              entryParcDatePeriodStart: /* Dirty */{
                                                                _0: entryParcDatePeriodStartResult,
                                                                _1: /* Shown */0
                                                              },
                                                              financialCodeOrientation: /* Dirty */{
                                                                _0: financialCodeOrientationResult,
                                                                _1: /* Shown */0
                                                              },
                                                              financialCodes: /* Dirty */{
                                                                _0: financialCodesResult,
                                                                _1: /* Shown */0
                                                              },
                                                              includeFinancialCode: /* Dirty */{
                                                                _0: includeFinancialCodeResult,
                                                                _1: /* Hidden */1
                                                              },
                                                              perfType: /* Dirty */{
                                                                _0: perfTypeResult,
                                                                _1: /* Shown */0
                                                              },
                                                              numberType: /* Dirty */{
                                                                _0: numberTypeResult,
                                                                _1: /* Shown */0
                                                              },
                                                              value: /* Dirty */{
                                                                _0: valueResult,
                                                                _1: /* Shown */0
                                                              },
                                                              stocDateType: /* Dirty */{
                                                                _0: stocDateTypeResult,
                                                                _1: /* Shown */0
                                                              },
                                                              stocDateEnd: /* Dirty */{
                                                                _0: stocDateEndResult,
                                                                _1: /* Shown */0
                                                              },
                                                              stocDateStart: /* Dirty */{
                                                                _0: stocDateStartResult,
                                                                _1: /* Shown */0
                                                              },
                                                              orderType: /* Dirty */{
                                                                _0: orderTypeResult,
                                                                _1: /* Shown */0
                                                              },
                                                              budgetType: /* Dirty */{
                                                                _0: budgetTypeResult,
                                                                _1: /* Shown */0
                                                              },
                                                              actionType: /* Dirty */{
                                                                _0: actionTypeResult,
                                                                _1: /* Shown */0
                                                              },
                                                              actionLabel: /* Dirty */{
                                                                _0: actionLabelResult,
                                                                _1: /* Shown */0
                                                              },
                                                              actionID: /* Dirty */{
                                                                _0: actionIDResult,
                                                                _1: /* Shown */0
                                                              }
                                                            },
                                                            collectionsStatuses: undefined
                                                          };
                                                  }
                                                  requestTypeResult$1 = requestTypeResult;
                                                } else {
                                                  requestTypeResult$1 = requestTypeResult;
                                                }
                                              } else {
                                                requestTypeResult$1 = requestTypeResult;
                                              }
                                            } else {
                                              requestTypeResult$1 = requestTypeResult;
                                            }
                                          } else {
                                            requestTypeResult$1 = requestTypeResult;
                                          }
                                        } else {
                                          requestTypeResult$1 = requestTypeResult;
                                        }
                                      } else {
                                        requestTypeResult$1 = requestTypeResult;
                                      }
                                    } else {
                                      requestTypeResult$1 = requestTypeResult;
                                    }
                                  } else {
                                    requestTypeResult$1 = requestTypeResult;
                                  }
                                } else {
                                  requestTypeResult$1 = requestTypeResult;
                                }
                              } else {
                                requestTypeResult$1 = requestTypeResult;
                              }
                            } else {
                              requestTypeResult$1 = requestTypeResult;
                            }
                          } else {
                            requestTypeResult$1 = requestTypeResult;
                          }
                        } else {
                          requestTypeResult$1 = requestTypeResult;
                        }
                      } else {
                        requestTypeResult$1 = requestTypeResult;
                      }
                    } else {
                      requestTypeResult$1 = requestTypeResult;
                    }
                  } else {
                    requestTypeResult$1 = requestTypeResult;
                  }
                } else {
                  requestTypeResult$1 = requestTypeResult;
                }
              } else {
                requestTypeResult$1 = requestTypeResult;
              }
            } else {
              requestTypeResult$1 = requestTypeResult;
            }
          } else {
            requestTypeResult$1 = requestTypeResult;
          }
        } else {
          requestTypeResult$1 = requestTypeResult;
        }
      } else {
        requestTypeResult$1 = requestTypeResult;
      }
    } else {
      requestTypeResult$1 = requestTypeResult;
    }
  } else {
    requestTypeResult$1 = requestTypeResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            requestType: /* Dirty */{
              _0: requestTypeResult$1,
              _1: /* Shown */0
            },
            dealerWithAgents: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            dealerIds: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            isPARCExit: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            priceType: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            detentionMax: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            },
            detentionMin: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            },
            exitParcDatePeriodEnd: /* Dirty */{
              _0: match_0$7,
              _1: /* Shown */0
            },
            exitParcDatePeriodStart: /* Dirty */{
              _0: match_0$8,
              _1: /* Shown */0
            },
            entryParcDatePeriodEnd: /* Dirty */{
              _0: match_0$9,
              _1: /* Shown */0
            },
            entryParcDatePeriodStart: /* Dirty */{
              _0: match_0$10,
              _1: /* Shown */0
            },
            financialCodeOrientation: /* Dirty */{
              _0: match_0$11,
              _1: /* Shown */0
            },
            financialCodes: /* Dirty */{
              _0: match_0$12,
              _1: /* Shown */0
            },
            includeFinancialCode: /* Dirty */{
              _0: match_0$13,
              _1: /* Hidden */1
            },
            perfType: /* Dirty */{
              _0: match_0$14,
              _1: /* Shown */0
            },
            numberType: /* Dirty */{
              _0: match_0$15,
              _1: /* Shown */0
            },
            value: /* Dirty */{
              _0: match_0$16,
              _1: /* Shown */0
            },
            stocDateType: /* Dirty */{
              _0: match_0$17,
              _1: /* Shown */0
            },
            stocDateEnd: /* Dirty */{
              _0: match_0$18,
              _1: /* Shown */0
            },
            stocDateStart: /* Dirty */{
              _0: match_0$19,
              _1: /* Shown */0
            },
            orderType: /* Dirty */{
              _0: match_0$20,
              _1: /* Shown */0
            },
            budgetType: /* Dirty */{
              _0: match_0$21,
              _1: /* Shown */0
            },
            actionType: /* Dirty */{
              _0: match_0$22,
              _1: /* Shown */0
            },
            actionLabel: /* Dirty */{
              _0: match_0$23,
              _1: /* Shown */0
            },
            actionID: /* Dirty */{
              _0: match_0$24,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, metadata, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurRequestTypeField */0 :
                  var result = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.requestType, validators_requestType, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.requestType = status;
                          return newrecord;
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDealerWithAgentsField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.dealerWithAgents, validators_dealerWithAgents, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.dealerWithAgents = status;
                          return newrecord;
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDealerIdsField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.dealerIds, validators_dealerIds, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.dealerIds = status;
                          return newrecord;
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurIsPARCExitField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.isPARCExit, validators_isPARCExit, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.isPARCExit = status;
                          return newrecord;
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurPriceTypeField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.priceType, validators_priceType, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.priceType = status;
                          return newrecord;
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDetentionMaxField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.detentionMax, validators_detentionMax, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.detentionMax = status;
                          return newrecord;
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDetentionMinField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.detentionMin, validators_detentionMin, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.detentionMin = status;
                          return newrecord;
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurExitParcDatePeriodEndField */7 :
                  var result$7 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.exitParcDatePeriodEnd, validators_exitParcDatePeriodEnd, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.exitParcDatePeriodEnd = status;
                          return newrecord;
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurExitParcDatePeriodStartField */8 :
                  var result$8 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.exitParcDatePeriodStart, validators_exitParcDatePeriodStart, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.exitParcDatePeriodStart = status;
                          return newrecord;
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEntryParcDatePeriodEndField */9 :
                  var result$9 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.entryParcDatePeriodEnd, validators_entryParcDatePeriodEnd, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.entryParcDatePeriodEnd = status;
                          return newrecord;
                        }));
                  if (result$9 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$9,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEntryParcDatePeriodStartField */10 :
                  var result$10 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.entryParcDatePeriodStart, validators_entryParcDatePeriodStart, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.entryParcDatePeriodStart = status;
                          return newrecord;
                        }));
                  if (result$10 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$10,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurFinancialCodeOrientationField */11 :
                  var result$11 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.financialCodeOrientation, validators_financialCodeOrientation, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.financialCodeOrientation = status;
                          return newrecord;
                        }));
                  if (result$11 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$11,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurFinancialCodesField */12 :
                  var result$12 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.financialCodes, validators_financialCodes, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.financialCodes = status;
                          return newrecord;
                        }));
                  if (result$12 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$12,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurIncludeFinancialCodeField */13 :
                  var result$13 = Formality.validateFieldOnBlurWithoutValidator(state.input.includeFinancialCode, state.fieldsStatuses.includeFinancialCode, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.includeFinancialCode = status;
                          return newrecord;
                        }));
                  if (result$13 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$13,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurPerfTypeField */14 :
                  var result$14 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.perfType, validators_perfType, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.perfType = status;
                          return newrecord;
                        }));
                  if (result$14 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$14,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurNumberTypeField */15 :
                  var result$15 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.numberType, validators_numberType, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.numberType = status;
                          return newrecord;
                        }));
                  if (result$15 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$15,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurValueField */16 :
                  var result$16 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.value, validators_value, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.value = status;
                          return newrecord;
                        }));
                  if (result$16 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$16,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurStocDateTypeField */17 :
                  var result$17 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.stocDateType, validators_stocDateType, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.stocDateType = status;
                          return newrecord;
                        }));
                  if (result$17 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$17,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurStocDateEndField */18 :
                  var result$18 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.stocDateEnd, validators_stocDateEnd, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.stocDateEnd = status;
                          return newrecord;
                        }));
                  if (result$18 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$18,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurStocDateStartField */19 :
                  var result$19 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.stocDateStart, validators_stocDateStart, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.stocDateStart = status;
                          return newrecord;
                        }));
                  if (result$19 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$19,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurOrderTypeField */20 :
                  var result$20 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.orderType, validators_orderType, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.orderType = status;
                          return newrecord;
                        }));
                  if (result$20 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$20,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBudgetTypeField */21 :
                  var result$21 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.budgetType, validators_budgetType, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.budgetType = status;
                          return newrecord;
                        }));
                  if (result$21 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$21,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurActionTypeField */22 :
                  var result$22 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.actionType, validators_actionType, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.actionType = status;
                          return newrecord;
                        }));
                  if (result$22 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$22,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurActionLabelField */23 :
                  var result$23 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.actionLabel, validators_actionLabel, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.actionLabel = status;
                          return newrecord;
                        }));
                  if (result$23 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$23,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurActionIDField */24 :
                  var result$24 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.actionID, validators_actionID, (function (status) {
                          var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                          newrecord.actionID = status;
                          return newrecord;
                        }));
                  if (result$24 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$24,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */25 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses, metadata);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */25,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */26,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */28);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */27);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */26 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */27 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */28 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateRequestTypeField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput, metadata, state.fieldsStatuses.requestType, state.submissionStatus, validators_requestType, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.requestType = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDealerWithAgentsField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$1, metadata, state.fieldsStatuses.dealerWithAgents, state.submissionStatus, validators_dealerWithAgents, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.dealerWithAgents = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDealerIdsField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$2, metadata, state.fieldsStatuses.dealerIds, state.submissionStatus, validators_dealerIds, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.dealerIds = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateIsPARCExitField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  var result$25 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$3, metadata, nextFieldsStatuses.contents.entryParcDatePeriodStart, validators_entryParcDatePeriodStart, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses.contents);
                          newrecord.entryParcDatePeriodStart = status;
                          return newrecord;
                        }));
                  if (result$25 !== undefined) {
                    nextFieldsStatuses.contents = result$25;
                  }
                  var result$26 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$3, metadata, nextFieldsStatuses.contents.entryParcDatePeriodEnd, validators_entryParcDatePeriodEnd, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses.contents);
                          newrecord.entryParcDatePeriodEnd = status;
                          return newrecord;
                        }));
                  if (result$26 !== undefined) {
                    nextFieldsStatuses.contents = result$26;
                  }
                  var result$27 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$3, metadata, nextFieldsStatuses.contents.exitParcDatePeriodStart, validators_exitParcDatePeriodStart, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses.contents);
                          newrecord.exitParcDatePeriodStart = status;
                          return newrecord;
                        }));
                  if (result$27 !== undefined) {
                    nextFieldsStatuses.contents = result$27;
                  }
                  var result$28 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$3, metadata, nextFieldsStatuses.contents.exitParcDatePeriodEnd, validators_exitParcDatePeriodEnd, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses.contents);
                          newrecord.exitParcDatePeriodEnd = status;
                          return newrecord;
                        }));
                  if (result$28 !== undefined) {
                    nextFieldsStatuses.contents = result$28;
                  }
                  var result$29 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$3, metadata, nextFieldsStatuses.contents.detentionMin, validators_detentionMin, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses.contents);
                          newrecord.detentionMin = status;
                          return newrecord;
                        }));
                  if (result$29 !== undefined) {
                    nextFieldsStatuses.contents = result$29;
                  }
                  var result$30 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$3, metadata, nextFieldsStatuses.contents.detentionMax, validators_detentionMax, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses.contents);
                          newrecord.detentionMax = status;
                          return newrecord;
                        }));
                  if (result$30 !== undefined) {
                    nextFieldsStatuses.contents = result$30;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$3, metadata, nextFieldsStatuses.contents.isPARCExit, state.submissionStatus, validators_isPARCExit, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses.contents);
                                    newrecord.isPARCExit = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdatePriceTypeField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$4, metadata, state.fieldsStatuses.priceType, state.submissionStatus, validators_priceType, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.priceType = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDetentionMaxField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  var result$31 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$5, metadata, nextFieldsStatuses$1.contents.detentionMin, validators_detentionMin, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$1.contents);
                          newrecord.detentionMin = status;
                          return newrecord;
                        }));
                  if (result$31 !== undefined) {
                    nextFieldsStatuses$1.contents = result$31;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$5, metadata, nextFieldsStatuses$1.contents.detentionMax, state.submissionStatus, validators_detentionMax, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$1.contents);
                                    newrecord.detentionMax = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDetentionMinField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$2 = {
                    contents: state.fieldsStatuses
                  };
                  var result$32 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$6, metadata, nextFieldsStatuses$2.contents.detentionMax, validators_detentionMax, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$2.contents);
                          newrecord.detentionMax = status;
                          return newrecord;
                        }));
                  if (result$32 !== undefined) {
                    nextFieldsStatuses$2.contents = result$32;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$6, metadata, nextFieldsStatuses$2.contents.detentionMin, state.submissionStatus, validators_detentionMin, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$2.contents);
                                    newrecord.detentionMin = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateExitParcDatePeriodEndField */7 :
                  var nextInput$7 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$3 = {
                    contents: state.fieldsStatuses
                  };
                  var result$33 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$7, metadata, nextFieldsStatuses$3.contents.exitParcDatePeriodStart, validators_exitParcDatePeriodStart, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$3.contents);
                          newrecord.exitParcDatePeriodStart = status;
                          return newrecord;
                        }));
                  if (result$33 !== undefined) {
                    nextFieldsStatuses$3.contents = result$33;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$7, metadata, nextFieldsStatuses$3.contents.exitParcDatePeriodEnd, state.submissionStatus, validators_exitParcDatePeriodEnd, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$3.contents);
                                    newrecord.exitParcDatePeriodEnd = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateExitParcDatePeriodStartField */8 :
                  var nextInput$8 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$4 = {
                    contents: state.fieldsStatuses
                  };
                  var result$34 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$8, metadata, nextFieldsStatuses$4.contents.exitParcDatePeriodEnd, validators_exitParcDatePeriodEnd, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$4.contents);
                          newrecord.exitParcDatePeriodEnd = status;
                          return newrecord;
                        }));
                  if (result$34 !== undefined) {
                    nextFieldsStatuses$4.contents = result$34;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$8, metadata, nextFieldsStatuses$4.contents.exitParcDatePeriodStart, state.submissionStatus, validators_exitParcDatePeriodStart, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$4.contents);
                                    newrecord.exitParcDatePeriodStart = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEntryParcDatePeriodEndField */9 :
                  var nextInput$9 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$5 = {
                    contents: state.fieldsStatuses
                  };
                  var result$35 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$9, metadata, nextFieldsStatuses$5.contents.entryParcDatePeriodStart, validators_entryParcDatePeriodStart, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$5.contents);
                          newrecord.entryParcDatePeriodStart = status;
                          return newrecord;
                        }));
                  if (result$35 !== undefined) {
                    nextFieldsStatuses$5.contents = result$35;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$9,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$9, metadata, nextFieldsStatuses$5.contents.entryParcDatePeriodEnd, state.submissionStatus, validators_entryParcDatePeriodEnd, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$5.contents);
                                    newrecord.entryParcDatePeriodEnd = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEntryParcDatePeriodStartField */10 :
                  var nextInput$10 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$6 = {
                    contents: state.fieldsStatuses
                  };
                  var result$36 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$10, metadata, nextFieldsStatuses$6.contents.entryParcDatePeriodEnd, validators_entryParcDatePeriodEnd, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$6.contents);
                          newrecord.entryParcDatePeriodEnd = status;
                          return newrecord;
                        }));
                  if (result$36 !== undefined) {
                    nextFieldsStatuses$6.contents = result$36;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$10,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$10, metadata, nextFieldsStatuses$6.contents.entryParcDatePeriodStart, state.submissionStatus, validators_entryParcDatePeriodStart, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$6.contents);
                                    newrecord.entryParcDatePeriodStart = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateFinancialCodeOrientationField */11 :
                  var nextInput$11 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$7 = {
                    contents: state.fieldsStatuses
                  };
                  var result$37 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$11, metadata, nextFieldsStatuses$7.contents.financialCodes, validators_financialCodes, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$7.contents);
                          newrecord.financialCodes = status;
                          return newrecord;
                        }));
                  if (result$37 !== undefined) {
                    nextFieldsStatuses$7.contents = result$37;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$11,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$11, metadata, nextFieldsStatuses$7.contents.financialCodeOrientation, state.submissionStatus, validators_financialCodeOrientation, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$7.contents);
                                    newrecord.financialCodeOrientation = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateFinancialCodesField */12 :
                  var nextInput$12 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$8 = {
                    contents: state.fieldsStatuses
                  };
                  var result$38 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$12, metadata, nextFieldsStatuses$8.contents.financialCodeOrientation, validators_financialCodeOrientation, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$8.contents);
                          newrecord.financialCodeOrientation = status;
                          return newrecord;
                        }));
                  if (result$38 !== undefined) {
                    nextFieldsStatuses$8.contents = result$38;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$12,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$12, metadata, nextFieldsStatuses$8.contents.financialCodes, state.submissionStatus, validators_financialCodes, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$8.contents);
                                    newrecord.financialCodes = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateIncludeFinancialCodeField */13 :
                  var nextInput$13 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$13,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$13.includeFinancialCode, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.includeFinancialCode = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdatePerfTypeField */14 :
                  var nextInput$14 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$14,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$14, metadata, state.fieldsStatuses.perfType, state.submissionStatus, validators_perfType, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.perfType = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateNumberTypeField */15 :
                  var nextInput$15 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$15,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$15, metadata, state.fieldsStatuses.numberType, state.submissionStatus, validators_numberType, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.numberType = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateValueField */16 :
                  var nextInput$16 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$16,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$16, metadata, state.fieldsStatuses.value, state.submissionStatus, validators_value, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.value = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateStocDateTypeField */17 :
                  var nextInput$17 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$17,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$17, metadata, state.fieldsStatuses.stocDateType, state.submissionStatus, validators_stocDateType, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.stocDateType = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateStocDateEndField */18 :
                  var nextInput$18 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$9 = {
                    contents: state.fieldsStatuses
                  };
                  var result$39 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$18, metadata, nextFieldsStatuses$9.contents.stocDateStart, validators_stocDateStart, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$9.contents);
                          newrecord.stocDateStart = status;
                          return newrecord;
                        }));
                  if (result$39 !== undefined) {
                    nextFieldsStatuses$9.contents = result$39;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$18,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$18, metadata, nextFieldsStatuses$9.contents.stocDateEnd, state.submissionStatus, validators_stocDateEnd, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$9.contents);
                                    newrecord.stocDateEnd = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateStocDateStartField */19 :
                  var nextInput$19 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$10 = {
                    contents: state.fieldsStatuses
                  };
                  var result$40 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$19, metadata, nextFieldsStatuses$10.contents.stocDateEnd, validators_stocDateEnd, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$10.contents);
                          newrecord.stocDateEnd = status;
                          return newrecord;
                        }));
                  if (result$40 !== undefined) {
                    nextFieldsStatuses$10.contents = result$40;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$19,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$19, metadata, nextFieldsStatuses$10.contents.stocDateStart, state.submissionStatus, validators_stocDateStart, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$10.contents);
                                    newrecord.stocDateStart = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateOrderTypeField */20 :
                  var nextInput$20 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$11 = {
                    contents: state.fieldsStatuses
                  };
                  var result$41 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$20, metadata, nextFieldsStatuses$11.contents.detentionMin, validators_detentionMin, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$11.contents);
                          newrecord.detentionMin = status;
                          return newrecord;
                        }));
                  if (result$41 !== undefined) {
                    nextFieldsStatuses$11.contents = result$41;
                  }
                  var result$42 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$20, metadata, nextFieldsStatuses$11.contents.detentionMax, validators_detentionMax, (function (status) {
                          var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$11.contents);
                          newrecord.detentionMax = status;
                          return newrecord;
                        }));
                  if (result$42 !== undefined) {
                    nextFieldsStatuses$11.contents = result$42;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$20,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$20, metadata, nextFieldsStatuses$11.contents.orderType, state.submissionStatus, validators_orderType, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(nextFieldsStatuses$11.contents);
                                    newrecord.orderType = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBudgetTypeField */21 :
                  var nextInput$21 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$21,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$21, metadata, state.fieldsStatuses.budgetType, state.submissionStatus, validators_budgetType, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.budgetType = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateActionTypeField */22 :
                  var nextInput$22 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$22,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$22, metadata, state.fieldsStatuses.actionType, state.submissionStatus, validators_actionType, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.actionType = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateActionLabelField */23 :
                  var nextInput$23 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$23,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$23, metadata, state.fieldsStatuses.actionLabel, state.submissionStatus, validators_actionLabel, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.actionLabel = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateActionIDField */24 :
                  var nextInput$24 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$24,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$24, metadata, state.fieldsStatuses.actionID, state.submissionStatus, validators_actionID, (function (status) {
                                    var newrecord = Caml_obj.obj_dup(state.fieldsStatuses);
                                    newrecord.actionID = status;
                                    return newrecord;
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */25 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */26 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */27 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateRequestType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateRequestTypeField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDealerWithAgents: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDealerWithAgentsField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDealerIds: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDealerIdsField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateIsPARCExit: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateIsPARCExitField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updatePriceType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdatePriceTypeField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDetentionMax: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDetentionMaxField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDetentionMin: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDetentionMinField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateExitParcDatePeriodEnd: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateExitParcDatePeriodEndField */7,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateExitParcDatePeriodStart: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateExitParcDatePeriodStartField */8,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEntryParcDatePeriodEnd: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEntryParcDatePeriodEndField */9,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEntryParcDatePeriodStart: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEntryParcDatePeriodStartField */10,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateFinancialCodeOrientation: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateFinancialCodeOrientationField */11,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateFinancialCodes: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateFinancialCodesField */12,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateIncludeFinancialCode: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateIncludeFinancialCodeField */13,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updatePerfType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdatePerfTypeField */14,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateNumberType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateNumberTypeField */15,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateValue: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateValueField */16,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateStocDateType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateStocDateTypeField */17,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateStocDateEnd: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateStocDateEndField */18,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateStocDateStart: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateStocDateStartField */19,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateOrderType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateOrderTypeField */20,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateBudgetType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBudgetTypeField */21,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateActionType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateActionTypeField */22,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateActionLabel: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateActionLabelField */23,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateActionID: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateActionIDField */24,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurRequestType: (function (param) {
              Curry._1(dispatch, /* BlurRequestTypeField */0);
            }),
          blurDealerWithAgents: (function (param) {
              Curry._1(dispatch, /* BlurDealerWithAgentsField */1);
            }),
          blurDealerIds: (function (param) {
              Curry._1(dispatch, /* BlurDealerIdsField */2);
            }),
          blurIsPARCExit: (function (param) {
              Curry._1(dispatch, /* BlurIsPARCExitField */3);
            }),
          blurPriceType: (function (param) {
              Curry._1(dispatch, /* BlurPriceTypeField */4);
            }),
          blurDetentionMax: (function (param) {
              Curry._1(dispatch, /* BlurDetentionMaxField */5);
            }),
          blurDetentionMin: (function (param) {
              Curry._1(dispatch, /* BlurDetentionMinField */6);
            }),
          blurExitParcDatePeriodEnd: (function (param) {
              Curry._1(dispatch, /* BlurExitParcDatePeriodEndField */7);
            }),
          blurExitParcDatePeriodStart: (function (param) {
              Curry._1(dispatch, /* BlurExitParcDatePeriodStartField */8);
            }),
          blurEntryParcDatePeriodEnd: (function (param) {
              Curry._1(dispatch, /* BlurEntryParcDatePeriodEndField */9);
            }),
          blurEntryParcDatePeriodStart: (function (param) {
              Curry._1(dispatch, /* BlurEntryParcDatePeriodStartField */10);
            }),
          blurFinancialCodeOrientation: (function (param) {
              Curry._1(dispatch, /* BlurFinancialCodeOrientationField */11);
            }),
          blurFinancialCodes: (function (param) {
              Curry._1(dispatch, /* BlurFinancialCodesField */12);
            }),
          blurIncludeFinancialCode: (function (param) {
              Curry._1(dispatch, /* BlurIncludeFinancialCodeField */13);
            }),
          blurPerfType: (function (param) {
              Curry._1(dispatch, /* BlurPerfTypeField */14);
            }),
          blurNumberType: (function (param) {
              Curry._1(dispatch, /* BlurNumberTypeField */15);
            }),
          blurValue: (function (param) {
              Curry._1(dispatch, /* BlurValueField */16);
            }),
          blurStocDateType: (function (param) {
              Curry._1(dispatch, /* BlurStocDateTypeField */17);
            }),
          blurStocDateEnd: (function (param) {
              Curry._1(dispatch, /* BlurStocDateEndField */18);
            }),
          blurStocDateStart: (function (param) {
              Curry._1(dispatch, /* BlurStocDateStartField */19);
            }),
          blurOrderType: (function (param) {
              Curry._1(dispatch, /* BlurOrderTypeField */20);
            }),
          blurBudgetType: (function (param) {
              Curry._1(dispatch, /* BlurBudgetTypeField */21);
            }),
          blurActionType: (function (param) {
              Curry._1(dispatch, /* BlurActionTypeField */22);
            }),
          blurActionLabel: (function (param) {
              Curry._1(dispatch, /* BlurActionLabelField */23);
            }),
          blurActionID: (function (param) {
              Curry._1(dispatch, /* BlurActionIDField */24);
            }),
          requestTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.requestType),
          dealerWithAgentsResult: Formality.exposeFieldResult(state.fieldsStatuses.dealerWithAgents),
          dealerIdsResult: Formality.exposeFieldResult(state.fieldsStatuses.dealerIds),
          isPARCExitResult: Formality.exposeFieldResult(state.fieldsStatuses.isPARCExit),
          priceTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.priceType),
          detentionMaxResult: Formality.exposeFieldResult(state.fieldsStatuses.detentionMax),
          detentionMinResult: Formality.exposeFieldResult(state.fieldsStatuses.detentionMin),
          exitParcDatePeriodEndResult: Formality.exposeFieldResult(state.fieldsStatuses.exitParcDatePeriodEnd),
          exitParcDatePeriodStartResult: Formality.exposeFieldResult(state.fieldsStatuses.exitParcDatePeriodStart),
          entryParcDatePeriodEndResult: Formality.exposeFieldResult(state.fieldsStatuses.entryParcDatePeriodEnd),
          entryParcDatePeriodStartResult: Formality.exposeFieldResult(state.fieldsStatuses.entryParcDatePeriodStart),
          financialCodeOrientationResult: Formality.exposeFieldResult(state.fieldsStatuses.financialCodeOrientation),
          financialCodesResult: Formality.exposeFieldResult(state.fieldsStatuses.financialCodes),
          includeFinancialCodeResult: Formality.exposeFieldResult(state.fieldsStatuses.includeFinancialCode),
          perfTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.perfType),
          numberTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.numberType),
          valueResult: Formality.exposeFieldResult(state.fieldsStatuses.value),
          stocDateTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.stocDateType),
          stocDateEndResult: Formality.exposeFieldResult(state.fieldsStatuses.stocDateEnd),
          stocDateStartResult: Formality.exposeFieldResult(state.fieldsStatuses.stocDateStart),
          orderTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.orderType),
          budgetTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.budgetType),
          actionTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.actionType),
          actionLabelResult: Formality.exposeFieldResult(state.fieldsStatuses.actionLabel),
          actionIDResult: Formality.exposeFieldResult(state.fieldsStatuses.actionID),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              var exit = 0;
              if (match.requestType || match.dealerWithAgents || match.dealerIds || match.isPARCExit || match.priceType || match.detentionMax || match.detentionMin || match.exitParcDatePeriodEnd || match.exitParcDatePeriodStart || match.entryParcDatePeriodEnd || match.entryParcDatePeriodStart || match.financialCodeOrientation || match.financialCodes || match.includeFinancialCode || match.perfType || match.numberType || match.value || match.stocDateType || match.stocDateEnd || match.stocDateStart || match.orderType || match.budgetType || match.actionType || match.actionLabel) {
                exit = 1;
              } else {
                if (!match.actionID) {
                  return false;
                }
                exit = 1;
              }
              if (exit === 1) {
                var exit$1 = 0;
                exit$1 = 2;
                if (exit$1 === 2) {
                  var exit$2 = 0;
                  exit$2 = 3;
                  if (exit$2 === 3) {
                    var exit$3 = 0;
                    exit$3 = 4;
                    if (exit$3 === 4) {
                      var exit$4 = 0;
                      exit$4 = 5;
                      if (exit$4 === 5) {
                        var exit$5 = 0;
                        exit$5 = 6;
                        if (exit$5 === 6) {
                          var exit$6 = 0;
                          exit$6 = 7;
                          if (exit$6 === 7) {
                            var exit$7 = 0;
                            exit$7 = 8;
                            if (exit$7 === 8) {
                              var exit$8 = 0;
                              exit$8 = 9;
                              if (exit$8 === 9) {
                                var exit$9 = 0;
                                exit$9 = 10;
                                if (exit$9 === 10) {
                                  var exit$10 = 0;
                                  exit$10 = 11;
                                  if (exit$10 === 11) {
                                    var exit$11 = 0;
                                    exit$11 = 12;
                                    if (exit$11 === 12) {
                                      var exit$12 = 0;
                                      exit$12 = 13;
                                      if (exit$12 === 13) {
                                        var exit$13 = 0;
                                        exit$13 = 14;
                                        if (exit$13 === 14) {
                                          return true;
                                        }
                                        
                                      }
                                      
                                    }
                                    
                                  }
                                  
                                }
                                
                              }
                              
                            }
                            
                          }
                          
                        }
                        
                      }
                      
                    }
                    
                  }
                  
                }
                
              }
              
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses, metadata);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */25);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */26);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */27);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */27,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */28);
            })
        };
}

var FlashForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var Types;

var initialState$1 = {
  actionID: "",
  actionLabel: "",
  actionType: /* NOTSET */3,
  budgetType: /* [] */0,
  orderType: /* NOTSET */10,
  stocDateStart: undefined,
  stocDateEnd: undefined,
  stocDateType: /* NOTSET */2,
  value: "",
  numberType: undefined,
  perfType: "",
  includeFinancialCode: false,
  financialCodes: /* [] */0,
  financialCodeOrientation: /* NOTSET */2,
  entryParcDatePeriodStart: undefined,
  entryParcDatePeriodEnd: undefined,
  exitParcDatePeriodStart: undefined,
  exitParcDatePeriodEnd: undefined,
  detentionMin: "",
  detentionMax: "",
  priceType: "PVRINVOICEAMOUNT",
  isPARCExit: undefined,
  dealerIds: /* [] */0,
  dealerWithAgents: /* [] */0,
  requestType: ""
};

export {
  Types ,
  validateDate ,
  FlashForm ,
  initialState$1 as initialState,
}
/* react Not a pure module */
