// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_array from "rescript/lib/es6/caml_array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Core from "@material-ui/core";
import * as Utils_Api$DvmAdminFrontendFr from "../../common/utils/Utils_Api.bs.js";
import * as App_Spinner$DvmAdminFrontendFr from "../../common/components/App_Spinner.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../common/App_Typography.bs.js";
import * as Context_Router$DvmAdminFrontendFr from "../../context/router/Context_Router.bs.js";
import * as App_Table_Result$DvmAdminFrontendFr from "../../common/components/table/App_Table_Result.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../common/types/App_Types_Result.bs.js";
import * as Messages_TopMenu$DvmAdminFrontendFr from "../../intl/messages/topMenu/Messages_TopMenu.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../context/user/User_Types_Utils.bs.js";
import * as Bonuses_Search_Api$DvmAdminFrontendFr from "./Bonuses_Search_Api.bs.js";
import * as Bonuses_Search_Form$DvmAdminFrontendFr from "./Bonuses_Search_Form.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../common/components/table/App_Table_Pagination.bs.js";
import * as Bonuses_Search_Table$DvmAdminFrontendFr from "./Bonuses_Search_Table.bs.js";
import * as Bonuses_Search_Filter$DvmAdminFrontendFr from "./Bonuses_Search_Filter.bs.js";
import * as Bonuses_Search_Footer$DvmAdminFrontendFr from "./Bonuses_Search_Footer.bs.js";
import * as Messages_Bonuses_List$DvmAdminFrontendFr from "../../intl/messages/bonuses/Messages_Bonuses_List.bs.js";
import * as Bonuses_Search_Modal_Form$DvmAdminFrontendFr from "./filterModal/filterPages/Bonuses_Search_Modal_Form.bs.js";
import * as Bonuses_Search_Modal_VO_Form$DvmAdminFrontendFr from "./filterModal/filterPages/Bonuses_Search_Modal_VO_Form.bs.js";
import * as Order_Detail_Types_Order_Type$DvmAdminFrontendFr from "../order/detail/Order_Detail_Types_Order_Type.bs.js";
import * as Bonuses_Search_Modal_Additional_Form$DvmAdminFrontendFr from "./filterModal/filterPages/Bonuses_Search_Modal_Additional_Form.bs.js";
import * as Bonuses_Search_Modal_Additional_Second_Form$DvmAdminFrontendFr from "./filterModal/filterPages/Bonuses_Search_Modal_Additional_Second_Form.bs.js";

function Bonuses_Search_Page(Props) {
  var operation = Props.operation;
  var performanceOpt = Props.performance;
  var performance = performanceOpt !== undefined ? performanceOpt : false;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = React.useState(function () {
        return /* [] */0;
      });
  var setConfirmedOrderType = match[1];
  var match$1 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var selectedRole = match$1[0].selectedRole;
  var match$2 = Curry._1(Context_Router$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$3 = match$2[0];
  var bonusesFilter = match$3.bonusesFilter;
  var match$4 = React.useState(function () {
        
      });
  var setSelectedRowsMap = match$4[1];
  var selectedRowsMap = match$4[0];
  var match$5 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setTablePage = match$5[1];
  var tablePage = match$5[0];
  var match$6 = React.useState(function () {
        
      });
  var setSubmissionCallbacks = match$6[1];
  var submissionCallbacks = match$6[0];
  var match$7 = React.useState(function () {
        
      });
  var setSubmissionCallbacksModal = match$7[1];
  var submissionCallbacksModal = match$7[0];
  var match$8 = React.useState(function () {
        
      });
  var setSubmissionCallbacksModalAdditional = match$8[1];
  var submissionCallbacksModalAdditional = match$8[0];
  var match$9 = React.useState(function () {
        
      });
  var setSubmissionCallbacksModalAdditionalSecond = match$9[1];
  var submissionCallbacksModalAdditionalSecond = match$9[0];
  var match$10 = React.useState(function () {
        
      });
  var setSubmissionCallbacksModalVO = match$10[1];
  var submissionCallbacksModalVO = match$10[0];
  var match$11 = React.useState(function () {
        return /* [] */0;
      });
  var setFormList = match$11[1];
  var formList = match$11[0];
  var match$12 = React.useState(function () {
        return /* [] */0;
      });
  var setFormModalList = match$12[1];
  var formModalList = match$12[0];
  var match$13 = React.useState(function () {
        return /* [] */0;
      });
  var setFormModalAdditionalList = match$13[1];
  var formModalAdditionalList = match$13[0];
  var match$14 = React.useState(function () {
        return /* [] */0;
      });
  var setFormModalAdditionalSecondList = match$14[1];
  var formModalAdditionalSecondList = match$14[0];
  var match$15 = React.useState(function () {
        return /* [] */0;
      });
  var setFormModalVOList = match$15[1];
  var formModalVOList = match$15[0];
  var match$16 = React.useState(function () {
        return /* [] */0;
      });
  var setParamsList = match$16[1];
  var match$17 = React.useState(function () {
        return {
                page: 0,
                rowsPerPage: Caml_array.get(App_Table_Pagination$DvmAdminFrontendFr.options, 0),
                headers: Bonuses_Search_Table$DvmAdminFrontendFr.headersConfiguration(selectedRole, operation),
                filterQueryParams: "",
                additionalQueryParams: /* [] */0
              };
      });
  var setFetchRequest = match$17[1];
  var fetchRequest = match$17[0];
  var match$18 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$19 = match$18[0];
  var userManager = match$19.userManager;
  var selectedRole$1 = match$19.selectedRole;
  var fetchData = function (submissionCallbacks, submissionCallbacksModal, submissionCallbacksModalAdditional, submissionCallbacksModalAdditionalSecond, submissionCallbacksModalVO, fetchRequest) {
    if (operation === /* Manage */0) {
      Bonuses_Search_Api$DvmAdminFrontendFr.fetchBonusesManageSearch(submissionCallbacks, userManager, setTablePage, Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, fetchRequest), selectedRole$1);
    } else {
      Bonuses_Search_Api$DvmAdminFrontendFr.fetchBonusesSearch(submissionCallbacks, submissionCallbacksModal, submissionCallbacksModalAdditional, submissionCallbacksModalAdditionalSecond, submissionCallbacksModalVO, userManager, setTablePage, Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, fetchRequest), selectedRole$1);
    }
    Curry._1(setFetchRequest, (function (param) {
            return fetchRequest;
          }));
    Curry._1(setSelectedRowsMap, (function (param) {
            
          }));
  };
  React.useEffect((function () {
          if (operation && submissionCallbacks !== undefined && submissionCallbacksModal !== undefined && submissionCallbacksModalAdditional !== undefined && submissionCallbacksModalAdditionalSecond !== undefined && submissionCallbacksModalVO !== undefined) {
            Curry._1(setParamsList, (function (param) {
                    return Belt_List.concatMany([
                                formList,
                                formModalList,
                                formModalAdditionalList,
                                formModalAdditionalSecondList,
                                formModalVOList
                              ]);
                  }));
            fetchData(submissionCallbacks, submissionCallbacksModal, submissionCallbacksModalAdditional, submissionCallbacksModalAdditionalSecond, submissionCallbacksModalVO, {
                  page: 0,
                  rowsPerPage: fetchRequest.rowsPerPage,
                  headers: fetchRequest.headers,
                  filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap(Belt_List.concatMany([
                                    formList,
                                    formModalList,
                                    formModalAdditionalList,
                                    formModalAdditionalSecondList,
                                    formModalVOList
                                  ]), (function (queryParam) {
                                  return Belt_Option.map(queryParam, (function (x) {
                                                return x.en;
                                              }));
                                }))), ";", (function (queryParam) {
                          return queryParam;
                        })),
                  additionalQueryParams: /* [] */0
                });
          }
          
        }), [formModalVOList]);
  React.useEffect((function () {
          if (operation || !(submissionCallbacks !== undefined && submissionCallbacksModal !== undefined)) {
            
          } else {
            Curry._1(setParamsList, (function (param) {
                    return formList;
                  }));
            fetchData(submissionCallbacks, submissionCallbacksModal, undefined, undefined, undefined, {
                  page: 0,
                  rowsPerPage: fetchRequest.rowsPerPage,
                  headers: fetchRequest.headers,
                  filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap(formList, (function (queryParam) {
                                  return Belt_Option.map(queryParam, (function (x) {
                                                return x.en;
                                              }));
                                }))), ";", (function (queryParam) {
                          return queryParam;
                        })),
                  additionalQueryParams: /* [] */0
                });
          }
        }), [formList]);
  var updateSelectedRows = function (row, action) {
    Curry._1(setSelectedRowsMap, (function (selectedRowsMap) {
            if (action === "remove") {
              return Belt_MapString.remove(selectedRowsMap, row.actionId + row.orderId);
            } else {
              return Belt_MapString.set(selectedRowsMap, row.actionId + row.orderId, row);
            }
          }));
  };
  var form = Bonuses_Search_Form$DvmAdminFrontendFr.TableFilterForm.useForm(performance ? Belt_Option.mapWithDefault(bonusesFilter, {
              dr: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.dr,
              dealerId: {
                hd: "213700",
                tl: /* [] */0
              },
              orderId: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.orderId,
              budgetType: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.budgetType,
              actionType: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.actionType,
              actionId: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.actionId,
              status: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.status,
              model: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.model,
              version: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.version,
              budgetYear: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.budgetYear,
              showBonusesPlusHistory: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.showBonusesPlusHistory,
              maxAmount: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.maxAmount,
              isOverMaximum: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.isOverMaximum,
              orderType: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.orderType,
              siretPERF: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.siretPERF,
              showDealerHistory: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.showDealerHistory,
              clientTypes: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.clientTypes,
              saleTypes: Bonuses_Search_Form$DvmAdminFrontendFr.initialState.saleTypes
            }, (function (x) {
                return {
                        dr: x.dr,
                        dealerId: {
                          hd: "213700",
                          tl: /* [] */0
                        },
                        orderId: x.orderId,
                        budgetType: x.budgetType,
                        actionType: x.actionType,
                        actionId: x.actionId,
                        status: x.status,
                        model: x.model,
                        version: x.version,
                        budgetYear: x.budgetYear,
                        showBonusesPlusHistory: x.showBonusesPlusHistory,
                        maxAmount: x.maxAmount,
                        isOverMaximum: x.isOverMaximum,
                        orderType: x.orderType,
                        siretPERF: x.siretPERF,
                        showDealerHistory: x.showDealerHistory,
                        clientTypes: x.clientTypes,
                        saleTypes: x.saleTypes
                      };
              })) : (
          operation ? Belt_Option.getWithDefault(bonusesFilter, Bonuses_Search_Form$DvmAdminFrontendFr.initialState) : Belt_Option.mapWithDefault(match$3.bonusesPERFFilter, Bonuses_Search_Form$DvmAdminFrontendFr.initialState, (function (perfFilter) {
                    if (typeof selectedRole$1 !== "number") {
                      return perfFilter;
                    }
                    switch (selectedRole$1) {
                      case /* CompanyManager */2 :
                          return {
                                  dr: perfFilter.dr,
                                  dealerId: perfFilter.dealerId,
                                  orderId: perfFilter.orderId,
                                  budgetType: "AVS",
                                  actionType: perfFilter.actionType,
                                  actionId: perfFilter.actionId,
                                  status: perfFilter.status,
                                  model: perfFilter.model,
                                  version: perfFilter.version,
                                  budgetYear: perfFilter.budgetYear,
                                  showBonusesPlusHistory: perfFilter.showBonusesPlusHistory,
                                  maxAmount: perfFilter.maxAmount,
                                  isOverMaximum: perfFilter.isOverMaximum,
                                  orderType: perfFilter.orderType,
                                  siretPERF: perfFilter.siretPERF,
                                  showDealerHistory: perfFilter.showDealerHistory,
                                  clientTypes: perfFilter.clientTypes,
                                  saleTypes: perfFilter.saleTypes
                                };
                      case /* LldManager */5 :
                          return {
                                  dr: perfFilter.dr,
                                  dealerId: perfFilter.dealerId,
                                  orderId: perfFilter.orderId,
                                  budgetType: "LLD",
                                  actionType: perfFilter.actionType,
                                  actionId: perfFilter.actionId,
                                  status: perfFilter.status,
                                  model: perfFilter.model,
                                  version: perfFilter.version,
                                  budgetYear: perfFilter.budgetYear,
                                  showBonusesPlusHistory: perfFilter.showBonusesPlusHistory,
                                  maxAmount: perfFilter.maxAmount,
                                  isOverMaximum: perfFilter.isOverMaximum,
                                  orderType: perfFilter.orderType,
                                  siretPERF: perfFilter.siretPERF,
                                  showDealerHistory: perfFilter.showDealerHistory,
                                  clientTypes: perfFilter.clientTypes,
                                  saleTypes: perfFilter.saleTypes
                                };
                      case /* BigAccountManager */6 :
                          return {
                                  dr: perfFilter.dr,
                                  dealerId: perfFilter.dealerId,
                                  orderId: perfFilter.orderId,
                                  budgetType: "PROT",
                                  actionType: perfFilter.actionType,
                                  actionId: perfFilter.actionId,
                                  status: perfFilter.status,
                                  model: perfFilter.model,
                                  version: perfFilter.version,
                                  budgetYear: perfFilter.budgetYear,
                                  showBonusesPlusHistory: perfFilter.showBonusesPlusHistory,
                                  maxAmount: perfFilter.maxAmount,
                                  isOverMaximum: perfFilter.isOverMaximum,
                                  orderType: perfFilter.orderType,
                                  siretPERF: perfFilter.siretPERF,
                                  showDealerHistory: perfFilter.showDealerHistory,
                                  clientTypes: perfFilter.clientTypes,
                                  saleTypes: perfFilter.saleTypes
                                };
                      case /* Advisor */0 :
                      case /* PreDirector */1 :
                      case /* SeniorCompanyManager */3 :
                      case /* AcountManager */4 :
                      case /* Dealer */7 :
                      case /* MRR */8 :
                      case /* NoRole */9 :
                          return perfFilter;
                      
                    }
                  }))
        ), {
        operation: operation
      }, (function (output, submissionCallbacks) {
          var paramsList = Belt_List.concat(Belt_List.concat({
                    hd: output.dr,
                    tl: {
                      hd: output.dealerId,
                      tl: {
                        hd: output.orderId,
                        tl: {
                          hd: output.budgetType,
                          tl: {
                            hd: operation === /* Manage */0 ? undefined : output.actionType,
                            tl: {
                              hd: output.actionId,
                              tl: {
                                hd: output.siretPERF,
                                tl: {
                                  hd: output.status,
                                  tl: {
                                    hd: output.model,
                                    tl: {
                                      hd: output.version,
                                      tl: {
                                        hd: output.budgetYear,
                                        tl: {
                                          hd: output.maxAmount,
                                          tl: {
                                            hd: output.isOverMaximum,
                                            tl: {
                                              hd: output.showDealerHistory,
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }, User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole$1) === /* Spain */1 ? ({
                        hd: output.clientTypes,
                        tl: {
                          hd: output.saleTypes,
                          tl: /* [] */0
                        }
                      }) : /* [] */0), User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole$1) === /* France */0 ? ({
                    hd: output.orderType,
                    tl: /* [] */0
                  }) : /* [] */0);
          Curry._1(setSubmissionCallbacks, (function (param) {
                  return submissionCallbacks;
                }));
          Curry._1(setFormList, (function (param) {
                  return paramsList;
                }));
        }));
  React.useEffect((function () {
          Curry._1(setConfirmedOrderType, (function (param) {
                  return Belt_List.map(form.input.orderType, (function (x) {
                                return Belt_Option.getWithDefault(Order_Detail_Types_Order_Type$DvmAdminFrontendFr.fromString(x), /* NOTSET */11);
                              }));
                }));
        }), [formList]);
  var formModal = Bonuses_Search_Modal_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Belt_Option.getWithDefault(match$3.bonusesModalFilter, Bonuses_Search_Modal_Form$DvmAdminFrontendFr.initialState), {
        orderType: form.input.orderType
      }, (function (output, submissionCallbacks) {
          var paramsList_0 = output.orderDateStart;
          var paramsList_1 = {
            hd: output.registrationDateStart,
            tl: {
              hd: output.customerInvoiceStart,
              tl: {
                hd: output.deliveryDateStart,
                tl: {
                  hd: output.renaultInvoiceDateStart,
                  tl: {
                    hd: output.delivered,
                    tl: {
                      hd: output.customerName,
                      tl: {
                        hd: output.salesmanName,
                        tl: {
                          hd: output.agentCode,
                          tl: {
                            hd: output.entryDateParcStart,
                            tl: {
                              hd: output.exitDateParcStart,
                              tl: {
                                hd: output.parcAutomatic,
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          };
          var paramsList = {
            hd: paramsList_0,
            tl: paramsList_1
          };
          Curry._1(setSubmissionCallbacksModal, (function (param) {
                  return submissionCallbacks;
                }));
          Curry._1(setFormModalList, (function (param) {
                  return paramsList;
                }));
        }));
  var formAdditional = Bonuses_Search_Modal_Additional_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Belt_Option.getWithDefault(match$3.bonusesModalAdditionalFilter, Bonuses_Search_Modal_Additional_Form$DvmAdminFrontendFr.initialState), {
        budgetType: form.input.budgetType
      }, (function (output, submissionCallbacks) {
          var paramsList_0 = output.dealerName;
          var paramsList_1 = {
            hd: output.dealerGroup,
            tl: {
              hd: output.keyAccountManagerID,
              tl: {
                hd: output.sectorManagerID,
                tl: {
                  hd: output.sirenClientFinalLLDName,
                  tl: {
                    hd: output.loueurID,
                    tl: {
                      hd: output.loueurName,
                      tl: {
                        hd: output.contractID,
                        tl: {
                          hd: output.contractName,
                          tl: {
                            hd: output.mainContractSIREN,
                            tl: {
                              hd: output.siren,
                              tl: {
                                hd: output.modelID,
                                tl: {
                                  hd: output.versionID,
                                  tl: {
                                    hd: output.categoryLabel,
                                    tl: {
                                      hd: output.brand,
                                      tl: {
                                        hd: output.phase,
                                        tl: {
                                          hd: output.vehicleType,
                                          tl: {
                                            hd: output.engineType,
                                            tl: {
                                              hd: output.codeAPE,
                                              tl: {
                                                hd: output.customerName,
                                                tl: {
                                                  hd: output.firstName,
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          };
          var paramsList = {
            hd: paramsList_0,
            tl: paramsList_1
          };
          Curry._1(setSubmissionCallbacksModalAdditional, (function (param) {
                  return submissionCallbacks;
                }));
          Curry._1(setFormModalAdditionalList, (function (param) {
                  return paramsList;
                }));
        }));
  var formAdditionalSecond = Bonuses_Search_Modal_Additional_Second_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Belt_Option.getWithDefault(match$3.bonusesModalAdditionalSecondFilter, Bonuses_Search_Modal_Additional_Second_Form$DvmAdminFrontendFr.initialState), (function (output, submissionCallbacks) {
          var paramsList_0 = output.invoiceDateStart;
          var paramsList_1 = {
            hd: output.preinvoiceDateStart,
            tl: {
              hd: output.preinvoiceNumber,
              tl: {
                hd: output.orderStatus,
                tl: /* [] */0
              }
            }
          };
          var paramsList = {
            hd: paramsList_0,
            tl: paramsList_1
          };
          Curry._1(setSubmissionCallbacksModalAdditionalSecond, (function (param) {
                  return submissionCallbacks;
                }));
          Curry._1(setFormModalAdditionalSecondList, (function (param) {
                  return paramsList;
                }));
        }));
  var formModalVO = Bonuses_Search_Modal_VO_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Belt_Option.getWithDefault(match$3.bonusesModalVOFilter, Bonuses_Search_Modal_VO_Form$DvmAdminFrontendFr.initialState), (function (output, submissionCallbacks) {
          var paramsList_0 = output.brandUsedCar;
          var paramsList_1 = {
            hd: output.brandEmpty,
            tl: {
              hd: output.modelUsedCar,
              tl: {
                hd: output.modelEmpty,
                tl: {
                  hd: output.vinUsedCar,
                  tl: {
                    hd: output.vinEmpty,
                    tl: {
                      hd: output.usedCarPoliceNumber,
                      tl: {
                        hd: output.policeNumberEmpty,
                        tl: {
                          hd: output.usedCarLastRegistrationDateStart,
                          tl: {
                            hd: output.registrationDateEmpty,
                            tl: {
                              hd: output.usedCarFirstReleaseDateStart,
                              tl: {
                                hd: output.releaseDateEmpty,
                                tl: {
                                  hd: output.usedCarRegistrationID,
                                  tl: {
                                    hd: output.registrationIDEmpty,
                                    tl: {
                                      hd: output.usedCarStatus,
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          };
          var paramsList = {
            hd: paramsList_0,
            tl: paramsList_1
          };
          Curry._1(setSubmissionCallbacksModalVO, (function (param) {
                  return submissionCallbacks;
                }));
          Curry._1(setFormModalVOList, (function (param) {
                  return paramsList;
                }));
        }));
  var fetchManage = function (param) {
    Curry._1(form.submit, undefined);
    Curry._1(formModal.submit, undefined);
    Curry._1(formAdditional.submit, undefined);
    Curry._1(formAdditionalSecond.submit, undefined);
    Curry._1(formModalVO.submit, undefined);
  };
  React.useEffect((function () {
          if (operation === /* Manage */0) {
            fetchManage(undefined);
          }
          
        }), []);
  React.useEffect((function () {
          if (Belt_Option.isSome(Belt_List.getBy(form.input.orderType, (function (x) {
                        switch (x) {
                          case "CL" :
                          case "EP" :
                          case "ES" :
                          case "PG" :
                          case "RC" :
                          case "RP" :
                          case "RR" :
                          case "RS" :
                          case "VD" :
                          case "VE" :
                              return true;
                          default:
                            return false;
                        }
                      })))) {
            Curry._2(formAdditional.updateSirenClientFinalLLDName, (function (input, sirenClientFinalLLDName) {
                    var newrecord = Caml_obj.obj_dup(input);
                    newrecord.sirenClientFinalLLDName = sirenClientFinalLLDName;
                    return newrecord;
                  }), "");
            Curry._2(form.updateBudgetType, (function (input, budgetType) {
                    return {
                            dr: input.dr,
                            dealerId: input.dealerId,
                            orderId: input.orderId,
                            budgetType: budgetType,
                            actionType: input.actionType,
                            actionId: input.actionId,
                            status: input.status,
                            model: input.model,
                            version: input.version,
                            budgetYear: input.budgetYear,
                            showBonusesPlusHistory: input.showBonusesPlusHistory,
                            maxAmount: input.maxAmount,
                            isOverMaximum: input.isOverMaximum,
                            orderType: input.orderType,
                            siretPERF: input.siretPERF,
                            showDealerHistory: input.showDealerHistory,
                            clientTypes: input.clientTypes,
                            saleTypes: input.saleTypes
                          };
                  }), "");
          }
          if (Belt_List.has(form.input.orderType, "SO", (function (a, b) {
                    return a === b;
                  }))) {
            Curry._2(formModal.updateCustomerName, (function (input, customerName) {
                    var newrecord = Caml_obj.obj_dup(input);
                    newrecord.customerName = customerName;
                    return newrecord;
                  }), "");
          }
          
        }), [form.input.orderType]);
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.forError(tablePage, (function (error) {
                  Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                            message: {
                              TAG: /* RestError */2,
                              _0: error
                            }
                          }));
                }));
        }), [tablePage]);
  var tmp;
  tmp = typeof tablePage === "number" ? (
      tablePage === /* NotStarted */0 ? null : React.createElement(App_Spinner$DvmAdminFrontendFr.make, {})
    ) : (
      tablePage.TAG === /* Ok */0 ? React.createElement(App_Table_Result$DvmAdminFrontendFr.make, {
              number: tablePage._0.total,
              message: Messages_Bonuses_List$DvmAdminFrontendFr.resultInfo
            }) : null
    );
  return React.createElement(React.Fragment, undefined, React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                  style: {
                    fontWeight: "600"
                  },
                  children: intl.formatMessage(operation === /* View */1 ? Messages_TopMenu$DvmAdminFrontendFr.bonusesView : Messages_TopMenu$DvmAdminFrontendFr.bonusesEdit),
                  variant: "h3"
                }), React.createElement(Bonuses_Search_Filter$DvmAdminFrontendFr.make, {
                  form: form,
                  formModal: formModal,
                  formAdditional: formAdditional,
                  formAdditionalSecond: formAdditionalSecond,
                  formModalVO: formModalVO,
                  operation: operation
                }), React.createElement(Core.Box, {
                  children: null
                }, tmp, React.createElement(Bonuses_Search_Table$DvmAdminFrontendFr.make, {
                      tablePage: tablePage,
                      fetchRequest: fetchRequest,
                      fetchData: fetchData,
                      updateSelectedRows: updateSelectedRows,
                      selectedRowsMap: selectedRowsMap,
                      selectedRole: selectedRole$1,
                      operation: operation,
                      paramsList: match$16[0],
                      confirmedOrderType: match[0]
                    }), tablePage === 0 ? React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                        gutterBottom: true,
                        style: {
                          fontWeight: "600",
                          paddingTop: "100px",
                          textAlign: "center"
                        },
                        children: intl.formatMessage(Messages_Bonuses_List$DvmAdminFrontendFr.searchForBonuses),
                        variant: "bodyBig"
                      }) : null), Belt_MapString.size(selectedRowsMap) > 0 ? React.createElement(Bonuses_Search_Footer$DvmAdminFrontendFr.make, {
                    selectedRowsMap: selectedRowsMap,
                    fetch: fetchManage
                  }) : null);
}

var Api;

var Table;

var Filter;

var Types;

var Form;

var FormModal;

var FormModalAdditional;

var FormModalAdditionalSecond;

var FormModalVO;

var make = Bonuses_Search_Page;

export {
  Api ,
  Table ,
  Filter ,
  Types ,
  Form ,
  FormModal ,
  FormModalAdditional ,
  FormModalAdditionalSecond ,
  FormModalVO ,
  make ,
}
/* react Not a pure module */
