// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Button.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Button.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../../intl/messages/orders/Messages_Orders_Detail.bs.js";

function Order_Detail_Reselling_Confirm_Modal(Props) {
  var onClose = Props.onClose;
  var onOk = Props.onOk;
  var createReselling = Props.createReselling;
  var intl = ReactIntl.useIntl();
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(createReselling ? Messages_Orders_Detail$DvmAdminFrontendFr.makeResellingConfirm : Messages_Orders_Detail$DvmAdminFrontendFr.areYouSureNoReselling),
              minWidth: "700px",
              children: React.createElement(Core.Grid, {
                    children: null,
                    container: true,
                    direction: "column",
                    item: true,
                    spacing: 2,
                    style: {
                      overflow: "hidden"
                    }
                  }, createReselling ? null : React.createElement(Core.Grid, {
                          children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                color: "error",
                                style: {
                                  fontWeight: "600",
                                  maxWidth: "500px",
                                  textAlign: "center"
                                },
                                children: intl.formatMessage(Messages_Orders_Detail$DvmAdminFrontendFr.noResellingWarning),
                                variant: "subtitle"
                              }),
                          container: true,
                          item: true,
                          justifyContent: "center"
                        }), React.createElement(Core.Grid, {
                        children: null,
                        container: true,
                        item: true,
                        justifyContent: "center",
                        spacing: 9
                      }, React.createElement(Core.Grid, {
                            children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                  onClick: onClose,
                                  label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.cancel),
                                  color: "primaryBorder",
                                  size: "medium"
                                }),
                            item: true
                          }), React.createElement(Core.Grid, {
                            children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                  onClick: onOk,
                                  label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                  color: "primary",
                                  size: "medium"
                                }),
                            item: true
                          }))),
              titleMaxWidth: "100vw"
            });
}

var make = Order_Detail_Reselling_Confirm_Modal;

export {
  make ,
}
/* react Not a pure module */
