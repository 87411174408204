// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.bs.js";
import * as Core from "@material-ui/core";
import * as User_Context$DvmAdminFrontendFr from "../../../../../context/user/User_Context.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Result.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../common/styles/App_Styles_Common.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Order_Detail_Reselling_Edits_Api$DvmAdminFrontendFr from "./Order_Detail_Reselling_Edits_Api.bs.js";
import * as Order_Detail_Reselling_Edits_Inputs_CompanyFormInput$DvmAdminFrontendFr from "./Order_Detail_Reselling_Edits_Inputs_CompanyFormInput.bs.js";

function Order_Detail_Reselling_Edits_Inputs_Sirets(Props) {
  var form = Props.form;
  var siretActiveResult = Props.siretActiveResult;
  var setSiretActiveResult = Props.setSiretActiveResult;
  var siretBudgetLLD = Props.siretBudgetLLD;
  var setSiretBudgetLLD = Props.setSiretBudgetLLD;
  var siretExists = Props.siretExists;
  var setSiretExists = Props.setSiretExists;
  var finalCustomerExists = Props.finalCustomerExists;
  var setFinalCustomerExists = Props.setFinalCustomerExists;
  var setFormConfirmDisable = Props.setFormConfirmDisable;
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.mapWithDefault(siretActiveResult, undefined, (function (x) {
                  Curry._2(form.updateSiretActive, (function (input, siretActive) {
                          return {
                                  orderType: input.orderType,
                                  siret: input.siret,
                                  finalCustomer: input.finalCustomer,
                                  shortCustomerName: input.shortCustomerName,
                                  customerFirstName: input.customerFirstName,
                                  orderDate: input.orderDate,
                                  registrationDate: input.registrationDate,
                                  deliveryDate: input.deliveryDate,
                                  invoiceNumber: input.invoiceNumber,
                                  invoiceClient: input.invoiceClient,
                                  customerDiscount: input.customerDiscount,
                                  agentCode: input.agentCode,
                                  salesmanName: input.salesmanName,
                                  easyPack: input.easyPack,
                                  siretExists: input.siretExists,
                                  siretActive: siretActive,
                                  siretBudgetLLD: input.siretBudgetLLD,
                                  finalCustomerExists: input.finalCustomerExists
                                };
                        }), x);
                }));
        }), [siretActiveResult]);
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.mapWithDefault(siretBudgetLLD, undefined, (function (x) {
                  Curry._2(form.updateSiretBudgetLLD, (function (input, siretBudgetLLD) {
                          return {
                                  orderType: input.orderType,
                                  siret: input.siret,
                                  finalCustomer: input.finalCustomer,
                                  shortCustomerName: input.shortCustomerName,
                                  customerFirstName: input.customerFirstName,
                                  orderDate: input.orderDate,
                                  registrationDate: input.registrationDate,
                                  deliveryDate: input.deliveryDate,
                                  invoiceNumber: input.invoiceNumber,
                                  invoiceClient: input.invoiceClient,
                                  customerDiscount: input.customerDiscount,
                                  agentCode: input.agentCode,
                                  salesmanName: input.salesmanName,
                                  easyPack: input.easyPack,
                                  siretExists: input.siretExists,
                                  siretActive: input.siretActive,
                                  siretBudgetLLD: siretBudgetLLD,
                                  finalCustomerExists: input.finalCustomerExists
                                };
                        }), x);
                }));
        }), [siretBudgetLLD]);
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.mapWithDefault(siretExists, undefined, (function (exists) {
                  if (exists) {
                    Order_Detail_Reselling_Edits_Api$DvmAdminFrontendFr.checkSiretActive(userManager, setSiretActiveResult, selectedRole, form.input.siret);
                    Order_Detail_Reselling_Edits_Api$DvmAdminFrontendFr.checkSiretBudgetLLD(userManager, setSiretBudgetLLD, selectedRole, form.input.siret);
                  }
                  Curry._2(form.updateSiretExists, (function (input, siretExists) {
                          return {
                                  orderType: input.orderType,
                                  siret: input.siret,
                                  finalCustomer: input.finalCustomer,
                                  shortCustomerName: input.shortCustomerName,
                                  customerFirstName: input.customerFirstName,
                                  orderDate: input.orderDate,
                                  registrationDate: input.registrationDate,
                                  deliveryDate: input.deliveryDate,
                                  invoiceNumber: input.invoiceNumber,
                                  invoiceClient: input.invoiceClient,
                                  customerDiscount: input.customerDiscount,
                                  agentCode: input.agentCode,
                                  salesmanName: input.salesmanName,
                                  easyPack: input.easyPack,
                                  siretExists: siretExists,
                                  siretActive: input.siretActive,
                                  siretBudgetLLD: input.siretBudgetLLD,
                                  finalCustomerExists: input.finalCustomerExists
                                };
                        }), exists);
                }));
        }), [siretExists]);
  React.useEffect((function () {
          App_Types_Result$DvmAdminFrontendFr.mapWithDefault(finalCustomerExists, undefined, (function (exists) {
                  Curry._2(form.updateFinalCustomerExists, (function (input, finalCustomerExists) {
                          return {
                                  orderType: input.orderType,
                                  siret: input.siret,
                                  finalCustomer: input.finalCustomer,
                                  shortCustomerName: input.shortCustomerName,
                                  customerFirstName: input.customerFirstName,
                                  orderDate: input.orderDate,
                                  registrationDate: input.registrationDate,
                                  deliveryDate: input.deliveryDate,
                                  invoiceNumber: input.invoiceNumber,
                                  invoiceClient: input.invoiceClient,
                                  customerDiscount: input.customerDiscount,
                                  agentCode: input.agentCode,
                                  salesmanName: input.salesmanName,
                                  easyPack: input.easyPack,
                                  siretExists: input.siretExists,
                                  siretActive: input.siretActive,
                                  siretBudgetLLD: input.siretBudgetLLD,
                                  finalCustomerExists: finalCustomerExists
                                };
                        }), exists);
                }));
        }), [finalCustomerExists]);
  var checkSiret = function (siret) {
    if (form.input.siret.length > 0) {
      return Order_Detail_Reselling_Edits_Api$DvmAdminFrontendFr.checkSiretExists(userManager, setSiretExists, selectedRole, siret);
    }
    
  };
  var checkFinalCustomer = function (finalCustomer) {
    if (form.input.finalCustomer.length > 0) {
      return Order_Detail_Reselling_Edits_Api$DvmAdminFrontendFr.checkSirenExists(userManager, setFinalCustomerExists, selectedRole, finalCustomer);
    }
    
  };
  var debounceSiret = ReactDebounce.useDebounced(1000, checkSiret);
  var debounceFinalCustomer = ReactDebounce.useDebounced(1000, checkFinalCustomer);
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                  children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                        show: App_Types_Result$DvmAdminFrontendFr.isPending(siretExists) || App_Types_Result$DvmAdminFrontendFr.isPending(siretActiveResult) || App_Types_Result$DvmAdminFrontendFr.isPending(siretBudgetLLD),
                        children: React.createElement(Order_Detail_Reselling_Edits_Inputs_CompanyFormInput$DvmAdminFrontendFr.make, {
                              onChange: (function (siret) {
                                  Curry._1(debounceSiret, siret);
                                  Curry._2(form.updateSiret, (function (input, siret) {
                                          return {
                                                  orderType: input.orderType,
                                                  siret: siret,
                                                  finalCustomer: input.finalCustomer,
                                                  shortCustomerName: input.shortCustomerName,
                                                  customerFirstName: input.customerFirstName,
                                                  orderDate: input.orderDate,
                                                  registrationDate: input.registrationDate,
                                                  deliveryDate: input.deliveryDate,
                                                  invoiceNumber: input.invoiceNumber,
                                                  invoiceClient: input.invoiceClient,
                                                  customerDiscount: input.customerDiscount,
                                                  agentCode: input.agentCode,
                                                  salesmanName: input.salesmanName,
                                                  easyPack: input.easyPack,
                                                  siretExists: input.siretExists,
                                                  siretActive: input.siretActive,
                                                  siretBudgetLLD: input.siretBudgetLLD,
                                                  finalCustomerExists: input.finalCustomerExists
                                                };
                                        }), siret.trim());
                                }),
                              value: form.input.siret,
                              validationResult: form.siretResult,
                              companies: match$2[0],
                              setCompanies: match$2[1],
                              className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
                              companyType: "siret",
                              onBlur: (function (param) {
                                  Curry._1(setFormConfirmDisable, (function (param) {
                                          return false;
                                        }));
                                  checkSiret(form.input.siret);
                                }),
                              onFocus: (function (param) {
                                  Curry._1(setFormConfirmDisable, (function (param) {
                                          return true;
                                        }));
                                })
                            })
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                        show: App_Types_Result$DvmAdminFrontendFr.isPending(finalCustomerExists) || App_Types_Result$DvmAdminFrontendFr.isPending(siretActiveResult) || App_Types_Result$DvmAdminFrontendFr.isPending(siretBudgetLLD),
                        children: React.createElement(Order_Detail_Reselling_Edits_Inputs_CompanyFormInput$DvmAdminFrontendFr.make, {
                              onChange: (function (siren) {
                                  Curry._1(debounceFinalCustomer, siren);
                                  Curry._2(form.updateFinalCustomer, (function (input, finalCustomer) {
                                          return {
                                                  orderType: input.orderType,
                                                  siret: input.siret,
                                                  finalCustomer: finalCustomer,
                                                  shortCustomerName: input.shortCustomerName,
                                                  customerFirstName: input.customerFirstName,
                                                  orderDate: input.orderDate,
                                                  registrationDate: input.registrationDate,
                                                  deliveryDate: input.deliveryDate,
                                                  invoiceNumber: input.invoiceNumber,
                                                  invoiceClient: input.invoiceClient,
                                                  customerDiscount: input.customerDiscount,
                                                  agentCode: input.agentCode,
                                                  salesmanName: input.salesmanName,
                                                  easyPack: input.easyPack,
                                                  siretExists: input.siretExists,
                                                  siretActive: input.siretActive,
                                                  siretBudgetLLD: input.siretBudgetLLD,
                                                  finalCustomerExists: input.finalCustomerExists
                                                };
                                        }), siren.trim());
                                }),
                              value: form.input.finalCustomer,
                              validationResult: form.finalCustomerResult,
                              companies: match$3[0],
                              setCompanies: match$3[1],
                              className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput,
                              companyType: "siren",
                              label: Messages_Orders_Detail$DvmAdminFrontendFr.finalCustomer,
                              onBlur: (function (param) {
                                  Curry._1(setFormConfirmDisable, (function (param) {
                                          return false;
                                        }));
                                  checkFinalCustomer(form.input.finalCustomer);
                                }),
                              onFocus: (function (param) {
                                  Curry._1(setFormConfirmDisable, (function (param) {
                                          return true;
                                        }));
                                })
                            })
                      }),
                  item: true
                }));
}

var Api;

var Form;

var InputStyles;

var make = Order_Detail_Reselling_Edits_Inputs_Sirets;

export {
  Api ,
  Form ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
