// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as App_Modal$DvmAdminFrontendFr from "../../../../../common/components/App_Modal.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../context/user/User_Context.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Order_Detail_Correct_Api$DvmAdminFrontendFr from "../../correct/Order_Detail_Correct_Api.bs.js";
import * as Order_Detail_Reselling_Edits_Inputs$DvmAdminFrontendFr from "./Order_Detail_Reselling_Edits_Inputs.bs.js";

function sortCorrections(correction) {
  return Belt_List.sort(correction.content, (function (a, b) {
                return ReDate.compareDesc(Belt_Option.getWithDefault(a.timeStamp, new Date(0.0)), Belt_Option.getWithDefault(b.timeStamp, new Date(0.0)));
              }));
}

function Order_Detail_Reselling_Edits_Modal(Props) {
  var onClose = Props.onClose;
  var orderId = Props.orderId;
  var order = Props.order;
  var reloadPage = Props.reloadPage;
  var correctionsResult = Props.correctionsResult;
  var actualOrderType = Props.actualOrderType;
  var intl = ReactIntl.useIntl();
  var alert = ReactAlert.useAlert();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var match$2 = React.useState(function () {
        return /* NotStarted */0;
      });
  var saveResult = match$2[0];
  var match$3 = React.useState(function () {
        
      });
  var setLastCorrectionValues = match$3[1];
  var match$4 = React.useState(function () {
        
      });
  var setLastOrigOrderCorrectionValues = match$4[1];
  var match$5 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setOrigOrderCorrectionsResult = match$5[1];
  var origOrderCorrectionsResult = match$5[0];
  React.useEffect((function () {
          Order_Detail_Correct_Api$DvmAdminFrontendFr.getAllCorrections(alert, userManager, setOrigOrderCorrectionsResult, orderId.replace("R", ""), selectedRole);
        }), []);
  React.useEffect((function () {
          if (typeof origOrderCorrectionsResult === "number") {
            origOrderCorrectionsResult === /* NotStarted */0;
          } else if (origOrderCorrectionsResult.TAG === /* Ok */0) {
            var corr = origOrderCorrectionsResult._0;
            Curry._1(setLastOrigOrderCorrectionValues, (function (param) {
                    return Belt_List.get(sortCorrections(corr), 0);
                  }));
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: origOrderCorrectionsResult._0
                      }
                    }));
          }
        }), [origOrderCorrectionsResult]);
  React.useEffect((function () {
          if (typeof correctionsResult === "number") {
            correctionsResult === /* NotStarted */0;
          } else if (correctionsResult.TAG === /* Ok */0) {
            var corr = correctionsResult._0;
            Curry._1(setLastCorrectionValues, (function (param) {
                    return Belt_List.get(sortCorrections(corr), 0);
                  }));
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: correctionsResult._0
                      }
                    }));
          }
        }), [correctionsResult]);
  React.useEffect((function () {
          if (typeof saveResult === "number") {
            saveResult === /* NotStarted */0;
          } else if (saveResult.TAG === /* Ok */0) {
            Utils_Alert$DvmAdminFrontendFr.success(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {}));
            Curry._1(reloadPage, undefined);
            Curry._1(onClose, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: saveResult._0
                      }
                    }));
          }
        }), [saveResult]);
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Messages_Orders_Detail$DvmAdminFrontendFr.editOrder),
              maxWidth: "1000px",
              children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                    show: App_Types_Result$DvmAdminFrontendFr.isPending(saveResult) || App_Types_Result$DvmAdminFrontendFr.isPending(correctionsResult) || App_Types_Result$DvmAdminFrontendFr.isPending(origOrderCorrectionsResult),
                    children: React.createElement(Order_Detail_Reselling_Edits_Inputs$DvmAdminFrontendFr.make, {
                          orderId: orderId,
                          order: order,
                          setSaveResult: match$2[1],
                          lastCorrectionValues: match$3[0],
                          lastOrigOrderCorrectionValues: match$4[0],
                          actualOrderType: actualOrderType
                        })
                  })
            });
}

var Types;

var make = Order_Detail_Reselling_Edits_Modal;

export {
  Types ,
  sortCorrections ,
  make ,
}
/* react Not a pure module */
