// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_LabeledText$DvmAdminFrontendFr from "../../../common/components/App_LabeledText.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as Order_Detail_Utils$DvmAdminFrontendFr from "./Order_Detail_Utils.bs.js";
import * as Order_Detail_Styles$DvmAdminFrontendFr from "./Order_Detail_Styles.bs.js";
import * as Order_Detail_Layout_Setup$DvmAdminFrontendFr from "./Order_Detail_Layout_Setup.bs.js";
import * as Order_Detail_Latest_ParcStart$DvmAdminFrontendFr from "./dealerEdits/Order_Detail_Latest_ParcStart.bs.js";
import * as Order_Detail_Dealer_Edits_ParcExit$DvmAdminFrontendFr from "./dealerEdits/Order_Detail_Dealer_Edits_ParcExit.bs.js";
import * as Order_Detail_Dealer_Edits_CustomerDiscount$DvmAdminFrontendFr from "./dealerEdits/Order_Detail_Dealer_Edits_CustomerDiscount.bs.js";

function Order_Detail_Page_NonFleet_Detail_Items_Box(Props) {
  var order = Props.order;
  var orderId = Props.orderId;
  var reloadPage = Props.reloadPage;
  var lastCorrectionValues = Props.lastCorrectionValues;
  var siretBudgetLLD = Props.siretBudgetLLD;
  var correctionsResult = Props.correctionsResult;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  return React.createElement(Core.Grid, {
              children: Belt_Array.mapWithIndex(Order_Detail_Utils$DvmAdminFrontendFr.isResell(Order_Detail_Utils$DvmAdminFrontendFr.actualOrderType(order, lastCorrectionValues)) ? Order_Detail_Layout_Setup$DvmAdminFrontendFr.orderDetailResell(order, Order_Detail_Utils$DvmAdminFrontendFr.actualOrderType(order, lastCorrectionValues), lastCorrectionValues, intl, App_Types_Result$DvmAdminFrontendFr.getWithDefault(siretBudgetLLD, false)) : Order_Detail_Layout_Setup$DvmAdminFrontendFr.orderDetail(order, intl, match[0].selectedRole), (function (index, row) {
                      return React.createElement(Core.Grid, {
                                  children: Belt_Array.mapWithIndex(row, (function (index, element) {
                                          if (!Belt_Option.getWithDefault(element.show, true)) {
                                            return null;
                                          }
                                          var tmp = {
                                            label: element.label,
                                            children: Belt_Option.mapWithDefault(element.edit, element.val, (function (edit) {
                                                    switch (edit) {
                                                      case /* CustomerDiscount */0 :
                                                          return React.createElement(Order_Detail_Dealer_Edits_CustomerDiscount$DvmAdminFrontendFr.make, {
                                                                      orderId: orderId,
                                                                      order: order,
                                                                      reloadPage: reloadPage,
                                                                      lastCorrectionValues: lastCorrectionValues
                                                                    });
                                                      case /* ParcStartDate */1 :
                                                          return React.createElement(Order_Detail_Latest_ParcStart$DvmAdminFrontendFr.make, {
                                                                      order: order,
                                                                      lastCorrectionValues: lastCorrectionValues
                                                                    });
                                                      case /* ParcEndDate */2 :
                                                          return React.createElement(Order_Detail_Dealer_Edits_ParcExit$DvmAdminFrontendFr.make, {
                                                                      orderId: orderId,
                                                                      order: order,
                                                                      reloadPage: reloadPage,
                                                                      lastCorrectionValues: lastCorrectionValues,
                                                                      correctionsResult: correctionsResult
                                                                    });
                                                      
                                                    }
                                                  }))
                                          };
                                          var tmp$1 = Belt_Option.mapWithDefault(order.detail.orderStatus, Order_Detail_Utils$DvmAdminFrontendFr.isResell(Order_Detail_Utils$DvmAdminFrontendFr.actualOrderType(order, lastCorrectionValues)) && Belt_Option.getWithDefault(element.error, false) ? ({
                                                    TAG: /* Typography */0,
                                                    _0: "error"
                                                  }) : undefined, (function (orderStatus) {
                                                  if (orderStatus !== 1 || !Belt_Option.getWithDefault(element.error, false)) {
                                                    return ;
                                                  } else {
                                                    return {
                                                            TAG: /* Typography */0,
                                                            _0: "error"
                                                          };
                                                  }
                                                }));
                                          if (tmp$1 !== undefined) {
                                            tmp.colorLabel = Caml_option.valFromOption(tmp$1);
                                          }
                                          return React.createElement(Core.Grid, {
                                                      children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, tmp),
                                                      className: Order_Detail_Styles$DvmAdminFrontendFr.detailItem,
                                                      item: true,
                                                      key: String(index)
                                                    });
                                        })),
                                  className: Order_Detail_Styles$DvmAdminFrontendFr.detailRow,
                                  container: true,
                                  item: true,
                                  key: String(index)
                                });
                    })),
              className: Order_Detail_Styles$DvmAdminFrontendFr.detailBox,
              container: true,
              direction: "column",
              item: true
            });
}

var OrderUtils;

var Setup;

var make = Order_Detail_Page_NonFleet_Detail_Items_Box;

export {
  OrderUtils ,
  Setup ,
  make ,
}
/* react Not a pure module */
