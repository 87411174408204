// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as Route_Paths$DvmAdminFrontendFr from "../../../../route/Route_Paths.bs.js";

function routeToResellPage(resellingOrderId) {
  RescriptReactRouter.push(Route_Paths$DvmAdminFrontendFr.rView + Route_Paths$DvmAdminFrontendFr.rOrder + "/" + resellingOrderId);
}

function routeToOriginalOrderPage(resellingOrderId) {
  RescriptReactRouter.push(Route_Paths$DvmAdminFrontendFr.rView + Route_Paths$DvmAdminFrontendFr.rOrder + "/" + resellingOrderId.replace("R", ""));
}

export {
  routeToResellPage ,
  routeToOriginalOrderPage ,
}
/* RescriptReactRouter Not a pure module */
