// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Core from "@material-ui/core";
import * as ReactCountryFlag from "react-country-flag";
import * as EnvVar$DvmAdminFrontendFr from "../../../bindings/global/EnvVar.bs.js";
import * as App_Modal$DvmAdminFrontendFr from "../App_Modal.bs.js";
import * as Constants$DvmAdminFrontendFr from "../../Constants.bs.js";
import * as Intl_Locale$DvmAdminFrontendFr from "../../../intl/Intl_Locale.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../App_Typography.bs.js";
import * as Messages_TopMenu$DvmAdminFrontendFr from "../../../intl/messages/topMenu/Messages_TopMenu.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../context/user/User_Types_Utils.bs.js";
import * as App_Clickable_Element$DvmAdminFrontendFr from "../button/App_Clickable_Element.bs.js";

function countries(selectedRole) {
  return Belt_Array.concat(Belt_Array.concat([
                  /* En */0,
                  /* Fr */1
                ], EnvVar$DvmAdminFrontendFr.currentEnvironmentProd ? [] : [/* Es */4]), User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole) ? [
                /* EnWithID */2,
                /* FrWithID */3
              ] : []);
}

function toStringLocale(locale) {
  switch (locale) {
    case /* En */0 :
        return "EN";
    case /* Fr */1 :
        return "FR";
    case /* EnWithID */2 :
        return "EN (id)";
    case /* FrWithID */3 :
        return "FR (id)";
    case /* Es */4 :
        return "ES";
    
  }
}

function toFlagString(locale) {
  switch (locale) {
    case /* En */0 :
    case /* EnWithID */2 :
        return "GB";
    case /* Fr */1 :
    case /* FrWithID */3 :
        return "FR";
    case /* Es */4 :
        return "ES";
    
  }
}

var style = {
  width: "40px",
  height: "40px"
};

function App_TopMenu_Locale_Selector(Props) {
  var onClose = Props.onClose;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var dispatchUser = match[1];
  var match$1 = match[0];
  var locale = match$1.locale;
  var localStorage$1 = localStorage;
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Messages_TopMenu$DvmAdminFrontendFr.changeLanguage),
              minWidth: "450px",
              children: React.createElement(Core.Grid, {
                    children: Belt_Array.map(countries(match$1.selectedRole), (function (country) {
                            return React.createElement(Core.Grid, {
                                        children: React.createElement(App_Clickable_Element$DvmAdminFrontendFr.make, {
                                              onClick: (function (param) {
                                                  Curry._1(dispatchUser, {
                                                        TAG: /* StoreLocale */4,
                                                        _0: country
                                                      });
                                                  localStorage$1.setItem(Constants$DvmAdminFrontendFr.dvmeLocaleLocalStorageKey, Intl_Locale$DvmAdminFrontendFr.toString(country));
                                                  Curry._1(onClose, undefined);
                                                }),
                                              children: React.createElement(Core.Grid, {
                                                    alignItems: "center",
                                                    children: null,
                                                    container: true,
                                                    spacing: 2
                                                  }, React.createElement(Core.Grid, {
                                                        children: React.createElement(ReactCountryFlag.ReactCountryFlag, {
                                                              countryCode: toFlagString(country),
                                                              svg: true,
                                                              title: toFlagString(country),
                                                              style: style
                                                            }),
                                                        item: true
                                                      }), React.createElement(Core.Grid, {
                                                        children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                              children: toStringLocale(country),
                                                              variant: "h4"
                                                            }),
                                                        item: true
                                                      }))
                                            }),
                                        item: true,
                                        style: {
                                          border: "1px dashed",
                                          borderColor: Theme_Colors$DvmAdminFrontendFr.primaryBrown,
                                          margin: "10px",
                                          boxShadow: country === locale ? "0 2px 6px 3px rgba(0, 0, 0, 0.2)" : ""
                                        },
                                        key: toStringLocale(country)
                                      });
                          })),
                    container: true,
                    item: true,
                    justifyContent: "space-around"
                  })
            });
}

var make = App_TopMenu_Locale_Selector;

export {
  countries ,
  toStringLocale ,
  toFlagString ,
  style ,
  make ,
}
/* react Not a pure module */
